<template>
  <div>
    <v-chip
      class="mx-1"
      :class="params.data.is_completed ? 'green--text' : 'white--text'"
      :style="
        params.data.is_completed
          ? { backgroundColor: '#a4fbdb !important' }
          : { backgroundColor: '#9b182f !important' }
      "
      depressed
      rounded
      small
    >
      <v-icon
        :color="params.data.is_completed ? 'light_pgreen' : 'white'"
        class="pr-2"
        small
        >mdi-check</v-icon
      >
      <span v-if="params.data.is_completed">Processed</span>
      <span v-else>Not Processed</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
