import { render, staticRenderFns } from "./PlanInfoNew.vue?vue&type=template&id=8f2e7256&scoped=true&"
import script from "./PlanInfoNew.vue?vue&type=script&lang=js&"
export * from "./PlanInfoNew.vue?vue&type=script&lang=js&"
import style0 from "./PlanInfoNew.vue?vue&type=style&index=0&id=8f2e7256&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f2e7256",
  null
  
)

export default component.exports