import { systemLogs, zones } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getZonesList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(zones.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSelectProjectZones(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${zones.base}display_zones/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getZonesDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${zones.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createZoneObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${zones.base}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateZoneObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${zones.base}${payload.id}/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteZoneObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${zones.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectZoneLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(systemLogs, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
