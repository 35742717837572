<template>
  <v-navigation-drawer
    permanent
    width="300"
    class="primary"
    style="z-index: 11 !important"
    dark
    clipped
    app
    mini-variant
    expand-on-hover
  >
    <v-list dense>
      <v-list-item v-for="(item, i) in navMenu" :key="i" class="pa-0 ma-0">
        <v-list-group
          v-if="item && item.items && item.items.length > 0"
          class="w-100 v-list-sub-group white--text"
        >
          <template v-slot:activator>
            <v-list-item class="border-bottom-maroon" router exact>
              <v-list-item-action class="mr-2">
                <v-icon class="text-h6 font-weight-light">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="text-transform: capitalize">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            router
            exact
            v-for="(subItem, index) in item.items"
            :key="index"
            :to="{ name: subItem.name }"
            class="primary darken-1"
          >
            <v-list-item-action class="mr-2">
              <v-icon class="text-h6 font-weight-light">
                {{ subItem.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title style="text-transform: capitalize">
                {{ subItem.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          class="border-bottom-maroon"
          :to="{ name: item.name }"
          router
          exact
        >
          <v-list-item-action class="mr-2">
            <v-icon class="text-h6 font-weight-light">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title style="text-transform: capitalize">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      permission: JSON.parse(localStorage.getItem("permissions")),
      menu: [
        {
          title: "Dashboard",
          icon: "mdi-monitor-dashboard",
          to: "/",
          key: "project",
          name: "dashboard",
        },
        {
          title: "Operations",
          icon: "mdi-cog-transfer-outline",
          to: "/operations",
          key: "trip",
          name: "operations",
        },
        {
          title: "Trips",
          icon: "mdi-truck-fast-outline",
          to: "/trips",
          key: "trip",
          name: "trip",
        },
        /*{
          title: "Trip Planning",
          icon: "mdi-map-marker-distance",
          to: "/trip_planning_old",
          key: "trip",
          name: "plan_trips_old",
        },*/
        {
          title: "Trip Planning",
          icon: "mdi-map-marker-distance",
          to: "/trip_planning",
          key: "trip",
          name: "plan_trips_new",
        },
        {
          title: "Trip Planning Templates",
          icon: "mdi-file-cad",
          to: "/trip_planning_templates",
          key: "trip",
          name: "trip_planning_templates",
        },
        {
          title: "Drivers",
          icon: "mdi-card-account-details-outline",
          to: "/drivers",
          key: "driver",
          name: "drivers",
        },
        {
          title: "Orders",
          icon: "mdi-archive-outline",
          to: "/orders",
          key: "order",
          name: "orders",
        },
        {
          title: "Order History",
          icon: "mdi-archive-search",
          to: "/order_history",
          key: "order",
          name: "order_history",
        },
        {
          title: "Integration Log",
          icon: "mdi-archive-edit-outline",
          to: "/integration",
          key: "order",
          name: "integration",
        },
        {
          title: "Vehicles",
          icon: "mdi-truck",
          to: "/vehicles",
          key: "vehicle",
          name: "vehicles",
        },
        {
          title: "Projects",
          icon: "mdi-file-document-outline",
          to: "/projects",
          key: "project",
          name: "projects",
        },
        {
          title: "Zones",
          icon: "mdi-shape-square-plus",
          to: "/zones",
          key: "zone",
          name: "zones",
        },
        {
          title: "Statuses",
          icon: "mdi-list-status",
          to: "/statuses",
          key: "order",
          name: "statuses",
        },
        {
          title: "Item Master",
          icon: "mdi-inbox-multiple",
          to: "/items",
          key: "itemmaster",
          name: "items",
        },
        {
          title: "Customers",
          icon: "mdi-account-group",
          to: "/customer_addresses",
          key: "customeraddress",
          name: "customer_addresses",
        },
        {
          title: "Reports",
          icon: "mdi-chart-areaspline",
          items: [
            {
              title: "Order Report",
              icon: "mdi-archive-clock",
              to: "/reports/order_report",
              key: "project",
              name: "order_report",
            },
            {
              title: "Trip Report",
              icon: "mdi-map-marker-distance",
              to: "/reports/trip_report",
              key: "project",
              name: "trip_report",
            },
            {
              title: "Driver Report",
              icon: "mdi-account-tie",
              to: "/reports/driver_report",
              key: "project",
              name: "driver_report",
            },
            {
              title: "Vehicle Report",
              icon: "mdi-bus-clock",
              to: "/reports/vehicle_report",
              key: "project",
              name: "vehicle_report",
            },
            {
              title: "Driver Expense Report",
              icon: "mdi-chart-histogram",
              to: "/reports/fms_report",
              key: "project",
              name: "fms_report",
            },
            {
              title: "Fleet Utilization Report",
              icon: "mdi-truck-cargo-container",
              to: "/reports/fleet_utilization_report",
              key: "project",
              name: "fleet_utilization_report",
            },
            {
              title: "Capacity Utilization Report",
              icon: "mdi-rv-truck",
              to: "/reports/trip_volume_utilization",
              key: "project",
              name: "trip_volume_utilization",
            },
          ],
        },
        {
          title: "Users",
          icon: "mdi-account-check",
          to: "/users",
          key: "dashuser",
          name: "users",
        },
        {
          title: "Roles",
          icon: "mdi-account-key",
          to: "/roles",
          key: "role",
          name: "roles",
        },
        {
          title: "Support",
          icon: "mdi-face-agent",
          to: "/app/admin/support",
          key: "ticket",
          name: "support",
        },
      ],
      userType: null,
      currentProfile: null,
      sideNav: [],
      groupOpened: [],
    };
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    navMenu() {
      if (this.permission && Object.keys(this.permission).length != 0) {
        return this.menu.filter((obj) => {
          if (obj.key) {
            return this.permission[obj.key]["view"];
          } else {
            if (obj.items && obj.items.length > 0) {
              obj.items = obj.items.filter((subMenu) => {
                if (subMenu.key) {
                  return this.permission[subMenu.key]["view"];
                } else {
                  return false;
                }
              });
            }
            if (obj.items && obj.items.length > 0) {
              return true;
            } else {
              return false;
            }
          }
        });
      } else {
        return [];
      }
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.v-list-sub-group .border-bottom-maroon {
  border: none !important;
}
.v-list-sub-group {
  .v-list-group__header {
    padding: 0 !important;
  }
}
</style>
