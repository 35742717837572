<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 edit white--text"
          small
          v-bind="attrs"
          v-on="on"
          @click="btnedit()"
          depressed
        >
          <v-icon small class="ma-0">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 grey white--text"
          depressed
          small
          v-bind="attrs"
          v-on="on"
          @click="btnClickedHandler"
        >
          <v-icon small class="ma-0"> mdi-eye </v-icon>
        </v-btn>
      </template>
      <span>View</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  methods: {
    btnedit() {
      this.params.context.parentComponent.editTicket(this.params.data.id);
    },
    btnClickedHandler() {
      this.params.context.parentComponent.viewTicket(this.params.data.id);

      // this.$api.support.getSupportTicketObject(this.params.data.id).then(() => {
      //   this.$api.support
      //     .getSupportTicketObjectLogs(this.params.data.id)
      //     .then((res) => {
      //       this.$router.push({
      //         path: "/app/admin/ticket-support",
      //       });
      //     });
      // });
    },
  },
};
</script>