import UsersIndex from "../pages/admin/users/index.vue";

const Users = [
  {
    path: "users",
    name: "users",
    component: UsersIndex,
  },
];

export default Users;
