<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="edit text-white mr-1"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.project && userPermissions.project.change"
          @click="btnClickedHandler"
          depressed
        >
          <v-icon small class="ma-0" color="white">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="grey text-white mr-1"
          small
          v-if="userPermissions.project && userPermissions.project.change"
          v-bind="attrs"
          v-on="on"
          @click="viewZones()"
          depressed
        >
          <v-icon small class="ma-0" color="white">mdi-eye</v-icon>
        </v-btn>
      </template>
      <span>View Project Zones</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="grey text-white"
          small
          v-if="userPermissions.project && userPermissions.project.change"
          v-bind="attrs"
          v-on="on"
          @click="viewLogs"
          depressed
        >
          <v-icon small class="ma-0" color="white">mdi-alpha-l-box</v-icon>
        </v-btn>
      </template>
      <span>View Project Logs</span>
    </v-tooltip>
  </div>
</template>


<script>
// import { makeInstance, encryptLocal } from "~/assets/js/encryptLocal";

export default {
  data() {
    return {
      loader: false,
      openProjectForm: false,
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  computed: {},
  methods: {
    btnClickedHandler() {
      // this.params.clicked(this.params.value);
      this.params.context.parentComponent.editProject(this.params.data.id);
    },
    viewZones() {
      this.params.context.parentComponent.viewZonesHandler(this.params.data);
    },
    viewLogs() {
      this.params.context.parentComponent.viewLogsHandler({
        project: this.params.data.project_id,
      });
    },
  },
  // beforeCreate() {
  //   if (encryptLocal == null) {
  //     makeInstance(localStorage.getItem("user"));
  //   }
  // },
};
</script>