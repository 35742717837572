<template>
  <ListLayout
    ref="listLayout"
    title="Item Master"
    :tableData="itemsList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :hasFilter="true"
    :context="context"
    @getList="getItemList"
    :tableScrollable="false"
    localStorageKey="items_columns"
    :isColumnShowVisibility="true"
  >
    <template #listAction>
      <v-row no-gutters>
        <v-col class="d-flex justify-end align-center">
          <v-btn
            v-if="
              permissions &&
              permissions.itemmaster &&
              permissions.itemmaster.add
            "
            small
            depressed
            class="primary mr-1"
            @click="addItem()"
          >
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Add Item</span>
          </v-btn>

          <v-menu
            offset-y
            rounded="lg"
            elevation="0"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  permissions &&
                  permissions.itemmaster &&
                  (permissions.itemmaster.add || permissions.itemmaster.change)
                "
                small
                depressed
                v-bind="attrs"
                v-on="on"
                Dropdown
                class="primary mr-1"
              >
                <v-icon small class="mr-1">mdi-arrow-up-bold</v-icon>
                <span>Bulk Operations</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="showBulkDialog('items')"
                v-if="
                  permissions &&
                  permissions.itemmaster &&
                  permissions.itemmaster.add
                "
              >
                <v-list-item-icon class="text-center d-flex align-center mx-0">
                  <v-icon class="primary--text" small
                    >mdi-table-arrow-up</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="ma-0">
                  <v-list-item-title class="text-capitalize text-center">
                    Bulk Upload
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="showBulkDialog('items_update')"
                v-if="
                  permissions &&
                  permissions.itemmaster &&
                  permissions.itemmaster.change
                "
              >
                <v-list-item-icon class="text-center d-flex align-center mx-0">
                  <v-icon class="primary--text" small
                    >mdi-microsoft-excel</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="ma-0">
                  <v-list-item-title class="text-capitalize text-center">
                    Bulk Update
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- <v-btn
            v-if="
              permissions &&
              permissions.itemmaster &&
              permissions.itemmaster.add
            "
            depressed
            small
            class="primary mr-1"
            @click="itemListBulkUpload = true"
          >
            <v-icon small class="mr-1">mdi-arrow-up-bold</v-icon>
            <span>Bulk Upload</span>
          </v-btn> -->
          <v-btn
            small
            depressed
            class="primary"
            @click="openDownloadDataDialog = true"
          >
            <v-icon small class="mr-1">mdi-arrow-down-bold</v-icon>
            <span>Download All Items</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #leftFilterSlot>
      <v-col class="d-flex justify-start pa-0">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="success"
              overlap
              small
              dot
              :value="appliedFilters && appliedFilters.length > 0"
            >
              <v-btn
                small
                fab
                depressed
                color="primary"
                class="rounded ml-2"
                @click="openItemFilterDialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="">mdi-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Items Filters</span>
        </v-tooltip>
      </v-col>
    </template>
    <template #dialogs>
      <ItemFilter
        ref="itemFilterDialog"
        v-model="openItemFilterDialog"
        @itemFilterChanged="$refs.listLayout.searchTriggered()"
      />
      <ItemsForm
        ref="itemForm"
        v-model="openItemForm"
        :formType="formType"
        @refreshList="$refs.listLayout.searchTriggered()"
      />
      <BulkUpload
        v-model="itemListBulkUpload"
        :uploadTo="bulkDialogType"
        @refreshList="getItemList({ limit: 10, offset: 0 })"
      />
      <DownloadAllData parent="items" v-model="openDownloadDataDialog" />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import itemEdit from "@/components/common/aggrid/buttons/itemEdit.vue";
import { downloadBlobData } from "@/assets/utils.js";
import ItemsForm from "@/components/items/ItemsForm.vue";
import BulkUpload from "@/components/common/BulkUpload.vue";
import ItemFilter from "@/components/items/ItemFilter.vue";
import DownloadAllData from "@/components/common/DownloadAllData.vue";
import { bus } from "@/main.js";

export default {
  name: "itemsIndex",
  components: {
    ListLayout,
    itemEdit,
    ItemsForm,
    BulkUpload,
    ItemFilter,
    DownloadAllData,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      itemsList: [],
      filters: {},
      customFilter: {},
      openItemForm: false,
      itemListBulkUpload: false,
      openItemFilterDialog: false,
      bulkDialogType: "",
      formType: "Add",

      headerSelected: [
        {
          headerName: "Item number",
          field: "item_no",
          resizable: true,

          pinned: "left",
          maxWidth: 150,
          minWidth: 150,
          hide: false,
        },
        {
          headerName: "Item name",
          field: "item_description",
          resizable: true,
          width: 500,
          hide: false,
        },
        {
          headerName: "Unit",
          field: "unit",
          hide: false,
        },
        {
          headerName: "Item Group",
          field: "storage_type",
          hide: false,
        },
        {
          headerName: "Length",
          field: "length",
          hide: false,
        },
        {
          headerName: "Width",
          field: "width",
          hide: false,
        },
        {
          headerName: "Height",
          field: "height",
          hide: false,
        },
        {
          headerName: "Weight",
          field: "weight",
          hide: false,
        },
        {
          headerName: "L*W*H (Volume)",
          field: "cbm",
          hide: false,
        },
      ],

      openDownloadDataDialog: false,
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        ...(this.permissions.itemmaster &&
        (this.permissions.itemmaster.change ||
          this.permissions.itemmaster.view ||
          this.permissions.itemmaster.delete)
          ? [
              {
                headerName: "Actions",
                field: "actions",
                pinned: "right",
                minWidth: 150,
                sortable: false,
                width: 250,
                cellRenderer: "itemEdit",
              },
            ]
          : []),
      ];
    },
    context() {
      return { parentComponent: this };
    },
    appliedFilters() {
      return Object.keys(this.customFilter).map((filter) => {
        return filter.replace(/\_/, " ");
      });
    },
  },
  methods: {
    showBulkDialog(type) {
      this.bulkDialogType = type;
      this.itemListBulkUpload = true;
    },
    downloadAllItems(params) {
      bus.$emit("showLoader", true);
      this.$api.items
        .downloadItemSheet(params)
        .then((result) => {
          downloadBlobData(result, "Item Masters.xls");
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "All Items Downloaded",
            color: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.message) {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          } else {
            bus.$emit("showToastMessage", {
              message: "Something went wrong!",
              color: "error",
            });
          }
        });
    },


    getItemList(params = {}) {
      let filter = localStorage.getItem("itemFilters");
      if (!filter) {
        filter = {};
      }
      if (typeof filter == typeof "string") {
        filter = JSON.parse(filter);
      }
      this.customFilter = filter;
      params = {
        ...params,
        ...this.filters,
        ...this.customFilter,
      };

      if (params && params.storage_type && Array.isArray(params.storage_type)) {
        params.storage_type = params.storage_type.join(",");
      }
      if (params && params.unit && Array.isArray(params.unit)) {
        params.unit = params.unit.join(",");
      }
      if (params && params.ordering && params.sorting) {
        if (params.sorting == "descending") {
          params.ordering = "-" + params.ordering;
        }
      }
      bus.$emit("showLoader", true);
      this.$api.items
        .getItemList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.itemsList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },

    addItem() {
      this.formType = "Add";
      this.openItemForm = true;
    },

    editItem(id) {
      this.formType = "Edit";
      this.openItemForm = true;
      this.$refs.itemForm.getItemDetails(id);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
  },

  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
