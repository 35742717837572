<template>
  <v-row no-gutters>
    <v-col cols="3" lg="3" md="3">
      <div class="border-right-light_grey">
        <v-row no-gutters>
          <v-col
            id="orderListHeaderContainer"
            cols="12"
            class="background-light_grey px-4 pt-4"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                class="px-2 pt-2 d-flex align-center justify-space-between"
              >
                <span class="text-h5 text-uppercase font-weight-bold text-grey">
                  Orders
                </span>
                <v-chip class="primary font-weight-bold" small>
                  Total Orders : {{ totalCount }}
                </v-chip>
              </v-col>
              <v-scale-transition mode="out-in"> </v-scale-transition>
              <v-col cols="12" class="d-flex px-2 pt-2 pb-4">
                <InputField
                  label="Search here.."
                  prepend-inner-icon="mdi-magnify"
                  hide-details="auto"
                  outlined
                  dense
                  aria-autocomplete="false"
                  class="background-white"
                  v-model="orderFilter.search"
                  @keydown.enter="
                    syncOrderFilter($event.target.value, 'search')
                  "
                ></InputField>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      fab
                      depressed
                      color="primary"
                      class="ml-1 rounded"
                      v-bind="attrs"
                      v-on="on"
                      @click="getOrderList(true)"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-capitalize">Refresh</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      bordered
                      color="success"
                      overlap
                      small
                      dot
                      :value="appliedFilters && appliedFilters.length !== 0"
                    >
                      <v-btn
                        small
                        fab
                        depressed
                        color="primary"
                        class="ml-1 rounded"
                        @click="openOrderFilterDialog = true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-filter</v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <span class="text-capitalize">Order Filter</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-menu
                        transition="slide-y-transition"
                        bottom
                        right
                        left
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            fab
                            depressed
                            color="primary"
                            class="ml-1 rounded"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense style="width: 250px">
                          <v-list-item
                            @click="addOrder"
                            v-if="
                              userPermissions &&
                              userPermissions.order &&
                              userPermissions.order.add
                            "
                          >
                            <v-list-item-title class="text-capitalize"
                              >Create Order</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item @click="openBulkUploadDialog('orders')">
                            <v-list-item-title
                              >Bulk Upload Orders</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                            @click="openBulkUploadDialog('b2c_orders')"
                          >
                            <v-list-item-title
                              >Bulk Upload B2C Orders</v-list-item-title
                            >
                          </v-list-item>

                          <v-list-item
                            @click="openBulkUploadDialog('orders_update')"
                          >
                            <v-list-item-title
                              >Bulk Update Orders</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                  <span class="text-capitalize">Create Order</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                class="px-2 pb-2"
                v-if="appliedFilters && appliedFilters.length !== 0"
              >
                <v-chip
                  small
                  close
                  dark
                  class="mr-1 mb-1 text-capitalize background-black"
                  v-for="(filter, index) in appliedFilters"
                  :key="index"
                  @click:close="removeFilter(filter)"
                >
                  {{
                    filter && filter == "project id"
                      ? "project"
                      : filter == "has warning"
                      ? "Has Warning"
                      : filter
                  }}
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-row class="pb-2 px-2">
                  <v-col cols="6" class="d-flex justify-start">
                    <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      :input-value="
                        selectedOrders.length != 0 &&
                        selectedOrders.length ==
                          Orders.filter(
                            (order) => Object.keys(order).length != 0
                          ).length
                          ? true
                          : false
                      "
                      @change="selectAllOrders($event)"
                    ></v-checkbox>

                    <span class="text-capitalize">Select All</span>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex justify-end align-center px-2 pt-2"
                    v-if="
                      selectedOrders.length &&
                      userPermissions.order &&
                      userPermissions.order.delete
                    "
                  >
                    <span class="pr-3">
                      Selected : {{ selectedOrders.length }}
                    </span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            fab
                            x-small
                            depressed
                            color="primary"
                            class="rounded mr-2"
                            @click="deleteOrders"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon v-if="hover">mdi-delete-empty</v-icon>
                            <v-icon v-else>mdi-delete</v-icon>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span class="text-capitalize"
                        >Delete Selected Orders?</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="d-flex px-2 pb-4" v-if="loaderProgress">
                <v-scroll-x-transition mode="out-in">
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-scroll-x-transition>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            ref="orderParent"
            cols="12"
            class="px-4 pb-4 mt-4 overflow-y-auto"
            @scroll="scrollControlOrder"
            style="height: calc(100vh - 250px)"
          >
            <div v-for="(order, index) in Orders" :key="index">
              <OrderDetailCard
                @selectOrder="getOrderDetails(order.id)"
                @orderSelected="orderSelected($event, order.id)"
                @openReasonDialog="orderWarningDialog"
                :selectedOrders="selectedOrders"
                :order="order"
              />
            </div>
          </v-col>
        </v-row>
        <OrderFilter
          ref="orderFilterDialog"
          v-model="openOrderFilterDialog"
          @filterChanged="filterChanged"
        />

        <OrderForm
          v-model="openOrderForm"
          :formType="formType"
          @refreshList="getOrderList(true)"
        />
      </div>
    </v-col>
    <v-col :cols="mdColumn" class="position-relative expand-transation">
      <Gmap
        :mapHeight="containerHeight"
        ref="ordersMap"
        mapWidth="auto"
        :clusterMarkers="false"
      />
      <div class="rounded-l-lg position-absolute details-toggle-btn">
        <v-btn
          :class="`rounded-0 rounded-l-lg background-${orderStatus}`"
          fab
          small
          depressed
          v-if="defaultSet"
          @click="changeMapSize"
        >
          <v-icon color="white" :class="rotatedIcon">
            mdi-arrow-right-bold
          </v-icon>
        </v-btn>
      </div>
      <Mapinfo :legends="legends" />
    </v-col>
    <v-col cols="3" v-if="setmdColumn">
      <OrderDetails
        :order="orderDetailsObj"
        @refreshData="refreshData"
        @refreshOrderDetails="getOrderDetails"
      />
    </v-col>

    <div id="dialogs">
      <BaseDialogForm title="Warnings" v-model="openWarningDialog">
        <template #content>
          <v-row no-gutters>
            <ul>
              <v-col
                class="py-1"
                cols="12"
                v-for="(item, i) in orderWarning"
                :key="i"
              >
                <h6 class="body-2">
                  <li class="px-1 py-0 text-uppercase">{{ item }}</li>
                </h6>
              </v-col>
            </ul>
          </v-row>
        </template>
      </BaseDialogForm>
      <BulkUpload
        v-model="bulkUpload"
        :upload-to="uploadTo"
        @refreshList="getOrderList(true)"
      />
    </div>
  </v-row>
</template>

<script>
/* eslint-disable no-useless-escape */
// import Gmap from "@/components/common/Gmap.vue";
import Gmap from "@/components/common/Gmap.vue";
import OrderDetails from "@/components/orders/OrderDetails.vue";
import OrderForm from "@/components/orders/OrderForm.vue";
import OrderFilter from "@/components/orders/OrderFilter.vue";
import OrderDetailCard from "@/components/orders/OrderDetailCard.vue";
import { bus } from "@/main.js";
import BulkUpload from "@/components/common/BulkUpload.vue";
import {
  WarehouseIcon,
  OrderUnassignedIcon,
  OrderAssignedIcon,
  OrderPickupIcon,
  OrderSuccessfulIcon,
  OrderFailedIcon,
  OrderEnrouteIcon,
  OrderCancelledIcon,
} from "@/assets/mapIcons/icons";
import Mapinfo from "@/components/common/MapInfo.vue";
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";

export default {
  name: "ordersIndex",
  components: {
    OrderDetailCard,
    Gmap,
    OrderDetails,
    OrderForm,
    Mapinfo,
    OrderFilter,
    BulkUpload,
    BaseDialogForm,
  },
  data() {
    return {
      setmdColumn: false,
      mdColumn: 9,
      rotatedIcon: "mdi-rotate-180",
      defaultSet: false,
      orderStatus: "",
      openOrderForm: false,
      openOrderFilterDialog: false,
      bulkUpload: false,
      orderDetailsObj: {},
      selectedOrders: [],
      orderFilter: {},
      Orders: [],
      uploadTo: "",
      openWarningDialog: false,
      loaderProgress: false,
      orderWarning: [],
      totalCount: 0,
      formType: "Add",
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
      legends: [
        { text: "Warehouse", marker: WarehouseIcon },
        { text: "Unassigned", marker: OrderUnassignedIcon },
        { text: "Assigned", marker: OrderAssignedIcon },
        { text: "Shipped", marker: OrderPickupIcon },
        { text: "Delivered", marker: OrderSuccessfulIcon },
        { text: "Returned", marker: OrderFailedIcon },
        { text: "Cancelled", marker: OrderCancelledIcon },
        { text: "Partially Delivered", marker: OrderEnrouteIcon },
      ],
    };
  },
  computed: {
    containerHeight() {
      return `${window.innerHeight - 64}px`;
    },
    appliedFilters() {
      return Object.keys(this.orderFilter).map((filter) => {
        return filter.replace(/\_/, " ");
      });
    },
  },
  methods: {
    openBulkUploadDialog(uploadType) {
      this.uploadTo = uploadType;
      this.bulkUpload = true;
    },
    selectAllOrders(isChecked) {
      if (isChecked) {
        this.selectedOrders = [];
        this.Orders.forEach((order) => {
          this.selectedOrders.push(order.id);
        });
      } else {
        this.selectedOrders = [];
      }
    },
    syncOrderFilter(value, key) {
      let filters = localStorage.getItem("orderFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      filters[key] = value;
      localStorage.setItem("orderFilters", JSON.stringify(filters));
      this.filterChanged();
    },
    refreshData(id = null) {
      this.getOrderList(true);
      if (id) {
        this.getOrderDetails(id);
      }
    },
    removeFilter(key) {
      let actualKey = key.replace(" ", "_");
      let orderFilters = JSON.parse(localStorage.getItem("orderFilters"));
      delete orderFilters[actualKey];
      this.orderFilter = orderFilters;
      localStorage.setItem("orderFilters", JSON.stringify(orderFilters));
      this.filterChanged();
    },
    deleteOrders(params = {}) {
      let deleteOrdersId = this.selectedOrders.join(",");
      params = {
        ...params,
        order_ids: deleteOrdersId,
      };
      bus.$emit("showLoader", true);
      this.$api.orders
        .deleteOrder(params)
        .then((res) => {
          bus.$emit("showLoader", false);

          this.selectedOrders = [];
          bus.$emit("showToastMessage", {
            message: "Order(s) deleted successfully!",
            color: "success",
          });
          this.getOrderList(true);
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showLoader", false);
        });
    },
    orderSelected(e, id) {
      if (e) {
        this.selectedOrders.push(id);
      } else {
        this.selectedOrders.splice(
          this.selectedOrders.indexOf(this.selectedOrders.find((v) => v == id)),
          1
        );
      }
    },
    orderWarningDialog(reason) {
      this.openWarningDialog = true;
      this.orderWarning = reason;
    },
    filterChanged() {
      let filters = localStorage.getItem("orderFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.orderFilter = filters;
      this.getOrderList(true);
    },
    getOrderFilters() {
      let filters = localStorage.getItem("orderFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if ("project_id" in filters && filters != null) {
        filters.project_id = filters.project_id.join(",");
      }
      if ("status" in filters && filters.status != null) {
        filters.status = filters.status.toString();
      }
      if ("order_segment" in filters && filters.order_segment != null) {
        filters.order_segment = filters.order_segment.toString();
      }
      if ("order_sub_segment" in filters && filters.order_sub_segment != null) {
        filters.order_sub_segment = filters.order_sub_segment.toString();
      }
      if ("has_warning" in filters && filters.has_warning == true) {
        filters.has_warning = filters.has_warning.toString();
      }

      return filters;
    },
    scrollControlOrder() {
      let elem = this.$refs.orderParent;
      if (elem && !this.loaderProgress) {
        const maxPossibleScroll = elem.scrollHeight - elem.offsetHeight - 50;
        if (maxPossibleScroll > 0) {
          if (maxPossibleScroll - Math.ceil(elem.scrollTop) < 0) {
            this.getOrderList(false);
            return;
          }
        }
      }
    },

    getOrderList(reload, params = {}) {
      params = {
        ...params,
        ...this.getOrderFilters(),
        offset: reload ? 0 : this.Orders.length,
        limit: 20,
      };
      if (reload || this.totalCount != this.Orders.length) {
        this.loaderProgress = true;

        this.$api.orders
          .getOrderList(params)
          .then((res) => {
            if (reload) {
              this.Orders = res.data.results;
            } else {
              this.Orders = [...this.Orders, ...res.data.results];
            }
            this.totalCount = res.count;
            this.loaderProgress = false;
          })
          .catch((err) => {
            console.error(err);
            this.loaderProgress = false;
            bus.$emit("showToastMessage", {
              message: "Couldn't fetch order list",
              color: "error",
            });
          });
      }
    },
    addOrder() {
      this.formType = "Add";
      this.openOrderForm = true;
    },
    getOrderDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.orders
        .getOrderDetails(id)
        .then((result) => {
          let data = result.data;
          this.orderDetailsObj = result.data;
          this.openOrderDetails({
            obj: data,
            orders: [
              {
                address: data.address,
                coordinates: [
                  data.coordinates.longitude,
                  data.coordinates.latitude,
                ],
                status: data.status,
                reference_number: data.reference_number,
                actual_delivery_location: data.actual_delivery_location,
              },
            ],
            warehouse_details: data.warehouse_details,
          });
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    openOrderDetails(data) {
      this.orderStatus = data.orders[0].status;
      this.$refs.ordersMap.clearMarker();
      this.$refs.ordersMap.clearGeoJson();
      this.$refs.ordersMap.loadRoutes(
        null,
        data.orders,
        data.warehouse_details
      );

      this.setmdColumn = true;
      this.defaultSet = true;
      this.mdColumn = 6;
      this.rotatedIcon = "";
    },
    changeMapSize() {
      if (this.setmdColumn) {
        this.setmdColumn = false;
        this.mdColumn = 9;
        this.rotatedIcon = "mdi-rotate-180";
      } else {
        this.setmdColumn = true;
        this.mdColumn = 6;
        this.rotatedIcon = "";
      }
    },
  },
  mounted() {
    let filters = localStorage.getItem("orderFilters");
    if (!filters) {
      filters = {};
    }
    if (typeof filters == typeof "string") {
      filters = JSON.parse(filters);
    }
    this.orderFilter = filters;
    this.getOrderList(true);
  },
};
</script>

<style>
.details-toggle-btn {
  top: 1px;
  right: 0px;
}
.expand-transation {
  transition: 0.3s;
}
</style>