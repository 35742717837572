<template>
  <BaseDialogForm
    v-model="ticketFormDialog"
    :title="`${formType} Ticket`"
    maxWidth="60vw"
  >
    <template #content>
      <v-form
        v-if="ticketFormDialog"
        v-model="isValid"
        ref="ticketForm"
        id="ticketForm"
      >
        <v-row class="pt-4">
          <v-col cols="12">
            <InputField
              hide-details="auto"
              label="Title*"
              name="title"
              v-model="ticketDetails.title"
              :error-messages="
                formErrors && formErrors.title ? formErrors.title : ''
              "
              @input="
                formErrors && formErrors.title ? delete formErrors.title : ''
              "
              :rules="[
                (v) => (!!v && v.trim().length > 0) || 'Title  is Required',
              ]"
              prepend-icon=""
              outlined
            />
          </v-col>

          <v-col cols="6" lg="4">
            <SelectField
              outlined
              hide-details="auto"
              :items-list="moduleName"
              item-text="title"
              name="module"
              item-value="value"
              :error-messages="
                formErrors && formErrors.module ? formErrors.module : ''
              "
              @change="
                formErrors && formErrors.module ? delete formErrors.module : ''
              "
              label="Module Name*"
              v-model="ticketDetails.module"
              :rules="[(v) => !!v || 'Module Name is Required']"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>

          <v-col cols="6" lg="4">
            <SelectField
              outlined
              hide-details="auto"
              :items-list="priority"
              name="priority"
              v-model="ticketDetails.priority"
              item-text="title"
              item-value="value"
              :error-messages="
                formErrors && formErrors.priority ? formErrors.priority : ''
              "
              @change="
                formErrors && formErrors.priority
                  ? delete formErrors.priority
                  : ''
              "
              label="Priority*"
              :rules="[(v) => !!v || 'Priority  is Required']"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>

          <v-col cols="6" lg="4" v-if="formType == 'Add'">
            <FileFieldVue
              dense
              v-model="file"
              multiple="multiple"
              hide-details="auto"
              label="Upload Document*"
              :rules="
                formType == 'add' || formType == 'Add'
                  ? [(v) => !!v || 'Upload Document  is Required']
                  : []
              "
              outlined
            ></FileFieldVue>
          </v-col>
          <v-col cols="6" lg="4" v-if="formType == 'Edit'">
            <SelectField
              hide-details="auto"
              :items-list="ticketStatus"
              name="status"
              v-model="ticketDetails.status"
              item-text="title"
              item-value="value"
              label="Status"
              v-if="formType == 'Edit'"
              :menu-props="{ offsetY: true }"
              outlined
            ></SelectField>
          </v-col>

          <v-col cols="12">
            <TextAreaField
              hide-details="auto"
              label="Description*"
              name="description"
              v-model="ticketDetails.description"
              :rules="[
                (v) =>
                  (!!v && v.trim().length > 0) || 'Description  is Required',
              ]"
              prepend-icon=""
              outlined
            ></TextAreaField>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn
        type="submit"
        class="primary text-uppercase mr-3"
        :disabled="!isValid"
        @click.prevent="submitTicketForm()"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'add' || formType == 'Add'"
        @click="clear()"
        class="primary text-uppercase"
      >
        <span>Reset</span>
      </v-btn>
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { moduleName, priority, ticketStatus } from "@/utils/choices.js";
import InputField from "@/components/BaseFormComponents/InputField.vue";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import FileFieldVue from "@/components/BaseFormComponents/FileField.vue";
import TextAreaField from "../BaseFormComponents/TextAreaField.vue";
import { bus } from "@/main.js";

export default {
  components: {
    BaseDialogForm,
    InputField,
    SelectField,
    FileFieldVue,
    TextAreaField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      moduleName,
      priority,
      ticketStatus,
      error: {},
      file: null,
      nonFieldError: [],
      isValid: false,
      formData: null,
      ticketDetails: {},
      formErrors: {},
    };
  },
  computed: {
    ticketFormDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    closeDialog() {
      this.clear();
      this.ticketFormDialog = false;
    },

    imageFileCheck() {
      if (this.file) {
        this.file.forEach((file) => {
          let extension = file.name.slice(
            (Math.max(0, file.name.lastIndexOf(".")) || Infinity) + 1
          );
          if (extension == "png" || extension == "jpeg" || extension == "jpg") {
            return true;
          } else {
            bus.$emit("showToastMessage", {
              message: `Select file(s) are not supported.Support file Types: PNG, JPEG, JPG `,
              color: "error",
            });

            this.file = [];
            return false;
          }
        });
      }
    },

    generateFormData(data) {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (key != "ticket_attachments") {
          formData.set(key, data[key]);
        }
      });
      for (var i = 0; i < this.file.length; i++) {
        let file = this.file[i];
        formData.append("ticket_attachments", file);
      }
      return formData;
    },

    getTicketDetails(id = null) {
      bus.$emit("showLoader", true);
      this.$api.support
        .getSupportTicketObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.ticketDetails = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
          this.formErrors = err.data;
        });
    },

    submitTicketForm() {
      let postData;
      if (this.formType == "Add") {
        postData = this.generateFormData(this.ticketDetails);
      } else {
        postData = this.ticketDetails;
      }
      // if (this.file != null) {
      //   this.ticketDetails.ticket_attachments.forEach((file) => {
      //     postData.append("ticket_attachments", file);
      //   });
      // }
      bus.$emit("showLoader", true);
      if (this.formType == "add" || this.formType == "Add") {
        this.$api.support
          .addSupportTicket(postData)
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Successfully Created Ticket",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.non_field_errors) {
              this.nonFieldError = err.non_field_errors;
            }
            this.error = err;
          });
      } else {
        let payload = {
          id: this.ticketDetails.id,
          data: postData,
        };
        this.$api.support
          .updateSupportTicket(payload)
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Successfully Created Ticket",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.non_field_errors) {
              this.nonFieldError = err.non_field_errors;
            }
            this.error = err;
          });
      }
    },

    clear() {
      this.ticketDetails = {
        description: null,
        module: null,
        priority: null,
        ticket_attachments: [],
        ticket_comments: [],
      };
      if (this.$refs.ticketForm) {
        this.$refs.ticketForm.reset();
      }
    },
  },
  mounted() {
    this.clear();
  },
};
</script>

<style>
</style>