import IntegrationIndex from "../pages/admin/integration/index.vue";

const Integration = [
  {
    path: "integration",
    name: "integration",
    component: IntegrationIndex,
  },
];

export default Integration;
