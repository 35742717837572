<template>
  <div
    @click="$emit('selectTrip')"
    class="
      mb-4
      border-x-light_grey
      border-y-light_grey
      full-width
      background-white
      rounded-lg
    "
    :class="
      selectedTrip != null && selectedTrip.id == trip.id
        ? 'selected-card'
        : null
    "
  >
    <div class="pa-2 background-light_grey rounded-t-lg">
      <v-row dense>
        <v-col cols="6">
          <span class="text-body-2 font-weight-bold">
            {{ trip.reference_number }}
          </span>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <div>
            <span class="caption text-capitalize">
              {{ trip.status }}
            </span>
            <v-avatar
              :class="getTripStatusLightColor(trip.status)"
              size="16"
              class="ml-1"
            ></v-avatar>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="pa-2">
      <v-row no-gutters>
        <v-col cols="6" class="text-caption">
          {{ trip.driver_details.driver_name }}
        </v-col>
        <v-col cols="6" class="text-caption d-flex justify-end">
          <b>{{ trip.driver_details.contact_number }}</b>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="trip && trip.project_name">
        <v-col cols="6" class="text-caption"> Project </v-col>
        <v-col cols="6" class="d-flex justify-end text-caption">
          <b>{{ trip.project_name }}</b>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="trip && trip.zone_name">
        <v-col cols="6" class="text-caption"> Zone </v-col>
        <v-col cols="6" class="d-flex justify-end text-caption text-justify">
          <b>{{ trip.zone_name }}</b>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="trip && trip.vehicle_name">
        <v-col cols="6" class="text-caption"> Vehicle </v-col>
        <v-col cols="6" class="d-flex justify-end text-caption">
          <b>{{ trip.vehicle_name }}</b>
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-1" v-if="showChatButton">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            x-small
            depressed
            color="primary"
            @click.stop.prevent="$emit('showChat')"
          >
            <span class="text-caption">Chat&nbsp;</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { getTripStatusLightColor } from "@/utils/functions.js";
export default {
  props: {
    trip: {
      required: true,
    },
    selectedTrip: {
      required: false,
    },
    showChatButton: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      getTripStatusLightColor,
    };
  },
  methods: {},
};
</script>

<style>
</style>