<template>
  <div
    @scroll="reSetColumn()"
    style="
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      position: absolute;
    "
  >
    <v-row no-gutters style="height: 100%">
      <v-col cols="12" class="report-bottom-box-shadow px-8">
        <h3 class="text-uppercase pt-3">Reports</h3>
        <v-row>
          <v-col cols="12">
            <v-row class="px-0 pt-4">
              <v-col cols="10">
                <v-row>
                  <v-col cols="2">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="filter.start_date"
                      transition="scale-transition"
                      :nudge-right="40"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-if="
                            generatedReportType != 'trip_volume_utilization'
                          "
                          v-model="filter.start_date"
                          label="Start Date*"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        @change="dateValidator"
                        v-model="filter.start_date"
                        @click:date="$refs.menu.save(filter.start_date)"
                        :max="nowDate"
                        no-title
                        scrollable
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="filter.end_date"
                      transition="scale-transition"
                      :nudge-right="40"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-if="
                            generatedReportType != 'trip_volume_utilization'
                          "
                          v-model="filter.end_date"
                          label="End Date*"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.end_date"
                        @change="dateValidator"
                        no-title
                        scrollable
                        @click:date="$refs.menu2.save(filter.end_date)"
                        :max="todayDate"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="2"
                    v-if="generatedReportType != 'trip_volume_utilization'"
                  >
                    <SelectField
                      :multiple="true"
                      dense
                      hide-details="auto"
                      outlined
                      v-model="filter.project"
                      label="Select Project"
                      :items-list="allProjects"
                      :menu-props="{ offsetY: true }"
                    ></SelectField>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :return-value.sync="filter.date"
                      transition="scale-transition"
                      :nudge-right="40"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-if="
                            generatedReportType == 'trip_volume_utilization'
                          "
                          v-model="filter.date"
                          label=" Select Date*"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense
                          outlined
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filter.date"
                        @change="dateValidator"
                        no-title
                        scrollable
                        @click:date="$refs.menu3.save(filter.date)"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <v-btn
                  v-if="generatedReportType == 'trip_volume_utilization'"
                  class="primary"
                  :disabled="!filter.date"
                  @click="getReportType()"
                >
                  Get report
                </v-btn>
                <v-btn
                  v-else
                  class="primary"
                  :disabled="!filter.start_date || !filter.end_date"
                  @click="getReportType()"
                >
                  Get report
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0" style="height: 85%">
        <div>
          <div
            class="pa-5"
            v-show="
              generatedReportType == 'driver' ||
              generatedReportType == 'vehicle' ||
              generatedReportType == 'fleet_utilization' ||
              generatedReportType == 'driver_expense' ||
              generatedReportType == 'trip_volume_utilization'
            "
          >
            <div class="set-grid">
              <div
                v-if="
                  generatedReportType == 'driver' ||
                  generatedReportType == 'vehicle'
                "
              >
                <v-row no-gutters>
                  <v-col cols="12" class="pb-3">
                    <h4 class="text-capitalize">
                      Top 10 {{ generatedReportType }}
                    </h4>
                  </v-col>
                  <!-- <v-col cols="6">
                    <h5>Sort By</h5>
                  </v-col> -->
                  <v-col
                    cols="6"
                    class="d-flex align-center justify-end text-right"
                  >
                    <!-- <h6 class="pr-2">Ascending</h6>
                    <h6 class="grey--text">Descending</h6> -->
                  </v-col>
                  <v-col cols="12" style="overflow-y: scroll; height: 50%">
                    <v-card
                      elevation="0"
                      v-for="(item, index) in listData"
                      :key="index"
                      class="pa-3 mt-3"
                      style="border: 1px solid #8080803b; border-radius: 0px"
                    >
                      <v-row>
                        <v-col cols="1">
                          <h5>{{ index + 1 }}</h5>
                        </v-col>
                        <v-col cols="6">
                          <div v-if="item.driver_name">
                            <h5>{{ item.driver_name }}</h5>
                            <h5 class="grey--text">
                              <!-- {{ index + 1 }} -->
                            </h5>
                          </div>
                          <div v-if="item.vehicle_plate_no">
                            <h5>{{ item.vehicle_plate_no }}</h5>
                            <h5 class="grey--text">
                              <!-- {{ index + 1 }} -->
                            </h5>
                          </div>
                        </v-col>
                        <v-col cols="5">
                          <div
                            v-if="item.total_trips"
                            class="d-flex align-center"
                          >
                            <span class="text-caption mr-3">Total Trip</span>
                            <h5 class="grey--text font-weight-bolder">
                              {{ item.total_trips }}
                            </h5>
                          </div>
                          <div
                            v-if="item.trip_count"
                            class="d-flex align-center"
                          >
                            <span class="text-caption mr-3">Total Trip</span>
                            <h5 class="grey--text font-weight-bolder">
                              {{ item.trip_count }}
                            </h5>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div class="px-3" v-if="generatedReportType == 'driver_expense'">
                <v-row class="mb-2">
                  <v-col cols="5">
                    <h4 v-if="generatedReportType == 'driver_expense'">
                      Driver Expenses Chart
                    </h4>
                  </v-col>
                  <v-card
                    class="mt-1 elevation-0"
                    outlined
                    :style="{ height: 'calc(75vh - 270px)', width: '450px' }"
                  >
                    <v-card-text class="py-lg-12">
                      <PieChart
                        v-if="pieChatVisible"
                        :data="{
                          labels: this.pieChartData.labels,
                          datasets: [
                            {
                              backgroundColor: [
                                '#9b182f',
                                '#90CAF9',
                                '#607D8B',
                                '#fab079',
                              ],
                              data: this.pieChartData.data,
                            },
                          ],
                        }"
                        :options="pieChartOptions"
                        :height="320"
                      />
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
              <div class="px-5">
                <div
                  v-if="
                    generatedReportType == 'driver' ||
                    generatedReportType == 'vehicle' ||
                    generatedReportType == 'driver_expense'
                  "
                >
                  <v-row class="pb-4">
                    <v-col cols="6">
                      <h4 v-if="generatedReportType == 'driver_expense'">
                        Driver Expense Report
                      </h4>
                      <h4 v-else>Document Expiry Report</h4>
                    </v-col>
                    <v-col
                      cols="6"
                      class="d-flex justify-end align-center"
                      v-if="generatedReportType == 'driver_expense'"
                    >
                      <v-btn
                        :disabled="!filter.start_date || !filter.end_date"
                        tile
                        depressed
                        text
                        @click="getReportDownload(true)"
                      >
                        <h6 class="text-caption mr-2">Download</h6>
                        <v-icon small>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <AgGridVue
                    :grid-options="gridOptions1"
                    :column-defs="documentHeader"
                    :row-data="documentDataList"
                    style="width: 100%; height: calc(75vh - 270px)"
                    class="ag-theme-alpine cf-table"
                  >
                  </AgGridVue>
                </div>
              </div>
            </div>
          </div>
          <div class="pa-5" style="height: 100%; overflow-y: scroll">
            <v-row>
              <v-col cols="12" v-if="Object.keys(reportCountData).length > 0">
                <h4 class="pb-4 text-capitalize">
                  {{ generatedReportType.replaceAll("_", " ") }} Statistics
                </h4>
                <div
                  style="display: grid; gap: 10px"
                  :style="{
                    gridTemplateColumns: `repeat( ${
                      Object.keys(reportCountData).length
                    } , calc(100% / ${
                      Object.keys(reportCountData).length + 1
                    }))`,
                  }"
                >
                  <div
                    cols="2"
                    class="set-border pa-3"
                    v-for="(value, key, index) in reportCountData"
                    :key="index"
                  >
                    <h2 class="font-weight-bolder primary--text text-center">
                      {{ value ? value : 0 }}
                    </h2>
                    <h5
                      class="
                        grey--text
                        pt-1
                        font-weight-bolder
                        text-capitalize text-center
                      "
                    >
                      {{ key ? key.replaceAll("_", " ") : "" }}
                    </h5>
                  </div>
                </div>
              </v-col>

              <v-col cols="12">
                <LineChart
                  v-if="openOrderStatisticChart"
                  :datasets="OrderStatisticChart"
                  :options="barOptions"
                />
              </v-col>

              <v-col
                cols="12"
                class="px-8 pt-8"
                v-if="generatedReportType != 'driver_expense'"
              >
                <v-row class="set-border">
                  <v-col cols="4" class="d-flex">
                    <h4 class="mr-2 text-capitalize">
                      {{ generatedReportType.replaceAll("_", " ") }} Report
                    </h4>
                    <!-- <v-btn
                      class="primary pa-2 mr-2"
                      style="min-width: 5px; height: 20px !important"
                      >filter</v-btn
                    > -->
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center align-center">
                    <!-- <h6 class="pr-2 grey--text">Expiry 15 Days</h6>
                    <h6>30 Days</h6> -->
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end align-center">
                    <v-btn
                      :disabled="!filter.start_date || !filter.end_date"
                      tile
                      depressed
                      text
                      @click="getReportDownload(false)"
                    >
                      <h6 class="text-caption mr-2">Download</h6>
                      <v-icon small>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="set-border">
                  <v-col cols="12">
                    <AgGridVue
                      :grid-options="gridOptions"
                      :column-defs="tableHeader"
                      :row-data="tableData"
                      style="width: 100%; height: 550px"
                      class="ag-theme-alpine cf-table"
                    >
                    </AgGridVue>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12" class="px-8 pt-8">
                <v-row class="set-border">
                  <v-col cols="4" class="d-flex">
                    <h4 class="mr-2">Previously Trips Lists</h4>
                    <v-btn
                      class="primary pa-2 mr-2"
                      style="min-width: 5px; height: 20px !important"
                      >filter</v-btn
                    >
                  </v-col>
                  <v-col cols="4" class="d-flex justify-center align-center">
                    <h6 class="pr-2 grey--text">Expiry 15 Days</h6>
                    <h6>30 Days</h6>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end align-center">
                    <h6 class="text-caption mr-2">Download</h6>
                    <v-icon small>mdi-download</v-icon>
                  </v-col>
                </v-row>

                <v-row class="set-border">
                  <v-col cols="12">
                    <v-data-table
                      :headers="TripHeader"
                      :items="tripDataList"
                      item-key="name"
                      class="elevation-1"
                    >
                      <template v-slot:item.trip_id="{ item }">
                        <v-btn
                          class="primary pa-1 mr-2"
                          style="min-width: 5px; height: 20px !important"
                          >#1</v-btn
                        >
                        {{ item.trip_id }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main.js";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import XLSX from "xlsx";
import { downloadBlobData } from "@/assets/utils";
import PieChart from "@/components/common/charts/PieChart.vue";
import LineChart from "@/components/common/charts/LineChart.vue";

export default {
  name: "Generated-Report",
  props: {
    generatedReportType: {
      type: String,
      required: true,
    },
  },
  components: {
    PieChart,
    LineChart,
    AgGridVue,
    SelectField,
  },
  data() {
    return {
      type: [],
      distinctErrors: [],
      rotatedIcon: "mdi-rotate-180",
      expenseCategory: [
        {
          text: "Fuel Receipt",
          value: "FuelFillReceipt",
        },
        {
          text: "Toll Receipt",
          value: "TollReceipt",
        },
        {
          text: "Parking Tickets",
          value: "ParkingTickets",
        },
        {
          text: "Other Receipt",
          value: "OtherReceipt",
        },
      ],
      selectedReoprt: {},

      disabled: true,
      menu: false,
      menu2: false,
      menu3: false,
      tripDate: false,
      gridApi: null,
      columnApi: null,
      gridOptions1: {
        onGridSizeChanged: () => {
          if (this.gridOptions1 && this.gridOptions1.api) {
            this.gridOptions1.api.sizeColumnsToFit();
          }
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        pagination: true,
        paginationPageSize: 20,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          if (this.gridOptions && this.gridOptions.api) {
            this.gridOptions.api.sizeColumnsToFit();
          }
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        pagination: true,
        paginationPageSize: 20,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      orderStatus: [
        {
          text: "Unassigned",
          value: "unassigned",
        },
        {
          text: "Assigned",
          value: "assigned",
        },
        {
          text: "Shipped",
          value: "pickedup",
        },
        {
          text: "Partially Delivered",
          value: "partially_delivered",
        },
        {
          text: "Delivered",
          value: "successful",
        },
        {
          text: "Returned",
          value: "failed",
        },
        {
          text: "Cancelled",
          value: "cancelled",
        },
      ],
      tripStatus: [
        {
          text: "Scheduled",
          value: "scheduled",
        },
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Paused",
          value: "paused",
        },
        {
          text: "Completed",
          value: "completed",
        },
      ],
      nowDate: new Date().toISOString().slice(0, 10),
      tripDataList: [
        {
          trip_id: "Trip11",
          start_time: "10:00",
          end_time: "23:00",
          duration: 24,
          distance: "123 Km",
          orders: "1443",
          drop_point: "123",
        },
        {
          trip_id: "Trip11",
          start_time: "10:00",
          end_time: "23:00",
          duration: 24,
          distance: "123 Km",
          orders: "1443",
          drop_point: "123",
        },
        {
          trip_id: "Trip11",
          start_time: "10:00",
          end_time: "23:00",
          duration: 24,
          distance: "123 Km",
          orders: "1443",
          drop_point: "123",
        },
        {
          trip_id: "Trip11",
          start_time: "10:00",
          end_time: "23:00",
          duration: 24,
          distance: "123 Km",
          orders: "1443",
          drop_point: "123",
        },
        {
          trip_id: "Trip11",
          start_time: "10:00",
          end_time: "23:00",
          duration: 24,
          distance: "123 Km",
          orders: "1443",
          drop_point: "123",
        },
      ],
      driverDataList: [
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
        {
          driver_name: "DV00001",
          duty_hours: "13",
          vehicle_no: "VH00001",
          duty_start: "10:33",
          duty_end: "23:00",
          no_of_trips: "34",
          overtime: "32",
        },
      ],
      driverHeader: [
        {
          text: "Driver Name",
          align: "start",
          sortable: false,
          value: "driver_name",
        },
        { text: "Duty Hours", value: "duty_hours" },
        { text: "Vehicle No", value: "vehicle_no" },
        { text: "Duty Start", value: "duty_start" },
        { text: "Duty end", value: "duty_end" },
        { text: "No of trips", value: "no_of_trips" },
        { text: "overtime", value: "overtime" },
      ],
      TripHeader: [
        {
          text: "Trip ID",
          align: "start",
          sortable: false,
          value: "trip_id",
        },
        { text: "Start Time", value: "start_time" },
        { text: "End Time", value: "end_time" },
        { text: "Duration", value: "duration" },
        { text: "Distance", value: "distance" },
        { text: "Orders", value: "orders" },
        { text: "Drop Point", value: "drop_point" },
      ],

      driverStatisticsData: {},
      tripStatisticsData: {},

      pieChatVisible: false,
      pieChartData: {},
      pieChartOptions: {
        responsive: true,
        legend: {
          boxWidth: 20,
          display: true,
          textAlign: "start",
          position: "bottom",
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data, d) {
              return ` ${data.labels[tooltipItem.index]} : ${
                data.datasets[0].data[tooltipItem.index]
              }  %`;
            },
          },
        },
      },

      documentHeader: [],
      documentDataList: [],
      listData: [],
      reportCountData: {},
      todayDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      filter: {
        start_date: moment().subtract(1, "week").format("YYYY-MM-DD"),
        end_date: moment().subtract(1, "day").format("YYYY-MM-DD"),
        project: [],
      },
      openOrderStatisticChart: false,
      OrderStatisticChart: null,
      barOptions: {
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                stepSize: 1,
                beginAtZero: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      tableHeader: [],
      tableData: [],
      orderTableData: [],
      orderData: {},
      allProjects: [],
    };
  },
  methods: {
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTripStatistics() {
      let payload = {
        url: "/api/v1/trip_reports/get_statistics",
      };
      this.$api.report
        .getTripStatistics(payload)
        .then((result) => {
          const response = result.data;
          this.disabled = false;
          if (response && response.statistics) {
            this.tripStatisticsData = response.statistics;
          }
        })
        .catch((err) => {
          if (err.message) {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          } else {
            bus.$emit("showToastMessage", {
              message: "Error Fetching data!",
              color: "error",
            });
          }
          // this.reportData = [];
        });
    },
    getDriverStatistics() {
      let payload = {
        url: "/api/v1/driver_reports/get_statistics",
      };
      this.$api.report
        .getTripStatistics(payload)
        .then((result) => {
          const response = result.data;
          this.disabled = false;
          if (response && response.statistics) {
            this.driverStatisticsData = response.statistics;
          }
          if (
            response.report &&
            response.report &&
            response.report.length > 0
          ) {
            this.documentHeader = this.getTableHeader(response.report[0]);
            this.documentDataList = this.getTableData(response.report);
            this.documentDataList.splice(0, 1);
          }
        })
        .catch((err) => {
          if (err.message) {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          } else {
            bus.$emit("showToastMessage", {
              message: "Error Fetching data!",
              color: "error",
            });
          }
        });
    },
    reSetColumn() {
      if (this.$refs.select) {
        this.$refs.select.blur();
      }
      if (this.$refs.expenses_Category) {
        this.$refs.expenses_Category.blur();
      }
    },
    autoSizeAll() {
      let timer = setTimeout(() => {
        let allColumnIds = [];
        this.gridOptions1.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.gridOptions1.columnApi.autoSizeColumns(allColumnIds);
      }, 1000);
    },
    dateValidator() {
      if (
        this.filter.start_date &&
        this.filter.end_date &&
        this.filter.start_date > this.filter.end_date
      ) {
        let _ = this.filter.end_date;
        this.filter.end_date = this.filter.start_date;
        this.filter.start_date = _;
      }
    },
    coloredOrderStatus(status) {
      switch (status) {
        case "unassigned":
          return "#90A4AE";
        case "total_trips":
          return "#90CAF9";
        case "pickedup":
          return "#FFB300";
        case "partially_delivered_count":
          return "#CE93D8";
        case "delivered_count":
          return "#66BB6A";
        case "returned_count":
          return "#E53935";
        case "cancelled":
          return "#880E4F";
      }
    },
    setFilter() {
      let project = null;
      let filters = {};
      if (Object.keys(this.filter).length == 0) {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 10);
        let startDate = `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()}`;

        filters.start_date = startDate;
        filters.end_date = this.todayDate;
      }
      if (this.filter.project && this.filter.project.length > 0) {
        project = this.filter.project;
        filters = { ...this.filter, project: project.join(",") };
      } else {
        filters = { ...filters, ...this.filter };
      }
      return filters;
    },
    getTableHeader(rowData) {
      return Object.keys(rowData).map((key) => {
        return {
          headerName: key.replaceAll("_", " ").toUpperCase(),
          field: key,
          resizable: true,
        };
      });
    },
    getDownloadExcel(url, filename) {
      let newFilter = this.setFilter();
      newFilter.limit = "all";
      const params = {
        params: newFilter,
        url: url,
      };
      this.$api.report
        .downloadReportSheet(params)
        .then((result) => {
          downloadBlobData(result, `${filename}.xls`);
        })
        .catch((err) => {
          console.error(err);

          bus.$emit("showToastMessage", {
            message: "Error Fetching Data",
            color: "error",
          });
        });
    },
    getExpiryData(url) {
      let newFilter = this.setFilter();
      delete newFilter.end_date;
      delete newFilter.start_date;
      const params = {
        url: url,
        params: newFilter,
      };
      this.$api.report
        .getReportList(params)
        .then((result) => {
          const response = result.data;
          this.pieChatVisible = false;
          if (response.tabular_data && response.tabular_data.length > 0) {
            this.documentHeader = this.getTableHeader(response.tabular_data[0]);
            this.documentDataList = response.tabular_data;
          }
          if (
            response.expense_pie_chart &&
            Object.keys(response.expense_pie_chart).length > 0
          ) {
            this.pieChartData.labels = Object.keys(
              response.expense_pie_chart
            ).map((item) => item.replaceAll("_", " ").toUpperCase());
            this.pieChartData.data = Object.values(response.expense_pie_chart);
            setTimeout(() => {
              this.pieChatVisible = true;
            }, 200);
          }
          setTimeout(() => {
            if (this.gridOptions1 && this.gridOptions1.api) {
              this.gridOptions1.api.sizeColumnsToFit();
            }
          }, 300);
        })
        .catch((err) => {
          console.error(err);

          bus.$emit("showToastMessage", {
            message: "Error Fetching Data",
            color: "error",
          });
        });
    },
    getVehicleAndDriverList(url) {
      let newFilter = this.setFilter();
      const params = {
        url: url,
        params: newFilter,
      };

      this.$api.report
        .getReportList(params)
        .then((result) => {
          const response = result.data;
          if (response.driver_ranking && response.driver_ranking.length > 0) {
            this.listData = response.driver_ranking;
          }
          if (response.vehicle_ranking && response.vehicle_ranking.length > 0) {
            this.listData = response.vehicle_ranking;
          }
        })
        .catch((err) => {
          console.error(err);

          bus.$emit("showToastMessage", {
            message: "Error Fetching Data",
            color: "error",
          });
        });
    },
    getReportData(url) {
      let newFilter = this.setFilter();
      const params = {
        url: url,
        params: newFilter,
      };
      this.$api.report
        .getReportList(params)
        .then((result) => {
          const response = result.data;
          this.tableData = [];

          if (response.tabular_data && response.tabular_data.length > 0) {
            this.tableHeader = this.getTableHeader(response.tabular_data[0]);
            this.tableData = response.tabular_data;
          }
          if (response.widgets) {
            this.reportCountData = response.widgets;
          }
          if (this.generatedReportType == "vehicle") {
            setTimeout(() => {
              if (this.gridOptions && this.gridOptions.api) {
                this.gridOptions.api.sizeColumnsToFit();
              }
            }, 300);
          }
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showToastMessage", {
            message: "Error Fetching Data",
            color: "error",
          });
        });
    },
    getReportDetails(url) {
      let newFilter = this.setFilter();
      const params = {
        url: url,
        params: newFilter,
      };

      this.$api.report
        .getReportList(params)
        .then((result) => {
          const response = result.data;
          this.openOrderStatisticChart = false;

          if (response.order_status_line_chart) {
            this.OrderStatisticChart = this.getGenerateChatData(
              response.order_status_line_chart
            );
          }

          if (response.line_chart) {
            this.OrderStatisticChart = this.getGenerateChatData(
              response.line_chart
            );
          }

          if (response.widgets) {
            this.reportCountData = response.widgets;
          }

          setTimeout(() => {
            this.openOrderStatisticChart = true;
          }, 400);
        })
        .catch((err) => {
          console.error(err);

          bus.$emit("showToastMessage", {
            message: "Error Fetching Data",
            color: "error",
          });
        });
    },
    getGenerateChatData(data) {
      let orderChat = {
        labels: [],
        datasets: [],
      };
      if (data && data.length > 0) {
        Object.keys(data[0]).map((key) => {
          if (key != "date") {
            orderChat.datasets.push({
              label: key.replaceAll("_", " ").toUpperCase(),
              data: data.map((item) => item[key]),
              fill: false,
              borderColor: this.coloredOrderStatus(key),
            });
          }
        });
      }
      orderChat.labels = data.map((item) => item.date);
      return orderChat;
    },
    getReportDownload(isTrue) {
      if (isTrue) {
        // For Expense report true, false for every other report
        this.getDownloadExcel("expense_report_download", "expense_report");
      } else {
        if (this.generatedReportType == "order") {
          this.getDownloadExcel("order_report_download", "order_report");
        } else if (this.generatedReportType == "trip") {
          this.getDownloadExcel("trip_report_download", "trip_report");
        } else if (this.generatedReportType == "driver") {
          this.getDownloadExcel("driver_report_download", "driver_report");
        } else if (this.generatedReportType == "vehicle") {
          this.getDownloadExcel("vehicle_report_download", "vehicle_report");
        } else if (this.generatedReportType == "fleet_utilization") {
          this.getDownloadExcel(
            "fleet_utilization_report_download",
            "fleet_utilization_report"
          );
        } else if (this.generatedReportType == "trip_volume_utilization") {
          this.getDownloadExcel(
            "trip_volume_utilization_report_download",
            "trip_volume_utilization_report"
          );
        }
      }
    },
    getReportType() {
      const startDate = new Date(this.filter.start_date);
      const endDate = new Date(this.filter.end_date);

      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 40) {
        alert("Cannot get data for greater than 40 days.");
        return;
      }
      if (this.generatedReportType == "order") {
        this.getReportData("order_report");
        this.getReportDetails("order_chart_widget");
      } else if (this.generatedReportType == "trip") {
        this.getReportData("trip_report");
        this.getReportDetails("trip_chart_widget");
      } else if (this.generatedReportType == "driver") {
        this.getReportData("driver_report");
        this.getVehicleAndDriverList("driver_trip_ranking");
        this.getExpiryData("driver_document_expiry");
      } else if (this.generatedReportType == "vehicle") {
        this.getReportData("vehicle_report");
        this.getVehicleAndDriverList("vehicle_trip_ranking");
        this.getExpiryData("vehicle_document_expiry");
      } else if (this.generatedReportType == "fleet_utilization") {
        this.getReportData("fleet_utilization_report");
      } else if (this.generatedReportType == "driver_expense") {
        this.getReportData("expense_report");
      } else if (this.generatedReportType == "trip_volume_utilization") {
        this.getReportData("trip_volume_utilization_report");
      }
    },
  },
  mounted() {
    this.getAllProjects();
    this.getReportType(this.generatedReportType);
  },
};
</script>

<style lang="scss" scoped>
.on-hover {
  &:hover {
    box-shadow: 2px 2px 10px -5px rgb(0 0 0 / 20%);
  }
}
.report-grid {
  display: grid;
  grid-template-columns: repeat(8, 12.5%);
  overflow-y: auto;
}
@media screen and (max-width: 1600px) {
  .report-grid {
    grid-template-columns: repeat(6, 16%);
  }
}
@media screen and (max-width: 1400px) {
  .report-grid {
    grid-template-columns: repeat(5, 20%);
  }
}
@media screen and (max-width: 1300px) {
  .report-grid {
    grid-template-columns: repeat(4, 250px);
  }
}
.report-bottom-box-shadow {
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.24);
}
.set-border {
  border: 1px solid #8080803b;
}
.set-grid {
  height: 100%;
  display: grid;
  grid-template-columns: 450px 1fr;
}
</style>
