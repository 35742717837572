<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="edit text-white"
          small
          v-bind="attrs"
          v-on="on"
          v-if="
            params &&
            params.data &&
            !params.data.is_success &&
            userPermissions.customeraddress &&
            userPermissions.customeraddress.change
          "
          @click="btnClickedHandler()"
          depressed
        >
          <v-icon small class="ma-0">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openCustomerAddressForm: false,
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  computed: {},
  methods: {
    async btnClickedHandler() {
      this.params.context.parentComponent.editB2COrder(this.params.data.id);
    },
  },
};
</script>