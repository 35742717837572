import SupportIndex from "@/pages/admin/support/index.vue";
import SupportTicket from "@/pages/admin/support/view/_id/index.vue";

const Support = [
  {
    path: "support",
    name: "support",
    component: SupportIndex,
  },
  {
    path: "support/view/:id",
    name: "ticket-support",
    component: SupportTicket,
  },
];

export default Support;
