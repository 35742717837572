<template>
  <v-row no-gutters>
    <v-row no-gutters v-if="logs.length == 0">
      <v-col cols="12" class="d-flex justify-center py-5">
        <h5 class="text-body-1 font-weight-bold">No data at the moment.</h5>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="logs.length > 0">
      <v-col cols="12" class="pr-4">
        <v-timeline dense>
          <v-timeline-item v-for="(log, i) in logs" :key="i" small>
            <template v-slot:opposite> </template>
            <div>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <h5
                        v-bind="attrs"
                        v-on="on"
                        class="black--text text-caption font-weight-bold"
                      >
                        {{ log.message }}
                      </h5>
                    </template>
                    <span class="text-capitalize">message</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="6">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="
                          font-weight-medium
                          text-subtitle-2
                          secondary--text
                        "
                      >
                        {{ log.added_by }}
                      </span>
                    </template>
                    <span class="text-capitalize">Added By </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                        class="font-weight-medium text_color--text"
                        style="font-size: 13px"
                      >
                        {{ log.created }}
                      </span>
                    </template>
                    <span class="text-capitalize">created</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "logsComponent",
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>