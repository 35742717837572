<template>
  <v-row no-gutters>
    <v-col cols="3" lg="3" md="3">
      <div class="border-right-light_grey">
        <v-row no-gutters>
          <v-col
            id="tripListHeaderContainer"
            cols="12"
            class="background-light_grey px-4 pt-4"
          >
            <v-row no-gutters>
              <v-col cols="12" class="px-2 pt-2">
                <span class="text-h5 text-uppercase font-weight-bold text-grey">
                  Trips
                </span>
              </v-col>
              <v-col cols="12" class="d-flex px-2 pt-2 pb-4">
                <InputField
                  label="Search here.."
                  prepend-inner-icon="mdi-magnify"
                  hide-details="auto"
                  outlined
                  dense
                  class="background-white"
                  v-model="tripFilter.search"
                  @input="syncTripFilter($event, 'search')"
                ></InputField>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      fab
                      depressed
                      color="primary"
                      class="ml-1 rounded"
                      v-bind="attrs"
                      v-on="on"
                      @click="getTripList(true)"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Refresh</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      bordered
                      color="success"
                      overlap
                      small
                      dot
                      :value="appliedFilters && appliedFilters.length > 0"
                    >
                      <v-btn
                        small
                        fab
                        depressed
                        color="primary"
                        class="ml-1 rounded"
                        @click="openTripFilterDialog = true"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-filter</v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <span>Trip Filters</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-menu
                        transition="slide-y-transition"
                        bottom
                        right
                        left
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            fab
                            depressed
                            color="primary"
                            class="ml-1 rounded"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense style="width: 250px">
                          <v-list-item
                            link
                            class="tile"
                            @click="createTrip()"
                            v-if="
                              userPermissions.trip && userPermissions.trip.add
                            "
                          >
                            <v-list-item-title> Create Trip </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="tile"
                            @click="openBulkUploadDialog = true"
                          >
                            <v-list-item-title>
                              Temperature Sheet Bulk Upload
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="tile"
                            @click="openLoadSheetDownloadDialog = true"
                          >
                            <v-list-item-title>
                              Picking Sheet
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                  <span>Create Trip</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                class="px-2 pb-2"
                v-if="appliedFilters && appliedFilters.length !== 0"
              >
                <v-chip
                  small
                  close
                  dark
                  class="mr-1 mb-1 text-capitalize background-black"
                  v-for="(filter, index) in appliedFilters"
                  :key="index"
                  @click:close="removeFilter(filter.key)"
                >
                  {{ filter.name }}
                </v-chip>
              </v-col>
              <v-col cols="12" class="d-flex px-2 pb-4" v-if="loaderProgress">
                <v-scroll-x-transition mode="out-in">
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-scroll-x-transition>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            ref="tripParent"
            cols="12"
            class="px-4 pb-4 mt-4 overflow-y-auto"
            @scroll="scrollControlTrip()"
            :style="{ height: listContainerHeight }"
          >
            <div v-for="(trip, index) in Trips" :key="index">
              <TripDetailCard
                @toggleChart="toggleChart"
                @selectTrip="getTripDetails(trip.id)"
                :trip="trip"
              />
            </div>
          </v-col>
        </v-row>
        <TripFilter
          ref="tripFilterDialog"
          @tripFilterChanged="tripFilterChanged()"
          v-model="openTripFilterDialog"
        />
        <TripBulkUpload v-model="openBulkUploadDialog" />
        <TripLoadSheet v-model="openLoadSheetDownloadDialog" />
      </div>
    </v-col>
    <v-col :cols="mdColumn" class="position-relative expand-transation">
      <Gmap
        :mapHeight="containerHeight"
        mapWidth="auto"
        ref="tripMap"
        page="trip"
        :clusterMarkers="true"
      />
      <div class="rounded-l-lg position-absolute details-toggle-btn">
        <v-btn
          :class="`rounded-0 rounded-l-lg background-${getTripStatusColor(
            currentTrip.status
          )}`"
          fab
          small
          depressed
          v-if="defaultSet"
          @click="changeMapSize"
        >
          <v-icon color="white" :class="rotatedIcon">
            mdi-arrow-right-bold
          </v-icon>
        </v-btn>
      </div>
      <MapInfo :legends="legends">
        <v-row no-gutters>
          <v-col cols="2" class="d-flex justify-center align-center">
            <div
              style="height: 4px; background: #3498eb; width: 80%"
              class="my-2"
            ></div>
          </v-col>
          <v-col cols="10" class="d-flex justify-start">
            <span
              class="my-1 mx-2 text-caption float-left"
              :class="
                plannedRoute != true ? 'text-decoration-line-through' : ''
              "
              style="cursor: pointer"
              @click="routeDisplay('planned')"
            >
              Planned Route
            </span>
          </v-col>
          <v-col cols="2" class="d-flex justify-center align-center">
            <div
              style="height: 4px; background: red; width: 80%"
              class="my-2"
            ></div>
          </v-col>
          <v-col cols="10" class="d-flex justify-start">
            <span
              :class="actualRoute != true ? 'text-decoration-line-through' : ''"
              class="my-1 mx-2 text-caption float-left"
              @click="routeDisplay('actual')"
              style="cursor: pointer"
            >
              Actual Route
            </span>
          </v-col>

          <!-- <v-col
            cols="10"
            class="d-flex justify-start"
            @click="plannedLocation"
          >
            <span
              class="my-1 mx-2 text-caption float-left"
              :class="!isVisiblePlanned ? 'text-decoration-line-through' : ''"
            >
              Planned Route
            </span>
          </v-col>
          <v-col cols="2" class="d-flex justify-center align-center">
            <div
              style="height: 4px; background: red; width: 80%"
              class="my-2"
            ></div>
          </v-col>
          <v-col cols="10" class="d-flex justify-start">
            <span
              class="my-1 mx-2 text-caption float-left"
              :class="!isVisibleUnplanned ? 'text-decoration-line-through' : ''"
            >
              Actual Route
            </span>
          </v-col> -->
        </v-row>
      </MapInfo>
    </v-col>
    <v-col cols="3" md="3" lg="3" v-if="setmdColumn">
      <TripDetails :reloadChart="reloadChart" :currentTrip="currentTrip" />
    </v-col>
  </v-row>
</template>

<script>
/* eslint-disable */
import {
  WarehouseIcon,
  OrderUnassignedIcon,
  OrderAssignedIcon,
  OrderPickupIcon,
  OrderSuccessfulIcon,
  OrderFailedIcon,
  OrderCancelledIcon,
  OrderEnrouteIcon,
} from "@/assets/mapIcons/icons";
import { bus } from "@/main.js";
import Gmap from "@/components/common/Gmap.vue";
import MapInfo from "@/components/common/MapInfo.vue";
import TripFilter from "@/components/trip/TripFilter.vue";
import TripDetailCard from "@/components/trip/TripDetailCard.vue";
import TripBulkUpload from "@/components/trip/TripBulkUpload.vue";
import TripLoadSheet from "@/components/trip/TripLoadSheet.vue";
import TripDetails from "@/components/trip/TripDetails.vue";
export default {
  name: "Trip-Index",
  components: {
    Gmap,
    MapInfo,
    TripFilter,
    TripDetailCard,
    TripBulkUpload,
    TripLoadSheet,
    TripDetails,
  },
  data() {
    return {
      currentTrip: {},
      Trips: [],
      reloadChart: false,
      setmdColumn: false,
      defaultSet: false,
      plannedRoute: true,
      actualRoute: true,
      mdColumn: 9,
      statusColor: "",
      rotatedIcon: "",
      legends: [
        { text: "Warehouse", marker: WarehouseIcon },
        { text: "Unassigned", marker: OrderUnassignedIcon },
        { text: "Assigned", marker: OrderAssignedIcon },
        { text: "Shipped", marker: OrderPickupIcon },
        { text: "Delivered", marker: OrderSuccessfulIcon },
        { text: "Returned", marker: OrderFailedIcon },
        { text: "Cancelled", marker: OrderCancelledIcon },
        { text: "Partially Delivered", marker: OrderEnrouteIcon },
      ],

      //  Trip List components vars
      // containerHeight: null,
      openTripFilterDialog: false,
      openBulkUploadDialog: false,
      openLoadSheetDownloadDialog: false,
      sortingType: null,
      selectedTrips: [],
      loaderProgress: false,
      tripFilter: {},
      userPermissions: null,
      // userPermissions: encryptLocal.getItem("permissions"),
      listContainerHeight: `${window.innerHeight - 200}px`,
    };
  },
  computed: {
    containerHeight() {
      return `${window.innerHeight - 64}px`;
    },
    // currentTrip() {
    //   return this.trip.currentTrip;
    // },
    appliedFilters() {
      return Object.keys(this.tripFilter).map((filter) => {
        return {
          name: filter?.replace(/\_/, " "),
          key: filter,
        };
      });
    },
  },
  methods: {
    routeDisplay(typeOfRoute) {
      if (typeOfRoute == "planned") {
        this.plannedRoute = !this.plannedRoute;
      } else {
        this.actualRoute = !this.actualRoute;
      }
      let sendData = {
        routeType: typeOfRoute,
        display:
          typeOfRoute == "planned" ? this.plannedRoute : this.actualRoute,
      };
      this.$emit("displayRoute", sendData);
    },

    getTripStatusColor(status) {
      switch (status) {
        case "scheduled":
          return "unassigned";
        case "active":
          return "assigned";
        case "paused":
          return "pickedup";
        case "completed":
          return "successful";
      }
    },
    toggleChart(value) {
      this.reloadChart = value;
    },
    openTripDetails(e, route, locations, driver_location, id) {
      let data = {
        id: id,
      };
      this.toggleChart(false);
      let warehouse = locations.warehouse;
      let orders = locations.locations;
      let driverDetail = {
        driver_location: driver_location,
      };
      this.$refs.tripMap.clearGeoJson();
      this.$refs.tripMap.clearMarker();
      this.$refs.tripMap.initMap();
      this.$refs.tripMap.loadRoutes(
        route,
        orders,
        warehouse,
        driverDetail,
        data
      );
      this.setmdColumn = true;
      this.defaultSet = true;
      this.mdColumn = 6;
      this.rotatedIcon = "";
      this.toggleChart(true);
    },
    changeMapSize() {
      if (this.setmdColumn) {
        this.setmdColumn = false;
        this.mdColumn = 9;
        this.rotatedIcon = "mdi-rotate-180";
      } else {
        this.setmdColumn = true;
        this.mdColumn = 6;
        this.rotatedIcon = "";
      }
    },

    // Trip List Component Methods
    createTrip() {
      this.$router.push({
        path: "/app/admin/trip/create",
      });
    },
    syncTripFilter(value, key) {
      let filters = localStorage.getItem("tripFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (value !== null && value.length > 3) {
        filters[key] = value;
      } else if (value == null || value.length == 0) {
        delete filters[key];
      }
      localStorage.setItem("tripFilters", JSON.stringify(filters));
      if (value.length > 3 || value.length == 0 || value == null) {
        this.tripFilterChanged();
      }
    },
    tripFilterChanged() {
      let filters = localStorage.getItem("tripFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.tripFilter = filters;
      this.getTripList(true);
    },
    removeFilter(key) {
      let filters = localStorage.getItem("tripFilters");
      filters = JSON.parse(filters);

      if (key === "start_date" || key === "end_date") {
        delete filters.start_date;
        delete filters.end_date;
      } else {
        delete filters[key];
      }

      this.tripFilter = filters;
      localStorage.setItem("tripFilters", JSON.stringify(filters));
      this.getTripList(true);
    },
    scrollControlTrip() {
      let elem = this.$refs.tripParent;
      if (elem == null) {
        return false;
      } else if (
        Math.ceil(elem.offsetHeight + elem.scrollTop) == elem.scrollHeight
      ) {
        this.getTripList(false);
      }
    },
    getTripList(reload, params = {}) {
      params = {
        ...params,
        limit: 50,
        offset: reload ? 0 : this.Trips?.length,
      };

      let filters = localStorage.getItem("tripFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (filters && "ordering" in filters && "sorting" in filters) {
        if (filters.sorting == "descending") {
          filters.ordering = "-" + filters.ordering;
        }
      }

      Object.assign(params, filters);
      if ("project" in params && params.project != null) {
        params.project = params.project.toString();
      }
      if ("status" in params && params.status != null) {
        params.status = params.status.toString();
      }
      if (params && params.vehicle && params.vehicle.length > 0) {
        params.vehicle = params.vehicle.join(",");
      }
      if (params && params.driver && params.driver.length > 0) {
        params.driver = params.driver.join(",");
      }

      // bus.$emit("showLoader", true);
      this.loaderProgress = true;
      if (reload || this.tripTotalCount != this.Trips.length) {
        this.$api.trips
          .getTripList(params)
          .then((res) => {
            if (reload) {
              this.Trips = res.data.results;
            } else {
              this.Trips = [...this.Trips, ...res.data?.results];
            }
            this.tripTotalCount = res.count;
            this.loaderProgress = false;
            // bus.$emit("showLoader", false);
            this.refreshHeight();
          })
          .catch((err) => {
            this.loaderProgress = false;
            // bus.$emit("showLoader", false);
            console.error(err);
          });
      } else {
        // bus.$emit("showLoader", false);
      }
    },

    getTripDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.trips
        .getTripDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);

          const response = res.data;
          this.currentTrip = response;

          this.openTripDetails(
            true,
            response.trip_route,
            response.locations,
            response.driver_location,
            id
          );
        })
        .catch((err) => {
          bus.$emit("showLoader", false);

          console.error(err);
        });
    },

    refreshHeight() {
      setTimeout(() => {
        let element = document.getElementById("tripListHeaderContainer");
        let rowHeight = element.offsetHeight;

        if (typeof rowHeight == typeof 1) {
          this.listContainerHeight = `${
            window.innerHeight - (rowHeight + 80)
          }px`;
        }
      }, 100);
    },
  },

  created() {
    this.userPermissions = JSON.parse(localStorage.getItem("permissions"));
  },
  mounted() {
    let filters = localStorage.getItem("tripFilters");
    if (!filters) {
      filters = {};
    }
    if (typeof filters == typeof "string") {
      filters = JSON.parse(filters);
    }
    this.tripFilter = filters;
    this.getTripList(true);
  },
};
</script>

<style></style>
