<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="edit white--text mr-1"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.dashuser && userPermissions.dashuser.change"
          @click="btnClickedHandler"
          depressed
        >
          <v-icon small class="ma-0 white--text">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit User</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="delete white--text mr-1"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.dashuser && userPermissions.dashuser.delete"
          @click="btnDeleteUser()"
          depressed
        >
          <v-icon small class="ma-0">mdi-delete</v-icon>
        </v-btn>
      </template>
      <span>Delete User</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openUserForm: false,
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  computed: {},
  methods: {
    async btnClickedHandler() {
      this.params.context.parentComponent.editUser(this.params.data.id);
    },
    btnDeleteUser() {
      this.params.context.parentComponent.deleteUser(this.params.data.id);
    },
  },
  mounted() {},
  // beforeCreate() {
  //   if (encryptLocal == null) {
  //     makeInstance(localStorage.getItem("user"));
  //   }
  // },
};
</script>