<template>
  <ListLayout
    title="Tickets"
    :tableData="supportList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getSupportList"
    :tableScrollable="false"
    localStorageKey="support_columns"
  >
    <template #listAction>
      <v-menu
        offset-y
        v-if="permissions && permissions.ticket && permissions.ticket.add"
      >
        <template v-slot:activator="{ on: tooltip }">
          <div v-on="{ ...tooltip }">
            <v-btn
              small
              depressed
              class="primary"
              @click="addTicket()"
              v-if="permissions.ticket.add == true"
            >
              <v-icon small class="mr-1">mdi-plus</v-icon>
              <span>{{ buttonTooltip }}</span>
            </v-btn>
          </div>
        </template>
      </v-menu>
    </template>
    <template #dialogs>
      <v-col v-if="openTicketForm">
        <SupportForm
          v-model="openTicketForm"
          ref="supportFormRef"
          :formType="formType"
          @refreshList="getSupportList({ limit: 10, offset: 0 })"
        />
      </v-col>
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { bus } from "@/main.js";
import supportButton from "@/components/common/aggrid/buttons/supportButton.vue";
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import { toTitleCase } from "@/utils/functions.js";
import SupportForm from "@/components/support/SupportForm.vue";

export default {
  name: "supportIndex",
  components: {
    ListLayout,
    supportButton,
    SupportForm,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      supportList: [],
      openTicketForm: false,
      formType: "Add",
      headerSelected: [
        {
          headerName: "Title",
          field: "title",
          sortable: false,
          pinned: "left",
          minWidth: 200,
        },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: (params) => {
            return toTitleCase(params.value);
          },
        },
        {
          headerName: "Module",
          field: "module",
          cellRenderer: (params) => {
            return toTitleCase(params.value);
          },
        },
        {
          headerName: "Priority",
          field: "priority",
          cellRenderer: (params) => {
            return toTitleCase(params.value);
          },
        },
      ],
      buttonTooltip: "Add Ticket",
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 150,
          sortable: false,
          width: 250,
          cellRenderer: "supportButton",
        },
      ];
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getSupportList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.support
        .getSupportList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.supportList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },

    addTicket() {
      this.formType = "Add";
      this.openTicketForm = true;
    },

    editTicket(id) {
      this.openTicketForm = true;
      this.formType = "Edit";
      setTimeout(() => {
        if (this.$refs && this.$refs.supportFormRef) {
          this.$refs.supportFormRef.getTicketDetails(id);
        }
      }, 100);
    },
    viewTicket(id) {
      this.$router.push(`/app/admin/support/view/${id}`);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
