<template>
  <v-dialog v-model="openChangeBranchDialog" max-width="800px">
    <v-card>
      <v-card-title class="background-primary text-white px-3 py-2">
        <span>{{
          type == "driver"
            ? `Select Driver's Project`
            : `Select Vehicle's Project`
        }}</span>
        <v-spacer></v-spacer>
        <v-btn small icon @click="openChangeBranchDialog = false" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-5">
        <v-col>
          <SelectField
            :itemsList="allProjects"
            label="Select Project"
            item-text="text"
            hide-details="auto"
            item-value="value"
            outlined
            :error-messages="
              formErrors && formErrors.project ? formErrors.project : ''
            "
            @change="
              formErrors && formErrors.project ? delete formErrors.project : ''
            "
            dense
            :menu-props="{ offsetY: true }"
            v-model="changeProject.project"
          ></SelectField>
        </v-col>

        <v-col>
          <v-textarea
            v-model="changeProject.change_project_remarks"
            label="Add Project Remarks"
            rows="3"
            outlined
            :error-messages="
              formErrors && formErrors.change_project_remarks
                ? formErrors.change_project_remarks
                : ''
            "
            @input="
              formErrors && formErrors.change_project_remarks
                ? delete formErrors.change_project_remarks
                : ''
            "
          ></v-textarea>
        </v-col>
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <v-btn color="primary" depressed @click="changeBranch">Change</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
export default {
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      openChangeBranchDialog: false,
      changeProject: {},
      allProjects: [],
      driverId: null,
      vehicleId: null,
      formErrors: [],
    };
  },
  watch: {
    openChangeBranchDialog(val) {
      if (val) {
        this.changeProject = {};
        this.getAllProjects();
      }
    },
  },
  methods: {
    openChangeBranch(id) {
      this.openChangeBranchDialog = true;

      if (this.type == "driver") {
        this.driverId = id;
      } else {
        this.vehicleId = id;
      }
    },
    changeBranch() {
      // let module;

      // module = this.type == 'driver' ? drivers : vehicles;
      let payload = {
        id: this.type == "driver" ? this.driverId : this.vehicleId,
        data: this.changeProject,
      };

      if (this.type == "driver") {
        this.$api.drivers
          .changeBranch(payload)
          .then((res) => {
            this.openChangeBranchDialog = false;
            window.location.reload();
          })
          .catch((err) => {
            if (err.data) {
              this.formErrors = err.data;
            }
            bus.$emit("showToastMessage", {
              message: "Something went wrong!",
              color: "error",
            });
          });
      } else {
        this.$api.vehicles
          .changeBranch(payload)
          .then((res) => {
            this.openChangeBranchDialog = false;
            window.location.reload();
          })
          .catch((err) => {
            if (err.data) {
              this.formErrors = err.data;
            }
            bus.$emit("showToastMessage", {
              message: "Something went wrong!",
              color: "error",
            });
          });
      }
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style>
</style>