import Dashboard from "./dashboard";
import CustomerAddresses from "./customer_addresses";
import Drivers from "./drivers";
import Integration from "./integration";
import Items from "./items";
import Operations from "./operations";
import Orders from "./orders";
import OrderHistory from "./order_history";
import PlanningTripNew from "./planning_trip_new";
import PlanningTripOld from "./planning_trip_old";
import Projects from "./projects";
import Reports from "./reports";
import Roles from "./roles";
import Statuses from "./statuses";
import Support from "./support";
import Trips from "./trip";
import TripPlanningTemplates from "./trip_panning_templates";
import Users from "./users";
import Vehicles from "./vehicles";
import Zones from "./zones";

export default [
  {
    path: "",
    redirect: "/app/admin/dashboard",
  },
  ...Dashboard,
  ...CustomerAddresses,
  ...Drivers,
  ...Integration,
  ...Items,
  ...Operations,
  ...OrderHistory,
  ...Orders,
  ...PlanningTripNew,
  ...PlanningTripOld,
  ...Projects,
  ...Reports,
  ...Roles,
  ...Statuses,
  ...Support,
  ...TripPlanningTemplates,
  ...Trips,
  ...Users,
  ...Vehicles,
  ...Zones,
];
