var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
          _vm.userPermissions.itemmaster &&
          _vm.userPermissions.itemmaster.change &&
          _vm.params &&
          _vm.params.data &&
          !_vm.params.data.is_success
        )?_c('v-btn',_vm._g(_vm._b({staticClass:"edit text-white",attrs:{"small":"","depressed":""},on:{"click":_vm.btnClickedHandler}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"ma-0",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Edit Details")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }