<template>
  <BaseFormDialog
    v-model="openProjectDialog"
    :title="`${formType} Project`"
    :fullscreen="true"
    @reset="closeDialog()"
    class=""
    :isStickTitleRequired="true"
    :persistent="true"
  >
    <template #content>
      <v-form ref="projectForm" id="projectForm" v-model="isValid">
        <v-alert v-if="nonFieldError.length" dense type="error">
          <v-list
            class="pa-0"
            dense
            style="background: inherit !important"
            v-for="(error, i) in nonFieldError"
            :key="i"
          >
            <v-list-item dense style="min-height: 20px !important">
              <span>{{ i }} .</span><span>{{ error }}</span>
            </v-list-item>
          </v-list>
        </v-alert>

        <v-row>
          <v-col cols="4" class="">
            <v-row class="pa-2">
              <v-col cols="12" class="">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Project Name*"
                  :rules="[(v) => !!v || 'Project Name is required']"
                  :error-messages="error.project_name"
                  @input="
                    error && error.project_name ? delete error.project_name : ''
                  "
                  v-model="projectFormDetails.project_name"
                ></InputField>
              </v-col>

              <v-col cols="12" class="">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Project ID*"
                  :rules="[(v) => !!v || 'Project ID is required']"
                  :error-messages="error.project_id"
                  @input="
                    error && error.project_id ? delete error.project_id : ''
                  "
                  v-model="projectFormDetails.project_id"
                ></InputField>
              </v-col>

              <v-col cols="12" class="">
                <SelectField
                  outlined
                  hide-details="auto"
                  label="Select Template*"
                  ref="select"
                  :menu-props="{ offsetY: true }"
                  name="planning_template"
                  :itemsList="templateList"
                  item-text="template_name"
                  item-value="template_name"
                  :rules="[(v) => !!v || 'Template is required']"
                  :error-messages="error.planning_template"
                  @input="
                    error && error.planning_template
                      ? delete error.planning_template
                      : ''
                  "
                  v-model="projectFormDetails.planning_template"
                ></SelectField>
              </v-col>

              <v-col cols="12" class="">
                <v-text-field
                  dense
                  outlined
                  ref="projectFormAddress"
                  id="projectFormAddress"
                  hide-details="auto"
                  placeholder="Enter Address *"
                  class="background-white"
                  :rules="[(v) => !!v || 'Base Address is required']"
                  :error-messages="error.base_address"
                  @input="
                    error && error.base_address ? delete error.base_address : ''
                  "
                  v-model="projectFormDetails.base_address"
                ></v-text-field>
              </v-col>

              <v-col cols="6" class="">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Latitude*"
                  type="number"
                  step="0.0000001"
                  :rules="[(v) => !!v || 'Latitude is required']"
                  :error-messages="error.base_coordinates"
                  @input="
                    error && error.base_coordinates
                      ? delete error.base_coordinates
                      : ''
                  "
                  v-model="projectFormDetails.base_coordinates.latitude"
                ></InputField>
              </v-col>

              <v-col cols="6" class="">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Longitude*"
                  type="number"
                  step="0.0000001"
                  :rules="[(v) => !!v || 'Latitude is required']"
                  :error-messages="error.base_coordinates"
                  @input="
                    error && error.base_coordinates
                      ? delete error.base_coordinates
                      : ''
                  "
                  v-model="projectFormDetails.base_coordinates.longitude"
                ></InputField>
              </v-col>

              <v-col cols="6">
                <SwitchField
                  dense
                  hide-details="auto"
                  class="mt-0 pt-0"
                  label="Update Customer Location"
                  :error-messages="error.update_customer_location"
                  @input="
                    error && error.update_customer_location
                      ? delete error.update_customer_location
                      : ''
                  "
                  v-model="projectFormDetails.update_customer_location"
                ></SwitchField>
              </v-col>

              <v-col cols="6">
                <SwitchField
                  dense
                  hide-details="auto"
                  class="mt-0 pt-0"
                  label="Order Creation Notification"
                  :true-value="true"
                  :false-value="false"
                  :error-messages="error.order_creation_notification"
                  @input="
                    error && error.order_creation_notification
                      ? delete error.order_creation_notification
                      : ''
                  "
                  v-model="projectFormDetails.order_creation_notification"
                ></SwitchField>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="8" class="cf-zone-map">
            <!-- <v-col cols="12" class="d-flex justify-start">
              <span class="text-h6">Set Project's Serviceable area</span>
            </v-col> -->
            <!-- <v-col cols="12" v-if="openProjectDialog"> -->
            <Gmap
              mapWidth="100%"
              mapHeight="84.5vh"
              :dialog-status="openProjectDialog"
              ref="projectFormMap"
              :isDrawable="true"
              :clusterMarkers="false"
            />
            <!-- </v-col> -->
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        :disabled="!isValid"
        class="primary text-uppercase mr-3"
        @click.prevent="submitProjectForm()"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'add' || formType == 'Add'"
        class="primary text-uppercase"
        @click="clear()"
      >
        <span>Reset</span>
      </v-btn>
    </template>
  </BaseFormDialog>
</template>

<script>
import BaseFormDialog from "@/components/LayoutComponents/BaseDialogForm.vue";
import Gmap from "@/components/common/Gmap.vue";
import InputField from "@/components/BaseFormComponents/InputField.vue";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseFormDialog,
    Gmap,
    InputField,
    SelectField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      error: [],
      google: null,
      nonFieldError: [],
      isValid: false,
      projectFormDetails: {
        base_coordinates: {
          latitude: null,
          longitude: null,
        },
        update_customer_location: false,
        order_creation_notification: false,
      },
      templateList: [],
    };
  },
  watch: {
    openProjectDialog(val) {
      if (val) {
        this.getTemplateList();
        this.getDefaultServiceableArea();
      } else {
        this.clear();
      }
    },
  },
  computed: {
    openProjectDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    clear() {
      this.projectFormDetails = {
        base_coordinates: {
          latitude: null,
          longitude: null,
        },
        order_creation_notification: false,
        update_customer_location: false,
      };
      this.error = {};
      this.nonFieldError = [];
      setTimeout(() => {
        this.$refs.projectFormMap.clearGeoJson();
        this.$refs.projectFormMap.deleteShape();
        this.$refs.projectFormMap.clearMarker();
      }, 100);

      this.$refs.projectForm.resetValidation();
    },
    closeDialog() {
      this.clear();
      this.projectFormDialog = false;
    },
    getTemplateList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.tripPlanningTemplate
        .getTripPlanningTemplateList(params)
        .then((res) => {
          this.templateList = res.data.results;
        })
        .catch(() => {
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch Project List!",
            color: "error",
          });
        });
    },
    getDefaultServiceableArea(params) {
      bus.$emit("showLoader", true);
      this.$api.projects
        .getDefaultServiceableArea(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          let interval = setInterval(() => {
            if (this.$refs.projectFormMap) {
              clearInterval(interval);
              this.$refs.projectFormMap.initMap();
              this.$refs.projectFormMap.loadDisabledMultiPolygon(
                res.data.serviceable_area.coordinates
              );
            }
          }, 100);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Something went wrong!",
            color: "error",
          });
        });
    },
    loadServiceableArea(coordinates) {
      let interval = setInterval(() => {
        if (this.$refs.projectFormMap && this.$refs.projectFormMap.map) {
          clearInterval(interval);
          let path = [];
          coordinates.forEach((array) => {
            array.forEach((element) => {
              path.push({
                lat: parseFloat(element[1]),
                lng: parseFloat(element[0]),
              });
            });
          });
          this.$refs.projectFormMap.setEditablePolygon(path, coordinates[0]);
        }
      }, 100);
    },

    getProjectDetails(id = null) {
      bus.$emit("showLoader", true);
      this.$api.projects
        .getProjectObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);

          this.projectFormDetails = res.data;

          let path = [];
          const interval = setInterval(() => {
            if (
              this.$refs.projectFormMap &&
              this.$refs.projectFormMap.map &&
              window.google &&
              window.google.maps
            ) {
              clearInterval(interval);
              if (
                this.$refs.projectFormMap &&
                this.projectFormDetails &&
                this.projectFormDetails.serviceable_area &&
                this.projectFormDetails.serviceable_area.coordinates
              ) {
                this.loadServiceableArea(
                  this.projectFormDetails.serviceable_area.coordinates[0]
                );
              }
              if (
                this.$refs.projectFormMap &&
                this.projectFormDetails &&
                this.projectFormDetails.base_coordinates &&
                this.projectFormDetails.base_coordinates.latitude &&
                this.projectFormDetails.base_coordinates.longitude
              ) {
                let newObj = {};
                newObj = {
                  coordinates: [
                    parseFloat(
                      this.projectFormDetails.base_coordinates.longitude
                    ),
                    parseFloat(
                      this.projectFormDetails.base_coordinates.latitude
                    ),
                  ],
                };
                setTimeout(() => {
                  this.$refs.projectFormMap.clearMarker();
                  this.$refs.projectFormMap.addMultipleMarkers([newObj]);
                }, 100);
              }
            }
          }, 100);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    submitProjectForm() {
      let getData = this.$refs.projectFormMap.convertToGeoJSON();
      if (getData) {
        this.projectFormDetails.serviceable_area = getData.geometry;
      } else {
        bus.$emit("showToastMessage", {
          message: "Please draw your geofence",
          color: "error",
          timeout: 150,
        });
        return false;
      }
      bus.$emit("showLoader", true);
      if (this.formType == "Add") {
        this.$api.projects
          .createProjectObject(this.projectFormDetails)
          .then(() => {
            bus.$emit("showLoader", false);
            this.openProjectDialog = false;
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Project created successfully!",
              color: "success",
              timeout: 1500,
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      } else {
        this.$api.projects
          .updateProjectObject({
            id: this.projectFormDetails.id,
            data: this.projectFormDetails,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            this.openProjectDialog = false;
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Project updated successfully!",
              color: "success",
              timeout: 1500,
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            console.error(err);
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getProjectDetails(this.$route.params.id);
    }
    const interval = setInterval(() => {
      if (
        this.$refs.projectFormAddress &&
        window.google &&
        window.google.maps
      ) {
        clearInterval(interval);
        let input = document.getElementById("projectFormAddress");

        this.autocomplete = new window.google.maps.places.Autocomplete(input);
        this.autocomplete.setComponentRestrictions({
          country: ["AE"],
        });
        this.autocomplete.addListener("place_changed", () => {
          let place = this.autocomplete.getPlace();
          let lat = place.geometry.location.lat();
          let lon = place.geometry.location.lng();

          setTimeout(() => {
            this.projectFormDetails.base_address =
              place.name + ", " + place.formatted_address;
            this.projectFormDetails.base_coordinates.latitude = lat;
            this.projectFormDetails.base_coordinates.longitude = lon;
          }, 100);

          let newObj = {};
          newObj = {
            coordinates: [
              place.geometry.location.lng(),
              place.geometry.location.lat(),
            ],
          };
          setTimeout(() => {
            this.$refs.projectFormMap.clearMarker();
            this.$refs.projectFormMap.addMultipleMarkers([newObj]);
          }, 100);
        });
      }
    }, 100);
  },
};
</script>

<style>
</style>