<template>
  <div class="pa-4" v-if="tripData">
    <v-form v-model="isValid" ref="tripMarkForm" id="tripMarkForm">
      <v-row>
        <v-col l cols="6">
          <h2>Trip Mark as complete</h2>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn
            :disabled="!isValid"
            @click.prevent="submitForm()"
            class="primary text-uppercase mr-3"
          >
            <span>Submit</span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters class="mb-4 pr-">
            <v-col cols="6">
              <v-card outlined>
                <v-card-title
                  class="
                    primary
                    px-4
                    py-1
                    d-flex
                    justify-space-between
                    align-center
                  "
                >
                  <span
                    class="
                      cf-info-title
                      font-weight-bold
                      text-uppercase
                      white--text
                    "
                  >
                    Trip Details
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-row class="py-3">
                    <v-col cols="12">
                      <v-row class="pt-2">
                        <v-col cols="3" class="py-1">
                          <span class="cf-info-title font-weight-regular">
                            Trip Reference No
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-end text-right py-1"
                        >
                          <span class="cf-info-title font-weight-bold">
                            {{
                              tripData.reference_number
                                ? tripData.reference_number
                                : "-"
                            }}
                          </span>
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <span class="cf-info-title font-weight-regular">
                            Status
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-end text-right py-1"
                        >
                          <span class="cf-info-title font-weight-bold">
                            {{ tripData.status ? tripData.status : "-" }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col cols="3" class="py-1">
                          <span class="cf-info-title font-weight-regular">
                            Start Date
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-end text-right py-1"
                        >
                          <span class="cf-info-title font-weight-bold">
                            {{ tripData.trip_date ? tripData.trip_date : "-" }}
                          </span>
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <span class="cf-info-title font-weight-regular">
                            End Date
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-end text-right py-1"
                        >
                          <span class="cf-info-title font-weight-bold">
                            {{ tripData.trip_date ? tripData.trip_start : "-" }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col cols="3" class="py-1">
                          <span class="cf-info-title font-weight-regular">
                            Planned Duration
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-end text-right py-1"
                        >
                          <span class="cf-info-title font-weight-bold">
                            {{
                              tripData.planned_trip_duration
                                ? tripData.planned_trip_duration
                                : "-"
                            }}
                          </span>
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <span class="cf-info-title font-weight-regular">
                            Actual Duration
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-end text-right py-1"
                        >
                          <span class="cf-info-title font-weight-bold">
                            {{
                              tripData.actual_trip_duration
                                ? tripData.actual_trip_duration
                                : "-"
                            }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="pt-2">
                        <v-col cols="3" class="py-1">
                          <span class="cf-info-title font-weight-regular">
                            Planned Distance
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-end text-right py-1"
                        >
                          <span class="cf-info-title font-weight-bold">
                            {{
                              tripData.trip_start_km
                                ? `${tripData.trip_start_km} KM`
                                : "-"
                            }}
                          </span>
                        </v-col>
                        <v-col cols="3" class="py-1">
                          <span class="cf-info-title font-weight-regular">
                            Actual Distance
                          </span>
                        </v-col>
                        <v-col
                          cols="3"
                          class="d-flex justify-end text-right py-1"
                        >
                          <span class="cf-info-title font-weight-bold">
                            {{
                              tripData.trip_end_km
                                ? `${tripData.trip_end_km} KM`
                                : "-"
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-expansion-panels
            multiple
            v-model="expandPanel"
            flat
            class="e-border"
          >
            <v-row
              no-gutters
              class="mb-1 py-2 pl-3 pr-8"
              style="background-color: #9b182f"
            >
              <v-col cols="2" class="pl-2">
                <span class="body-1 font-weight-black white--text">
                  Reference
                </span>
              </v-col>
              <v-col cols="3" class="pl-2">
                <span class="body-1 font-weight-black white--text"
                  >Address</span
                >
              </v-col>
              <v-col cols="2" class="pl-2">
                <span class="body-1 font-weight-black white--text"
                  >Current Status</span
                >
              </v-col>
              <v-col cols="3" class="pl-2">
                <span class="body-1 font-weight-black white--text">Status</span>
              </v-col>
              <v-col cols="2" class="pl-2">
                <span class="body-1 font-weight-black white--text">Action</span>
              </v-col>
            </v-row>
            <v-expansion-panel
              v-for="(order, i) in tripData.trip_orders"
              :key="i"
              class="ma-0 ma-0"
            >
              <v-expansion-panel-header style="min-height: auto !important">
                <div>
                  <v-row>
                    <v-col cols="2">
                      <span class="text-body-2">{{
                        order.reference_number
                      }}</span>
                    </v-col>
                    <v-col cols="3">
                      <span class="text-body-2">{{ order.address }}</span>
                    </v-col>
                    <v-col cols="2">
                      <span class="text-body-2">{{
                        order.current_status
                      }}</span>
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        @click.stop=""
                        label="Status"
                        class="pr-4"
                        :items="orderType"
                        v-model="order.status"
                        item-text="name"
                        item-value="value"
                        @change="
                          formErrors && formErrors[i]
                            ? delete formErrors[i].status
                            : null;
                          validateForm(), actionChange(i);
                        "
                        hide-details="auto"
                        outlined
                        :menu-props="{ offsetY: true }"
                        :rules="[(v) => !!v || 'status is required']"
                        :error-messages="
                          formErrors &&
                          formErrors.length != 0 &&
                          formErrors[i] &&
                          formErrors[i].status
                            ? formErrors[i].status
                            : ''
                        "
                        dense
                        :value="order.status"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="2" v-if="order.status == 'unassigned'">
                      <v-text-field
                        type="date"
                        dense
                        hide-details="auto"
                        @click.stop=""
                        v-model="order.execution_date"
                        label="Execution Date"
                        :min="returnToday()"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                style="
                  background-color: #80808021;
                  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.14);
                "
              >
                <v-row no-gutters class="pt-4 pb-4">
                  <v-col cols="3">
                    <span class="text-body-2 font-weight-black button">
                      Item Number
                    </span>
                  </v-col>
                  <v-col cols="3">
                    <span class="text-body-2 font-weight-black button">
                      Item Name</span
                    >
                  </v-col>
                  <v-col cols="3">
                    <span class="text-body-2 font-weight-black button">
                      Original Quantity
                    </span>
                  </v-col>
                  <v-col cols="3">
                    <span class="text-body-2 font-weight-black button">
                      Delivery Quantity
                    </span>
                  </v-col>
                </v-row>
                <div style="max-height: 300px; overflow-y: auto">
                  <v-row
                    no-gutters
                    v-for="(item, index) in order.order_items"
                    :key="index"
                    class="py-2"
                  >
                    <v-col cols="3">
                      <span class="text-caption">{{ item.item_no }}</span>
                    </v-col>
                    <v-col cols="3">
                      <span class="text-caption">{{
                        item.item_description
                      }}</span>
                    </v-col>
                    <v-col cols="3">
                      <span class="text-caption">{{
                        item.original_quantity
                      }}</span>
                    </v-col>
                    <v-col cols="3" class="pr-9">
                      <span class="text-caption">
                        <v-text-field
                          label="Quantity"
                          type="number"
                          v-model.number="item.delivered_quantity"
                          hide-details="auto"
                          outlined
                          dense
                          :min="1"
                          :value="item.delivered_quantity"
                          @input="
                            formErrors &&
                            formErrors[i] &&
                            formErrors[i].order_items &&
                            formErrors[i].order_items[index] &&
                            formErrors[i].order_items[index].delivered_quantity
                              ? delete formErrors[i].order_items[index]
                                  .delivered_quantity
                              : null
                          "
                          :rules="getRules(order, i, item)"
                          :max="item.original_quantity"
                          :error-messages="
                            formErrors &&
                            formErrors[i] &&
                            formErrors[i].order_items &&
                            formErrors[i].order_items[index] &&
                            formErrors[i].order_items[index].delivered_quantity
                              ? formErrors[i].order_items[index]
                                  .delivered_quantity
                              : []
                          "
                        >
                        </v-text-field>
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { bus } from "@/main.js";
export default {
  name: "Trip-Mark-Complete",

  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      tripData: null,
      isValid: false,
      expandPanel: [],
      orderList: [],
      formErrors: [],
      tripId: null,
      orderType: [
        {
          name: "Unassigned",
          value: "unassigned",
        },
        {
          name: "Assigned",
          value: "assigned",
        },
        {
          name: "Shipped",
          value: "pickedup",
        },
        {
          name: "Partially Delivered",
          value: "partially_delivered",
        },
        {
          name: "Delivered",
          value: "successful",
        },
        {
          name: "Returned",
          value: "failed",
        },
        {
          name: "Cancelled",
          value: "cancelled",
        },
      ],
    };
  },
  methods: {
    returnToday() {
      let today = new Date();
      return this.getDateTime(today);
    },
    actionChange(index) {
      if (this.order && this.order[index].status != "unassigned") {
        delete this.order[index].execution_date;
      }
    },
    getDateTime(_d) {
      let _ = new Date(_d),
        date = _.getDate() < 10 ? "0" + _.getDate() : _.getDate(),
        month = _.getMonth() + 1,
        year = _.getFullYear();

      month = month < 10 ? "0" + month : month;

      return [year, month, date].join("-");
    },
    submitForm() {
      this.tripData.id = this.tripId;

      if (this.tripData && this.tripData.trip_orders) {
        this.tripData.trip_orders = this.tripData.trip_orders.map((item) => {
          if (item.status != "unassigned") {
            delete item.execution_date;
          }
          return item;
        });
        this.$api.trips
          .markTripAsComplete(this.tripData)
          .then((res) => {
            bus.$emit("showToastMessage", {
              message: "Mark as trip complete Successfully!",
              color: "success",
            });
            this.$router.push({ path: `/app/admin/trip/` });
          })
          .catch((err) => {
            this.formErrors = err.data.trip_orders;
            this.expandPanel = this.formErrors.map((item, index) => {
              return item && item.order_items && item.order_items.length > 0
                ? index
                : "";
            });
            this.$forceUpdate();
          });
      }
    },
    validateForm() {
      setTimeout(() => {
        const form = this.$refs.tripMarkForm;

        if (form) {
          form.validate();
        }
      }, 100);
    },
    getRules(order, i, item) {
      let rule = [
        (v) => !!v || "Delivered Quantity is required",
        (v) =>
          v > 0 || "Delivered Quantity can not be Less than original quantity",
      ];
      if (order.status == "partially_delivered") {
        rule.push(
          (v) =>
            v < item.original_quantity ||
            "Delivered Quantity can not be more than original quantity"
        );
      } else {
        rule.push(
          (v) =>
            v <= item.original_quantity ||
            "Delivered Quantity can not be more than original quantity"
        );
      }
      return rule;
    },
    getTripDetails(id) {
      this.tripId = id;
      this.$api.trips
        .getMarkAsTripDetails(id)
        .then((res) => {
          this.tripData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
      //   this.$store
      //     .dispatch("trip/GET_MARK_AS_TRIP_DETAILS", id)
      //     .then((response) => {
      //       this.tripData = response;
      //     });
    },
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.getTripDetails(this.$route.params.id);
    }
  },
};
</script>

<style>
.details-toggle-btn {
  top: 1px;
  right: 0px;
}
.expand-transation {
  transition: 0.3s;
}
.e-border {
  border: 1px solid #80808052;
}
</style>
