import { dashboard } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getFleetUtilization(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(dashboard.fleetUtilization, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderGraphData(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(dashboard.orderGraphData, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDashboardScoreCard(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(dashboard.scorecard, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
