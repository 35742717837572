import RolesIndex from "../pages/admin/roles/index.vue";

const Roles = [
  {
    path: "roles",
    name: "roles",
    component: RolesIndex,
  },
];

export default Roles;
