import { tripPlanningOld, project, version } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getTripPlans(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(tripPlanningOld.base, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deletePlannedTrip(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${tripPlanningOld.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  planNewTrip(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${tripPlanningOld.base}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  retryPlanTrip(id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${tripPlanningOld.base}${id}/retry_plan/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getPlanSummary(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tripPlanningOld.base}${id}/summary/`, { responseType: "blob" })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUnplannedOrderReasons(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tripPlanningOld.base}${id}/unplanned_order_reasons/`, {
          responseType: "blob",
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getPlanInformation(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tripPlanningOld.base}${id}/plan_info/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLocationData(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${tripPlanningOld.base}${params.plan_id}/planned_trips/${params.trip_id}/trip_route/`,
          { params: params }
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  confirmTrip(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${tripPlanningOld.base}${payload.plan_id}/create_trips/`,
          payload.data
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTripPlanData(plan_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tripPlanningOld.base}${plan_id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUnassignedOrders(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${version}project/${params.project_id}/validate_trip_plan/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
