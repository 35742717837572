import DriversIndex from "../pages/admin/drivers/index.vue";

const Drivers = [
  {
    path: "drivers",
    name: "drivers",
    component: DriversIndex,
  },
];

export default Drivers;
