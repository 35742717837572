<template>
   <GeneratedReport generatedReportType="order" />
</template>

<script>
import GeneratedReport from "@/components/reports/GeneratedReport.vue";
export default {
    name:"Order-Report",
    components  : {
        GeneratedReport
    }
}
</script>