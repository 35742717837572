import { support } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getSupportList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(support.base, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addSupportTicket(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(support.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSupportTicketObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${support.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateSupportTicket(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${support.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addSupportTicketComment(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${support.base}${data.id}/comment/`, data.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getSupportTicketObjectLogs(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${support.base}${id}/logs/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteSupportTicket(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${support.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
