<template >
  <BaseDialogForm
    v-model="openAssetChangeDialog"
    title="reassign driver and vehicle"
    :btnEnable="true"
    @reset="resetAssetChangeForm"
  >
    <template #content>
      <v-form ref="assetChangeForm" class="pt-4" v-model="isAssetChangeForm">
        <v-row>
          <v-col
            cols="12"
            v-if="formErrors && 'non_field_errors' in formErrors"
          >
            <v-alert color="red lighten-2" class="white--text">{{
              formErrors.non_field_errors
            }}</v-alert>
          </v-col>
          <v-col cols="6">
            <h3>Current Driver</h3>
            <p v-if="currentTrip && currentTrip.driver_name">
              {{ currentTrip.driver_name }}
            </p>
          </v-col>
          <v-col cols="6">
            <h3>Current Vehicle</h3>
            <p v-if="currentTrip && currentTrip.vehicle_info">
              {{ currentTrip.vehicle_info.vehicle_plate_no }}
            </p>
          </v-col>
          <v-col cols="6">
            <SelectField
              :itemsList="driversList"
              item-text="first_name"
              item-value="id"
              name="driver"
              hide-details="auto"
              label="Select Driver"
              required
              return-object
              :isClearable="true"
              @change="
                vehicleAssign();
                formErrors && formErrors.driver ? delete formErrors.driver : '';
                formErrors && formErrors.vehicle
                  ? delete formErrors.vehicle
                  : '';
              "
              :menu-props="{ offsetY: true }"
              v-model="selectedDriverDetails"
              :error-messages="formErrors.driver"
              :rules="[(v) => !!v || `Driver is required`]"
            />
          </v-col>
          <v-col cols="6">
            <SelectField
              :itemsList="VehicleList"
              item-text="vehicle_plate_no"
              item-value="id"
              outlined
              dense
              :isClearable="true"
              name="vehicle"
              hide-details="auto"
              label="Select Vehicle"
              required
              @change="
                formErrors && formErrors.vehicle
                  ? delete formErrors.vehicle
                  : ''
              "
              :menu-props="{ offsetY: true }"
              v-model="assetChangeObject.vehicle"
              :error-messages="formErrors.vehicle"
              :rules="[(v) => !!v || `Vehicle is required`]"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn
            color="primary"
            :disabled="!isAssetChangeForm"
            @click="submitAssetChangeForm"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </BaseDialogForm>
</template>
<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main";
export default {
  components: {
    BaseDialogForm,
  },
  props: {
    value: Boolean,
    currentTrip: Object,
  },
  data() {
    return {
      selectedDriverDetails: null,
      assetChangeObject: {},
      isAssetChangeForm: false,
      VehicleList: [],
      driversList: [],
      formErrors: [],
    };
  },
  watch: {
    openAssetChangeDialog(val) {
      if (val) {
        this.assetChangeObject = {};
        this.formErrors = [];
      }
    },
  },
  computed: {
    openAssetChangeDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    vehicleAssign() {
      let selectedVehicle = this.VehicleList.find(
        (v) => v.id == this.selectedDriverDetails.vehicle_assigned
      );
      this.assetChangeObject.driver = this.selectedDriverDetails.id;
      if (selectedVehicle && selectedVehicle.id) {
        this.assetChangeObject.vehicle = selectedVehicle.id;
      }
    },
    resetAssetChangeForm() {
      this.assetChangeObject = {};
      this.formErrors = [];
      this.$refs.assetChangeForm.reset();
      this.$refs.assetChangeForm.resetValidation();
    },
    submitAssetChangeForm() {
      let payload;
      payload = {
        id: this.currentTrip.id,
        data: this.assetChangeObject,
      };
      this.$api.trips
        .tripAssetChange(payload)
        .then((resp) => {
          bus.$emit("showToastMessage", {
            message: "Trip Details updated successfully",
            color: "success",
          });
          this.$emit("refreshTripDetails");
          this.resetAssetChangeForm();
          this.openAssetChangeDialog = false;
        })
        .catch((err) => {
          console.error(err);
          this.formErrors = err.data;
        });
    },
    getVehicleList(params = {}) {
      params = {
        ...params,
        // status: "idle",
        // limit: "all",
        // is_active: true,
      };
      bus.$emit("showLoader", true);
      this.$api.vehicles
        .getVehicleList(params)
        .then((res) => {
          this.VehicleList = res.data.results;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showLoader", false);
        });
    },
    getDriverList(params = {}) {
      params = {
        ...params,
        is_active: true,
        limit: "all",
      };
      bus.$emit("showLoader", true);
      this.$api.drivers
        .getDriverList(params)
        .then((res) => {
          this.driversList = res.data.results;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showLoader", false);
        });
    },
  },
};
</script>