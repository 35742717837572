import { integration } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  fetchCustomers() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.fetchCustomers}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  fetchItems() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.fetchItems}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  fetchB2BOrders() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.fetchB2BOrders}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  fetchB2COrders() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.fetchB2COrders}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  fetchSameDayDeliveryOrders(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.fetchSameDayDeliveryOrders}`,payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  fetchInvoice(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.fetchInvoice}`,payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  fetchOrderInadequate(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.fetchOrderInadequate}`, {
          responseType: "blob",
          headers: {
            Accept: "application/xlsx",
          },
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  sendLoadSheet(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.sendLoadSheet}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getAllB2CLogs(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.B2COrder}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getB2COrderObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.B2COrder}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createB2CLogs(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.B2COrder}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getAllCustomerLogs(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.customer}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerLogsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.customer}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createCustomerLogs(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.addCustomer}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getAllItemsLogs(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.items}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getItemsObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.items}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createItemLogs(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.addItem}`, payload.payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getAllB2BLogs(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.order}`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getB2BObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${integration.order}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createB2BLogs(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${integration.order}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
