<template>
  <BaseDialogForm
    v-model="orderFormDialog"
    persistent
    scrollable
    max-width="70%"
    @keydown.esc="closeDialog()"
    @reset="closeDialog()"
    title="Create Order"
  >
    <template #content>
      <v-alert v-if="nonFieldError.length" dense type="error">
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-form
        v-if="orderFormDialog"
        id="orderForm"
        ref="orderForm"
        v-model="isValid"
        class="pt-3"
        :key="formCount"
      >
        <v-row>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="SO Number*"
              name="reference_number"
              :rules="[
                (v) => (!!v && v.trim().length > 0) || 'SO Number is required',
              ]"
              v-model="orderFormDetails.reference_number"
              :error-messages="error.reference_number"
              @input="syncData($event, 'reference_number')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <v-menu
              ref="orderDateMenu"
              v-model="DatePickerMenu1"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              max-width="290px"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <InputField
                  label="Order Date*"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  hide-details="auto"
                  name="execution_date"
                  v-bind="attrs"
                  v-on="on"
                  class="background-white"
                  :error-messages="error.execution_date"
                  :rules="[(v) => !!v || 'Order date is required']"
                  v-model="orderFormDetails.execution_date"
                  @input="syncData($event, 'execution_date')"
                  :menu-props="{ offsetY: true }"
                ></InputField>
              </template>
              <v-date-picker
                no-title
                scrollable
                reactive
                :min="nowDate"
                v-model="orderFormDetails.execution_date"
                @click:date="
                  syncData($event, 'execution_date'),
                    $refs.orderDateMenu.save(date)
                "
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="3"
            v-if="formType == 'Edit' && orderFormDetails.payment_type == 'cod'"
          >
            <InputField
              outlined
              hide-details="auto"
              label="Payment received"
              name="payment_received"
              :error-messages="error.payment_received"
              v-model="orderFormDetails.payment_received"
              @input="syncData($event, 'payment_received')"
            ></InputField>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" class="mt-4">
            <span class="font-weight-bold text-subtitle-1"
              >Customer Details
            </span>
          </v-col>
          <v-col
            v-if="formType == 'Edit'"
            cols="6"
            class="mt-4 d-flex justify-end"
          >
            <span class="font-weight-bold text-subtitle-1">
              <v-radio-group
                row
                hide-details="auto"
                class="ma-0"
                name="new_customer"
                mandatory
                v-model="customerGroup"
                @change="customerTypeChange"
              >
                <v-radio label="Existing Customer" :value="false"></v-radio>
                <v-radio label="New Customer" :value="true"></v-radio>
              </v-radio-group>
            </span>
          </v-col>
        </v-row>
        <hr class="my-4" v-if="orderFormDetails.status != 'cancelled'" />
        <!-------------------------- already exists customer -------------------------->
        <v-row v-if="formType == 'Edit' && !customerGroup">
          <v-col cols="6" lg="3">
            <SelectField
              outlined
              hide-details="auto"
              label="Customer code*"
              :itemsList="customerCode"
              item-text="customer_code"
              item-value="id"
              name="customer_code"
              :rules="[(v) => !!v || 'Customer code is required']"
              :error-messages="error.customer_code"
              v-model="customerDetails.customer_code"
              @change="
                setCustomerCode($event),
                  error && error.customer_code ? delete error.customer_code : ''
              "
              return-object
            >
            </SelectField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Customer name*"
              name="customer_name"
              :error-messages="error.customer_name"
              :rules="[(v) => !!v || 'Customer name is required']"
              v-model="customerDetails.customer_name"
              @input="syncData($event, 'customer_name')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact person"
              name="contact_person"
              :error-messages="error.contact_person"
              v-model="customerDetails.contact_person"
              @input="syncData($event, 'contact_person')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              type="number"
              class="background-white"
              label="Contact number"
              name="contact_number"
              min="0"
              :error-messages="error.contact_number"
              v-model="customerDetails.contact_number"
              @input="syncData($event, 'contact_number')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact email"
              name="contact_email"
              :rules="emailRules"
              :error-messages="error.contact_email"
              v-model="customerDetails.contact_email"
              @input="syncData($event, 'contact_email')"
            ></InputField>
          </v-col>
          <v-col cols="12">
            <v-text-field
              disabled
              outlined
              ref="customerAddressFormAddress"
              id="customerAddressFormAddress"
              hide-details="auto"
              class="background-white"
              dense
              name="address"
              placeholder="Enter Address*"
              :rules="[
                (v) => (!!v && v.trim().length > 0) || 'Address is required',
              ]"
              :error-messages="error.address"
              @input="
                error && error.address
                  ? delete error.address
                  : '' || (error && error.coordinates)
                  ? delete error.coordinates
                  : ''
              "
              v-model="customerDetails.address"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <InputField
              disabled
              outlined
              hide-details="auto"
              class="background-white"
              label="Latitude"
              type="number"
              step="0.000001"
              name="latitude"
              :error-messages="error.coordinates"
              @input="
                error && error.coordinates ? delete error.coordinates : ''
              "
              v-model="customerDetails.coordinates.latitude"
            ></InputField>
          </v-col>
          <v-col cols="3">
            <InputField
              disabled
              outlined
              hide-details="auto"
              class="background-white"
              label="Longitude"
              type="number"
              step="0.0000001"
              name="longitude"
              :error-messages="error.coordinates"
              @input="
                error && error.coordinates ? delete error.coordinates : ''
              "
              v-model="customerDetails.coordinates.longitude"
            ></InputField>
          </v-col>
        </v-row>
        <!------------------------ New customer  ----------------------------->
        <v-row v-if="formType == 'Edit' && customerGroup" v-model="newCustomer">
          <!--------------------------------- Customer form --------------------------------->
          <v-col cols="6" md="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Customer Code*"
              :rules="[
                (v) =>
                  (!!v && v.trim().length > 0) || 'Customer Code is required',
              ]"
              :error-messages="error.customer_code"
              v-model="customerDetails.customer_code"
              @input="syncData($event, 'customer_code')"
            ></InputField>
          </v-col>
          <v-col cols="6" md="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Customer Name*"
              name="customer_name"
              :rules="[
                (v) =>
                  (!!v && v.trim().length > 0) || 'Customer Name is required',
              ]"
              :error-messages="error.customer_name"
              v-model="customerDetails.customer_name"
              @input="syncData($event, 'customer_name')"
            ></InputField>
          </v-col>
          <v-col cols="6" md="3">
            <SelectField
              outlined
              hide-details="auto"
              :menu-props="{ offsetY: true }"
              label="Customer Type"
              :itemsList="customerType"
              name="customer_type"
              :error-messages="error.customer_type"
              v-model="customerDetails.customer_type"
              @change="syncData($event, 'customer_type')"
            ></SelectField>
          </v-col>
          <v-col cols="6" md="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact Person"
              name="contact_person"
              :error-messages="error.contact_person"
              v-model="customerDetails.contact_person"
              @input="syncData($event, 'contact_person')"
            ></InputField>
          </v-col>
          <v-col cols="6" md="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact Number"
              name="contact_number"
              type="number"
              min="0"
              step="1"
              :error-messages="error.contact_number"
              v-model="customerDetails.contact_number"
              @input="syncData($event, 'contact_number')"
            ></InputField>
          </v-col>
          <v-col cols="6" md="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact email*"
              name="contact_email"
              :rules="emailRules"
              :error-messages="error.contact_email"
              v-model="orderFormDetails.contact_email"
              @input="syncData($event, 'contact_email')"
            ></InputField>
          </v-col>
          <v-col cols="3">
            <SelectField
              outlined
              hide-details="auto"
              label="Project*"
              :menu-props="{ offsetY: true }"
              name="project"
              :itemsList="allProjects"
              :rules="[(v) => !!v || 'Project is required']"
              :error-messages="error.project"
              v-model="customerDetails.project"
              @change="syncData($event, 'project')"
            ></SelectField>
          </v-col>
          <v-col cols="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Processing Time(Minutes)*"
              name="processing_time"
              min="0"
              type="number"
              step="01"
              :error-messages="error.processing_time"
              v-model="customerDetails.processing_time"
              :rules="[(v) => !!v || 'Processing Time is required']"
              @input="syncData($event, 'processing_time')"
            ></InputField>
          </v-col>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              ref="customerAddressFormAddress"
              hide-details="auto"
              class="background-white"
              name="address"
              placeholder="Enter Address*"
              :rules="[
                (v) => (!!v && v.trim().length > 0) || 'Address is required',
              ]"
              :error-messages="error.address"
              v-model="customerDetails.address"
              @input="syncData($event, 'address')"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Latitude"
              type="number"
              step="0.000001"
              name="latitude"
              :error-messages="error.coordinates"
              v-model="customerDetails.coordinates.latitude"
              @input="syncData($event, 'coordinates', 'latitude')"
            ></InputField>
          </v-col>
          <v-col cols="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Longitude"
              type="number"
              step="0.0000001"
              name="longitude"
              :error-messages="error.coordinates"
              v-model="customerDetails.coordinates.longitude"
              @input="syncData($event, 'coordinates', 'longitude')"
            ></InputField>
          </v-col>
          <v-col cols="3">
            <InputField
              label="From Time*"
              outlined
              type="time"
              class="background-white"
              hide-details="auto"
              name="from_time"
              :error-messages="error.delivery_window_start"
              v-model="customerDetails.delivery_window_start"
              :rules="[(v) => !!v || 'From time is required']"
              @change="syncData($event, 'delivery_window_start')"
            ></InputField>
          </v-col>
          <v-col cols="3">
            <InputField
              label="To Time*"
              type="time"
              outlined
              class="background-white"
              hide-details="auto"
              name="to_time"
              :error-messages="error.delivery_window_end"
              v-model="customerDetails.delivery_window_end"
              :rules="[
                (v) => !!v || 'To time is required',
                (v) =>
                  (customerDetails.delivery_window_start &&
                    v > customerDetails.delivery_window_start) ||
                  'To time can not be less than from time',
              ]"
              @change="syncData($event, 'delivery_window_end')"
            ></InputField>
          </v-col>
          <v-col cols="6">
            <SwitchField
              label="WhatsApp Notifications"
              v-model="customerDetails.whatsapp_notification"
              @change="syncData($event, 'whatsapp_notification')"
            ></SwitchField>
          </v-col>
          <v-col cols="6">
            <SwitchField
              label="E-mail Notifications"
              v-model="customerDetails.email_notification"
              @change="syncData($event, 'email_notification')"
            ></SwitchField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-4">
            <span class="font-weight-bold text-subtitle-1"> Add Item </span>
            <hr class="my-4" />
          </v-col>

          <v-col cols="12" class="px-6">
            <v-row
              v-for="(item, index) in orderFormDetails.order_items"
              :key="index"
            >
              <v-col cols="3" class="pl-0">
                <SelectField
                  :itemsList="itemCode"
                  label="Item Code"
                  v-model="item.item_no"
                  item-text="item_no"
                  item-value="item_no"
                  required
                  :return-object="true"
                  :rules="[(val) => !!val || `Item Code is required`]"
                  :error-messages="
                    error &&
                    error.order_items &&
                    error.order_items[index] &&
                    error.order_items[index].item
                      ? error.order_items[index].item
                      : []
                  "
                  @change="
                    error &&
                    error.order_items &&
                    error.order_items[index] &&
                    error.order_items[index].item
                      ? (delete error.order_items[index].item,
                        delete error.order_items[index].unit)
                      : [];
                    setItemCode($event, index);
                  "
                />
              </v-col>
              <v-col cols="3">
                <InputField
                  outlined
                  hide-details="auto"
                  dense
                  :readonly="true"
                  label="Item Name*"
                  :rules="[(v) => !!v || 'Item Name is required']"
                  v-model="item.item_description"
                ></InputField>
              </v-col>
              <v-col cols="2">
                <InputField
                  type="number"
                  min="1"
                  outlined
                  hide-details="auto"
                  dense
                  label="Quantity*"
                  :error-messages="error.Quantity"
                  :rules="[
                    (v) => !!v || 'Quantity is required',
                    (v) => v > 0 || 'Quantity can not be 0',
                  ]"
                  v-model="item.quantity"
                ></InputField>
              </v-col>
              <v-col cols="2" class="d-flex justify-center">
                <div class="pr-3">
                  <v-btn
                    :small="$vuetify.breakpoint.xl"
                    :x-small="$vuetify.breakpoint.lgAndDown"
                    v-if="orderFormDetails.order_items.length > 1"
                    class="light_grey elevation-0 py-5"
                    @click="removeItem(index)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </div>
                <v-btn
                  :small="$vuetify.breakpoint.xl"
                  :x-small="$vuetify.breakpoint.lgAndDown"
                  class="primary elevation-0 py-5"
                  @click="addItem()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        :disabled="!isValid"
        width="9%"
        class="primary"
        @click.prevent="submitOrderForm()"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'Add'"
        width="9%"
        class="primary mr-4"
        @click="clear()"
      >
        <span>Reset</span>
      </v-btn></template
    >
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
  },
  props: {
    value: Boolean,
    formType: {
      type: String,
      default: "Add",
    },
  },
  data() {
    return {
      formCount: 0,
      orderFormDetails: {
        coordinates: {},
        order_items: [{}],
      },
      newCustomer: false,
      customerDetails: {},
      uploadFiles: null,
      allProjects: [],
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      // variables for autocomplete
      longitude: null,
      latitude: null,
      // variables for widgets
      menuFromTime: false,
      menuToTime: false,
      customerGroup: false,
      nonFieldError: [],
      isLoading: false,
      isLoadingItem: false,
      customerCode: [],
      documentList: [],
      itemCode: [],
      order_log_id: null,
      error: {
        customer_code: [],
      },
      search: "",
      customer_data: {},
      searchItem: [],
      customerType: [
        { text: "Business", value: "B2B" },
        { text: "Individual", value: "B2C" },
      ],
      selectedItemIndex: 0,
      customerNotifications: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
      podRequired: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
      barcodeScanning: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
      orderType: [
        {
          name: "Delivery",
          value: "delivery",
        },
        {
          name: "Pickup",
          value: "pickup",
        },
      ],
      paymentType: [
        {
          name: "Prepaid",
          value: "prepaid",
        },
        {
          name: "Cash on delivery",
          value: "cod",
        },
        {
          name: "Credit",
          value: "credit",
        },
      ],
      orderStatus: [
        {
          name: "Unassigned",
          value: "unassigned",
        },
        {
          name: "Assigned",
          value: "assigned",
        },
        {
          name: "Shipped",
          value: "pickedup",
        },
        {
          name: "Partially Delivered",
          value: "partially_delivered",
        },
        {
          name: "Delivered",
          value: "successful",
        },
        {
          name: "Returned",
          value: "failed",
        },
        {
          name: "Cancelled",
          value: "cancelled",
        },
      ],
      DatePickerMenu1: false,
      isValid: false,
      date: null,
      nowDate: new Date().toISOString().slice(0, 10),
      executed: true,
    };
  },
  watch: {
    orderFormDialog(val) {
      if (val) {
        this.customerDetails = {
          coordinates: {},
        };
        this.getAllItems();
        this.getAllProjects();
        this.getCustomerDropDownList();
        this.autoCompleteAddress();
      }
    },
  },
  computed: {
    orderFormDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getCustomerDropDownList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      bus.$emit("showLoader", true);
      this.$api.customerAddresses
        .getCustomerDropDownList(params)
        .then((res) => {
          this.customerCode = res.data.results;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        })
        .finally(() => bus.$emit("showLoader", false));
    },
    getAllItems(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.items
        .getItemList(params)
        .then((res) => {
          this.itemCode = res.data.results;
        })
        .catch((err) => {});
    },
    addItem() {
      this.orderFormDetails.order_items.push({});
      setTimeout(() => {
        let elmnt = document.getElementById("orderForm");
        elmnt.scrollIntoView({ block: "end", behavior: "smooth" });
      }, 300);
    },
    removeItem(index) {
      this.orderFormDetails.order_items.splice(index, 1);
    },
    customerTypeChange() {
      this.customerDetails = {
        customer_code: "",
        coordinates: {},
      };

      if (this.$refs.orderForm) {
        this.$refs.orderForm.resetValidation();
      }

      this.syncData(null, "customer_code");
      this.syncData(null, "customer_name");
      this.syncData(null, "contact_number");
      this.syncData(null, "contact_email");
      this.syncData(null, "contact_person");
      this.syncData(null, "address");
      this.syncData(null, "project");
      this.executed = true;
    },
    setCustomerCode(e) {
      this.customerDetails.customer_name = e.customer_name;
      this.customerDetails.contact_person = e.contact_person;
      this.customerDetails.contact_number = e.contact_number;
      this.customerDetails.contact_email = e.contact_email;
      this.customerDetails.project = e.project;
      this.customerDetails.address = e.address;
      this.customerDetails.coordinates.latitude = e.coordinates.latitude;
      this.customerDetails.coordinates.longitude = e.coordinates.longitude;

      // this.syncData(e.customer_code, "customer_code");
      // this.syncData(e.customer_name, "customer_name");
      // this.syncData(e.contact_number, "contact_number");
      // this.syncData(e.contact_email, "contact_email");
      // this.syncData(e.contact_person, "contact_person");
      // this.syncData(e.address, "address");
      // this.syncData(e.project, "project");
    },
    async closeDialog() {
      this.orderFormDialog = false;
      await this.clear();
    },
    syncData(input_value, key, subKey) {
      if (this.error[key]) {
        this.error[key] = null;
        delete this.error[key];
      }
      // this.orderFormDetails[key] = input_value;
      if (key == "address" && this.executed) {
        this.executed = false;
        this.autoCompleteAddress();
      }
      // }
    },
    setItemCode(e, index) {
      let itemNo = this.itemCode.find((v) => v.item_no == e.item_no).item_no;
      let itemDescription = this.itemCode.find(
        (v) => v.item_no == e.item_no
      ).item_description;

      this.orderFormDetails.order_items[index].item_no = itemNo;
      this.orderFormDetails.order_items[index].item_description =
        itemDescription;
    },
    getOrderDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.integration
        .getB2BObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.formCount++;
          this.orderFormDetails = res.data;
          let customerDetailsObj = {
            customer_code: res.data.customer_code,
            contact_person: res.data.contact_person,
            contact_number: res.data.contact_number,
            contact_email: res.data.contact_email,
            address: res.data.address,
            coordinates: {
              latitude: res.data.coordinates.latitude,
              longitude: res.data.coordinates.longitude,
            },
            // delivery_window_start: res.data.delivery_window_start,
            // delivery_window_end: res.data.delivery_window_end,
          };

          this.customerDetails = customerDetailsObj;

          if (res.data.error.non_field_errors) {
            this.nonFieldError = res.data.error.non_field_errors;
          }
          if (res.data.error.customer_address) {
            if (res.data.error.customer_address) {
              this.error["customer_code"] = res.data.error["customer_address"];
              delete res.data.error["customer_address"];
            }
          }
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Something went wrong",
            color: "error",
          });
        });
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    autoCompleteAddress() {
      const interval = setInterval(async () => {
        if (
          this.$refs.customerAddressFormAddress &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(interval);
          let input = this.$refs.customerAddressFormAddress.$refs.input;

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["AE"],
          });
          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat();
            let lon = place.geometry.location.lng();

            setTimeout(() => {
              this.customerDetails.address =
                place.name + ", " + place.formatted_address;
              this.customerDetails.coordinates.latitude = lat;
              this.customerDetails.coordinates.longitude = lon;
            }, 100);
          });
        }
      }, 100);
    },
    clear() {
      this.$refs.orderForm.reset();
      this.error = {};
      this.nonFieldError = [];
      this.executed = true;
      this.documentList = [];
    },

    submitOrderForm() {
      let payload;

      let customerObj;

      customerObj = {
        ...this.customerDetails,
        customer_code:
          typeof this.customerDetails.customer_code == "object"
            ? this.customerDetails.customer_code.customer_code
            : this.customerDetails.customer_code,
      };
      payload = {
        ...this.orderFormDetails,
        ...customerObj,
        log_id: this.orderFormDetails.id,
        new_customer: this.customerGroup ? true : false,
      };
      this.$api.integration
        .createB2BLogs(payload)
        .then((result) => {
          this.closeDialog();
          this.$emit("refreshList");
          bus.$emit("showToastMessage", {
            message: "Order successfully Added!",
            color: "success",
          });
        })
        .catch((err) => {
          if (err.data.non_field_errors) {
            this.nonFieldError = err.data.non_field_errors;
          }
          this.error = err.data;
        });
    },
  },
};
</script>

<style>
</style>