<template>
  <v-dialog v-model="openTripPlanningTemplateForm" scrollable width="50vw">
    <v-card>
      <v-card-title class="background-primary text-white px-3 py-2">
        <span
          class="text-subtitle-1 text-uppercase"
          v-if="!readOnly && !editMode"
        >
          Create Template
        </span>
        <span
          class="text-subtitle-1 text-uppercase"
          v-if="!readOnly && editMode"
        >
          Edit Template
        </span>
        <span
          class="text-subtitle-1 text-uppercase"
          v-if="readOnly && !editMode"
        >
          View Template
        </span>
        <v-spacer></v-spacer>
        <v-btn depressed color="white" icon small @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form
          ref="templateForm"
          v-model="isTemplateFormValid"
          class="pa-0"
          :key="formCount"
        >
          <v-row no-gutters class="px-4 pt-4 background-light_primary">
            <v-col class="pb-4" cols="12">
              <InputField
                label="Template Name *"
                class="background-white"
                dense
                :rules="[(v) => !!v || 'Template Name is required']"
                hide-details="auto"
                outlined
                :error-messages="error.template_name"
                v-model="templateDetails.template_name"
                :readonly="readOnly"
              >
              </InputField>
            </v-col>
            <v-col class="pb-4 pr-2" cols="6" md="4" lg="4" xl="4">
              <InputField
                label="Fill Ratio"
                hide-details="auto"
                type="number"
                class="background-white"
                dense
                :min="1"
                :max="100"
                prepend-inner-icon=""
                outlined
                :error-messages="error.fill_ratio"
                v-model="templateDetails.fill_ratio"
                :rules="[
                  (val) => !!val || 'Fill Ratio is required',
                  (val) =>
                    parseInt(val) > 0 || 'Fill Ratio can not be less than 0',
                  (val) =>
                    parseInt(val) < 101 ||
                    'Fill Ratio can not be greater than 100',
                ]"
              >
              </InputField>
            </v-col>
            <!-- <v-col class="pb-4 px-2" cols="6" md="4" lg="4" xl="4"> </v-col> -->
            <v-col
              class="pb-4 pl-2 d-flex justify-end"
              cols="12"
              md="6"
              lg="6"
              xl="6"
            >
              <SelectField
                outlined
                dense
                :readonly="readOnly"
                hide-details="auto"
                class="background-white"
                label="Optimization Option"
                :items-list="configurationOptions"
                item-text="text"
                item-value="value"
                v-model="templateDetails.configuration"
                :menu-props="{ offsetY: true }"
              ></SelectField>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4 pt-4">
            <v-col cols="8">
              <v-row no-gutters>
                <v-col class="pb-4 pr-2" cols="6" md="6" lg="6" xl="6">
                  <InputField
                    label="Loading Start Time *"
                    hide-details="auto"
                    type="time"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Loading start time is required']"
                    :readonly="readOnly"
                    :error-messages="error.loading_start_time"
                    v-model="templateDetails.loading_start_time"
                  >
                  </InputField>
                </v-col>
                <v-col class="pb-4 pl-2" cols="6" md="6" lg="6" xl="6">
                  <InputField
                    label="Loading End Time *"
                    hide-details="auto"
                    type="time"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Loading end time is required']"
                    :readonly="readOnly"
                    :error-messages="error.loading_end_time"
                    v-model="templateDetails.loading_end_time"
                  >
                  </InputField>
                </v-col>
                <v-col class="pb-4 pr-2" cols="6" md="6" lg="6" xl="6">
                  <InputField
                    label="Offloading Start Time *"
                    hide-details="auto"
                    type="time"
                    outlined
                    :rules="[(v) => !!v || 'OffLoading start time is required']"
                    :readonly="readOnly"
                    dense
                    :error-messages="error.offloading_start_time"
                    v-model="templateDetails.offloading_start_time"
                  >
                  </InputField>
                </v-col>
                <v-col class="pb-4 pl-2" cols="6" md="6" lg="6" xl="6">
                  <InputField
                    label="Offloading End Time *"
                    hide-details="auto"
                    type="time"
                    :rules="[(v) => !!v || 'OffLoading end time is required']"
                    :readonly="readOnly"
                    outlined
                    dense
                    :error-messages="error.offloading_end_time"
                    v-model="templateDetails.offloading_end_time"
                  >
                  </InputField>
                </v-col>
                <v-col class="pr-2" cols="6" md="6" lg="6" xl="6">
                  <InputField
                    label="Loading Time(In Minutes) *"
                    hide-details="auto"
                    type="number"
                    min="0"
                    :rules="[(v) => !!v || 'Loading time is required']"
                    outlined
                    dense
                    :readonly="readOnly"
                    :error-messages="error.loading_time"
                    v-model="templateDetails.loading_time"
                  >
                  </InputField>
                </v-col>
                <v-col class="pl-2" cols="6" md="6" lg="6" xl="6">
                  <InputField
                    label="Offloading Time(In Minutes) *"
                    hide-details="auto"
                    type="number"
                    min="0"
                    outlined
                    dense
                    :readonly="readOnly"
                    :rules="[(v) => !!v || 'Offloading time is required']"
                    :error-messages="error.offloading_time"
                    v-model="templateDetails.offloading_time"
                  >
                  </InputField>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4" class="d-flex px-4">
              <v-row no-gutters class="d-flex align-center">
                <v-col class="px-3 d-flex align-center justify-start" cols="12">
                  <SwitchField
                    label="Round Trip"
                    color="primary"
                    class="ma-0 pa-0"
                    inset
                    dense
                    v-model="templateDetails.round_trip"
                    :readonly="readOnly"
                    hide-details
                  ></SwitchField>
                </v-col>
                <v-col class="px-3 d-flex align-center justify-start" cols="12">
                  <SwitchField
                    label="Zone Constraint"
                    color="primary"
                    class="mb-1 mt-2 pa-0"
                    inset
                    dense
                    :readonly="readOnly"
                    v-model="templateDetails.zone_constraint"
                    hide-details
                  ></SwitchField>
                </v-col>
                <v-col class="px-3 d-flex align-center justify-start" cols="12">
                  <SwitchField
                    label="Tag Validations"
                    color="primary"
                    class="mb-1 mt-2 pa-0"
                    inset
                    dense
                    :readonly="readOnly"
                    v-model="templateDetails.tag_validations"
                    hide-details
                  ></SwitchField>
                </v-col>
                <v-col class="px-3 d-flex align-center justify-start" cols="12">
                  <SwitchField
                    label="Disable time windows"
                    color="primary"
                    class="mt-1 mb-2 pa-0"
                    inset
                    dense
                    :readonly="readOnly"
                    v-model="templateDetails.disable_time_windows"
                    hide-details
                  ></SwitchField>
                </v-col>
                <v-col class="px-3 d-flex align-center justify-start" cols="12">
                  <SwitchField
                    label="Traffic Jams"
                    color="primary"
                    class="mt-1 mb-2 pa-0"
                    inset
                    dense
                    :readonly="readOnly"
                    v-model="templateDetails.traffic_jams"
                    hide-details
                  ></SwitchField>
                </v-col>
                <v-col class="px-3 d-flex align-center justify-start" cols="12">
                  <SwitchField
                    label="Toll Roads"
                    color="primary"
                    class="ma-0 pa-0"
                    inset
                    dense
                    :readonly="readOnly"
                    v-model="templateDetails.toll_roads"
                    hide-details
                  ></SwitchField>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters> </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions
        class="mt-4 d-flex justify-end pa-4 background-light_grey"
      >
        <v-btn
          small
          class="primary elevation-0"
          :disabled="!isTemplateFormValid"
          @click="submitTemplate"
        >
          <span>Submit</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { configurationOptions } from "@/utils/choices.js";
import InputField from "@/components/BaseFormComponents/InputField.vue";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import SwitchField from "@/components/BaseFormComponents/SwitchField.vue";
import { bus } from "@/main.js";
export default {
  components: {
    InputField,
    SelectField,
    SwitchField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      configurationOptions,

      templateDetails: {
        configuration: "optimize_cars_then_locality_grouping",
        loading_end_time: null,
        loading_start_time: null,
        loading_time: null,
        offloading_end_time: null,
        offloading_start_time: null,
        offloading_time: null,
        planning_time: null,
        // result_ttl: null,
        template_name: null,
        round_trip: false,
        // disable_compatibility: false,
        disable_time_windows: false,
        toll_roads: true,
        traffic_jams: true,
        zone_constraint: false,
        fill_ratio: 100,
      },
      isTemplateFormValid: false,
      nonFieldError: [],
      error: {},
      formCount: 0,
    };
  },
  computed: {
    openTripPlanningTemplateForm: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    syncData(key, value) {
      this.templateDetails[key] = value;
    },
    clearForm() {
      this.templateDetails = {
        configuration: "optimize_cars_then_locality_grouping",
        loading_end_time: null,
        loading_start_time: null,
        loading_time: null,
        offloading_end_time: null,
        offloading_start_time: null,
        offloading_time: null,
        planing_time: null,
        // result_ttl: null,
        template_name: null,
        round_trip: false,
        // disable_compatibility: false,
        toll_roads: true,
        traffic_jams: true,
        zone_constraint: false,
        tag_validations: false,
        disable_time_windows: false,
        fill_ratio: "100",
      };
      // this.$refs.templateForm.reset();
      this.$refs.templateForm.resetValidation();
    },
    closeForm() {
      this.clearForm();
      this.openTripPlanningTemplateForm = false;
    },
    submitTemplate() {
      bus.$emit("showLoader", true);
      if (!this.editMode && !this.readOnly) {
        this.$api.tripPlanningTemplate
          .addTripPlanningTemplate(this.templateDetails)
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeForm();
            bus.$emit("showToastMessage", {
              message: "Template Added!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else if (this.editMode && !this.readOnly) {
        this.$api.tripPlanningTemplate
          .updateTripPlanningTemplateObject({
            id: this.templateDetails.id,
            data: this.templateDetails,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeForm();
            bus.$emit("showToastMessage", {
              message: "Template Updated!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            this.formErrors = err.data;
          });
      } else {
        console.log("");
      }
    },
    getTripPlanningTemplateDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.tripPlanningTemplate
        .getTripPlanningTemplateObject(id)
        .then((res) => {
          this.formCount++;
          this.templateDetails = res.data;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.log(err);
          bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style>
</style>