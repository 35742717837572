<template>
  <ListLayout
    title="Statuses"
    :tableData="statusList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getStatusList"
    :tableScrollable="false"
    :isColumnShowVisibility="true"
  >
    <template #listAction>
      <v-btn small depressed class="primary" @click="addStatus()">
        <v-icon small class="mr-1">mdi-plus</v-icon>
        <span>Add Status</span>
      </v-btn>
    </template>
    <template #dialogs>
      <StatusForm
        ref="statusForm"
        v-model="openStatusForm"
        :formType="formType"
        @refreshList="getStatusList({ limit: 10, offset: 0 })"
      />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import statusButton from "@/components/common/aggrid/buttons/statusButton.vue";
import StatusForm from "@/components/status/StatusForm.vue";
import { bus } from "@/main.js";

export default {
  name: "statusIndex",
  components: {
    ListLayout,
    statusButton,
    StatusForm,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      statusList: [],
      openStatusForm: false,
      formType: "Add",
      headerSelected: [
        {
          headerName: "Status Name",
          pinned: "left",
          field: "name",
          width: 400,
        },
        {
          headerName: "Description",
          field: "description",
        },
        {
          headerName: "Status Type",
          field: "keyword",
          width: 50,
          cellRenderer: (params) => {
            return params.data.keyword == "successful"
              ? "Delivered"
              : params.data.keyword == "failed"
              ? "Returned"
              : params.data.keyword;
          },
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        {
          headerName: "Actions",
          width: 170,
          maxWidth: 170,
          field: "actions",
          pinned: "right",
          cellRendererFramework: "statusButton",
        },
      ];
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getStatusList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.status
        .getStatusList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.statusList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },

    addStatus() {
      this.formType = "Add";
      this.openStatusForm = true;
    },

    editStatus(id) {
      this.formType = "Edit";
      this.openStatusForm = true;
      this.$refs.statusForm.getStatusObject(id);
    },

    deleteStatus(id) {
      bus.$emit("showLoader", true);
      this.$api.status
        .deleteStatusObject(id)
        .then(() => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Status deleted successfully",
            color: "success",
          });
          this.getStatusList();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
