<template>
  <ListLayout
    title="Projects"
    :tableData="projectsList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getProjectList"
    :tableScrollable="false"
    localStorageKey="project_columns"
    :isColumnShowVisibility="false"
  >
    <template #listAction>
      <v-btn
        v-if="permissions && permissions.project && permissions.project.add"
        small
        depressed
        class="primary"
        @click="addProject()"
      >
        <v-icon small class="mr-1">mdi-plus</v-icon>
        <span>Add Project</span>
      </v-btn>
    </template>
    <template #dialogs>
      <ProjectForm
        ref="projectForm"
        v-model="openProjectForm"
        :formType="formType"
        @refreshList="getProjectList({ limit: 10, offset: 0 })"
      />

      <ProjectZoneVisualization
        ref="zoneVisualization"
        v-model="openProjectZoneVisualizationDialog"
      />

      <LogDialog
        :logsArray="projectLogs"
        title="Logs"
        v-model="openProjectLogs"
      />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import ProjectEdit from "@/components/common/aggrid/buttons/projectEdit.vue";
import { toTitleCase } from "@/utils/functions.js";
import ProjectForm from "@/components/projects/ProjectForm.vue";
import { bus } from "@/main.js";
import ProjectZoneVisualization from "@/components/projects/ProjectZoneVisualization.vue";
import ProjectLogs from "@/components/projects/ProjectLogs.vue";
import LogDialog from "@/components/BaseComponents/LogDialog.vue";
import CheckOrderCreationNotification from "@/components/common/aggrid/icons/CheckOrderCreationNotification.vue";
import CheckUpdateCustomerLocation from "@/components/common/aggrid/icons/CheckUpdateCustomerLocation.vue";

export default {
  name: "projectsIndex",
  components: {
    ListLayout,
    ProjectEdit,
    ProjectForm,
    ProjectLogs,
    LogDialog,
    ProjectZoneVisualization,
    CheckOrderCreationNotification,
    CheckUpdateCustomerLocation,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      projectsList: [],
      openProjectForm: false,
      openProjectZoneVisualizationDialog: false,
      openProjectLogs: false,
      projectLogs: [],
      formType: "Add",
      headerSelected: [
        {
          headerName: "Project Name",
          pinned: "left",
          field: "project_name",
        },
        {
          headerName: "Project ID",
          field: "project_id",
        },
        {
          headerName: "Template Name",
          field: "planning_template",
        },
        {
          headerName: "Base Address",
          field: "base_address",
        },
        {
          headerName: "Order Creation Notification",
          field: "order_creation_notification",
          cellRendererFramework: "CheckOrderCreationNotification",
        },
        {
          headerName: "Update Customer Location",
          field: "update_customer_location",
          cellRendererFramework: "CheckUpdateCustomerLocation",
        },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: (params) => {
            return toTitleCase(params.value);
          },
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        {
          headerName: "Actions",
          width: 220,
          maxWidth: 220,
          minWidth: 220,
          field: "actions",
          pinned: "right",
          cellRendererFramework: "ProjectEdit",
        },
      ];
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getProjectList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.projects
        .getProjectList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.projectsList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", true);
          bus.$emit("showToastMessage", {
            message: "Something went wrong!",
            color: "error",
          });
        });
    },

    addProject() {
      this.formType = "Add";
      this.openProjectForm = true;
    },

    editProject(id) {
      this.formType = "Edit";
      this.openProjectForm = true;
      this.$refs.projectForm.getProjectDetails(id);
    },
    viewZonesHandler(zone) {
      this.openProjectZoneVisualizationDialog = true;
      this.$refs.zoneVisualization.viewZones(zone);
    },
    viewLogsHandler(params = {}) {
      params = {
        ...params,
        module_type: "project",
      };
      this.$api.projects
        .getProjectLogs(params)
        .then((res) => {
          this.projectLogs = res.data.results;
          this.openProjectLogs = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
