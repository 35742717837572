<template>
  <BaseDialogForm
    v-model="itemFormDialog"
    persistent
    scrollable
    max-width="60%"
    :title="`${formType} Item`"
  >
    <template #content>
      <v-alert v-if="nonFieldError.length" dense type="error">
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ i }} .</span><span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-form ref="itemForm" v-model="isValid" :key="formCount">
        <v-row>
          <v-col cols="6" lg="4">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Item number is required']"
              type="text"
              class="background-white"
              label="Item Number*"
              :error-messages="error.item_no"
              @input="delete error.item_no"
              v-model="itemFormDetails.item_no"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              hide-details="auto"
              outlined
              label="Item name*"
              class="background-white"
              :rules="[
                (v) => (!!v && v.trim().length > 0) || 'Item Name is required',
              ]"
              :error-messages="error.item_description"
              @input="delete error.item_description"
              v-model="itemFormDetails.item_description"
            ></InputField>
          </v-col>
          <v-col cols="12" lg="2"></v-col>
          <v-col cols="6" lg="3">
            <SelectField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Item Group is required']"
              type="number"
              label="Item Group*"
              :itemsList="storageType"
              item-text="title"
              item-value="value"
              :error-messages="error.storage_type"
              @input="delete error.storage_type"
              v-model="itemFormDetails.storage_type"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="6" lg="3">
            <SelectField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Unit is required']"
              label="Unit*"
              :itemsList="unitType"
              item-text="text"
              item-value="value"
              v-model="itemFormDetails.unit"
              :error-messages="error.unit"
              @input="delete error.unit"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Case Factor is required']"
              type="number"
              class="background-white"
              label="Case Factor*"
              min="0"
              v-model="itemFormDetails.case_factor"
              :error-messages="error.case_factor"
              @input="delete error.case_factor"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Length is required']"
              step="0.0001"
              type="number"
              class="background-white"
              label="Length*"
              min="0.0001"
              v-model="itemFormDetails.length"
              :error-messages="error.length"
              @input="delete error.length"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Width is required']"
              step="0.0001"
              type="number"
              class="background-white"
              label="Width*"
              min="0.0001"
              v-model="itemFormDetails.width"
              :error-messages="error.width"
              @input="delete error.width"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Height is required']"
              step="0.0001"
              type="number"
              class="background-white"
              label="Height*"
              min="0.0001"
              v-model="itemFormDetails.height"
              :error-messages="error.height"
              @input="delete error.height"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Volume is required']"
              type="number"
              class="background-white"
              label="Volume (CBM)*"
              min="0.0001"
              step="0.0001"
              v-model="itemFormDetails.cbm"
              :error-messages="error.cbm"
              @input="delete error.cbm"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Weight is required']"
              step="0.0001"
              type="number"
              class="background-white"
              label="Weight*"
              min="0.0001"
              :error-messages="error.weight"
              v-model="itemFormDetails.weight"
              @input="delete error.weight"
            ></InputField>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        class="primary text-uppercase mr-3"
        lg="6"
        @click.prevent="submitItemForm()"
        :disabled="!isValid"
      >
        <span>Submit</span>
      </v-btn></template
    >
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      isValid: false,
      formCount: 0,
      error: {},
      nonFieldError: [],
      itemFormDetails: {},
      storageType: [
        { title: "Chilled", value: "CHILLED" },
        { title: "Dry", value: "DRY" },
        { title: "Frozen", value: "FROZEN" },
      ],
      unitType: [
        {
          text: "KG",
          value: "KG",
        },
        {
          text: "Case",
          value: "CASE",
        },
        {
          text: "Each",
          value: "EACH",
        },
      ],
      item_log_id: null,
    };
  },
  computed: {
    itemFormDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getItemDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.integration
        .getItemsObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.formCount++;
          this.itemFormDetails = res.data.payload;
          this.itemFormDetails["id"] = res.data.id;
          this.error = res.data.error;
          if (res.data.error.non_field_errors) {
            this.nonFieldError = res.data.error.non_field_errors;
          }
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },
    async closeDialog() {
      this.itemFormDialog = false;
    },
    submitItemForm() {
      this.itemFormDetails["log_id"] = this.itemFormDetails.id;
      let payload;
      payload = {
        id: this.itemFormDetails.id,
        payload: this.itemFormDetails,
      };
      this.$api.integration
        .createItemLogs(payload)
        .then((result) => {
          this.closeDialog();
          this.$emit("refreshList");
          bus.$emit("showToastMessage", {
            message: "Item created successfully!",
            color: "success",
          });
        })
        .catch((err) => {
          if (err.data.non_field_errors) {
            this.nonFieldError = err.data.non_field_errors;
          }
          this.error = err.data;
        });
    },
    clear() {
      this.itemFormDetails = {
        case_factor: null,
        cbm: null,
        height: null,
        item_description: null,
        item_no: null,
        length: null,
        storage_type: null,
        unit: null,
        weight: null,
        width: null,
      };
    },
  },
};
</script>

<style>
</style>