import { getDateTime } from "@/assets/utils.js";

export default {
  itemSampleData: [
    {
      "Item name": "STARBUCKS FRAPPUCINO MOCHA (8X250 ML) #5711953026096",
      "Item No": 37270,
      "Storage Type": "Chilled",
      Unit: "CASE",
      "Case Factor": 1,
      Length: 0.1261,
      Width: 0.15810000000000002,
      Height: 0.03681,
      "Volume (CBM)": 0.0007338592521,
      Weight: 3.681,
    },
    {
      "Item name": "STARBUCKS FRAPPUCINO CARAMEL (8X250 ML) #5711953041020",
      "Item No": 37271,
      "Storage Type": "Chilled",
      Unit: "CASE",
      "Case Factor": 1,
      Length: 0.1261,
      Width: 0.16010000000000002,
      Height: 0.03681,
      "Volume (CBM)": 0.0007431427341,
      Weight: 3.681,
    },
  ],
  orderSampleData: [
    {
      "Item No": 37270,
      "SO Number": "RF0001",
      "Customer Code": "R0011",
      "Delivery Date": "2021-06-08",
      Quantity: 8,
    },
    {
      "Item No": 37271,
      "SO Number": "RF0002",
      "Customer Code": "R0011",
      "Delivery Date": "2021-06-08",
      Quantity: 6,
    },
  ],
  customerAddressSampleData: [
    {
      "Customer Code": "C00121",
      "Customer Name": "name_1",
      "Customer Type": "B2B",
      "Contact Person": "jay pandya",
      "Customer Number": 98324092302,
      "Contact Email": "sam1@mail.com",
      "Project Id": "PRJ0001",
      "Processing time": "12",
      Address: "address_1",
      Latitude: 45.78364342,
      Longitude: 65.78364342,
      "From time": "05:30",
      "To time": "6:30",
      "Whatsapp Notification": "Yes",
      "Email Notification": "No",
      Tags: "Tag1, Tag2",
    },
    {
      "Customer Code": "C00122",
      "Customer Name": "name_2",
      "Customer Type": "B2B",
      "Contact Person": "krunal tank",
      "Customer Number": 98324092302,
      "Contact Email": "sam2@mail.com",
      "Project Id": "PRJ0001",
      "Processing time": "12",
      Address: "address_2",
      Latitude: 45.78364342,
      Longitude: 65.78364342,
      "From time": "05:30",
      "To time": "6:30",
      "Whatsapp Notification": "No",
      "Email Notification": "Yes",
      Tags: "Tag1, Tag2",
    },
  ],
  vehiclesSampleData: [
    {
      "Vehicle Number": "DX-00-1002",
      "Project Id": "PRJ0002",
      "Fuel Type": "diesel",
      "Tonnage Capacity": "70.00",
      "CBM Capacity": "99.00",
      "Box Capacity": 150,
      Permits: "Low Load",
      "Insurance Policy Number": "INUR00002",
      "Insurance Expiry Date": "2028-02-02",
      "Insurance Type": "Single Party",
      "RC Number": "RC-000-000-0002",
      "RC Expiry Date": "2026-11-17",
      "Vehicle Make": "Tata",
      "Vehicle Model": "NX",
      "Vehicle Year": "2012",
      "Vehicle Cost": 1233,
      "Odometer Reading": 12,
      Storages: "Dry,Chilled,Frozen",
      "Sensor Ids": "s_125,s_96416,s_54145",
      Tags: "Tag1, Tag2",
    },
    {
      "Vehicle Number": "DX-00-1001",
      "Project Id": "PRJ0001",
      "Fuel Type": "diesel",
      "Tonnage Capacity": "70.00",
      "CBM Capacity": "99.00",
      "Box Capacity": 200,
      Permits: "Low Load",
      "Insurance Policy Number": "INUR00002",
      "Insurance Expiry Date": "2028-02-02",
      "Insurance Type": "Single Party",
      "RC Number": "RC-000-000-0002",
      "RC Expiry Date": "2026-11-17",
      "Vehicle Make": "Tata",
      "Vehicle Model": "NX",
      "Vehicle Year": "2012",
      "Vehicle Cost": 1233,
      "Odometer Reading": 12,
      Storages: "Dry,Chilled,Frozen",
      "Sensor Ids": "s_125,s_96416,s_54145",
      Tags: "Tag3, Tag4",
    },
  ],
  driversSampleData: [
    {
      "First Name": "Kashyap1",
      "Last Name": "Padiya",
      Username: "kashyap",
      Password: "",
      "Contact Number": "324543254",
      "Project Id": "PRJ0002",
      Zone: null,
      "Shift Start": "09:00",
      "Shift End": "20:00",
      "License Number": "4234324",
      "License Expiry": "2024-10-26",
      Nationality: "Indian",
      "National Id Expiry": "2024-10-16",
      "Health Card Number": "34234234",
      "Health Card Expiry": "2024-10-16",
      Salary: 20000,
      "Service Type": "B2B",
      "Vehicle Assigned": "DX-00-1102",
    },
  ],
  bulkUpdateSampleData: [
    {
      "SO Number": "REF001",
      "Invoice Number": "INVOICE1",
      "Delivery Date": "2021-06-21",
      "Payment Type": "cod",
      "Order Value": "321",
      "Proof of Delivery": "Yes",
    },
    {
      "SO Number": "ORD002",
      "Invoice Number": "INVOICE2",
      "Delivery Date": "2021-06-21",
      "Payment Type": "prepaid",
      "Order Value": "321",
      "Proof of Delivery": "No",
    },
    {
      "SO Number": "ORD003",
      "Invoice Number": "INVOICE3",
      "Delivery Date": "2021-06-21",
      "Payment Type": "credit",
      "Order Value": "321",
      "Proof of Delivery": "No",
    },
  ],
  bulkUploadOrderB2CSampleData: [
    {
      "SO Number": "ORDER-0001",
      "Item No": "ITEM-01",
      "Delivery Date": "2021-12-08",
      Quantity: 8,
      "Customer Number": "98324094645",
      "Customer Name": "Name 1",
      "Contact Email": "sam1@mail.com",
      "Project Id": "Project 1",
      "Processing Time": 12,
      Address: "Address 1",
      Latitude: 25.0722592,
      Longitude: 55.1424116,
      "From Time": "05:30",
      "To Time": "06:30",
    },
    {
      "SO Number": "ORDER-0002",
      "Item No": "ITEM-02",
      "Delivery Date": "2021-12-08",
      Quantity: 8,
      "Customer Number": "98324092302",
      "Customer Name": "Name 2",
      "Contact Email": "sa21@mail.com",
      "Project Id": "Project 1",
      "Processing Time": 16,
      Address: "Address 2",
      Latitude: 25.1859023,
      Longitude: 55.2265797,
      "From Time": "10:30",
      "To Time": "11:30",
    },
  ],
};
