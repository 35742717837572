<template>
  <v-app-bar :clipped-left="true" :clipped-right="true" fixed app>
    <v-toolbar-title class="d-flex">
      <img :src="logo" height="60px" class="py-1" />
    </v-toolbar-title>
    <v-spacer />
    <div class="mr-6">
      <UserNotifications v-model="openNotifications" />
    </div>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="150"
      min-width="100px"
      offset-y
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-avatar height="90%">
            <!-- v-if="userDetails" -->
            <!-- userDetails.profile_image
                  ? userDetails.profile_image -->
            <img
              :src="$globalConstant.profileImage"
              style="width: 70% !important"
              alt="User"
            />
          </v-avatar>
          <span class="text-primary font-weight-bold pl-3">
            {{ userData && userData.first_name ? userData.first_name : "" }}
            {{ userData && userData.last_name ? userData.last_name : "" }}
          </span>
        </div>
      </template>
      <v-card elevation="0">
        <v-card-text class="pa-0">
          <v-list>
            <v-list-item @click="openProfileDialog = true">
              <v-icon>mdi-account</v-icon>
              <span class="pl-2 text-capitalize">profile</span>
            </v-list-item>
            <!-- <v-list-item @click="openBranchDialog = true"> </v-list-item> -->
            <v-list-item @click="openChangePasswordDialog = true">
              <v-icon>mdi-lock-reset</v-icon>
              <span class="pl-2 text-capitalize">change password</span>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-icon>mdi-logout</v-icon>
              <span class="pl-2 text-capitalize">Logout</span>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>

    <Profile v-model="openProfileDialog" />
    <ChangePassword v-model="openChangePasswordDialog" />
  </v-app-bar>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Logo from "@/assets/logo.png";
import { userLogout } from "@/utils/functions.js";
// import userImage from "@/assets/user.png";
import Profile from "@/components/profile/Profile.vue";
import ChangePassword from "@/components/profile/ChangePassword.vue";
import UserNotifications from "@/components/common/UserNotifications.vue";

export default {
  name: "mainHeader",
  components: {
    UserNotifications,
    Profile,
    ChangePassword,
  },
  data() {
    return {
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
      userLogout,
      openLanguageChangeDialog: false,
      menu: false,
      // userImage: userImage,
      menu1: false,
      logo: Logo,
      openNotifications: false,
      openProfileDialog: false,
      openBranchDialog: false,
      openProjectDialog: false,
      openChangePasswordDialog: false,
      userDetails: {
        last_name: "",
        first_name: "",
      },
    };
  },
  computed: {
    userData() {
      if (localStorage.getItem("userdetails")) {
        return JSON.parse(localStorage.getItem("userdetails"));
      } else {
        return console.log();
      }
    },
  },
  methods: {
    logout() {
      this.userLogout();
    },

    getProfileDetails() {
      this.$api.profile
        .getUserProfile()
        .then((result) => {
          this.userDetails = result.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    removeLocalStorage() {
      localStorage.removeItem("customer_columns");
      localStorage.removeItem("customeraddress_columns");
      localStorage.removeItem("vehicle_columns");
      localStorage.removeItem("drivers_columns");
      localStorage.removeItem("warehouse_columns");
      localStorage.removeItem("branch_columns");
      localStorage.removeItem("user_columns");
      localStorage.removeItem("warehouse_columns");
      localStorage.removeItem("itemmaster_columns");
      localStorage.removeItem("trip_planning_template_column");

      localStorage.removeItem("orderFilters");
      localStorage.removeItem("tripFilters");
      localStorage.removeItem("driverFilters");
      localStorage.removeItem("vehicleFilters");
      localStorage.removeItem("orderWarningFilters");
      localStorage.removeItem("zoneFilters");
      localStorage.removeItem("customerFilters");
      localStorage.removeItem("customerAddressFilters");
      localStorage.removeItem("voyage_columns");
      localStorage.removeItem("itemMasterFilters");
    },
  },
  mounted() {
    // this.getProfileDetails();
  },
};
</script>

<style></style>
