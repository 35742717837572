import { trips, version, version3,version2, orders } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getTripList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(trips.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTripDetails(id, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trips.base}${id}/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTripObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trips.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  createTrips(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${trips.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateTrip(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${trips.base}${payload.id}/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getSheetItemList(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trips.base}${id}/load_sheet/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getLoadSheetItemList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trips.base}trip_load_sheet/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTripSheetItemList(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trips.base}${id}/trip_sheet/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTripTemperatureData(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trips.base}${id}/temperature_log/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createTripTemperature(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${trips.base}bulk_upload_temperature_sheet/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  // getActualDriverRoute(id) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`${trips.base}${id}/map_route`, { params: params })
  //       .then((res) => {
  //         resolve(handleResponse(res));
  //       })
  //       .catch((err) => {
  //         reject(handleError(err));
  //       });
  //   });
  // },
  getMarkAsTripDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trips.base}${id}/mark_as_complete/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  markTripAsComplete(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${trips.base}${payload.id}/mark_as_complete/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getUnassignedOrders(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${version2}filter_trip_orders/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTripRecommendations(params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${trips.base}get_trip_recommendations/`, params)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  tripAssetChange(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${trips.base}${payload.id}/asset_change/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getActualDriverRoute(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${trips.base}${id}/map_route/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
