<template>
  <div>
    <v-icon v-if="params && params.data &&params.data.order_creation_notification" class="mr-4 green--text">mdi-check-circle</v-icon>
    <v-icon v-else class="mr-4 red--text">mdi-close-circle</v-icon>
  </div>
</template>
<script>
export default {
  
}
</script>

