<template>
  <ListLayout
    ref="listTable"
    title="Role"
    :context="context"
    :tableData="roleList"
    :tableHeader="tableHeaders"
    :total="totalItem"
    @getList="getRoleList"
    :hasFilter="true"
  >
    <template #listAction>
      <v-btn small depressed class="primary" @click="addRole()">
        <v-icon small class="mr-1">mdi-plus</v-icon>
        <span>Add Role</span>
      </v-btn>
    </template>

    <!-- <template #leftFilterSlot>
      <v-col cols="4">
        <InputField
          label="Search here.."
          prepend-inner-icon="mdi-magnify"
          hide-details="auto"
          outlined
          dense
          v-model="searchString"
          @input="getRoleList()"
        ></InputField>
      </v-col>
    </template> -->

    <template #dialogs>
      <RoleForm
        ref="roleForm"
        v-model="openRoleForm"
        :formType="formType"
        @refreshList="getRoleList({ limit: 10, offset: 0 })"
      />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { bus } from "@/main.js";
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import RoleButton from "@/components/common/aggrid/buttons/RoleButton.vue";
import RoleForm from "@/components/role/RoleForm.vue";

export default {
  name: "roleIndex",
  components: {
    ListLayout,
    RoleButton,
    RoleForm,
  },
  data() {
    return {
      permissions: null,
      searchString: null,
      formType: "add",
      openRoleForm: false,
      sorting: {},
      totalItem: 0,
      filters: {},
      roleList: [],
      headerSelected: [
        {
          headerName: "Name",
          field: "role_name",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 200,
          sortable: false,
          width: 200,
          cellRenderer: "RoleButton",
        },
      ];
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },

    addRole() {
      this.formType = "Add";
      this.openRoleForm = true;
    },

    editRole(id) {
      this.formType = "Edit";
      this.openRoleForm = true;
      this.$refs.roleForm.getRoleDetails(id);
    },

    deleteRole(id) {
      bus.$emit("showLoader", true);
      this.$api.role
        .deleteRoleObject(id)
        .then(() => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Role deleted successfully!",
            color: "success",
          });
          this.getRoleList();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Something went wrong!",
            color: "error",
          });
        });
    },

    getRoleList(params = {}) {
      params = {
        ...params,
        ...this.filters,
      };

      if (this.searchString) {
        params.search = this.searchString;
      }

      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      bus.$emit("showLoader", true);
      this.$api.role
        .getRoleList(params)
        .then((res) => {
          this.roleList = res.data.results;
          this.totalItem = res.count;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showLoader", false);
        });
    },
  },

  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
