<template>
  <div>
    <v-row no-gutters>
      <v-col cols="3">
        <div class="border-right-light_grey">
          <v-row no-gutters>
            <v-col cols="12" class="background-light_grey px-4 pt-4">
              <v-row no-gutters>
                <v-col cols="12" class="px-2 pt-2">
                  <span
                    class="text-h5 text-uppercase font-weight-bold text-grey"
                  >
                    Operations
                  </span>
                </v-col>
                <v-col cols="12" class="d-flex px-2 pb-4">
                  <v-select
                    ref="operationProjectFilter"
                    class="background-white"
                    placeholder="Select Project.."
                    :items="projectList"
                    v-model="selectedProjects"
                    hide-details
                    multiple
                    outlined
                    dense
                    :menu-props="{ offsetX: true }"
                  >
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0">{{ item.text }}</span>
                      <span
                        v-if="index === 1 && $refs.operationProjectFilter"
                        class="grey--text text-caption"
                      >
                        (+{{ selectedProjects.length - 1 }} others)
                      </span>
                    </template>
                    <template v-slot:append-item>
                      <hr style="opacity: 0.3" />
                      <v-list-item
                        class="
                          background-light_grey
                          px-1
                          position-sticky
                          d-flex
                          justify-end
                          stick-bottom
                        "
                      >
                        <v-btnf
                          small
                          depressed
                          color="primary"
                          class ="v-btn v-btn--has-bg theme--light v-size--small v-size--small primary"    
                          @click="applyProjectFilter()"
                        >
                          Apply
                        </v-btnf>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" class="d-flex px-2 pb-4" v-if="showTripLoader">
                  <v-scroll-x-transition mode="out-in">
                    <v-progress-linear
                      color="primary"
                      indeterminate
                      rounded
                      height="6"
                    ></v-progress-linear>
                  </v-scroll-x-transition>
                </v-col>

                <v-col cols="12">
                  <v-tabs
                    :show-arrows="true"
                    fixed-tabs
                    slider-color="primary"
                    background-color="light_grey"
                    class="px-2"
                    active-class="primary-text"
                    v-model="activeTab"
                  >
                    <v-tab small class="background-light_grey">
                      <span>Ongoing</span>
                    </v-tab>
                    <v-tab class="background-light_grey">
                      <span>Completed</span>
                    </v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pb-4 mt-4">
              <v-tabs-items
                v-model="activeTab"
                class="px-4 overflow-y-auto"
                :style="{ height: tabHeight }"
              >
                <v-tab-item>
                  <div class="pb-2">
                    <InputField
                      class="background-white"
                      placeholder="Search here..."
                      label="Search here.."
                      prepend-inner-icon="mdi-magnify"
                      v-model="searchOngoingTrips"
                      hide-details
                      outlined
                      dense
                      clearable
                    ></InputField>
                  </div>
                  <div v-for="(trip, index) in ongoingTrips" :key="index">
                    <TripCard
                      @selectTrip="selectedTripChanged(trip)"
                      @showChat="
                        getDriverChatData(
                          trip.driver_details.id,
                          trip.driver_details.driver_name,
                          trip.driver_details.status
                        )
                      "
                      :trip="trip"
                      :selectedTrip="selectedTrip"
                      :showChatButton="true"
                    />
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="pb-2">
                    <InputField
                      class="background-white"
                      placeholder="Search here..."
                      label="Search here.."
                      prepend-inner-icon="mdi-magnify"
                      v-model="searchCompletedTrips"
                      hide-details
                      outlined
                      dense
                      clearable
                    ></InputField>
                  </div>
                  <div v-for="(trip, index) in completedTrips" :key="index">
                    <TripCard
                      :trip="trip"
                      :selectedTrip="selectedTrip"
                      :showChatButton="false"
                    />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>

          <div class="position-relative">
            <Chat v-model="openChatDialogBox" :driverDetails="driverDetails" />
          </div>
        </div>
      </v-col>
      <v-col cols="9">
        <div class="px-4 pt-4">
          <v-row>
            <v-col cols="1" class="d-flex justify-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    icon
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    @click="previousDay"
                  >
                    <v-icon large> mdi-arrow-left-drop-circle </v-icon>
                  </v-btn>
                </template>
                <span>Previous Day </span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <InputField
                type="date"
                label="Select Date"
                v-model="date"
                hide-details
                dense
                outlined
              />
            </v-col>
            <v-col cols="1" class="d-flex justify-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    icon
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    @click="nextDay"
                  >
                    <v-icon large> mdi-arrow-right-drop-circle </v-icon>
                  </v-btn>
                </template>
                <span>Next Day </span>
              </v-tooltip>
            </v-col>
            <v-col cols="7" class="pb-4 d-flex justify-end">
              <v-btn
                small
                class="elevation-0 ma-1 primary"
                @click="viewMap = !viewMap"
              >
                <v-icon v-if="!viewMap" small class="mr-1">
                  mdi-map-marker-path
                </v-icon>
                <v-icon v-else small class="mr-1">mdi-table</v-icon>
                <span v-if="!viewMap">View map</span>
                <span v-else>View table</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="px-4 pb-4">
          <v-row>
            <v-col cols="12" v-if="selectedTrip && tripDetails">
              <v-row>
                <v-col cols="4">
                  <v-card class="full-height">
                    <v-card-title
                      class="pa-2 background-light_grey d-flex justify-center"
                    >
                      <span>Driver Details</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row
                        no-gutters
                        v-for="(value, key) in tripDetails.driver_details"
                        :key="key"
                      >
                        <v-col cols="6" class="text-caption text-capitalize">
                          {{ key }}
                        </v-col>
                        <v-col cols="6" class="text-caption d-flex justify-end">
                          <b>{{ value }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="full-height">
                    <v-card-title
                      class="pa-2 background-light_grey d-flex justify-center"
                    >
                      <span>Order Statistics</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row
                        no-gutters
                        v-for="(value, key) in tripDetails.trip_order_count"
                        :key="key"
                      >
                        <v-col cols="6" class="text-caption text-capitalize">
                          {{ key }}
                        </v-col>
                        <v-col cols="6" class="text-caption d-flex justify-end">
                          <b>{{ value }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="full-height">
                    <v-card-title
                      class="pa-2 background-light_grey d-flex justify-center"
                    >
                      <span>Vehicle Statistics</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row
                        no-gutters
                        v-for="(value, key) in tripDetails.trip_order_partition"
                        :key="key"
                      >
                        <v-col cols="6" class="text-caption text-capitalize">
                          {{ key }}
                        </v-col>
                        <v-col cols="6" class="text-caption d-flex justify-end">
                          <b>{{ value }}</b>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" v-show="viewMap" class="pt-4">
              <Gmap
                mapWidth="100%"
                :mapHeight="mapHeight"
                ref="operationsViewTrip"
                :clusterMarkers="true"
                @selectDriver="selectedTripChanged"
              />
              <Mapinfo :legends="legends" />
            </v-col>
            <v-col class="bottom-devider-shadow" cols="12" v-if="!viewMap">
              <v-tabs
                :slider-color="getColor(orderTab)"
                v-model="orderTab"
                height="70"
              >
                <v-tab>
                  <v-card class="elevation-0 background-transparent">
                    <v-card-title
                      class="font-weight-black text-md-h6 d-flex justify-center"
                      :class="
                        orderTab == 0 ? 'text-' + getColor(orderTab) : null
                      "
                      >{{ assignedOrders.length }}</v-card-title
                    >
                    <v-card-subtitle>Assigned</v-card-subtitle>
                  </v-card>
                </v-tab>
                <v-tab>
                  <v-card class="elevation-0 background-transparent">
                    <v-card-title
                      class="font-weight-black text-md-h6 d-flex justify-center"
                      :class="
                        orderTab == 1 ? 'text-' + getColor(orderTab) : null
                      "
                      >{{ pickedupOrders.length }}</v-card-title
                    >
                    <v-card-subtitle>Shipped</v-card-subtitle>
                  </v-card>
                </v-tab>
                <v-tab>
                  <v-card class="elevation-0 background-transparent">
                    <v-card-title
                      class="font-weight-black text-md-h6 d-flex justify-center"
                      :class="
                        orderTab == 2 ? 'text-' + getColor(orderTab) : null
                      "
                      >{{ partiallyDeliveredOrders.length }}</v-card-title
                    >
                    <v-card-subtitle>Partially Delivered</v-card-subtitle>
                  </v-card>
                </v-tab>
                <v-tab>
                  <v-card class="elevation-0 background-transparent">
                    <v-card-title
                      class="font-weight-black text-md-h6 d-flex justify-center"
                      :class="
                        orderTab == 3 ? 'text-' + getColor(orderTab) : null
                      "
                      >{{ successfulOrders.length }}</v-card-title
                    >
                    <v-card-subtitle>Delivered</v-card-subtitle>
                  </v-card>
                </v-tab>
                <v-tab>
                  <v-card class="elevation-0 background-transparent">
                    <v-card-title
                      class="font-weight-black text-md-h6 d-flex justify-center"
                      :class="
                        orderTab == 4 ? 'text-' + getColor(orderTab) : null
                      "
                      >{{ failedOrders.length }}</v-card-title
                    >
                    <v-card-subtitle>Returned</v-card-subtitle>
                  </v-card>
                </v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="12" class="pa-3" v-show="!viewMap">
              <AgGridVue
                @grid-ready="gridReady"
                :context="context"
                :grid-options="gridOptions"
                :column-defs="columnDefs"
                :default-col-def="defaultColDef"
                :row-data="
                  orderTab == 0
                    ? assignedOrders
                    : orderTab == 1
                    ? pickedupOrders
                    : orderTab == 2
                    ? partiallyDeliveredOrders
                    : orderTab == 3
                    ? successfulOrders
                    : orderTab == 4
                    ? failedOrders
                    : []
                "
                :style="{ width: '100%', height: gridHeight }"
                class="ag-theme-alpine"
              >
              </AgGridVue>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <OrderDetailDialog ref="orderDetails" v-model="showOrderDetails" />
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import TripCard from "@/components/operations/TripCard.vue";
import Gmap from "@/components/common/Gmap.vue";
import Mapinfo from "@/components/common/MapInfo.vue";
import {
  WarehouseIcon,
  OrderAssignedIcon,
  OrderUnassignedIcon,
  OrderPickupIcon,
  OrderSuccessfulIcon,
  OrderFailedIcon,
  OrderCancelledIcon,
  OrderEnrouteIcon,
} from "@/assets/mapIcons/icons";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import OrderOperationButton from "@/components/common/aggrid/buttons/OrderOperationButton.vue";
import OrderDetailDialog from "@/components/operations/OrderDetailDialog.vue";
import { getColor } from "@/utils/functions.js";
import Chat from "@/components/common/Chat.vue";
import { bus } from "@/main.js";

export default {
  name: "operationsIndex",
  components: {
    AgGridVue,
    TripCard,
    Gmap,
    Mapinfo,
    OrderOperationButton,
    OrderDetailDialog,
    Chat,
  },
  data() {
    return {
      getColor,
      showTripLoader: false,
      activeTab: 0,
      viewMap: true,

      searchOngoingTrips: null,
      searchCompletedTrips: null,
      date: moment().format("YYYY-MM-DD"),
      selectedProjects: [],
      filter: {},

      driverDetails: {},
      openChatDialogBox: false,

      selectedTrip: null,
      tripDetails: null,

      tripListInterval: null,

      legends: [
        { text: "Warehouse", marker: WarehouseIcon },
        { text: "Unassigned", marker: OrderUnassignedIcon },
        { text: "Assigned", marker: OrderAssignedIcon },
        { text: "Shipped", marker: OrderPickupIcon },
        { text: "Delivered", marker: OrderSuccessfulIcon },
        { text: "Returned", marker: OrderFailedIcon },
        { text: "Cancelled", marker: OrderCancelledIcon },
        { text: "Partially Delivered", marker: OrderEnrouteIcon },
      ],

      orderTab: null,
      orderSearch: null,

      defaultColDef: {
        lockPosition: true,
      },
      gridApi: null,
      columnApi: null,
      gridOptions: {
        pagination: true,
        paginationPageSize: 20,
        headerHeight: 40,
        rowHeight: 40,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },

      showOrderDetails: false,
      projectList: [],
      tripList: [],
      orderList: [],
    };
  },
  watch: {
    activeTab() {
      this.getTripList();
    },
    date() {
      this.getTripList();
      this.getOrderList();
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    gridHeight() {
      return `${window.innerHeight - 290}px`;
    },
    mapHeight() {
      if (this.selectedTrip) {
        return `${window.innerHeight - 363}px`;
      } else {
        return `${window.innerHeight - 161}px`;
      }
    },
    tabHeight() {
      return `${window.innerHeight - 320}px`;
    },
    columnDefs() {
      const headers = [
        {
          headerName: "Action",
          field: "action",
          cellRendererFramework: "OrderOperationButton",
          width: 90,
          pinned: "left",
        },
        {
          headerName: "Order Reference",
          field: "reference_number",
          width: 170,
          sortable: true,
        },
        {
          headerName: "No. Of Items",
          field: "item_count",
        },
        {
          headerName: "Order Type",
          field: "order_type",
          width: 140,
          sortable: true,
        },
        {
          headerName: "Address",
          field: "address",
          width: 135,
          sortable: true,
        },
        {
          headerName: "Driver Name",
          field: "driver_name",
        },
        {
          headerName: "Contact Number",
          field: "contact_number",
          width: 175,
          sortable: true,
        },
        {
          headerName: "Customer",
          field: "customer_name",
          width: 175,
          sortable: true,
        },
        {
          headerName: "Project",
          field: "project_name",
        },
        {
          headerName: "Updated On",
          field: "updated_on",
        },
      ];
      if (this.orderTab == 2 || this.orderTab == 4) {
        headers.push({
          headerName: "Status Keyword",
          field: "status_keyword",
        });
      }

      return headers;
    },
    ongoingTrips() {
      if (
        this.searchOngoingTrips != null &&
        this.searchOngoingTrips.length &&
        this.searchOngoingTrips.trim() != ""
      ) {
        return this.tripList.filter((trip) => {
          return (
            trip.reference_number
              .toLowerCase()
              .indexOf(this.searchOngoingTrips.toLowerCase()) > -1 &&
            trip.status != "completed"
          );
        });
      } else {
        return this.tripList.filter((trip) => {
          return trip.status != "completed";
        });
      }
    },
    completedTrips() {
      if (
        this.searchCompletedTrips != null &&
        this.searchCompletedTrips.length &&
        this.searchCompletedTrips.trim() != ""
      ) {
        return this.tripList.filter((trip) => {
          return (
            trip.reference_number
              .toLowerCase()
              .indexOf(this.searchCompletedTrips.toLowerCase()) > -1 &&
            trip.status == "completed"
          );
        });
      } else {
        return this.tripList.filter((trip) => {
          return trip.status == "completed";
        });
      }
    },
    allOrders() {
      if (this.orderSearch && this.orderSearch.trim().length > 0) {
        return this.orderList.filter((order) => {
          return (
            (order.driver_name &&
              order.driver_name
                .toLowerCase()
                .indexOf(this.orderSearch.toLowerCase()) > -1) ||
            (order.contact_person &&
              order.contact_person
                .toLowerCase()
                .indexOf(this.orderSearch.toLowerCase()) > -1) ||
            (order.customer_name &&
              order.customer_name
                .toLowerCase()
                .indexOf(this.orderSearch.toLowerCase()) > -1) ||
            (order.address &&
              order.address
                .toLowerCase()
                .indexOf(this.orderSearch.toLowerCase()) > -1) ||
            (order.project_name &&
              order.project_name
                .toLowerCase()
                .indexOf(this.orderSearch.toLowerCase()) > -1) ||
            (order.reference_number &&
              order.reference_number
                .toLowerCase()
                .indexOf(this.orderSearch.toLowerCase()) > -1)
          );
        });
      } else {
        return this.orderList;
      }
    },
    assignedOrders() {
      return this.allOrders.filter((order) => {
        return order.status.toLowerCase().replace(/\ /g, "_") == "assigned";
      });
    },
    partiallyDeliveredOrders() {
      return this.allOrders.filter((order) => {
        return (
          order.status.toLowerCase().replace(/\ /g, "_") ==
          "partially_delivered"
        );
      });
    },
    pickedupOrders() {
      return this.allOrders.filter((order) => {
        return order.status.toLowerCase().replace(/\ /g, "_") == "pickedup";
      });
    },
    successfulOrders() {
      return this.allOrders.filter((order) => {
        return order.status.toLowerCase().replace(/\ /g, "_") == "successful";
      });
    },
    failedOrders() {
      return this.allOrders.filter((order) => {
        return order.status.toLowerCase().replace(/\ /g, "_") == "failed";
      });
    },
  },
  methods: {
    previousDay() {
      this.date = moment(this.date).subtract(1, "day").format("YYYY-MM-DD");
      this.selectedTrip = null;
    },
    nextDay() {
      this.date = moment(this.date).add(1, "day").format("YYYY-MM-DD");
      this.selectedTrip = null;
    },
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    loadDriversOnMap() {
      let interval = setInterval(() => {
        if (this.$refs.operationOrdersList) {
          clearInterval(interval);
          this.$refs.operationOrdersList.setMapData(
            this.$refs.operationDriversList.onlineDrivers,
            "drivers"
          );
        }
      }, 500);
    },
    selectedTripChanged(trip) {
      this.selectedTrip = trip;
      this.getOrderList({ trip: trip.id });
      this.getSelectedTripDetails(trip.id);
    },
    getDriverChatData(id, driverName, status) {
      this.$api.chat
        .getChatData(id)
        .then((response) => {
          this.openChatDialogBox = true;
          this.driverDetails = {
            id: id,
            driverName: driverName,
            status: status,
          };
        })
        .catch((error) => {
          bus.$emit("showToastMessage", {
            message: "Couln't fetch data",
            color: "error",
          });
        });
    },
    getSelectedTripDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.operations
        .getTripDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.setMapData(res.data.trip_route, "orders");
          this.tripDetails = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);

          console.log(err);
        });
    },
    getTripList(params = {}) {
      this.showTripLoader = true;
      params = {
        trip_date: this.date,
        limit: "all",
        is_completed: this.activeTab == 0 ? "" : "completed",
      };
      if (this.selectedProjects && this.selectedProjects.length > 0) {
        params.projects = this.selectedProjects.toString();
      }
      this.$api.operations
        .getTripList(params)
        .then((res) => {
          this.showTripLoader = false;
          this.tripList = res.data;
          if (this.selectedTrip == null) {
            this.setMapData(
              res.data.map((obj) => obj.driver_details),
              "drivers"
            );
          }
        })
        .catch((err) => {
          this.showTripLoader = false;
        });
    },
    getOrderList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      if (this.selectedTrip) {
        params.trip = this.selectedTrip.id;
      } else {
        params.date = this.date;
        if (this.selectedProjects && this.selectedProjects.length > 0) {
          params.projects = this.selectedProjects.join(",");
        }
      }
      bus.$emit("showLoader", true);
      this.$api.operations
        .getOrderList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.orderList = res.data;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
          this.orderList = [];
        });
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.projectList = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getOrderDetails(id) {
      this.showOrderDetails = true;
      this.$refs.orderDetails.getOrderDetails(id);
    },
    setMapData(mapData, mapFor) {
      if (mapData !== null) {
        let interval = setInterval(() => {
          if (this.$refs.operationsViewTrip && window && window.google) {
            clearInterval(interval);

            if (mapFor == "drivers") {
              this.$refs.operationsViewTrip.clearMarker();
              this.$refs.operationsViewTrip.clearGeoJson();
              this.$refs.operationsViewTrip.initMap();
              this.$refs.operationsViewTrip.loadDrivers(mapData);
            } else {
              this.$refs.operationsViewTrip.clearMarker();
              this.$refs.operationsViewTrip.clearGeoJson();
              this.$refs.operationsViewTrip.initMap();
              this.$refs.operationsViewTrip.loadRoutes(
                mapData.trip_route,
                mapData.order_details,
                mapData.warehouse_details,
                this.selectedDriver
              );
            }
          }
        }, 100);
      } else {
        this.$refs.operationsViewTrip.clearMarker();
        this.$refs.operationsViewTrip.clearGeoJson();
        this.$refs.operationsViewTrip.initMap();
      }
    },
    setFilters() {
      if (this.selectedProjects.length > 0) {
        localStorage.setItem(
          "operations_projects_filter",
          this.selectedProjects.join()
        );
      } else {
        localStorage.removeItem("operations_projects_filter");
      }
    },
    refreshFilters() {
      this.selectedProjects =
        localStorage.getItem("operations_projects_filter") != null
          ? localStorage.getItem("operations_projects_filter").split(",")
          : [];
    },
    applyProjectFilter() {
      this.setFilters();
      if (this.$refs.operationProjectFilter) {
        this.$refs.operationProjectFilter.blur();
      }
      if (this.selectedProjects.length > 0) {
        this.filter.projects = this.selectedProjects.join(",");
      } else {
        delete this.filter.projects;
      }

      this.getTripList(this.filter);
      this.getOrderList(this.filter);
    },
  },
  mounted() {
    this.refreshFilters();
    this.applyProjectFilter();
    this.getAllProjects();
    this.getOrderList();
    this.getTripList();
  },
};
</script>

<style>
.stick-bottom {
  bottom: 0px;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
}
</style>