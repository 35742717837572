<template>
  <div class="pa-6">
    <v-row no-gutters>
      <v-col cols="12">
        <div class="full-width background-white rounded-lg">
          <div
            class="pa-2 background-light_grey rounded-t-lg border-x-light_black border-y-light_black"
          >
            <span
              class="px-3 text-grey text-h6 text-uppercase font-weight-bold"
            >
              Total Orders
            </span>
          </div>
          <div
            class="rounded-b-lg pa-4 border-x-light_black border-bottom-light_black"
          >
            <v-row no-gutters>
              <v-col cols="6" class="d-flex">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <InputField
                      style="max-width: 350px"
                      v-model="orderMonth"
                      label="Select Month"
                      prepend-inner-icon="mdi-calendar"
                      dense
                      outlined
                      hide-details
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></InputField>
                  </template>
                  <v-date-picker
                    v-model="orderMonth"
                    :max="nowDate"
                    type="month"
                    no-title
                    scrollable
                    @change="
                      GetOrderGraphData();
                      $refs.menu.save(orderMonth);
                    "
                  >
                  </v-date-picker>
                </v-menu>

                <SelectField
                  :multiple="true"
                  hide-details
                  outlined
                  dense
                  label="Select Project"
                  class="ml-4"
                  :itemsList="allProjects"
                  v-model="orderProjects"
                  @change="GetOrderGraphData()"
                  :menu-props="{ offsetY: true }"
                >
                  <!-- <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.text }} &nbsp;</span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ orderProjects.length - 1 }} others)
                    </span>
                  </template> -->
                </SelectField>
              </v-col>
              <v-col cols="12">
                <div>
                  <StackedBarChart
                    v-if="chart3"
                    :labels="getOrderGraphData.keys"
                    :datasets="[
                      {
                        label: 'Returned',
                        backgroundColor: '#B71C1C',
                        data: getOrderGraphData.failed,
                      },
                      {
                        label: 'Partially Delivered',
                        backgroundColor: '#9C27B0',
                        data: getOrderGraphData.partial,
                      },
                      {
                        label: 'Delivered',
                        backgroundColor: '#2E7D32',
                        data: getOrderGraphData.successful,
                      },
                    ]"
                    :options="stackBarOptions"
                  />
                </div>
                <div
                  class="d-flex align-center justify-center loader-container full-width"
                  v-if="!chart2"
                >
                  <v-progress-circular
                    :size="100"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="border-top-light_black pt-4 d-flex mt-8">
        <v-menu
          v-model="toDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <InputField
              v-model="getFromDate"
              label="From"
              class="mr-4"
              prepend-inner-icon="mdi-calendar"
              readonly
              outlined
              dense
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            ></InputField>
          </template>
          <v-date-picker
            @change="dateValidator"
            :max="nowDate"
            v-model="getFromDate"
            @input="toDate = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="fromDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <InputField
              v-model="getToDate"
              label="To"
              prepend-inner-icon="mdi-calendar"
              readonly
              dense
              outlined
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
            ></InputField>
          </template>
          <v-date-picker
            @change="dateValidator"
            v-model="getToDate"
            :max="nowDate"
            @input="fromDate = false"
          ></v-date-picker>
        </v-menu>
        <SelectField
          :multiple="true"
          hide-details
          outlined
          dense
          label="Select Project"
          :itemsList="allProjects"
          class="ml-4"
          :value="projects"
          v-model="projects"
          @change="dateValidator($event), updateZoneList($event)"
          :menu-props="{ offsetY: true }"
        >
          <!-- <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }} &nbsp;</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ projects.length - 1 }} others)
            </span>
          </template> -->
        </SelectField>
        <SelectField
          :multiple="true"
          hide-details
          outlined
          dense
          label="Select Zone"
          :itemsList="zoneList"
          item-text="zone_name"
          item-value="id"
          class="ml-4"
          v-model="selectedZones"
          @change="dateValidator($event)"
          :menu-props="{ offsetY: true }"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item.zone_name }} &nbsp;</span>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ selectedZones.length - 1 }} others)
            </span>
          </template>
        </SelectField>
      </v-col>

      <v-col cols="6" class="border-top-light_black pt-4 d-flex mt-8"> </v-col>
      <v-col cols="6" class="pt-4 pr-2">
        <div class="full-width background-white rounded-lg">
          <div
            class="pa-2 background-light_grey rounded-t-lg border-x-light_black border-y-light_black"
          >
            <span
              class="px-3 text-grey text-h6 text-uppercase font-weight-bold"
            >
              Fleet Utilization
            </span>
          </div>
          <div
            class="rounded-b-lg pa-4 border-x-light_black border-bottom-light_black"
          >
            <TwoBarChart
              v-if="chart"
              ref="barChart"
              :labels="fleetUtilization.fleetUtilizationLabels"
              :datasets="fleetUtilization.fleetUtilization"
              :options="barOptions"
            />
            <div
              class="loader-container d-flex align-center justify-center full-width"
              v-if="!chart"
            >
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="6" class="pt-4 pl-2">
        <div class="full-width background-white rounded-lg">
          <div
            class="pa-2 background-light_grey rounded-t-lg border-x-light_black border-y-light_black"
          >
            <span
              class="px-3 text-grey text-h6 text-uppercase font-weight-bold"
            >
              Drop Points per Day
            </span>
          </div>
          <div
            class="rounded-b-lg pa-4 border-x-light_black border-bottom-light_black"
          >
            <div v-if="Object.keys(fleetUtilization.totalDropPoint).length > 0">
              <LineChart
                v-if="chart"
                :datasets="fleetUtilization.totalDropPoint"
                :options="barOptions"
              />
            </div>
            <div
              class="loader-container d-flex align-center justify-center full-width"
              v-if="!chart"
            >
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="mt-4">
        <v-row no-gutters>
          <v-col
            class="pb-4"
            v-cloak
            cols="3"
            v-for="(key, index) in Object.keys(orderScoreData)"
            :key="index"
            :class="
              index == 0 || index == 4
                ? 'pr-2'
                : index == 3 || index == 7
                ? 'pl-2'
                : 'px-2'
            "
          >
            <div class="full-width background-white rounded-lg">
              <div
                class="pa-2 text-center background-light_grey rounded-t-lg border-x-light_black border-y-light_black"
              >
                <span
                  class="text-h6 text-grey font-weight-bold text-capitalize"
                >
                  {{ key.replace(/\_/g, " ") }}
                </span>
              </div>
              <div
                class="px-4 py-6 rounded-b-lg text-center text-h4 border-x-light_black font-weight-bold border-bottom-light_black"
              >
                {{ orderScoreData[key] }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { bus } from "@/main.js";
import StackedBarChart from "@/components/common/charts/StackedBarChart.vue";
import TwoBarChart from "@/components/common/charts/TwoBarChart.vue";
import LineChart from "@/components/common/charts/LineChart.vue";
export default {
  name: "dashboardIndex",
  components: {
    StackedBarChart,
    TwoBarChart,
    LineChart,
  },
  data() {
    return {
      chart: true,
      chart2: true,
      chart3: true,
      orderMonth: new Date().toISOString().substr(0, 7),
      menu: false,
      toDate: false,
      fromDate: false,
      getFromDate: null,
      getToDate: null,
      projects: [],
      selectedZones: [],
      menu2: false,
      orderProjects: [],
      nowDate: new Date().toISOString().slice(0, 10),
      selectedDates: [],
      stackBarOptions: {
        responsive: true,
        maintainAspectRatio: false,
        datasets: {
          bar: {
            categoryPercentage: 0.5,
            barPercentage: 1,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              ticks: {
                suggestedMin: 1,
                beginAtZero: true,
              },
              stacked: true,
            },
          ],
        },
      },
      barOptions: {
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 50,
                beginAtZero: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      allProjects: [],
      zoneList: [],
      showLoader: false,
      fleetUtilizationList: {},
      orderGraph: {},
      orderScore: {},
      orderScoreData: {},
      allZones: [],
    };
  },
  computed: {
    fleetUtilization() {
      let fleetUtilizationLabels = [];
      let fleetUtilization = [
        {
          label: "No. of Trucks",
          backgroundColor: "#9b182f",
          data: [],
        },
      ];
      let totalDropPoint = {
        labels: [],
        datasets: [
          {
            label: "Drop Points per Day",
            backgroundColor: "#f97d9d",
            data: [],
          },
        ],
      };
      for (const key in this.fleetUtilizationList) {
        fleetUtilizationLabels.push(this.fleetUtilizationList[key]["date"]);
        totalDropPoint.labels.push(this.fleetUtilizationList[key]["date"]);
        if (Object.keys(this.fleetUtilizationList[key]).length > 0) {
          totalDropPoint.datasets[0]["data"].push(
            this.fleetUtilizationList[key]["drop_points_per_day"]
          );
          fleetUtilization[0].data.push(
            this.fleetUtilizationList[key]["utilized_vehicle_count"]
          );
        } else {
          totalDropPoint.datasets[0]["data"].push(0);
          fleetUtilization[0].data.push(0);
        }
      }
      return { fleetUtilizationLabels, fleetUtilization, totalDropPoint };
    },
    orderGraphData() {
      let completedOrders = {
        labels: [],
        datasets: [
          {
            label: "Orders",
            backgroundColor: "#9b182f",
            data: [],
          },
        ],
      };
      for (const key in this.orderGraph) {
        completedOrders.labels.push(this.orderGraph[key]["report_date"]);
        completedOrders.datasets[0].data.push(this.orderGraph[key]);
      }
      return completedOrders;
    },
    getOrderGraphData() {
      let orders = this.orderGraph;
      let orderDates = [];

      if (orders.length > 0) {
        orderDates = orders.map((obj) => {
          return obj.date;
        });
      }

      let keys = orderDates;
      let successful = [];
      let failed = [];
      let partial = [];
      for (const [key, value] of Object.entries(orders)) {
        successful.push(orders[key].successful);
        failed.push(orders[key].failed);
        partial.push(orders[key].partially_delivered);
      }
      return {
        keys: keys,
        successful: successful,
        failed: failed,
        partial: partial,
      };
    },
  },
  methods: {
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getAllZones() {
      this.$api.zone
        .getZonesList({ limit: "all" })
        .then((res) => {
          this.zoneList = res.data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    dateValidator() {
      if (
        this.getFromDate &&
        this.getToDate &&
        this.getFromDate > this.getToDate
      ) {
        let _ = this.getToDate;
        this.getToDate = this.getFromDate;
        this.getFromDate = _;

        this.getFleetData();
        this.GetDashboardScorecard();
      } else {
        this.getFleetData();
        this.GetDashboardScorecard();
      }
    },

    updateZoneList(project) {
      let project_id_list = project.toString();
      let params = {
        project_ids_list: project_id_list,
        limit: "all",
      };

      if (project) {
        this.$api.zone
          .getZonesList(params)
          .then((result) => {
            this.allZones = result.data;
            this.$forceUpdate();
          })
          .catch((err) => {
            bus.$emit("showLoader", {
              message: err.message,
              color: "error",
            });
          });
      }
    },

    getFleetData() {
      this.chart = false;
      let params = {};
      if (this.getFromDate && this.getToDate) {
        params.start_date = this.getFromDate;
        params.end_date = this.getToDate;
      }
      if (this.projects) {
        params.projects = this.projects.toString();
      }
      if (this.selectedZones) {
        params.zones = this.selectedZones.toString();
      }

      this.$api.dashboard
        .getFleetUtilization(params)
        .then((result) => {
          this.fleetUtilizationList = result.data["fleet_utilization_data"]; //#TODO: check response

          this.chart = true;
        })
        .catch((err) => {
          if (err.message) {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          }
          bus.$emit("showToastMessage", {
            message: "Error fetching data",
            color: "error",
          });
        });
    },

    GetOrderGraphData() {
      this.chart2 = false;
      this.chart3 = false;
      let params = {};
      if (this.orderMonth) {
        let date = new Date(this.orderMonth);
        params.year = date.getFullYear();
        params.month = date.getMonth() + 1;
      }
      if (this.orderProjects && this.orderProjects.length > 0) {
        params.project = this.orderProjects.join(",");
      }

      this.$api.dashboard
        .getOrderGraphData(params)
        .then((result) => {
          this.orderGraph = result.data["order_graph"]; //TODO: check response
          // orderGraph = result.data["order_graph"]
          this.chart2 = true;
          this.chart3 = true;
        })
        .catch((err) => {
          if (err.message) {
            bus.$emit("showMessage", {
              message: err.message,
              color: "error",
            });
          }
          bus.$emit("showMessage", {
            message: "Error fetching data",
            color: "error",
          });
        });
    },

    GetDashboardScorecard() {
      this.chart = false;
      let params = {};
      if (this.getFromDate && this.getToDate) {
        params.start_date = this.getFromDate;
        params.end_date = this.getToDate;
      }
      if (this.projects) {
        params.projects = this.projects.toString();
      }
      if (this.selectedZones) {
        params.zones = this.selectedZones.toString();
      }

      this.$api.dashboard
        .getDashboardScoreCard(params)
        .then((result) => {
          // this.orderScore = result.data;
          this.orderScoreData = result.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.getToDate = new Date().toISOString().substr(0, 10);
    this.getFromDate = new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .substr(0, 10);
    this.getAllProjects();
    this.getAllZones();
    this.dateValidator();
    this.GetOrderGraphData();
  },
};
</script>

<style scoped>
.loader-container {
  height: 400px;
}
</style>
