import { drivers, version, systemLogs } from "@/utils/constants";
import { handleResponse, handleError } from "@/utils/functions";

export default (axios) => ({
  getDriverOldList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(drivers.oldBase, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(drivers.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${drivers.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createDriverObject(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${drivers.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateDriverObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${drivers.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  uploadDocument(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${drivers.base}${payload.id}/upload/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteDocument(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${version}driver-document/${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadDriverSheet(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${drivers.base}download/`, {
          params: params,
          headers: {
            // Accept: "application/xlsx",
          },
          responseType: "blob",
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(systemLogs, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadDrivers(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${drivers.base}bulk_upload/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateDrivers(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${drivers.base}bulk_update/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  changeBranch(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${drivers.base}${payload.id}/change_project/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
