<template>
  <v-dialog v-model="openAllProjectListDialog" scrollable max-width="500px">
    <v-card>
      <v-card-title class="d-flex justify-center primary white--text">
        <span class="text-body-1 white--text">{{
          parent == "items"
            ? "Download Items"
            : parent == "drivers"
            ? "Download Drivers"
            : parent == "vehicles"
            ? "Download Vehicles"
            : "Download Customer Address"
        }}</span>
        <span class="white--text">
          <v-icon
            color="white"
            style="position: absolute; right: 10px; top: 15px"
            @click="closeDialog()"
          >
            mdi-close
          </v-icon>
        </span>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 400px">
        <v-row no-gutters v-if="parent != 'items'">
          <v-col cols="12" class="px-4 pt-4 d-flex justify-center">
            <v-alert border="left" colored-border type="info">
              If you don't select any projects, All projects will be selected by
              default!
            </v-alert>
          </v-col>
          <v-col cols="12" class="pa-4 d-flex justify-center">
            <SelectField
              id="allProjects"
              v-model="projects"
              :items-list="allProjects"
              :multiple="true"
              label="Select Project"
              track-by="text"
              :limit="1"
              :limitText="(count) => `+ ${count}`"
              group-values="projects"
              group-label="selectAll"
              :group-select="true"
              :close-on-select="false"
              :showLabels="false"
              tag-position="bottom"
              :max-height="200"
            >
            </SelectField>
          </v-col>

          <v-col
            v-if="parent == 'drivers'"
            cols="12"
            class="pa-4 d-flex justify-center"
          >
            <DatePicker
              outlined
              label="License Expiry"
              name="license_expiry"
              v-model="license_expiry"
            ></DatePicker>
          </v-col>

          <v-col
            v-if="parent == 'drivers'"
            cols="12"
            class="pa-4 d-flex justify-center"
          >
            <DatePicker
              outlined
              label="National ID Expiry"
              name="national_id_expiry"
              v-model="national_id_expiry"
            ></DatePicker>
          </v-col>
          <v-col
            v-if="parent == 'drivers'"
            cols="6"
            class="pa-4 d-flex justify-center"
          >
            <InputField
              type="time"
              outlined
              label="Shift Start"
              name="shift_start"
              v-model="shift_start"
            ></InputField>
          </v-col>
          <v-col
            v-if="parent == 'drivers'"
            cols="6"
            class="pa-4 d-flex justify-center"
          >
            <InputField
              type="time"
              outlined
              label="Shift End"
              name="shift_end"
              v-model="shift_end"
            ></InputField>
          </v-col>
        </v-row>

        <v-row no-gutters v-else>
          <v-col cols="12" class="px-4 pt-4 d-flex justify-center">
            <v-alert border="left" colored-border type="info">
              If you don't select any Storage Type or Unit, All Storage Type
              and/or Unit will be selected by default!
            </v-alert>
          </v-col>
          <v-col cols="12" class="px-4 d-flex justify-center">
            <SelectField
              hide-details
              outlined
              dense
              :menu-props="{ offsetY: true }"
              label="Select Storage Type"
              placeholder="Select Storage Type"
              :items-list="storageTypes"
              item-text="name"
              item-value="value"
              multiple
              v-model="itemStorage"
              class="mb-4 background-white"
            ></SelectField>
          </v-col>
          <v-col cols="12" class="px-4 d-flex justify-center">
            <SelectField
              hide-details
              outlined
              dense
              :menu-props="{ offsetY: true }"
              label="Select Unit"
              placeholder="Select Unit"
              :items-list="unitOptions"
              item-text="name"
              item-value="value"
              multiple
              v-model="itemUnit"
              class="mb-4 background-white"
            ></SelectField>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn class="primary" @click="downloadData">
          <span>Download</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectField from "@/components/BaseFormComponents/SelectField.vue";

export default {
  components: {
    SelectField,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    parent: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      projects: [],
      allProjects: [],
      itemUnit: [],
      itemStorage: [],
      storageTypes: [
        {
          name: "Frozen",
          value: "Frozen",
        },
        {
          name: "Chilled",
          value: "Chilled",
        },
        {
          name: "Dry",
          value: "Dry",
        },
      ],
      unitOptions: [
        {
          name: "Case",
          value: "case",
        },
        {
          name: "K.G.",
          value: "kg",
        },
        {
          name: "Each",
          value: "each",
        },
      ],
      license_expiry: null,
      national_id_expiry: null,
      shift_start: null,
      shift_end: null,
    };
  },
  watch: {
    openAllProjectListDialog(value) {
      if (value) {
        this.getAllProjects();
      }
    },
  },
  computed: {
    openAllProjectListDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    downloadData() {
      let params;
      params = {
        ...params,
      };
      if (this.projects && this.projects.length > 0) {
        params.project_id = this.projects.join(",");
      }
      if (this.license_expiry) {
        params.license_expiry = this.license_expiry;
      }
      if (this.national_id_expiry) {
        params.national_id_expiry = this.national_id_expiry;
      }

      if (this.shift_start) {
        params.shift_start = this.shift_start;
      }
      if (this.shift_end) {
        params.shift_end = this.shift_end;
      }
      // let projectArray = this.projects;
      let itemsParams;
      itemsParams = {
        ...itemsParams,
      };
      if (this.itemUnit && this.itemUnit.length > 0) {
        itemsParams.unit = this.itemUnit.join(",");
      }
      if (this.itemStorage && this.itemStorage.length > 0) {
        itemsParams.storage_type = this.itemStorage.join(",");
      }

      if (this.parent == "zones") {
        this.$parent.downloadAllZones(params);
      }
      if (this.parent == "customers") {
        this.$parent.$parent.downloadAllCustomers(params);
      }
      if (this.parent == "vehicles") {
        this.$parent.$parent.downloadAllVehicles(params);
      }
      if (this.parent == "drivers") {
        this.$parent.$parent.downloadAllDrivers(params);
      }
      if (this.parent == "items") {
        this.$parent.$parent.downloadAllItems(itemsParams);
      }
    },
    closeDialog() {
      this.openAllProjectListDialog = false;
    },
  },
  mounted() {},
};
</script>
