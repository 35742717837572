<template>
  <ListLayout
    title="Integration Logs"
    :tableHeader="tableHeaders"
    :tableData="integrationList"
    :total="totalItems"
    :isColumnShowVisibility="false"
    @getList="onChangeFilter"
    :searchInput="false"
    :context="context"
    :hasAfterTitle="true"
    :filtersOnRight="true"
    :tableScrollable="false"
    localStorageKey="integration_columns"
  >
    <template #afterTitle>
      <v-row class="d-flex justify-space-between">
        <v-col cols="10" class="">
          <v-btn
            v-if="
              userPermissions.customeraddress &&
              userPermissions.customeraddress.add
            "
            small
            depressed
            class="primary mr-1"
            @click="fetchCustomer()"
          >
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Fetch Customers</span>
          </v-btn>

          <v-btn
            v-if="userPermissions.itemmaster && userPermissions.itemmaster.add"
            small
            depressed
            class="primary mr-1"
            @click="fetchItem()"
          >
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Fetch Items</span>
          </v-btn>

          <v-btn small depressed class="primary mr-1" @click="fetchB2BOrders()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Fetch B2B Orders</span>
          </v-btn>

          <v-btn small depressed class="primary mr-1" @click="fetchB2COrders()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Fetch B2C Orders</span>
          </v-btn>

          <v-btn
            small
            depressed
            class="primary mr-1"
            @click="fetchSameDayDeliveryOrders()"
          >
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Fetch Same Day Delivery Orders</span>
          </v-btn>

          <v-btn small depressed class="primary mr-1" @click="fetchInvoices()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Fetch Invoice</span>
          </v-btn>

          <v-btn small depressed class="primary mr-1" @click="sendLoadSheets()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Send Load Sheet </span>
          </v-btn>
        </v-col>
        <v-col cols="2" class="d-flex justify-end">
          <v-btn
            small
            depressed
            class="primary mr-1"
            @click="orderInadequateSheet()"
          >
            <v-icon small class="mr-1">mdi-download</v-icon>
            <span>Integration Statistics</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #rightFilterSlot>
      <v-row class="d-flex justify-space-between">
        <v-col cols="5">
          <v-tabs v-model="tab">
            <v-tab @click="getAllItemList({ limit: 'all', offset: 10 })">
              Item API
            </v-tab>
            <v-tab @click="getAllCustomerList({ limit: 'all', offset: 10 })">
              Customer API
            </v-tab>
            <v-tab @click="getAllOrdersList({ limit: 'all', offset: 10 })">
              B2B Order API
            </v-tab>
            <v-tab @click="getAllB2COrdersList({ limit: 'all', offset: 10 })">
              B2C Order API
            </v-tab>
          </v-tabs>
        </v-col>

        <v-col cols="7" class="d-flex justify-end">
          <v-row no-gutters class="d-flex justify-end">
            <v-col cols="3">
              <InputField
                class="mr-3"
                color="light_black"
                label="Search Here.."
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                v-model="filter.search_item"
                outlined
                dense
                clearable
                @input="onChangeFilter()"
              ></InputField
            ></v-col>
            <v-col cols="3">
              <SelectField
                :isSearchRequired="false"
                outlined
                dense
                hide-details="auto"
                type="select"
                name="status"
                class="pr-4"
                label="Select Status"
                :itemsList="statusList"
                item-text="text"
                item-value="value"
                v-model="filter.is_success"
                :menu-props="{ offsetY: true }"
                @change="onChangeFilter"
              />
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <InputField
                type="date"
                label="Select Date"
                v-model="filter.date_filter"
                @input="onChangeFilter()"
                hide-details
                dense
                outlined
              ></InputField>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <v-btn depressed class="primary ml-5" @click="resetFilter">
                Reset Filter
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <template #dialogs>
      <FetchB2BOrders v-model="fetchB2BOrder" />
      <FetchB2COrders v-model="fetchB2COrder" />
      <FetchSameDayDeliveryOrder v-model="fetchSameDayDeliveryOrder" />
      <FetchInvoice v-model="fetchInvoice" />
      <SendLoadSheet v-model="sendLoadSheet" />
      <OrderInadequate v-model="orderInadequate" />

      <!-- Forms -->
      <ItemIntegrationForm
        ref="itemForm"
        v-model="openItemForm"
        :formType="formType"
        :itemFormDetails="itemDetailObj"
        @refreshList="getAllItemList({ limit: 10, offset: 0 })"
      />

      <CustomerIntegrationForm
        ref="customerForm"
        v-model="openCustomerForm"
        :formType="formType"
        :customerDetails="customerDetailObj"
        @refreshList="getAllCustomerList({ limit: 10, offset: 0 })"
      />

      <B2BOrderIntegrationForm
        ref="b2bOrderForm"
        v-model="openOrderForm"
        :formType="formType"
        @refreshList="getAllOrdersList({ limit: 10, offset: 0 })"
      />

      <B2COrderIntegrationForm
        ref="b2cOrderForm"
        v-model="openB2COrderForm"
        :formType="formType"
        @refreshList="getAllB2COrdersList({ limit: 10, offset: 0 })"
      />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import FetchB2BOrders from "@/components/integration/FetchB2BOrders.vue";
import FetchB2COrders from "@/components/integration/FetchB2COrders.vue";
import FetchSameDayDeliveryOrder from "@/components/integration/FetchSameDayDeliveryOrder.vue";
import FetchInvoice from "@/components/integration/FetchInvoice.vue";
import SendLoadSheet from "@/components/integration/SendLoadSheet.vue";
import OrderInadequate from "@/components/integration/OrderInadequate.vue";
import integrationError from "@/components/common/aggrid/tooltip/integrationError.vue";
import ItemIntegrationButton from "@/components/common/aggrid/buttons/itemIntegrationButton.vue";
import ItemIntegrationForm from "@/components/integration/ItemIntegrationForm.vue";
import CustomerIntegrationButton from "@/components/common/aggrid/buttons/customerIntegrationButton.vue";
import CustomerIntegrationForm from "@/components/integration/CustomerIntegrationForm.vue";
import OrderIntegrationButton from "@/components/common/aggrid/buttons/OrderIntegrationButton.vue";
import B2BOrderIntegrationForm from "@/components/integration/B2BOrderIntegrationForm.vue";
import B2COrderIntegrationButton from "@/components/common/aggrid/buttons/B2COrderIntegrationButton.vue";
import B2COrderIntegrationForm from "@/components/integration/B2COrderIntegrationForm.vue";
import { bus } from "@/main.js";
export default {
  name: "integrationLogsIndex",
  components: {
    ListLayout,
    FetchB2BOrders,
    FetchB2COrders,
    FetchSameDayDeliveryOrder,
    FetchInvoice,
    SendLoadSheet,
    OrderInadequate,
    integrationError,
    ItemIntegrationButton,
    ItemIntegrationForm,
    CustomerIntegrationButton,
    CustomerIntegrationForm,
    OrderIntegrationButton,
    B2BOrderIntegrationForm,
    B2COrderIntegrationButton,
    B2COrderIntegrationForm,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      userPermissions: JSON.parse(localStorage.getItem("permissions")),
      fetchB2BOrder: false,
      fetchB2COrder: false,
      fetchSameDayDeliveryOrder: false,
      fetchInvoice: false,
      sendLoadSheet: false,
      orderInadequate: false,

      formType: "Edit",
      openItemForm: false,
      itemDetailObj: {},
      openCustomerForm: false,
      customerDetailObj: {},
      openOrderForm: false,
      openB2COrderForm: false,

      tab: 0,
      integrationList: [],
      customFilter: {},
      filter: {},
      statusList: [
        {
          text: "Logs without error",
          value: true,
        },
        {
          text: "Logs with error",
          value: false,
        },
      ],
      headerSelected: [
        {
          headerName: "Error",
          field: "error",
          minWidth: 90,
          maxWidth: 90,
          pinned: "left",
          cellRendererFramework: "integrationError",
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      if (this.tab == 0) {
        return [
          ...this.headerSelected,
          {
            headerName: "Item No",
            field: "item_no",
            flex: 1,
          },
          {
            headerName: "Item Description",
            field: "item_description",
            flex: 1,
          },
          {
            headerName: "Storage Type",
            field: "storage_type",
            flex: 1,
          },
          {
            headerName: "Unit",
            field: "unit",
            flex: 1,
          },
          {
            headerName: "Width",
            field: "width",
            flex: 1,
          },
          {
            headerName: "Height",
            field: "height",
            flex: 1,
          },
          {
            headerName: "Weight",
            field: "weight",
            flex: 1,
          },
          {
            headerName: "Length",
            field: "length",
            flex: 1,
          },
          {
            headerName: "Case Factor",
            field: "case_factor",
            flex: 1,
          },
          {
            headerName: "Created on",
            field: "created",
            width: 150,
            minWidth: 100,
            flex: 1,
          },
          {
            headerName: "Actions",
            width: 100,
            minWidth: 100,
            field: "actions",
            pinned: "right",
            cellRendererFramework: "ItemIntegrationButton",
          },
        ];
      }
      if (this.tab == 1) {
        return [
          ...this.headerSelected,
          {
            headerName: "Customer Code",
            hide: false,
            flex: 1,
            field: "customer_code",
          },
          {
            headerName: "Customer Name",
            hide: false,
            flex: 1,
            field: "customer_name",
          },
          {
            headerName: "Address",
            hide: false,
            flex: 1,
            field: "address",
          },
          {
            headerName: "Contact Person",
            hide: false,
            flex: 1,
            field: "contact_person",
          },
          {
            headerName: "Contact Number",
            hide: false,
            flex: 1,
            field: "contact_number",
          },
          {
            headerName: "Customer Type",
            hide: false,
            flex: 1,
            field: "customer_type",
          },
          {
            headerName: "Project",
            hide: false,
            flex: 1,
            field: "project",
          },
          {
            headerName: "Created on",
            field: "created",
            width: 150,
            minWidth: 100,
            flex: 1,
          },
          {
            headerName: "Actions",
            width: 100,
            minWidth: 100,
            field: "actions",
            pinned: "right",
            cellRendererFramework: "CustomerIntegrationButton",
          },
        ];
      }
      if (this.tab == 2) {
        return [
          ...this.headerSelected,
          {
            headerName: "Reference Number",
            hide: false,
            flex: 1,
            field: "reference_number",
          },
          {
            headerName: "Execution Date",
            hide: false,
            flex: 1,
            field: "execution_date",
          },
          {
            headerName: "Service Type",
            hide: false,
            flex: 1,
            field: "service_type",
          },
          {
            headerName: "Project",
            hide: false,
            flex: 1,
            field: "project",
          },
          {
            headerName: "Customer",
            hide: false,
            flex: 1,
            field: "customer_code",
          },
          {
            headerName: "Created on",
            field: "created",
            width: 150,
            minWidth: 100,
            flex: 1,
          },
          {
            headerName: "Actions",
            width: 100,
            minWidth: 100,
            field: "actions",
            pinned: "right",
            cellRendererFramework: "OrderIntegrationButton",
          },
        ];
      }
      if (this.tab == 3) {
        return [
          ...this.headerSelected,
          {
            headerName: "Reference Number",
            hide: false,
            flex: 1,
            field: "reference_number",
          },
          {
            headerName: "Execution Date",
            hide: false,
            flex: 1,
            field: "execution_date",
          },
          {
            headerName: "Project",
            hide: false,
            flex: 1,
            field: "project",
          },
          {
            headerName: "Created on",
            field: "created",
            width: 150,
            minWidth: 100,
            flex: 1,
          },
          {
            headerName: "Actions",
            width: 100,
            minWidth: 100,
            field: "actions",
            pinned: "right",
            cellRendererFramework: "B2COrderIntegrationButton",
          },
        ];
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
    fetchCustomer() {
      this.$api.integration
        .fetchCustomers()
        .then(() => {
          bus.$emit("showToastMessage", {
            message: "Fetching Customer Master Data",
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },

    fetchItem() {
      this.$api.integration
        .fetchItems()
        .then(() => {
          bus.$emit("showToastMessage", {
            message: "Fetching Item Master Data",
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },

    fetchB2BOrders() {
      this.fetchB2BOrder = true;
    },

    fetchB2COrders() {
      this.fetchB2COrder = true;
    },

    fetchSameDayDeliveryOrders() {
      this.fetchSameDayDeliveryOrder = true;
    },

    fetchInvoices() {
      this.fetchInvoice = true;
    },

    sendLoadSheets() {
      this.sendLoadSheet = true;
    },

    orderInadequateSheet() {
      this.orderInadequate = true;
    },

    resetFilter() {
      delete this.filter.is_success;
      delete this.filter.date_filter;
      delete this.filter.search_item;
      if (this.tab == 0) {
        this.getAllItemList();
      }
      if (this.tab == 1) {
        this.getAllCustomerList();
      }
      if (this.tab == 2) {
        this.getAllOrdersList();
      }
      if (this.tab == 3) {
        this.getAllB2COrdersList();
      }
    },

    onChangeFilter(params = {}) {
      params = {
        ...params,
      };
      if (this.tab == 0) {
        this.getAllItemList(params);
      } else if (this.tab == 1) {
        this.getAllCustomerList(params);
      } else if (this.tab == 2) {
        this.getAllOrdersList(params);
      } else {
        this.getAllB2COrdersList(params);
      }
    },

    getAllItemList(params = {}) {
      params = {
        ...params,
        ...this.filter,
        ...this.customFilter,
      };
      bus.$emit("showLoader", true);
      this.$api.integration
        .getAllItemsLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.integrationList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.detail,
            color: "error",
          });
        });
    },

    getAllCustomerList(params = {}) {
      params = {
        ...params,
        ...this.filter,
        ...this.customFilter,
      };

      bus.$emit("showLoader", true);
      this.$api.integration
        .getAllCustomerLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.integrationList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.detail,
            color: "error",
          });
        });
    },

    getAllOrdersList(params = {}) {
      params = {
        ...params,
        ...this.filter,
      };
      bus.$emit("showLoader", true);
      this.$api.integration
        .getAllB2BLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.integrationList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.detail,
            color: "error",
          });
        });
    },

    getAllB2COrdersList(params = {}) {
      params = {
        ...params,
        ...this.filter,
      };

      bus.$emit("showLoader", true);
      this.$api.integration
        .getAllB2CLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.integrationList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: err.detail,
            color: "error",
          });
        });
    },

    editItem(id) {
      this.formType = "Edit";
      this.openItemForm = true;
      this.$refs.itemForm.getItemDetails(id);
    },

    editCustomer(id) {
      this.formType = "Edit";
      this.openCustomerForm = true;
      this.$refs.customerForm.getCustomerDetails(id);
    },

    editOrder(id) {
      this.formType = "Edit";
      this.openOrderForm = true;
      this.$refs.b2bOrderForm.getOrderDetails(id);
    },
    editB2COrder(id) {
      this.formType = "Edit";
      this.openB2COrderForm = true;
      this.$refs.b2cOrderForm.getOrderDetails(id);
    },
  },
};
</script>

<style>
</style>