import { orders, version } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getOrderList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(orders.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteOrder(params) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${orders.base}delete_orders/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createOrderObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orders.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateOrderObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${orders.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addAttachmentIntoOrder(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}${payload.id}/upload_attachments/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  removeAttachmentFromOrder(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${version}order-attachments/${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderItems(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orders.base}${id}/order_items/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  shareDataToDriver(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}${id}/send_order_notification/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadOrders(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}b2b_bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateOrders(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}bulk_update/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadB2COrders(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}b2c_bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
