import { userNotification } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getUserNotificationList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${userNotification.base}`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  markAsReadUserNotification(id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${userNotification.base}${id}/mark_as_read/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  markAllAsReadUserNotification(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${userNotification.markAsAllRead}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getNotificationCount() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${userNotification.notificationCount}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
