export function handleResponse(res) {
  let obj = {};
  if (res.data && typeof res.data == typeof {}) {
    obj.data = res.data;
    if (res.data.data) {
      obj.data = res.data.data;
    }
    if (
      res.data.count != null ||
      res.data.count != undefined ||
      res.data.count != ""
    ) {
      obj.count = res.data.count;
    }
  } else {
    obj = res;
  }
  return obj;
}

export function handleError(err) {
  let obj = {};
  if (err.response && err.response.data) {
    obj.data = err.response.data;
    if (obj.data.error) {
      obj.data = err.response.data.error;
    }
  } else {
    obj.response = err.response;
  }
  // obj.status = err.response.status;
  return obj;
}

export function checkIfLoggedIn() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  } else {
    return true;
  }
}
export function toCapitalize(string) {
  let words = string.split(" ");
  return words
    .map((word) => {
      word = word.split("");
      let char = "";
      if (word.length !== 0) {
        char = word[0].toUpperCase();
      }
      word[0] = char;
      return word.join("");
    })
    .join(" ");
}

export function toTitleCase(text) {
  text = text.replace("_", " ").replace("-", " ");
  return toCapitalize(text);
}


export function userLogout() {
  if (window) {
    localStorage.clear();
    this.$router.push("/app/login");
  }
}

export function coloreddriverStatus(status) {
  switch (status) {
    case "Off Duty":
      return "#3f3f3f";
    case "On Duty":
      return "#79c267";
    default:
      return "#ffffff";
  }
}

export function coloredOrderStatus(status) {
  switch (status) {
    case "unassigned":
      return "#90A4AE";
    case "assigned":
      return "#90CAF9";
    case "pickedup":
      return "#FFB300";
    case "partially_delivered":
      return "#CE93D8";
    case "successful":
      return "#66BB6A";
    case "failed":
      return "#E53935";
    case "cancelled":
      return "#880E4F";
  }
}

export function orderStatusDarkColor(status) {
  switch (status) {
    case "unassigned":
      return "unassigned";
    case "assigned":
      return "assigned";
    case "pickedup":
      return "pickedup";
    case "partially_delivered":
      return "partially_delivered";
    case "successful":
      return "successful";
    case "failed":
      return "failed";
    case "cancelled":
      return "cancelled";
  }
}

export function orderStatusLightColor(status) {
  switch (status) {
    case "unassigned":
      return "light_unassigned";
    case "assigned":
      return "light_assigned";
    case "pickedup":
      return "light_pickedup";
    case "partially_delivered":
      return "light_partially_delivered";
    case "successful":
      return "light_successful";
    case "failed":
      return "light_failed";
    case "cancelled":
      return "light_cancelled";
  }
}

export function getTripStatusLightColor(status) {
  switch (status) {
    case "scheduled":
      return "light_unassigned";
    case "active":
      return "light_assigned";
    case "paused":
      return "light_pickedup";
    case "completed":
      return "light_successful";
  }
}

export function getColor(status) {
  switch (status) {
    case 0:
      return "assigned";
    case 1:
      return "pickedup";
    case 2:
      return "partially_delivered";
    case 3:
      return "successful";
    case 4:
      return "failed";

    default:
      return "primary";
  }
}

export let defaultTheme = {
  primary: "#9B182F",
  black: "#3f4040",
  blue: "#13b6cf",
  orange: "#f5841f",
  green: "#147d3e",
  pgreen: "#42b549",
  maroon: "#921b1e",
  red: "#ec2024",
  white: "#ffffff",
  edit: "#79C945",
  delete: "#FC4242",
  grey: "#58595B",
  unassigned: "#607D8B",
  assigned: "#2196F3",
  pickedup: "#FF8F00",
  partially_delivered: "#9C27B0",
  failed: "#B71C1C",
  cancelled: "#880E4F",
  successful: "#2E7D32",
  light_grey: "#E6E7E8",
  // light: {
  //   light_primary: "#FFECEF",
  //   light_black: "#939598",
  //   light_blue: "#41dde5",
  //   light_orange: "#fab079",
  //   light_green: "#28b260",
  //   light_pgreen: "#6cdd6c",
  //   light_maroon: "#c6283b",
  //   light_red: "#f97d9d",
  //   light_subBlue: "#17B6CF",
  //   light_grey: "#E6E7E8",
  //   light_unassigned: "#90A4AE",
  //   light_assigned: "#90CAF9",
  //   light_pickedup: "#FFB300",
  //   light_partially_delivered: "#CE93D8",
  //   light_failed: "#E53935",
  //   light_cancelled: "#F48FB1",
  //   light_successful: "#66BB6A",
  // },
};
