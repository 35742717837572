import { vehicles, version, systemLogs } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getVehicleList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(vehicles.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleDetail(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicles.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createVehicleObject(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${vehicles.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateVehicleObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${vehicles.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateVehicleObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${vehicles.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  uploadDocument(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${vehicles.base}${payload.id}/upload/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteDocument(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${version}vehicle-document/${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadVehicleSheet(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${vehicles.base}download/`, {
          params: params,
          headers: {
            // Accept: "application/xlsx",
          },
          responseType: "blob",
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getVehicleLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(systemLogs, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadVehicles(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${vehicles.base}bulk_upload/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateVehicles(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${vehicles.base}bulk_update/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  changeBranch(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${vehicles.base}${payload.id}/change_vehicle_project/`,
          payload.data
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
