import PlanningTripNewIndex from "../pages/admin/trip_planning/index.vue";
import PlanningTripView from "../pages/admin/trip_planning/view/_id/index.vue";

const PlanningTripNew = [
  {
    path: "trip_planning",
    name: "plan_trips_new",
    component: PlanningTripNewIndex,
  },
  {
    path: "trip_planning/view/:id",
    name: "view_plan_trip",
    component: PlanningTripView,
  },
];

export default PlanningTripNew;
