<template>
  <v-dialog v-model="customerFilterDialog" width="35%">
    <v-card>
      <v-card-title class="primary white--text px-3 py-2">
        <span class="text-subtitle-1 text-uppercase"
          >Customer Address Filters
        </span>
        <v-spacer />
        <v-btn small icon @click="customerFilterDialog = false">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form ref="customerFilterForm">
          <v-row no-gutters class="pt-4 px-4 background-light_primary">
            <v-col cols="12">
              <SelectField
                :multiple="true"
                :items-list="allProjects"
                label="Select Project"
                :returnObject="false"
                v-model="customerFilter.project"
                @change="syncCustomerFilter($event, 'project')"
                :dense="true"
                class="mb-4 background-white"
              />
            </v-col>
            <v-col cols="12">
              <SelectField
                :multiple="true"
                :items-list="customerTags"
                label="Select Tag"
                :returnObject="false"
                v-model="customerFilter.tags"
                @change="syncCustomerFilter($event, 'tags')"
                :dense="true"
                class="mb-4 background-white"
              />
            </v-col>
            <v-col cols="12">
              <SelectField
                hide-details
                outlined
                dense
                label="Customer Type"
                :items-list="customerType"
                v-model="customerFilter.customer_type"
                :menu-props="{ offsetY: true }"
                @change="syncCustomerFilter($event, 'customer_type')"
                class="mb-4 background-white"
              ></SelectField>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-4 pt-4">
            <v-col cols="12" class="px-1 pb-1 mb-4 border-bottom-light_black">
              <span class="text-subtitle-1 font-weight-bold text-grey">
                Filter By Time Slots
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              xl="6"
              :class="`pb-4 ${$vuetify.breakpoint.lgAndUp ? 'pr-2' : null}`"
            >
              <v-menu
                ref="fromTimeMenu"
                v-model="fromTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                :return-value.sync="customerFilter.from_time"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <InputField
                    label="From Time"
                    type="time"
                    outlined
                    dense
                    readonly
                    class=""
                    hide-details="auto"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    v-model="customerFilter.from_time"
                    v-bind="attrs"
                    v-on="on"
                  ></InputField>
                </template>
                <v-time-picker
                  format="24hr"
                  v-model="customerFilter.from_time"
                  full-width
                  @change="syncCustomerFilter($event, 'from_time')"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              xl="6"
              :class="`pb-4 ${$vuetify.breakpoint.lgAndUp ? 'pl-2' : null}`"
            >
              <v-menu
                ref="toTimeMenu"
                v-model="toTimeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                :return-value.sync="customerFilter.to_time"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <InputField
                    label="To Time"
                    placeholder="To Time"
                    type="time"
                    outlined
                    dense
                    readonly
                    class=""
                    hide-details="auto"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    v-model="customerFilter.to_time"
                    v-bind="attrs"
                    v-on="on"
                  ></InputField>
                </template>
                <v-time-picker
                  format="24hr"
                  dense
                  v-model="customerFilter.to_time"
                  full-width
                  @change="syncCustomerFilter($event, 'to_time')"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters class="pb-4 px-4">
            <v-col cols="12" class="px-1 pb-1 mb-4 border-bottom-light_black">
              <span class="text-subtitle-1 font-weight-bold text-grey">
                Ordering & Sorting
              </span>
            </v-col>
            <v-col cols="6" class="pr-2">
              <SelectField
                hide-details
                outlined
                dense
                label="Order By"
                :items-list="orderBy"
                v-model="customerFilter.ordering"
                :menu-props="{ offsetY: true }"
                @change="syncCustomerFilter($event, 'ordering')"
              ></SelectField>
            </v-col>
            <v-col cols="6" class="pl-2">
              <SelectField
                hide-details
                outlined
                dense
                label="Sort by"
                :items-list="sortBy"
                v-model="customerFilter.sorting"
                :menu-props="{ offsetY: true }"
                @change="syncCustomerFilter($event, 'sorting')"
                class="mb-4"
              ></SelectField>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4 background-light_grey">
        <v-spacer></v-spacer>
        <v-btn small depressed @click="customerFilterDialog = false">
          Cancel
        </v-btn>
        <v-btn small depressed type="reset" @click="resetFilters(true)">
          Reset
        </v-btn>
        <v-btn small depressed color="primary" @click="applyFilters(true)">
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import InputField from "@/components/BaseFormComponents/InputField.vue";
export default {
  components: {
    SelectField,
    InputField,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      fromTimeMenu: false,
      toTimeMenu: false,
      customerFilter: {},
      customerTags: [],
      allProjects: [],
      orderBy: [
        {
          text: "Customer Code",
          value: "customer_code",
        },
        {
          text: "Customer Name",
          value: "customer_name",
        },
      ],
      sortBy: [
        {
          text: "Ascending",
          value: "ascending",
        },
        {
          text: "Descending",
          value: "descending",
        },
      ],
      customerType: [
        {
          text: "B2B",
          value: "B2B",
        },
        {
          text: "B2C",
          value: "B2C",
        },
      ],
    };
  },
  watch: {
    customerFilterDialog(value) {
      if (value) {
        this.getAllProjects();
        this.getCustomerTags();
        let filters = localStorage.getItem("customerFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.customerFilter = filters;
      }
    },
  },
  computed: {
    customerFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getCustomerTags() {
      this.$api.tags.getTagList().then((res) => {
        this.customerTags = res.data.map((obj) => {
          return { text: obj.tag, value: obj.tag };
        });
      });
    },
    syncCustomerFilter(value, key) {
      let filters = localStorage.getItem("customerFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (value !== null && value.length > 0) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      if (key == "from_time") {
        this.fromTimeMenu = false;
      }
      if (key == "to_time") {
        this.toTimeMenu = false;
      }
      this.$nextTick(() => {
        this.customerFilter = filters;
      });
      localStorage.setItem("customerFilters", JSON.stringify(filters));
      this.$forceUpdate();
    },
    applyFilters(reload) {
      this.$emit("customerFilterChanged");
      this.customerFilterDialog = false;
    },
    resetFilters(reload) {
      this.customerFilter = {};
      localStorage.removeItem("customerFilters");
      if (this.$refs.customerFilterForm) {
        this.$refs.customerFilterForm.reset();
      }
      this.$emit("customerFilterChanged");
    },
  },
};
</script>