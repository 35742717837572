import { chat } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getChatData(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${chat.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  sendMessage(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${chat.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
