<template>
  <BaseDialogForm
    v-model="orderFormDialog"
    :title="formType == 'Add' ? 'Create Order' : 'Edit Order'"
    maxWidth="60vw"
  >
    <template #content>
      <v-alert v-if="nonFieldError.length" dense type="error">
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <div id="orderFormCard">
        <v-form
          v-if="orderFormDialog"
          id="orderForm"
          ref="orderForm"
          v-model="isValid"
          class="pt-3"
          :key="formCount"
        >
          <v-row>
            <v-col cols="6" lg="3">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="SO Number"
                required
                name="reference_number"
                :rules="[
                  (v) =>
                    (!!v && v.trim().length > 0) || 'SO Number is required',
                ]"
                v-model="orderFormDetails.reference_number"
                :error-messages="error.reference_number"
                @input="delete error.reference_number"
              ></InputField>
            </v-col>
            <v-col cols="6" lg="3">
              <v-menu
                ref="orderDateMenu"
                v-model="DatePickerMenu1"
                :close-on-content-click="false"
                :return-value.sync="orderFormDetails.execution_date"
                transition="scale-transition"
                max-width="290px"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <InputField
                    type="date"
                    label="Order Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    hide-details="auto"
                    name="execution_date"
                    v-bind="attrs"
                    v-on="on"
                    required
                    class="background-white"
                    :error-messages="
                      error && error.execution_date ? error.execution_date : ''
                    "
                    :rules="[(v) => !!v || 'Order date is required']"
                    v-model="orderFormDetails.execution_date"
                    :menu-props="{ offsetY: true }"
                  ></InputField>
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  reactive
                  :min="nowDate"
                  v-model="orderFormDetails.execution_date"
                  @change="
                    $refs.orderDateMenu.save(orderFormDetails.execution_date),
                      error && error.execution_date
                        ? delete error.execution_date
                        : ''
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" lg="3">
              <SelectField
                :itemsList="orderType"
                label="Order Type"
                outlined
                dense
                name="order_type"
                item-text="name"
                item-value="value"
                v-model="orderFormDetails.order_type"
                hide-details="auto"
                class="mt-0 pt-0"
                required
                :error-messages="error.order_type"
                :menu-props="{ offsetY: true }"
                @change="delete error.order_type"
                :isClearable="false"
                :isSearchRequired="false"
                :readonly="formType == 'edit' || formType == 'Edit'"
              >
              </SelectField>
            </v-col>
            <v-col cols="6" lg="3">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                type="number"
                label="Order Value"
                min="0"
                name="order_value"
                :error-messages="error.order_value"
                @input="delete error.order_value"
                v-model="orderFormDetails.order_value"
              ></InputField>
            </v-col>
            <v-col cols="6" lg="3">
              <SelectField
                hide-details="auto"
                outlined
                :menu-props="{ offsetY: true }"
                label="Barcode Scanning"
                :items-list="barcodeScanning"
                name="barcode_scanning"
                item-text="name"
                item-value="value"
                :isClearable="false"
                :isSearchRequired="false"
                required
                :error-messages="error.barcode_scanning"
                @input="delete error.barcode_scanning"
                v-model="orderFormDetails.barcode_scanning"
              ></SelectField>
            </v-col>
            <v-col cols="6" lg="3">
              <SelectField
                hide-details="auto"
                outlined
                label="Proof of delivery*"
                :menu-props="{ offsetY: true }"
                name="pod_required"
                :items-list="podRequired"
                item-text="name"
                item-value="value"
                :isClearable="false"
                :isSearchRequired="false"
                :error-messages="error.pod_required"
                @input="delete error.pod_required"
                v-model="orderFormDetails.pod_required"
              ></SelectField>
            </v-col>
            <v-col cols="6" lg="3">
              <SelectField
                hide-details="auto"
                outlined
                :menu-props="{ offsetY: true }"
                label="Customer notification"
                name="customer_notifications"
                :items-list="customerNotifications"
                item-text="name"
                item-value="value"
                :isClearable="false"
                :isSearchRequired="false"
                v-model="orderFormDetails.customer_notifications"
              ></SelectField>
            </v-col>
            <v-col cols="3">
              <InputField
                outlined
                hide-details="auto"
                label="Invoice Number"
                name="invoice_number"
                :error-messages="error.invoice_number"
                @input="delete error.invoice_number"
                v-model="orderFormDetails.invoice_number"
              ></InputField>
            </v-col>
            <v-col cols="6" lg="3">
              <SelectField
                hide-details="auto"
                :menu-props="{ offsetY: true }"
                outlined
                label="Payment type"
                name="payment_type"
                :items-list="paymentType"
                item-text="name"
                item-value="value"
                :isClearable="false"
                :isSearchRequired="false"
                :error-messages="error.payment_type"
                @input="delete error.payment_type"
                v-model="orderFormDetails.payment_type"
              ></SelectField>
            </v-col>
            <v-col cols="3">
              <InputField
                outlined
                hide-details="auto"
                label="Instructions"
                name="instructions"
                :error-messages="error.instructions"
                @input="delete error.instructions"
                v-model="orderFormDetails.instructions"
              ></InputField>
            </v-col>
            <v-col cols="6">
              <InputField
                outlined
                hide-details="auto"
                label="Order Remarks"
                name="order_remarks"
                :error-messages="error.order_remarks"
                @input="delete error.order_remarks"
                v-model="orderFormDetails.order_remarks"
              ></InputField>
            </v-col>
            <v-col
              cols="3"
              v-if="
                (formType == 'edit' || formType == 'Edit') &&
                orderFormDetails.payment_type == 'cod'
              "
            >
              <InputField
                outlined
                hide-details="auto"
                label="Payment received*"
                name="payment_received"
                :error-messages="error.payment_received"
                @input="delete error.payment_received"
                v-model="orderFormDetails.payment_received"
              ></InputField>
            </v-col>
            <v-col
              v-if="formType == 'Edit' || formType == 'edit'"
              cols="6"
              lg="3"
            >
              <SelectField
                outlined
                hide-details="auto"
                name="status"
                :items-list="orderStatus"
                item-text="name"
                item-value="value"
                label="Order Status"
                :isClearable="false"
                :isSearchRequired="false"
                :error-messages="error.status"
                @input="delete error.status"
                v-model="orderFormDetails.status"
              ></SelectField>
            </v-col>
            <v-col
              v-if="
                (formType == 'edit' || formType == 'Edit') &&
                orderFormDetails.status == 'cancelled'
              "
              cols="6"
              lg="9"
            >
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                name="cancellation_remarks"
                label="Cancellation Remarks"
                :rules="[(v) => !!v || 'Cancellation remarks is required']"
                :error-messages="error.cancellation_remarks"
                @input="delete error.cancellation_remarks"
                v-model="orderFormDetails.cancellation_remarks"
              ></InputField>
            </v-col>
            <v-col
              cols="12"
              class="mt-4"
              v-if="formType == 'edit' || formType == 'Edit'"
            >
              <span class="font-weight-bold text-subtitle-1">
                Upload attachment
              </span>
            </v-col>

            <v-col cols="4" v-if="formType == 'edit' || formType == 'Edit'">
              <v-file-input
                ref="fileInput"
                :clearable="true"
                hide-details
                dense
                type="file"
                multiple="multiple"
                v-model="uploadFiles"
                label="Upload Document"
                prepend-inner-icon="mdi-attachment mdi-rotate-90"
                accept="image/*, .pdf"
                prepend-icon=""
                outlined
                @change="getFileData($event)"
                @click:clear="clearFileSelection()"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              class="mt-4"
              v-if="
                (formType == 'edit' || formType == 'Edit') &&
                documentList.length != 0
              "
            >
              <span class="font-weight-bold text-subtitle-1"> New Upload </span>
            </v-col>

            <v-col
              cols="6"
              lg="9"
              v-if="
                (formType == 'edit' || formType == 'Edit') &&
                documentList.length != 0
              "
            >
              <v-card
                elevation="0"
                outlined
                v-for="(data, j) in documentList"
                :key="j"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="7">
                      <a :href="generateUrl(data.file)" target="_black">
                        <h4>{{ data.file.name }}</h4>
                      </a>
                    </v-col>
                    <v-col cols="2">
                      <h4>{{ getSize(data.file.size) }}</h4>
                    </v-col>
                    <v-col cols="2"> </v-col>
                    <v-col cols="1">
                      <v-icon small color="primary" @click="removeFile(j)"
                        >mdi-close</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              class="mt-4"
              v-if="
                (formType == 'edit' || formType == 'Edit') &&
                orderFormDetails.order_attachments &&
                orderFormDetails.order_attachments.length != 0
              "
            >
              <span class="font-weight-bold text-subtitle-1">
                Order Attachments
              </span>
            </v-col>

            <v-col
              cols="6"
              lg="9"
              v-if="
                (formType == 'edit' || formType == 'Edit') &&
                orderFormDetails.order_attachments &&
                orderFormDetails.order_attachments.length != 0
              "
            >
              <v-card
                elevation="0"
                outlined
                v-for="(data, j) in orderFormDetails.order_attachments"
                :key="j"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="9">
                      <a :href="data.url" target="_black">
                        <h4>{{ data.name }}</h4>
                      </a>
                    </v-col>
                    <v-col cols="2"> </v-col>
                    <v-col cols="1">
                      <v-icon
                        small
                        color="primary"
                        @click="deleteFile(data.id, j)"
                        >mdi-close</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="orderFormDetails.status != 'cancelled'">
            <v-col cols="6" class="mt-4">
              <span class="font-weight-bold text-subtitle-1"
                >Customer Details
              </span>
            </v-col>
            <v-col
              v-if="formType == 'add' || formType == 'Add'"
              cols="6"
              class="mt-4 d-flex justify-end"
            >
              <span class="font-weight-bold text-subtitle-1">
                <v-radio-group
                  row
                  hide-details="auto"
                  class="ma-0"
                  name="new_customer"
                  mandatory
                  v-model="customerGroup"
                  @change="customerTypeChange"
                >
                  <v-radio label="Existing Customer" :value="false"></v-radio>
                  <v-radio label="New Customer" :value="true"></v-radio>
                </v-radio-group>
              </span>
            </v-col>
          </v-row>
          <hr class="my-4" v-if="orderFormDetails.status != 'cancelled'" />
          <!-------------------------- order type is new & customer already exists -------------------------->
          <v-row
            v-if="
              (formType == 'add' || formType == 'Add') &&
              !customerGroup &&
              orderFormDetails.status != 'cancelled'
            "
          >
            <v-col cols="6" lg="3">
              <SelectField
                outlined
                hide-details="auto"
                label="Customer code*"
                :itemsList="customerCode"
                item-text="customer_code"
                item-value="id"
                name="customer_code"
                :rules="[(v) => !!v || 'Customer code is required']"
                :error-messages="error.customer_code"
                v-model="customerDetails.customer_code"
                @change="
                  setCustomerCode($event),
                    error && error.customer_code
                      ? delete error.customer_code
                      : ''
                "
                return-object
              >
              </SelectField>
            </v-col>
            <v-col cols="6" lg="3">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="Customer name*"
                name="customer_name"
                :error-messages="error.customer_name"
                @input="delete error.customer_name"
                :rules="[(v) => !!v || 'Customer name is required']"
                v-model="customerDetails.customer_name"
              ></InputField>
            </v-col>
            <v-col cols="6" lg="3">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="Contact person"
                name="contact_person"
                :error-messages="error.contact_person"
                @input="delete error.contact_person"
                v-model="customerDetails.contact_person"
              ></InputField>
            </v-col>
            <v-col cols="6" lg="3">
              <InputField
                outlined
                hide-details="auto"
                type="number"
                class="background-white"
                label="Contact number"
                name="contact_number"
                min="0"
                :error-messages="error.contact_number"
                @input="delete error.contact_number"
                v-model="customerDetails.contact_number"
              ></InputField>
            </v-col>
            <v-col cols="6" lg="3">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="Contact email"
                name="contact_email"
                :error-messages="error.contact_email"
                @input="delete error.contact_email"
                v-model="customerDetails.contact_email"
              ></InputField>
            </v-col>
            <v-col cols="12">
              <v-text-field
                disabled
                outlined
                ref="customerAddressFormAddress"
                id="customerAddressFormAddress"
                hide-details="auto"
                class="background-white"
                dense
                name="address"
                placeholder="Enter Address*"
                :rules="[
                  (v) => (!!v && v.trim().length > 0) || 'Address is required',
                ]"
                :error-messages="error.address"
                @input="
                  error && error.address
                    ? delete error.address
                    : '' || (error && error.coordinates)
                    ? delete error.coordinates
                    : ''
                "
                v-model="customerDetails.address"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <InputField
                disabled
                outlined
                hide-details="auto"
                class="background-white"
                label="Latitude"
                type="number"
                step="0.000001"
                name="latitude"
                :error-messages="error.coordinates"
                @input="
                  error && error.coordinates ? delete error.coordinates : ''
                "
                v-model="customerDetails.coordinates.latitude"
              ></InputField>
            </v-col>
            <v-col cols="3">
              <InputField
                disabled
                outlined
                hide-details="auto"
                class="background-white"
                label="Longitude"
                type="number"
                step="0.0000001"
                name="longitude"
                :error-messages="error.coordinates"
                @input="
                  error && error.coordinates ? delete error.coordinates : ''
                "
                v-model="customerDetails.coordinates.longitude"
              ></InputField>
            </v-col>
          </v-row>
          <!------------------------------- Order & customer both are new  ------------------------------->
          <v-row
            v-if="
              (formType == 'add' || formType == 'Add') &&
              customerGroup &&
              orderFormDetails.status != 'cancelled'
            "
          >
            <!--------------------------------- Customer form --------------------------------->
            <v-row class="pt-6 pa-3">
              <v-col cols="6" md="3">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Customer Code*"
                  :rules="[(v) => !!v || 'Customer Code is required']"
                  :error-messages="error.customer_code"
                  @input="
                    error && error.customer_code
                      ? (error.customer_code = '')
                      : ''
                  "
                  v-model="customerDetails.customer_code"
                ></InputField>
              </v-col>
              <v-col cols="6" md="3">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Customer Name*"
                  name="customer_name"
                  :rules="[
                    (v) =>
                      (!!v && v.trim().length > 0) ||
                      'Customer Name is required',
                  ]"
                  :error-messages="error.customer_name"
                  @input="
                    error && error.customer_name
                      ? delete error.customer_name
                      : ''
                  "
                  v-model="customerDetails.customer_name"
                ></InputField>
              </v-col>
              <v-col cols="6" md="3">
                <SelectField
                  :isSearchRequired="false"
                  outlined
                  hide-details="auto"
                  :menu-props="{ offsetY: true }"
                  label="Customer Type"
                  :items-list="customerType"
                  name="customer_type"
                  :error-messages="error.customer_type"
                  @change="
                    error && error.customer_type
                      ? delete error.customer_type
                      : ''
                  "
                  v-model="customerDetails.customer_type"
                ></SelectField>
              </v-col>
              <v-col cols="6" md="3">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Contact Person"
                  name="contact_person"
                  :error-messages="error.contact_person"
                  @input="
                    error && error.contact_person
                      ? delete error.contact_person
                      : ''
                  "
                  v-model="customerDetails.contact_person"
                ></InputField>
              </v-col>
              <v-col cols="6" md="3">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Contact Number"
                  name="contact_number"
                  type="number"
                  min="0"
                  step="1"
                  :error-messages="error.contact_number"
                  @input="
                    error && error.contact_number
                      ? delete error.contact_number
                      : ''
                  "
                  v-model="customerDetails.contact_number"
                ></InputField>
              </v-col>
              <v-col cols="6" md="3">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Contact email"
                  name="contact_email"
                  :error-messages="error.contact_email"
                  @input="
                    error && error.contact_email
                      ? delete error.contact_email
                      : ''
                  "
                  v-model="customerDetails.contact_email"
                ></InputField>
              </v-col>
              <v-col cols="3">
                <SelectField
                  outlined
                  hide-details="auto"
                  label="Project*"
                  :menu-props="{ offsetY: true }"
                  name="project"
                  :items-list="allProjects"
                  :rules="[(v) => !!v || 'Project is required']"
                  :error-messages="error.project"
                  @change="error && error.project ? delete error.project : ''"
                  v-model="customerDetails.project"
                ></SelectField>
              </v-col>
              <v-col cols="3">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Processing Time(Minutes)*"
                  name="processing_time"
                  min="0"
                  type="number"
                  step="01"
                  :error-messages="error.processing_time"
                  @input="
                    error && error.processing_time
                      ? delete error.processing_time
                      : ''
                  "
                  v-model="customerDetails.processing_time"
                  :rules="[(v) => !!v || 'Processing Time is required']"
                ></InputField>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  ref="customerAddressFormAddress"
                  id="customerAddressFormAddress"
                  hide-details="auto"
                  class="background-white"
                  dense
                  name="address"
                  placeholder="Enter Address*"
                  :rules="[
                    (v) =>
                      (!!v && v.trim().length > 0) || 'Address is required',
                  ]"
                  :error-messages="error.address"
                  @input="
                    error && error.address
                      ? delete error.address
                      : '' || (error && error.coordinates)
                      ? delete error.coordinates
                      : ''
                  "
                  v-model="customerDetails.address"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Latitude"
                  type="number"
                  step="0.000001"
                  name="latitude"
                  :error-messages="error.coordinates"
                  @input="
                    error && error.coordinates ? delete error.coordinates : ''
                  "
                  v-model="customerDetails.coordinates.latitude"
                ></InputField>
              </v-col>
              <v-col cols="3">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Longitude"
                  type="number"
                  step="0.0000001"
                  name="longitude"
                  :error-messages="error.coordinates"
                  @input="
                    error && error.coordinates ? delete error.coordinates : ''
                  "
                  v-model="customerDetails.coordinates.longitude"
                ></InputField>
              </v-col>
              <!------------------------ New customer time slot ----------------------------->
              <v-col cols="3">
                <InputField
                  label="From Time*"
                  outlined
                  type="time"
                  class="background-white"
                  hide-details="auto"
                  name="from_time"
                  :error-messages="error.delivery_window_start"
                  @input="
                    error && error.delivery_window_start
                      ? delete error.delivery_window_start
                      : ''
                  "
                  v-model="customerDetails.delivery_window_start"
                  :rules="[(v) => !!v || 'From time is required']"
                ></InputField>
              </v-col>
              <v-col cols="3">
                <InputField
                  label="To Time*"
                  type="time"
                  outlined
                  class="background-white"
                  hide-details="auto"
                  name="to_time"
                  :error-messages="error.delivery_window_end"
                  @input="
                    error && error.delivery_window_end
                      ? delete error.delivery_window_end
                      : ''
                  "
                  v-model="customerDetails.delivery_window_end"
                  :rules="[
                    (v) => !!v || 'To time is required',
                    (v) =>
                      (customerDetails.delivery_window_start &&
                        v > customerDetails.delivery_window_start) ||
                      'To time can not be less than from time',
                  ]"
                ></InputField>
              </v-col>
              <v-col cols="6">
                <SwitchField
                  label="WhatsApp Notifications"
                  v-model="customerDetails.whatsapp_notification"
                ></SwitchField>
              </v-col>
              <v-col cols="6">
                <SwitchField
                  label="E-mail Notifications"
                  v-model="customerDetails.email_notification"
                ></SwitchField>
              </v-col>
            </v-row>
          </v-row>
          <!--------------------------------------- If form type is edit --------------------------------------->
          <v-row
            v-if="
              (formType == 'edit' || formType == 'Edit') &&
              orderFormDetails.status != 'cancelled'
            "
          >
            <v-col cols="6" md="4" xl="4">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="Contact Person"
                name="contact_person"
                :error-messages="error.contact_person"
                @input="
                  error && error.contact_person
                    ? delete error.contact_person
                    : ''
                "
                v-model="customerDetails.contact_person"
              ></InputField>
            </v-col>
            <v-col cols="6" md="4" xl="4">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="Contact Number"
                name="contact_number"
                type="number"
                min="0"
                step="1"
                :error-messages="error.contact_number"
                @input="
                  error && error.contact_number
                    ? delete error.contact_number
                    : ''
                "
                v-model="customerDetails.contact_number"
              ></InputField>
            </v-col>
            <v-col cols="6" md="4" xl="4">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="Contact email"
                name="contact_email"
                :error-messages="error.contact_email"
                @input="
                  error && error.contact_email ? delete error.contact_email : ''
                "
                v-model="customerDetails.contact_email"
              ></InputField>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                ref="customerAddressFormAddress"
                hide-details="auto"
                class="background-white"
                name="address"
                placeholder="Enter Address*"
                :rules="[
                  (v) => (!!v && v.trim().length > 0) || 'Address is required',
                ]"
                :error-messages="error.address"
                @input="
                  error && error.address
                    ? delete error.address
                    : '' || (error && error.coordinates)
                    ? delete error.coordinates
                    : ''
                "
                v-model="customerDetails.address"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="Latitude"
                type="number"
                step="0.000001"
                name="latitude"
                :error-messages="error.coordinates"
                @input="
                  error && error.coordinates ? delete error.coordinates : ''
                "
                v-model="customerDetails.coordinates.latitude"
              ></InputField>
            </v-col>
            <v-col cols="4">
              <InputField
                outlined
                hide-details="auto"
                class="background-white"
                label="Longitude"
                type="number"
                step="0.0000001"
                name="longitude"
                :error-messages="error.coordinates"
                @input="
                  error && error.coordinates ? delete error.coordinates : ''
                "
                v-model="customerDetails.coordinates.longitude"
              ></InputField>
            </v-col>
            <v-col cols="4">
              <InputField
                outlined
                type="time"
                hide-details="auto"
                class="background-white"
                label="Delivery Window Start"
                name="delivery_window_start"
                required
                :error-messages="error.delivery_window_start"
                @input="
                  error && error.delivery_window_start
                    ? delete error.delivery_window_start
                    : ''
                "
                v-model="customerDetails.delivery_window_start"
              ></InputField>
            </v-col>
            <v-col cols="4">
              <InputField
                outlined
                type="time"
                hide-details="auto"
                class="background-white"
                label="Delivery Window End"
                name="delivery_window_end"
                required
                :error-messages="error.delivery_window_end"
                @input="
                  error && error.delivery_window_end
                    ? delete error.delivery_window_end
                    : ''
                "
                v-model="customerDetails.delivery_window_end"
              ></InputField>
            </v-col>
          </v-row>

          <v-row v-if="orderFormDetails.status != 'cancelled'">
            <v-col cols="12" class="mt-4">
              <span class="font-weight-bold text-subtitle-1"> Add Item </span>
              <hr class="my-4" />
            </v-col>

            <!-- ---------------------------------------------- Item Section ---------------------------- -->
            <v-col
              cols="12"
              v-for="(item, index) in orderFormDetails.order_items"
              :key="index"
            >
              <v-row>
                <v-col cols="3">
                  <SelectField
                    :itemsList="itemCode"
                    label="Item Code"
                    v-model="item.item_no"
                    item-text="item_no"
                    item-value="item_no"
                    required
                    name="item_no"
                    :return-object="true"
                    :rules="[(val) => !!val || `Item Code is required`]"
                    :error-messages="
                      error &&
                      error.order_items &&
                      error.order_items[index] &&
                      error.order_items[index].item
                        ? error.order_items[index].item
                        : []
                    "
                    @change="
                      error &&
                      error.order_items &&
                      error.order_items[index] &&
                      error.order_items[index].item
                        ? (delete error.order_items[index].item,
                          delete error.order_items[index].unit)
                        : [];
                      setItemCode($event, index);
                    "
                  />
                </v-col>
                <!-- <v-combobox
                    outlined
                    hide-details="auto"
                    dense
                    label="Item Code*"
                    class="comboClass"
                    :loading="isLoading"
                    :search-input.sync="searchItem[index]"
                    :items="itemCode"
                    item-text="item_no"
                    item-value="item_no"
                    :rules="[(v) => !!v || 'Item code is required']"
                    :error-messages="error.item_no"
                    v-model="item.item_no"
                    @focus="selectedItemIndex = itemCode"
                    @change="setItemCode($event, index)"
                    return-object
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.item_no"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-combobox> -->
                <!-- </v-col> -->

                <v-col cols="3">
                  <InputField
                    outlined
                    hide-details="auto"
                    dense
                    label="Item Name*"
                    :error-messages="error.item_description"
                    @input="delete error.item_description"
                    :rules="[
                      (v) =>
                        (!!v && v.trim().length > 0) || 'Item name is required',
                    ]"
                    v-model="item.item_description"
                  ></InputField>
                </v-col>
                <v-col cols="2">
                  <InputField
                    type="number"
                    min="1"
                    outlined
                    hide-details="auto"
                    dense
                    label="Quantity*"
                    :error-messages="error.original_quantity"
                    @input="
                      error && error.original_quantity
                        ? delete error.original_quantity
                        : ''
                    "
                    :rules="[
                      (v) => !!v || 'Quantity is required',
                      (v) => v > 0 || 'Quantity can not be 0',
                    ]"
                    v-model="item.original_quantity"
                  ></InputField>
                </v-col>
                <v-col cols="2">
                  <InputField
                    v-if="
                      (formType == 'edit' || formType == 'Edit') &&
                      orderFormDetails.status != 'unassigned' &&
                      orderFormDetails.status != 'assigned' &&
                      orderFormDetails.status != 'failed' &&
                      orderFormDetails.status != 'cancelled'
                    "
                    type="number"
                    min="1"
                    outlined
                    hide-details="auto"
                    dense
                    label="Delivered Quantity"
                    :error-messages="error.delivered_quantity"
                    @input="
                      error && error.delivered_quantity
                        ? delete error.delivered_quantity
                        : ''
                    "
                    v-model="item.delivered_quantity"
                  ></InputField>
                </v-col>
                <v-col cols="2" class="d-flex justify-center">
                  <div class="pr-3">
                    <v-btn
                      :small="$vuetify.breakpoint.xl"
                      :x-small="$vuetify.breakpoint.lgAndDown"
                      v-if="orderFormDetails.order_items.length > 1"
                      class="light_grey elevation-0 py-5"
                      @click="removeItem(index)"
                      :disabled="canRemoveItem(item)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                  <v-btn
                    :small="$vuetify.breakpoint.xl"
                    :x-small="$vuetify.breakpoint.lgAndDown"
                    class="primary elevation-0 py-5"
                    @click="addItem(index)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- ---------------------------------------------- Item Section ---------------------------- -->
          </v-row>
        </v-form>
      </div>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        :disabled="!isValid"
        width="9%"
        class="primary"
        @click.prevent="submitOrderForm()"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'Add'"
        width="9%"
        class="primary mr-4"
        @click="clear()"
      >
        <span>Reset</span>
      </v-btn>
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import InputField from "@/components/BaseFormComponents/InputField.vue";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import { bus } from "@/main.js";

export default {
  components: {
    BaseDialogForm,
    InputField,
    SelectField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      orderFormDetails: {
        coordinates: {},
        order_items: [{}],
        whatsapp_notification: false,
        email_notification: false,
      },
      allItemList: [],
      customerDetails: {},
      uploadFiles: null,
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      // variables for widgets
      menuFromTime: false,
      menuToTime: false,
      customerGroup: false,
      nonFieldError: [],
      isLoading: false,
      isLoadingItem: false,
      customerCode: [],
      documentList: [],
      itemCode: [],
      allProjects: [],
      error: {
        customer_code: [],
      },
      formCount: 0,

      search: "",
      searchItem: [],
      customerType: [
        { text: "Business", value: "B2B" },
        { text: "Individual", value: "B2C" },
      ],
      selectedItemIndex: 0,
      customerNotifications: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
      podRequired: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
      barcodeScanning: [
        {
          name: "Yes",
          value: true,
        },
        {
          name: "No",
          value: false,
        },
      ],
      orderType: [
        {
          name: "Delivery",
          value: "delivery",
        },
        {
          name: "Pickup",
          value: "pickup",
        },
      ],
      paymentType: [
        {
          name: "Prepaid",
          value: "prepaid",
        },
        {
          name: "Cash on delivery",
          value: "cod",
        },
        {
          name: "Credit",
          value: "credit",
        },
      ],
      orderStatus: [
        {
          name: "Unassigned",
          value: "unassigned",
        },
        {
          name: "Assigned",
          value: "assigned",
        },
        {
          name: "Shipped",
          value: "pickedup",
        },
        {
          name: "Partially Delivered",
          value: "partially_delivered",
        },
        {
          name: "Delivered",
          value: "successful",
        },
        {
          name: "Returned",
          value: "failed",
        },
        {
          name: "Cancelled",
          value: "cancelled",
        },
      ],
      DatePickerMenu1: false,
      isValid: false,
      date: null,
      nowDate: new Date().toISOString().slice(0, 10),
      executed: true,
    };
  },
  watch: {
    // customerGroup(val) {
    //   if (val) {
    //     this.orderFormDetails.customer_code = null;
    //     this.autoCompleteAddress();
    //   }
    // },
    orderFormDialog(val) {
      if (val) {
        this.customerDetails = {
          coordinates: {
            latitude: null,
            longitude: null,
          },
        };
        this.getAllProjects();
        this.getCustomerDropDownList();
        this.getItemList({ limit: "all" });
        this.autoCompleteAddress();
      } else {
        this.clear();
      }
    },
  },
  computed: {
    orderFormDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getItemList(params) {
      this.isLoadingItem = true;
      params = {
        ...params,
      };
      this.$api.items
        .getItemList(params)
        .then((res) => {
          this.isLoadingItem = false;
          this.itemCode = res.data.results;
        })
        .catch((err) => {});
    },
    getCustomerDropDownList(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.customerAddresses
        .getCustomerDropDownList(params)
        .then((res) => {
          this.customerCode = res.data.results;
        })
        .catch((err) => {});
    },
    clear() {
      this.error = {};
      this.nonFieldError = [];
      this.executed = true;
      this.documentList = [];
      this.orderFormDetails = {
        coordinates: {},
        order_items: [{}],
        whatsapp_notification: false,
        email_notification: false,
      };
      this.customerDetails = {
        coordinates: {},
      };
      // this.$refs.orderForm.reset();
      this.$refs.orderForm.resetValidation();
    },
    canRemoveItem(item) {
      if (
        this.orderFormDetails.status == "unassigned" &&
        this.orderFormDetails.status == "assigned"
      ) {
        return false;
      } else {
        if (item.isNewItem) {
          return false;
        } else {
          return true;
        }
      }
    },
    removeItem(index) {
      this.orderFormDetails.order_items.splice(index, 1);
    },
    addItem() {
      // let items = this.orderFormDetails.order_items;

      // items = [...items, {}];

      // this.orderFormDetails = {
      //   ...this.orderFormDetails,
      //   order_items: items,
      // };

      this.orderFormDetails.order_items.push({
        item_no: null,
        item_description: null,
        original_quantity: null,
        delivered_quantity: null,
        isNewItem: true,
      });
      this.formCount++;
      setTimeout(() => {
        let elmnt = document.getElementById("orderForm");
        elmnt.scrollIntoView({ block: "end", behavior: "smooth" });
      }, 100);
    },
    checkCustomerType(new_customer) {
      this.orderFormDetails.new_customer = new_customer;
      if (!new_customer && this.formType == "Add") {
        delete this.orderFormDetails.coordinates;
      } else if (new_customer && this.formType == "Add") {
        delete this.orderFormDetails.customer_address;
      } else {
        delete this.orderFormDetails.new_customer;
      }
    },
    customerTypeChange() {
      this.customerDetails = {
        customer_code: "",
        coordinates: {
          latitude: null,
          longitude: null,
        },
      };

      if (this.$refs.orderForm) {
        this.$refs.orderForm.resetValidation();
      }

      this.syncData(null, "customer_code");
      this.syncData(null, "customer_name");
      this.syncData(null, "contact_number");
      this.syncData(null, "contact_email");
      this.syncData(null, "contact_person");
      this.syncData(null, "address");
      // this.syncData(null, "coordinates", "latitude");
      // this.syncData(null, "coordinates", "longitude");
      this.syncData(null, "project");
      this.executed = true;
    },
    setItemCode(e, index) {
      let itemNo = this.itemCode.find((v) => v.item_no == e.item_no).item_no;
      let itemDescription = this.itemCode.find(
        (v) => v.item_no == e.item_no
      ).item_description;

      this.orderFormDetails.order_items[index].item_no = itemNo;
      this.orderFormDetails.order_items[index].item_description =
        itemDescription;
    },
    setCustomerCode(e) {
      // this.orderFormDetails.customer_code = e.customer_code;
      this.customerDetails.customer_name = e.customer_name;
      this.customerDetails.contact_person = e.contact_person;
      this.customerDetails.contact_number = e.contact_number;
      this.customerDetails.contact_email = e.contact_email;
      this.customerDetails.project = e.project;
      this.customerDetails.address = e.address;
      this.customerDetails.coordinates.latitude = e.coordinates.latitude;
      this.customerDetails.coordinates.longitude = e.coordinates.longitude;

      // this.syncData(e.customer_code, "customer_code");
      // this.syncData(e.customer_name, "customer_name");
      // this.syncData(e.contact_number, "contact_number");
      // this.syncData(e.contact_email, "contact_email");
      // this.syncData(e.contact_person, "contact_person");
      // this.syncData(e.address, "customer_address");
      // this.syncData(e.project, "project");
    },
    async closeDialog() {
      this.orderFormDialog = false;
      await this.clear();
    },
    syncData(input_value, key, subKey) {
      if (this.error[key]) {
        this.error[key] = null;
        delete this.error[key];
      }
      if (key == "address" && this.executed) {
        this.executed = false;
        this.autoCompleteAddress();
      }
      // }
    },

    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    imageFileCheck(fileName) {
      let extension = fileName.slice(
        (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1
      );
      if (extension == "png" || extension == "jpeg" || extension == "jpg") {
        return true;
      } else {
        bus.$emit("showToastMessage", {
          message: `Select file(s) are not supported.Support file Types: PNG, JPEG, JPG `,
          color: "error",
        });
        return false;
      }
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },

    getFileData(files) {
      if (files) {
        let l = files.length;
        let i = 0;
        while (l !== i) {
          const file = files[i];
          i++;
          if (this.imageFileCheck(file.name)) {
            this.documentList.push({
              file: file,
            });
          } else {
            this.clearFileSelection();
            return false;
          }
        }
      } else {
        this.clearFileSelection();
      }
    },

    clearFileSelection() {
      this.$refs.fileInput.reset();
      this.documentList = [];
    },

    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    removeFile(index) {
      this.documentList.splice(index, 1);
    },
    deleteFile(id, index) {
      let payload = {
        id: id,
        index: index,
      };
      bus.$emit("showLoader", true);
      this.$api.orders
        .removeAttachmentFromOrder(payload.id)
        .then((response) => {
          this.documentList.splice(index, 1);
          bus.$emit("showToastMessage", {
            message: `Deleted Attachment Successfully!`,
            color: "success",
          });
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: `Something went wrong!`,
            color: "error",
          });
          bus.$emit("showLoader", false);
        });
    },
    getOrderDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.orders
        .getOrderDetails(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.orderFormDetails = res.data;
          let customerDetailsObj = {
            contact_person: res.data.contact_person,
            contact_number: res.data.contact_number,
            contact_email: res.data.contact_email,
            address: res.data.address,
            coordinates: {
              latitude: res.data.coordinates.latitude,
              longitude: res.data.coordinates.longitude,
            },
            delivery_window_start: res.data.delivery_window_start,
            delivery_window_end: res.data.delivery_window_end,
          };
          this.customerDetails = customerDetailsObj;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },

    autoCompleteAddress() {
      const interval = setInterval(async () => {
        if (
          this.$refs.customerAddressFormAddress &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(interval);
          let input = this.$refs.customerAddressFormAddress.$refs.input;

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["AE"],
          });
          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat();
            let lon = place.geometry.location.lng();

            setTimeout(() => {
              this.customerDetails.address =
                place.name + ", " + place.formatted_address;
              this.customerDetails.coordinates.latitude = lat;
              this.customerDetails.coordinates.longitude = lon;
            }, 100);
          });
        }
      }, 100);
    },
    uploadDocument(id = null) {
      let length = this.documentList.length;
      let i = 0;
      if (length > 0) {
        while (i < length) {
          let payload = {
            id: null,
            data: null,
          };
          payload.id = id;
          let newForm = new FormData();
          this.documentList.forEach((file, index) => {
            newForm.append("attachments", file.file);
          });
          newForm.append("attachment_type", "order");
          payload.data = newForm;
          this.$api.orders.addAttachmentIntoOrder(payload).then((response) => {
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Order successfully Updated!",
              color: "success",
            });
          });
          i++;
        }
      }
    },
    submitOrderForm() {
      let payload;

      let customerObj;

      customerObj = {
        ...this.customerDetails,
        customer_code:
          typeof this.customerDetails.customer_code == "object"
            ? this.customerDetails.customer_code.customer_code
            : this.customerDetails.customer_code,
      };
      payload = {
        ...this.orderFormDetails,
        ...customerObj,
        new_customer: this.customerGroup ? true : false,
      };
      if (this.formType == "Add") {
        bus.$emit("showLoader", true);
        this.$api.orders
          .createOrderObject(payload)
          .then((result) => {
            bus.$emit("showLoader", false);

            this.orderFormDialog = false;
            this.clear();
            bus.$emit("showToastMessage", {
              message: "Order successfully Added!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err && err.data && err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      } else {
        let editPayload = {
          id: this.orderFormDetails.id,
          data: payload,
        };
        bus.$emit("showLoader", true);
        this.$api.orders
          .updateOrderObject(editPayload)
          .then((result) => {
            bus.$emit("showLoader", false);
            if (this.documentList && this.documentList.length > 0) {
              this.uploadDocument(this.orderFormDetails.id);
            }
            this.orderFormDialog = false;
            this.clear();
            bus.$emit("showToastMessage", {
              message: "Order successfully Updated!",
              color: "success",
            });

            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            // if (err.data.non_field_errors) {
            //   this.nonFieldError = err.data.non_field_errors;
            // }
            this.error = err.data;
          });
      }
    },
  },
};
</script>

<style>
</style>