<template>
  <v-app>
    <SideNavigation />
    <Header />
    <v-main style="position: relative">
      <v-container
        fluid
        class="pa-0"
        style="height: 100% !important; position: absolute"
      >
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/common/Header.vue";
import SideNavigation from "@/components/common/SideNavigation.vue";
import { Loader } from "@googlemaps/js-api-loader";
import { bus } from "@/main.js";

export default {
  name: "mainLayout",
  components: {
    Header,
    SideNavigation,
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    checkIfLoggedIn() {
      let user = localStorage.getItem("user");
      if (!user) {
        if (this.$route.path !== "/app/login") {
          this.$router.push({ path: "/app/login" });
        }
      } else {
        if (this.$route.fullPath == "/app") {
          this.$router.push({ path: "/app/admin" });
        }
        // if (["/app", "/app/admin"].indexOf(this.$route.fullPath) > -1) {
        //   this.$router.push({ path: "/app/admin/dashboard" });
        // }
      }
    },
  },
  async mounted() {
    if (window.google == undefined) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GMAP_KEY,
        version: "weekly",
        libraries: ["places", "geometry", "drawing"],
      });
      await loader.load();
    }
  },
  beforeDestroy() {
    this.checkIfLoggedIn();
  },
};
</script>
