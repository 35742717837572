const logo = require("@/assets/logo.png");

// const loginBackGroundLogo = require("@/assets/login-bg.png");
const profileImage = require("@/assets/user.png");

const itemsPerPage = 10;

export const orderStatus = [
  {
    label: "Unassigned",
    value: "unassigned",
  },
  {
    label: "Assigned",
    value: "assigned",
  },
  {
    label: "Picked Up",
    value: "pickedup",
  },
  {
    label: "Partially Delivered",
    value: "partially_delivered",
  },
  {
    label: "Delivered",
    value: "delivered",
  },
  {
    label: "Not Delivered",
    value: "not_delivered",
  },
  {
    label: "Out For Delivery",
    value: "enroute",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

export default {
  orderStatus,
  // loginBackGroundLogo,
  logo,
  itemsPerPage,
  profileImage,
};

// export globalObject;
