import { profile } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions.js";

export default (axios) => ({
  changePassword(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${profile.changePassword}`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUserProfile() {
    return new Promise((resolve, reject) => {
      axios
        .get(profile.base)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateProfile(data) {
    return new Promise((resolve, reject) => {
      axios
        .put(profile.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateProfilePost(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(profile.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addProfile(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(profile.base, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
