export default {
  solid: {
    primary: "#9B182F",
    black: "#3f4040",
    blue: "#13b6cf",
    orange: "#f5841f",
    green: "#147d3e",
    pgreen: "#42b549",
    maroon: "#921b1e",
    red: "#ec2024",
    edit: "#79C945",
    delete: "#FC4242",
    grey: "#58595B",
    white: "#ffffff",
    unassigned: "#607D8B",
    assigned: "#2196F3",
    pickedup: "#FF8F00",
    partially_delivered: "#9C27B0",
    failed: "#B71C1C",
    cancelled : "#880E4F",
    successful: "#2E7D32",
  },
  light: {
    light_primary: "#FFECEF",
    light_black: "#939598",
    light_blue: "#41dde5",
    light_orange: "#fab079",
    light_green: "#28b260",
    light_pgreen: "#6cdd6c",
    light_maroon: "#c6283b",
    light_red: "#f97d9d",
    light_subBlue: "#17B6CF",
    light_grey: "#E6E7E8",
    light_unassigned: "#90A4AE",
    light_assigned: "#90CAF9",
    light_pickedup: "#FFB300",
    light_partially_delivered: "#CE93D8",
    light_failed: "#E53935",
    light_cancelled: "#F48FB1",
    light_successful: "#66BB6A",
  }
};
