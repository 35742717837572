var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialogForm',{attrs:{"title":"reassign driver and vehicle","btnEnable":true},on:{"reset":_vm.resetAssetChangeForm},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-form',{ref:"assetChangeForm",staticClass:"pt-4",model:{value:(_vm.isAssetChangeForm),callback:function ($$v) {_vm.isAssetChangeForm=$$v},expression:"isAssetChangeForm"}},[_c('v-row',[(_vm.formErrors && 'non_field_errors' in _vm.formErrors)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"white--text",attrs:{"color":"red lighten-2"}},[_vm._v(_vm._s(_vm.formErrors.non_field_errors))])],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Current Driver")]),(_vm.currentTrip && _vm.currentTrip.driver_name)?_c('p',[_vm._v(" "+_vm._s(_vm.currentTrip.driver_name)+" ")]):_vm._e()]),_c('v-col',{attrs:{"cols":"6"}},[_c('h3',[_vm._v("Current Vehicle")]),(_vm.currentTrip && _vm.currentTrip.vehicle_info)?_c('p',[_vm._v(" "+_vm._s(_vm.currentTrip.vehicle_info.vehicle_plate_no)+" ")]):_vm._e()]),_c('v-col',{attrs:{"cols":"6"}},[_c('SelectField',{attrs:{"itemsList":_vm.driversList,"item-text":"first_name","item-value":"id","name":"driver","hide-details":"auto","label":"Select Driver","required":"","return-object":"","isClearable":true,"menu-props":{ offsetY: true },"error-messages":_vm.formErrors.driver,"rules":[(v) => !!v || `Driver is required`]},on:{"change":function($event){_vm.vehicleAssign();
              _vm.formErrors && _vm.formErrors.driver ? delete _vm.formErrors.driver : '';
              _vm.formErrors && _vm.formErrors.vehicle
                ? delete _vm.formErrors.vehicle
                : '';}},model:{value:(_vm.selectedDriverDetails),callback:function ($$v) {_vm.selectedDriverDetails=$$v},expression:"selectedDriverDetails"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('SelectField',{attrs:{"itemsList":_vm.VehicleList,"item-text":"vehicle_plate_no","item-value":"id","outlined":"","dense":"","isClearable":true,"name":"vehicle","hide-details":"auto","label":"Select Vehicle","required":"","menu-props":{ offsetY: true },"error-messages":_vm.formErrors.vehicle,"rules":[(v) => !!v || `Vehicle is required`]},on:{"change":function($event){_vm.formErrors && _vm.formErrors.vehicle
                ? delete _vm.formErrors.vehicle
                : ''}},model:{value:(_vm.assetChangeObject.vehicle),callback:function ($$v) {_vm.$set(_vm.assetChangeObject, "vehicle", $$v)},expression:"assetChangeObject.vehicle"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isAssetChangeForm},on:{"click":_vm.submitAssetChangeForm}},[_vm._v(" Submit ")])],1)],1)]},proxy:true}]),model:{value:(_vm.openAssetChangeDialog),callback:function ($$v) {_vm.openAssetChangeDialog=$$v},expression:"openAssetChangeDialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }