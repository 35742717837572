<template>
  <BaseDialogForm
    v-model="viewProjectZones"
    :title="`${projectOnMap.project_name} Zone's Visualization`"
    :fullscreen="true"
    :isStickTitleRequired="true"
  >
    <template #content>
      <v-col class="d-flex justify-end pa-0 mb-2">
        <v-btn class="primary" @click="viewCustomers" small :loading="loading"
          >Show Customer's Location on Map</v-btn
        >
      </v-col>
      <!-- <v-row no-gutters class="pa-0 ma-0"> -->
      <v-col cols="12" class="pa-0 ma-0">
        <Gmap
          mapWidth="100%"
          mapHeight="85vh"
          ref="viewZoneOnMap"
          :dialog-status="viewProjectZones"
          infowindowType="customer"
          :clusterMarkers="true"
        />
      </v-col>
      <!-- </v-row> -->
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
import Gmap from "@/components/common/Gmap.vue";
export default {
  components: {
    BaseDialogForm,
    Gmap,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      projectOnMap: {},
      customerList: [],
      loading: false,
    };
  },
  computed: {
    viewProjectZones: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    loadServiceableArea(coordinates) {
      let interval = setInterval(() => {
        if (this.$refs.viewZoneOnMap && this.$refs.viewZoneOnMap.map) {
          clearInterval(interval);
          this.$refs.viewZoneOnMap.initMap();
          this.$refs.viewZoneOnMap.loadDisabledMultiPolygon(coordinates, false);
        }
      }, 100);
    },
    viewZones(zone) {
      this.projectOnMap = {};
      this.projectOnMap = zone;
      let params = {
        project_id: zone.id,
      };
      bus.$emit("showLoader", true);
      this.$api.projects.getProjectZone(params).then((result) => {
        if (
          result &&
          result.data &&
          result.data.zones &&
          result.data.zones.features &&
          result.data.zones.features.length > 0
        ) {
          let interval = setInterval(() => {
            this.viewProjectZones = true;
            if (
              result &&
              result.data &&
              result.data.serviceable_area &&
              result.data.serviceable_area.coordinates &&
              result.data.serviceable_area.coordinates.length > 0
            ) {
              this.loadServiceableArea(
                result.data.serviceable_area.coordinates
              );
              bus.$emit("showLoader", false);
            }
            if (this.$refs.viewZoneOnMap && this.$refs.viewZoneOnMap.map) {
              clearInterval(interval);
              this.$refs.viewZoneOnMap.clearGeoJson();
              this.$refs.viewZoneOnMap.clearMarker();

              let customers = JSON.parse(
                JSON.stringify(result.data.customer_details)
              );
              const newCustomersArray = customers.map((obj) => {
                if (obj.coordinates != null) {
                  obj.coordinates = [obj.coordinates[0], obj.coordinates[1]];
                }
                return obj;
              });
              // this.customerList = result.data.customer_details.map((obj) => {
              //   if (obj.coordinates != null) {
              //     obj = {
              //       coordinates: [obj.coordinates[0], obj.coordinates[1]],
              //       details: {
              //         address: obj.address,
              //         customer_name: obj.customer_name,
              //         customer_code: obj.customer_code,
              //       },
              //       type: "customer",
              //     };
              //   }

              //   return obj;
              // });
              this.customerList = newCustomersArray;

              if (result.data.zones) {
                let FeatureArray = JSON.parse(
                  JSON.stringify(result.data.zones)
                );
                let newZone = FeatureArray.features.map((item) => {
                  item.properties.color = "#3498eb";

                  return item;
                });
                this.$refs.viewZoneOnMap.clearGeoJson();
                this.$refs.viewZoneOnMap.loadArea({
                  type: "FeatureCollection",
                  features: newZone,
                });
              }
            }
          }, 500);
        } else {
          let interval = setInterval(() => {
            this.viewProjectZones = true;
            if (
              this.$refs.viewZoneOnMap &&
              result &&
              result.data &&
              result.data.serviceable_area &&
              result.data.serviceable_area.coordinates.length > 0
            ) {
              clearInterval(interval);
              this.loadServiceableArea(
                result.data.serviceable_area.coordinates
              );
              bus.$emit("showLoader", false);
            }
          }, 500);
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "No Zones for the project at the moment.",
            color: "error",
          });
        }
      });
    },
    viewCustomers() {
      this.loading = true;
      if (this.customerList && this.customerList.length > 0) {
        this.$refs.viewZoneOnMap.loadRoutes(null, this.customerList, null);
        // this.$refs.viewZoneOnMap.addMultipleMarkers(this.customerList);
      } else {
        bus.$emit("showToastMessage", {
          message: "No Customers at the moment",
          color: "error",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>