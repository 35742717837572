import PlanningTripOldIndex from "../pages/admin/trip_planning_old/index.vue";
import PlanningTripView from "../pages/admin/trip_planning_old/view/_id/index.vue";

const PlanningTripOld = [
  {
    path: "trip_planning_old",
    name: "plan_trips_old",
    component: PlanningTripOldIndex,
  },
  {
    path: "trip_planning_old/view/:id",
    name: "view_plan_trip_old",
    component: PlanningTripView,
  },
];

export default PlanningTripOld;
