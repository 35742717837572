// import ReportsIndex from "../pages/admin/reports/index.vue";
import DriverReportIndex from "../pages/admin/reports/driver_report/index.vue";
import FleetUtilizationReportIndex from "../pages/admin/reports/fleet_utilization_report/index.vue";
import FMSReportIndex from "../pages/admin/reports/fms_report/index.vue";
import OrderReportIndex from "../pages/admin/reports/order_report/index.vue";
import TripReportIndex from "../pages/admin/reports/trip_report/index.vue";
import TripVolumeUtilizationIndex from "../pages/admin/reports/trip_volume_utilization/index.vue";
import VehicleReportIndex from "../pages/admin/reports/vehicle_report/index.vue";

const Reports = [
  {
    name: "driver_report",
    path: "driver_report",
    component: DriverReportIndex,
  },
  {
    name: "fleet_utilization_report",
    path: "fleet_utilization_report",
    component: FleetUtilizationReportIndex,
  },
  {
    name: "fms_report",
    path: "fms_report",
    component: FMSReportIndex,
  },
  {
    name: "order_report",
    path: "order_report",
    component: OrderReportIndex,
  },
  {
    name: "trip_report",
    path: "trip_report",
    component: TripReportIndex,
  },
  {
    name: "trip_volume_utilization",
    path: "trip_volume_utilization",
    component: TripVolumeUtilizationIndex,
  },
  {
    name: "vehicle_report",
    path: "vehicle_report",
    component: VehicleReportIndex,
  },
];

export default Reports;
