import { tags } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getTagList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(tags.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTagObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tags.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createTagObject(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${tags.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateTagObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${tags.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateTagObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${tags.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteTagObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${tags.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTagSelectList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(tags.select, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getAssignedAssetsList(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tags.base}${id}/assigned_assets/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadTagsSheet(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${tags.base}download_sheet/`, {
          responseType: "blob",
          params: params,
          headers: {
            Accept: "application/xlsx",
          },
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
