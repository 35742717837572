import CustomerAddressesIndex from "../pages/admin/customer_addresses/index.vue";

const CustomerAddresses = [
  {
    path: "customer_addresses",
    name: "customer_addresses",
    component: CustomerAddressesIndex,
  },
];

export default CustomerAddresses;
