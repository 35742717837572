import { role } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions.js";

export default (axios) => ({
  getRoleList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(role.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getRoleObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${role.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  addRole(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${role.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  updateRole(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${role.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  deleteRoleObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${role.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
