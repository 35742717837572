<template>
  <BaseDialogForm
    v-model="customerFormDialog"
    :title="`${formType} Customer Address`"
    maxWidth="60vw"
    @reset="closeDialog()"
  >
    <template #content>
      <v-alert v-if="nonFieldError.length" dense type="error">
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ i + 1 }} .</span><span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-form
        v-model="isFormVaid"
        class=""
        ref="customerAddressForm"
        id="customerAddressForm"
      >
        <v-row>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Customer Code*"
              :rules="[
                (v) =>
                  (!!v && v.trim().length > 0) || 'Customer Code is required',
              ]"
              :error-messages="error.customer_code"
              @input="
                error && error.customer_code ? delete error.customer_code : ''
              "
              v-model="addressDetails.customer_code"
            ></InputField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Customer Name*"
              :rules="[
                (v) =>
                  (!!v && v.trim().length > 0) || 'Customer Name is required',
              ]"
              :error-messages="error.customer_name"
              @input="
                error && error.customer_name ? delete error.customer_name : ''
              "
              v-model="addressDetails.customer_name"
            ></InputField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <SelectField
              :isSearchRequired="false"
              outlined
              hide-details="auto"
              class=""
              label="Customer Type"
              required
              :rules="[(v) => !!v || 'Customer Type is required']"
              :itemsList="customerType"
              :error-messages="error.customer_type"
              @change="
                error && error.customer_type ? delete error.customer_type : ''
              "
              v-model="addressDetails.customer_type"
              :menu-props="{ offsetY: true }"
              :isClearable="false"
            ></SelectField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact Person"
              :error-messages="error.contact_person"
              @input="
                error && error.contact_person ? delete error.contact_person : ''
              "
              v-model="addressDetails.contact_person"
            ></InputField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact Number"
              min="0"
              step="1"
              :error-messages="error.contact_number"
              :rules="[(v) => !!v || 'Contact Number is required']"
              @input="
                error && error.contact_number ? delete error.contact_number : ''
              "
              v-model="addressDetails.contact_number"
              :required="addressDetails.customer_type == 'B2C' ? true : false"
            ></InputField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact email"
              :required="false"
              :error-messages="error.contact_email"
              @input="
                error && error.contact_email ? delete error.contact_email : ''
              "
              v-model="addressDetails.contact_email"
            ></InputField>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="6">
            <SelectField
              outlined
              hide-details="auto"
              class=""
              label="Project*"
              :items-list="projects"
              :rules="[(v) => !!v || 'Project is required']"
              :error-messages="error.project"
              @change="error && error.project ? delete error.project : ''"
              v-model="addressDetails.project"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="6">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Processing Time(Minutes)*"
              min="0"
              type="number"
              step="01"
              :rules="[(v) => !!v || 'Processing Time is required']"
              :error-messages="error.processing_time"
              @input="
                error && error.processing_time
                  ? delete error.processing_time
                  : ''
              "
              v-model="addressDetails.processing_time"
            ></InputField>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  ref="customerAddressFormAddress"
                  id="customerAddressFormAddress"
                  hide-details="auto"
                  class="background-white"
                  placeholder="Enter Address*"
                  :rules="[
                    (v) =>
                      (!!v && v.trim().length > 0) || 'Address is required',
                  ]"
                  :error-messages="error.address"
                  @input="
                    error && error.address
                      ? delete error.address
                      : '' || (error && error.coordinates)
                      ? delete error.coordinates
                      : ''
                  "
                  v-model="addressDetails.address"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Latitude"
                  type="number"
                  step="0.000001"
                  :error-messages="error.coordinates"
                  @input="
                    error && error.coordinates ? delete error.coordinates : ''
                  "
                  v-model="addressDetails.coordinates.latitude"
                ></InputField>
              </v-col>
              <v-col cols="6">
                <InputField
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Longitude"
                  type="number"
                  step="0.0000001"
                  :error-messages="error.coordinates"
                  @input="
                    error && error.coordinates ? delete error.coordinates : ''
                  "
                  v-model="addressDetails.coordinates.longitude"
                ></InputField>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <TextAreaField
              outlined
              hide-details="auto"
              class="background-white"
              label="Remark"
              :error-messages="error.remarks"
              @input="error && error.remarks ? delete error.remarks : ''"
              v-model="addressDetails.remarks"
            ></TextAreaField>
          </v-col>
          <v-col cols="6">
            <SwitchField
              label="WhatsApp Notifications"
              v-model="addressDetails.whatsapp_notification"
              @change="
                error && error.whatsapp_notification
                  ? delete error.whatsapp_notification
                  : ''
              "
            ></SwitchField>
          </v-col>
          <v-col cols="6">
            <SwitchField
              label="E-mail Notifications"
              v-model="addressDetails.email_notification"
              :error-messages="error.email_notification"
              @change="
                error && error.email_notification
                  ? delete error.email_notification
                  : ''
              "
            ></SwitchField>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-5">
          <v-col cols="12">
            <span class="text-h6">Tags</span>
          </v-col>
        </v-row>
        <v-row>
          <!-- NOte : this functionality used in future -->
          <!-- <v-col cols="5">
              <SelectField
                :items="drivertagslist"
                v-model="drivertags"
                item-text="title"
                item-value="value"
                chips
                label="Driver Tags"
                multiple
                outlined
                hide-details="auto"
                class="background-white"
                :menu-props="{ offsetY: true }"
                @change="syncData($event, 'tags')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index == 0">
                    <span>{{ item.title }}</span>
                  </v-chip>
                  <span v-if="index == 0" class="grey--text text-caption">
                    ({{ drivertags.length }} selected)
                  </span>
                </template>
              </SelectField>
            </v-col> -->
          <v-col cols="5">
            <SelectField
              :items-list="tagsList"
              v-model="vehicletags"
              item-text="title"
              item-value="value"
              label="Select Tag"
              :returnObject="false"
              class=""
              :multiple="true"
            />
          </v-col>
        </v-row>

        <v-row no-gutters class="pt-5">
          <v-col cols="6">
            <span class="text-h6">Time slots</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <!-- <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="addressDetails.from_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }"> -->
            <InputField
              label="From Time*"
              outlined
              type="time"
              class="background-white"
              hide-details="auto"
              @click:prepend="menu1 = true"
              :rules="[(v) => !!v || 'From time is required']"
              :error-messages="error.from_time"
              @input="error && error.from_time ? delete error.from_time : ''"
              v-model="addressDetails.from_time"
            >
              <!-- v-bind="attrs"
              v-on="on" -->
            </InputField>
            <!-- </template> -->
            <!-- @click:prepend="menu1 = true" -->

            <!-- <v-time-picker
                format="24hr"
                v-if="menu1"
                v-model="addressDetails.from_time"
                full-width
                @click:minute="$refs.menu1.save(addressDetails.from_time)"
              ></v-time-picker>
            </v-menu> -->
          </v-col>
          <v-col cols="5">
            <!-- <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="addressDetails.to_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            > -->
            <!-- <template v-slot:activator="{ on, attrs }"> -->
            <InputField
              label="To Time*"
              type="time"
              outlined
              class="background-white"
              hide-details="auto"
              :rules="[
                (v) => !!v || 'To time is required',
                (v) =>
                  (v &&
                    v.slice(0, 5) !== addressDetails.from_time.slice(0, 5)) ||
                  'From time and To time not be same',
              ]"
              :error-messages="error.to_time"
              @input="error && error.to_time ? delete error.to_time : ''"
              v-model="addressDetails.to_time"
            ></InputField>
            <!-- v-bind="attrs"
              v-on="on" -->
            <!-- </template> -->
            <!-- <v-time-picker
                :min="addressDetails.from_time"
                format="24hr"
                v-if="menu2"
                v-model="addressDetails.to_time"
                full-width
                @click:minute="$refs.menu2.save(addressDetails.to_time)"
              ></v-time-picker> -->
            <!-- </v-menu> -->
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        class="primary text-uppercase mr-3"
        :disabled="!isFormVaid"
        @click="submit()"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        v-show="formType == 'Add'"
        class="primary text-uppercase"
        @click="clear()"
      >
        <span>Reset</span>
      </v-btn></template
    >
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      addressDetails: {
        coordinates: {
          latitude: null,
          longitude: null,
        },
        email_notification: false,
        whatsapp_notification: false,
      },
      timeSlots: [{}],
      tagsList: [],
      timeDiff: 1,
      menu1: false,
      menu2: false,
      fromTime: [],
      toTime: [],
      radioGroup: "active",
      rcExpiryDate: null,
      drivertags: null,
      vehicletags: [],
      rcExpiryDatePicker: false,
      insuranceExpiryDatePicker: false,
      insuranceExpiryDate: null,
      isFormVaid: false,
      error: {
        vehicletags: [],
      },
      nonFieldError: [],
      projects: [],
      customerType: [
        { text: "Business", value: "B2B" },
        { text: "Individual", value: "B2C" },
      ],
    };
  },
  watch: {
    customerFormDialog(val) {
      if (val) {
        this.getAllProjects();
        this.getTagsList();
      } else {
        this.clear();
      }
    },
  },
  computed: {
    customerFormDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.projects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTagsList() {
      this.$api.tags
        .getTagList({ limit: "all", tag_type: "vehicle_tag" })
        .then((res) => {
          this.tagsList = res.data.map((obj) => {
            return { title: obj.tag, value: obj.id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closeDialog() {
      this.clear();
      this.customerFormDialog = false;
    },
    clear() {
      // this.$refs.customerAddressForm.reset();
      this.addressDetails = {
        coordinates: {
          latitude: null,
          longitude: null,
        },
        email_notification: false,
        whatsapp_notification: false,
      };
      this.vehicletags = [];
      this.$refs.customerAddressForm.resetValidation();
      this.error = {};
      this.nonFieldError = [];
    },
    submit() {
      this.addressDetails.tags = this.vehicletags;

      let postData = this.addressDetails;

      bus.$emit("showLoader", true);
      if (this.formType == "Add") {
        this.$api.customerAddresses
          .createCustomerAddressObject({
            isBulkupload: false,
            ...this.addressDetails,
          })
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Address Successfully added!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }

            if (err.data.tags) {
              if (err.data.tags) {
                this.error.vehicletags = err.data["tags"];
                delete err.data["tags"];
              }
            }
            this.error = err.data;
            bus.$emit("showLoader", false);
          });
      } else {
        let payload = {
          id: this.addressDetails.id,
          data: postData,
        };
        this.$api.customerAddresses
          .updateCustomerAddressObject(payload)
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Address Successfully updated!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      }
    },
    getCustomerAddressDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.customerAddresses
        .getCustomerAddressObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.addressDetails = res.data;
          this.timeSlots = res.data.time_slots;
          this.vehicletags = res.data.tags
            ? res.data.tags.map((item) => item.id)
            : [];
          this.email_notification = res.data.email_notification;
          this.whatsapp_notification = res.data.whatsapp_notification;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit({ message: err.data.message, color: "error" });
        });
    },
  },
  mounted() {
    const interval = setInterval(async () => {
      if (
        this.$refs.customerAddressFormAddress &&
        window.google &&
        window.google.maps
      ) {
        clearInterval(interval);

        let input = this.$refs.customerAddressFormAddress.$refs.input;

        this.autocomplete = new window.google.maps.places.Autocomplete(input);
        this.autocomplete.setComponentRestrictions({
          country: ["AE"],
        });
        this.autocomplete.addListener("place_changed", () => {
          let place = this.autocomplete.getPlace();
          let lat = place.geometry.location.lat();
          let lon = place.geometry.location.lng();

          setTimeout(() => {
            this.addressDetails.address =
              place.name + ", " + place.formatted_address;
            this.addressDetails.coordinates.latitude = lat;
            this.addressDetails.coordinates.longitude = lon;
          }, 100);
        });
      }
    }, 100);
  },
};
</script>

<style>
</style>