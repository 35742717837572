<template>
  <ListLayout
    title="Users"
    :tableData="userList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getUsersList"
    :tableScrollable="false"
    localStorageKey="user_columns"
    :isColumnShowVisibility="true"
  >
    <template #listAction>
      <!-- <TextField name="test" id="test" /> -->
      <v-menu
        offset-y
        v-if="permissions && permissions.dashuser && permissions.dashuser.add"
      >
        <template v-slot:activator="{ on: tooltip }">
          <div v-on="{ ...tooltip }">
            <v-btn
              small
              depressed
              class="primary"
              @click="addUser()"
              v-if="permissions.dashuser.add == true"
            >
              <v-icon small class="mr-1">mdi-plus</v-icon>
              <span>Add User</span>
            </v-btn>
          </div>
        </template>
      </v-menu>
    </template>
    <template #dialogs>
      <UserForm
        ref="userForm"
        v-model="openUserForm"
        :formType="formType"
        @refreshList="getUsersList({ limit: 10, offset: 0 })"
      />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import UserButton from "@/components/common/aggrid/buttons/UserButton.vue";
import UserForm from "@/components/user/UserForm.vue";
import { bus } from "@/main.js";

export default {
  name: "usersIndex",
  components: {
    ListLayout,
    UserButton,
    UserForm,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      userList: [],
      openUserForm: false,
      formType: "Add",
      headerSelected: [
        {
          headerName: "First Name",
          field: "first_name",
        },
        {
          headerName: "Last Name",
          field: "last_name",
        },
        {
          headerName: "User Name",
          field: "username",
        },
        {
          headerName: "Contact Number",
          field: "contact_number",
        },
        {
          headerName: "Email",
          field: "email",
        },
        {
          headerName: "Role Name",
          field: "role_name",
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 150,
          sortable: false,
          width: 250,
          cellRenderer: "UserButton",
        },
      ];
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getUsersList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.user
        .getUserList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.userList = res.data.results;
          this.totalItems = res.data.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },

    addUser() {
      this.formType = "Add";
      this.openUserForm = true;
    },

    editUser(id) {
      this.formType = "Edit";
      this.openUserForm = true;
      this.$refs.userForm.getUserDetails(id);
    },

    deleteUser(id) {
      bus.$emit("showLoader", true);
      this.$api.user
        .deleteUserObject(id)
        .then(() => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "User deleted successfully!",
            color: "success",
          });
          this.getUsersList();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
