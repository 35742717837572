<template>
  <ListLayout
    ref="listLayout"
    title="Zones"
    :tableData="zoneList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getZonesList"
    :tableScrollable="false"
    localStorageKey="zones_columns"
    :isColumnShowVisibility="false"
    :searchInput="true"
    :hasFilter="true"
    :filtersOnRight="true"
  >
    <template #listAction>
      <v-btn
        small
        depressed
        class="primary"
        v-if="permissions && permissions.zone && permissions.zone.add"
        @click="addZone()"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Zone</span>
      </v-btn>
    </template>
    <template #leftFilterSlot>
      <v-col cols="12" class="d-flex justify-start pa-0">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="success"
              overlap
              small
              dot
              :value="appliedFilters && appliedFilters.length > 0"
            >
              <v-btn
                small
                fab
                depressed
                color="primary"
                class="rounded"
                @click="openZoneFilterDialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="">mdi-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Zone Filters</span>
        </v-tooltip>
      </v-col>
    </template>

    <template #dialogs>
      <ZoneFilter
        ref="zoneFilterDialog"
        v-model="openZoneFilterDialog"
        @zoneFilterChanged="$refs.listLayout.searchTriggered()"
      />

      <ZoneForm
        ref="zoneForm"
        v-model="openZoneForm"
        :formType="formType"
        @refreshList="$refs.listLayout.searchTriggered()"
      />

      <LogDialog :logsArray="zoneLogs" title="Logs" v-model="openZoneLogs" />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import ZoneButton from "@/components/common/aggrid/buttons/ZoneButton.vue";
import ZoneForm from "@/components/zones/ZoneForm.vue";
import { bus } from "@/main.js";
import LogDialog from "@/components/BaseComponents/LogDialog.vue";
import ZoneFilter from "@/components/zones/ZoneFilter.vue";

export default {
  name: "zonesIndex",
  components: {
    ListLayout,
    ZoneButton,
    ZoneForm,
    LogDialog,
    ZoneFilter,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      customFilter: {},
      zoneList: [],
      zoneLogs: [],
      openZoneForm: false,
      openZoneLogs: false,
      openZoneFilterDialog: false,
      formType: "Add",
      headerSelected: [
        {
          headerName: "Zone Name",
          field: "zone_name",
        },
        {
          headerName: "Zone Description",
          field: "zone_desc",
        },
        {
          headerName: "Project",
          field: "project_id",
        },
      ],
      filter: {},
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        {
          headerName: "Actions",
          width: 220,
          maxWidth: 220,
          minWidth: 220,
          field: "actions",
          pinned: "right",
          cellRendererFramework: "ZoneButton",
        },
      ];
    },
    context() {
      return { parentComponent: this };
    },
    appliedFilters() {
      return Object.keys(this.customFilter).map((filter) => {
        return filter.replace(/\_/, " ");
      });
    },
  },
  methods: {
    searchZone() {
      this.pageNo = 1;
      this.getZonesList();
    },
    getZonesList(params = {}) {
      let filters = localStorage.getItem("zoneFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.customFilter = filters;

      params = {
        ...params,
        ...this.customFilter,
        ...this.filter,
      };

      if (typeof params == typeof "string") {
        params = JSON.parse(params);
      }
      if (params && params.project && Array.isArray(params.project)) {
        params.project = params.project.join(",");
      }
      bus.$emit("showLoader", true);
      this.$api.zone
        .getZonesList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.zoneList = res?.data?.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          console.log(err);
          bus.$emit("showLoader", false);
        });
    },

    addZone() {
      this.formType = "Add";
      this.openZoneForm = true;
    },
    editZone(id) {
      this.formType = "Edit";
      this.openZoneForm = true;
      setTimeout(() => {
        if (this.$refs && this.$refs.zoneForm) {
          this.$refs.zoneForm.getZoneDetails(id);
        }
      }, 100);
    },

    deleteZone(id) {
      bus.$emit("showLoader", true);
      this.$api.zone
        .deleteZoneObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Zone deleted successfully!",
            color: "error",
          });
          this.getZonesList();
        })
        .catch((err) => {
          console.log(err);
          bus.$emit("showLoader", false);
        });
    },

    viewLogsHandler(params = {}) {
      params = {
        ...params,
        module_type: "zone",
      };
      bus.$emit("showLoader", true);
      this.$api.zone
        .getProjectZoneLogs(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.zoneLogs = res.data.results;
          this.openZoneLogs = true;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Couln't fetch data",
            color: "error",
          });
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },
  },

  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
