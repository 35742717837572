import { customerAddress, dropdownCustomers } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCustomerAddressList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(customerAddress.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerDropDownList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(dropdownCustomers.base, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerAddressObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerAddress.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createCustomerAddressObject(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${customerAddress.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateCustomerAddressObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${customerAddress.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateCustomerAddressObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${customerAddress.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadCustomerAddressSheet(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerAddress.base}download/`, {
          responseType: "blob",
          headers: {
            // Accept: "application/xlsx",
          },
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadCustomers(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${customerAddress.base}bulk_upload/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateCustomers(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${customerAddress.base}bulk_update/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
