<template>
  <div>
    <v-icon v-if="params.data && params.data && params.data.tag_validations" class="mr-4 green--text">mdi-check-circle</v-icon>
    <v-icon v-else class="mr-4 red--text">mdi-close-circle</v-icon>
  </div>
</template>
<script>
export default {
  
}
</script>

