<template>
  <BaseDialogForm
    v-model="openUserDialog"
    :title="`${formType} User`"
    maxWidth="60vw"
    @reset="closeDialog()"
  >
    <template #content>
      <v-form
        v-if="openUserDialog"
        v-model="isValid"
        ref="userForm"
        id="userForm"
      >
        <v-alert v-if="nonFieldError.length" dense type="error">
          <v-list
            class="pa-0"
            dense
            style="background: inherit !important"
            v-for="(error, i) in nonFieldError"
            :key="i"
          >
            <v-list-item dense style="min-height: 20px !important">
              <span>{{ i }} .</span><span>{{ error }}</span>
            </v-list-item>
          </v-list>
        </v-alert>
        <v-row class="pa-2">
          <v-col cols="12" lg="8">
            <v-row>
              <v-col cols="6" lg="4">
                <InputField
                  :rules="[(v) => !!v || 'First name is required']"
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="First Name*"
                  v-model="userDetails.first_name"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  :rules="[(v) => !!v || 'Last name is required']"
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Last Name*"
                  v-model="userDetails.last_name"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  :rules="[(v) => !!v || 'User name is required']"
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="User Name*"
                  :error-messages="error.username"
                  @input="delete error.username"
                  v-model="userDetails.username"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  :rules="[rules.required, rules.email]"
                  outlined
                  hide-details="auto"
                  class="background-white"
                  label="Email*"
                  v-model="userDetails.email"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  :rules="[(v) => !!v || 'Contact Number is required']"
                  outlined
                  type="number"
                  hide-details="auto"
                  class="background-white"
                  label="Contact Number*"
                  v-model="userDetails.contact_number"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <SelectField
                  :rules="[
                    (v) => (v != null && v.length > 0) || 'Project is required',
                  ]"
                  outlined
                  multiple
                  :items-list="allProjects"
                  hide-details="auto"
                  item-text="text"
                  item-value="value"
                  label="Project*"
                  v-model="userDetails.projects_assigned"
                  :menu-props="{ offsetY: true }"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.text }}</span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ userDetails.projects_assigned.length - 1 }} others)
                    </span>
                    <!-- <span
                      v-if="index === 1 && formType == 'edit'"
                      class="grey--text text-caption"
                    >
                      (+{{ userDetails.projects.length - 1 }} others)
                    </span> -->
                  </template>
                </SelectField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  :rules="
                    formType == 'add'
                      ? [(v) => !!v || 'Password is required']
                      : []
                  "
                  outlined
                  type="password"
                  hide-details="auto"
                  class="background-white"
                  label="Password*"
                  :error-messages="error.password"
                  @input="delete error.password"
                  v-model="userDetails.password"
                ></InputField>
              </v-col>

              <v-col cols="6" lg="4">
                <InputField
                  :rules="
                    formType == 'add'
                      ? [
                          (v) => !!v || 'Confirm Password is required',
                          (v) =>
                            !v ||
                            v == userDetails.password ||
                            'Password does not match',
                        ]
                      : [
                          (v) =>
                            !v ||
                            v == userDetails.password ||
                            'Password does not match',
                        ]
                  "
                  outlined
                  type="password"
                  hide-details="auto"
                  class="background-white"
                  label="Confirm Password*"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <SelectField
                  outlined
                  :rules="[(v) => !!v || 'Role is required']"
                  :items-list="allRoles"
                  label="Select Role*"
                  v-model="userDetails.role"
                  :menu-props="{ offsetY: true }"
                >
                </SelectField>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="4" class="px-6 pt-2 pb-6 d-flex justify-center">
            <v-img
              contain
              :src="userimage"
              max-height="200"
              max-width="200"
              height="200"
              width="200"
              class="rounded-circle"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #actions>
      <v-btn
        type="submit"
        :disabled="!isValid"
        class="primary text-uppercase mr-3"
        @click.prevent="submitUserForm()"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'add' || formType == 'Add'"
        class="primary text-uppercase"
        @click="clear()"
      >
        <span>Reset</span>
      </v-btn>
    </template>
  </BaseDialogForm>
</template>

<script>
import UserImage from "@/assets/default-user.jpg";
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import InputField from "@/components/BaseFormComponents/InputField.vue";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
    InputField,
    SelectField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      userimage: UserImage,
      nonFieldError: [],
      error: {},
      isValid: false,
      userDetails: {},
      allProjects: [],
      allRoles: [],
      rules: {
        required: (value) => !!value || "Email is required",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid Email ID.";
        },
      },
    };
  },
  watch: {
    openUserDialog(val) {
      if (val) {
        this.getAllProjects();
        this.getRoleList();
      } else {
        this.clear();
      }
    },
  },
  computed: {
    openUserDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    closeDialog() {
      this.clear();
      this.openUserDialog = false;
    },
    syncData(key, value) {
      this.userDetails[key] = value;
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getRoleList() {
      this.$api.role
        .getRoleList({ limit: "all" })
        .then((res) => {
          this.allRoles = res.data.results.map((obj) => {
            return { text: obj.role_name, value: obj.id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getUserDetails(id = null) {
      bus.$emit("showLoader", true);
      this.$api.user
        .getUserObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          let data = { ...res.data };
          data.projects_assigned = data.projects;
          this.userDetails = { ...data };
        })
        .catch((err) => {
          bus.$emit("showLoader", true);
          console.error(err);
        });
    },
    submitUserForm() {
      bus.$emit("showLoader", true);
      if (this.formType == "Add") {
        this.$api.user
          .createUserObject(this.userDetails)
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "User added successfully!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            if (err.non_field_errors) {
              this.nonFieldError = err.non_field_errors;
            }
            this.formErrors = err.data;
          });
      } else {
        if (this.userDetails.password === "") {
          delete this.userDetails.password;
        }
        this.$api.user
          .updateUserObject({ id: this.userDetails.id, data: this.userDetails })
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "User updated successfully!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.error(err);
            bus.$emit("showLoader", false);
            if (err.non_field_errors) {
              this.nonFieldError = err.non_field_errors;
            }
            this.formErrors = err.data;
          });
      }
    },
    clear() {
      this.userDetails = {
        first_name: null,
        all_projects: null,
        last_name: null,
        username: null,
        email: null,
        contact_number: null,
        id: null,
        is_active: null,
        permissions: null,
        profile_image: null,
        role: null,
        role_name: null,
        roles: null,
      };
      this.$refs.userForm.reset();
    },
  },
};
</script>

<style>
</style>