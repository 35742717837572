<template>
  <ListLayout
    title="Trip Planning Templates"
    :tableData="tripPlanningTemplateList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getTripPlanningTemplateList"
    :tableScrollable="false"
    localStorageKey="trip_planning_template_columns"
  >
    <template #listAction>
      <v-btn small depressed class="primary" @click="openForm(false, false)">
        <v-icon small class="mr-1">mdi-plus</v-icon>
        <span>Create Template</span>
      </v-btn>
    </template>
    <template #dialogs>
      <TripPlanningTemplateForm
        ref="tripPlanningForm"
        :editMode="editMode"
        v-model="openTripPlanningTemplateForm"
        :readOnly="readOnly"
        @refreshList="getTripPlanningTemplateList({ limit: 10, offset: 0 })"
      />

      <TripPlanningTemplateDetailsDialog
        ref="templateView"
        v-model="openTripPlanningDetails"
      />

      <LogDialog
        :logsArray="tripPlanningLogs"
        title="Logs"
        v-model="openTripPlanningLogs"
      />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import TripTemplateDetailsView from "@/components/common/aggrid/buttons/tripTemplateDetailsView.vue";
import TripPlanningTemplateForm from "@/components/trip_planning_template/TripPlanningTemplateForm.vue";
import LogDialog from "@/components/BaseComponents/LogDialog.vue";
import { bus } from "@/main.js";
import TripPlanningTemplateDetailsDialog from "@/components/trip_planning_template/TripPlanningTemplateDetailsDialog.vue";
import CheckZoneConstraint from "@/components/common/aggrid/icons/CheckZoneConstraint.vue";
import CheckTagValidations from "@/components/common/aggrid/icons/CheckTagValidations.vue";
import CheckTollRoads from "@/components/common/aggrid/icons/CheckTollRoads.vue";
import CheckTrafficJams from "@/components/common/aggrid/icons/CheckTrafficJams.vue";
import CheckDisableTimeWindows from "@/components/common/aggrid/icons/CheckDisableTimeWindows.vue";
import CheckRoundTripConstraint from "@/components/common/aggrid/icons/CheckRoundTripConstraint.vue";
export default {
  name: "tripPlanningTemplateIndex",
  components: {
    ListLayout,
    TripTemplateDetailsView,
    TripPlanningTemplateForm,
    LogDialog,
    TripPlanningTemplateDetailsDialog,
    CheckZoneConstraint,
    CheckTagValidations,
    CheckTollRoads,
    CheckTrafficJams,
    CheckDisableTimeWindows,
    CheckRoundTripConstraint,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      tripPlanningTemplateList: [],
      tripPlanningLogs: [],
      openTripPlanningLogs: false,
      openTripPlanningTemplateForm: false,
      openTripPlanningDetails: false,
      formType: "Add",
      editMode: false,
      readOnly: false,
      headerSelected: [
        {
          headerName: "Template Name",
          pinned: "left",
          field: "template_name",
        },
        {
          headerName: "Fill Ratio(%)",
          cellRenderer: (param) => {
            return param.data.fill_ratio + "%";
          },
        },
        {
          headerName: "Loading Timings",
          cellRenderer: (param) => {
            return (
              param.data.loading_start_time +
              " To " +
              param.data.loading_end_time
            );
          },
        },
        {
          headerName: "Offloading Timings",
          cellRenderer: (param) => {
            return (
              param.data.offloading_start_time +
              " To " +
              param.data.offloading_end_time
            );
          },
        },
        {
          headerName: "Optimize By",
          field: "configuration",
          cellClass: ["text-capitalize"],
        },
        {
          headerName: "Round Trip",
          field: "round_trip",
          cellRendererFramework: "CheckRoundTripConstraint",
        },
        {
          headerName: "Zone Constraint",
          field: "zone_constraint",
          cellRendererFramework: "CheckZoneConstraint",
        },
        {
          headerName: "Tag Validations",
          field: "tag_validations",
          cellRendererFramework: "CheckTagValidations",
        },
        {
          headerName: "Disable Time Windows",
          field: "disable_time_windows",
          cellRendererFramework: "CheckDisableTimeWindows",
        },
        {
          headerName: "Traffic Jams",
          field: "traffic_jams",
          cellRendererFramework: "CheckTrafficJams",
        },
        {
          headerName: "Toll Roads",
          field: "toll_roads",
          cellRendererFramework: "CheckTollRoads",
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        {
          headerName: "Actions",
          field: "actions",
          width: 220,
          maxWidth: 220,
          minWidth: 220,
          cellRendererFramework: "TripTemplateDetailsView",
          pinned: "right",
        },
      ];
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    getTripPlanningTemplateList(params = {}) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.tripPlanningTemplate
        .getTripPlanningTemplateList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.tripPlanningTemplateList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", true);
          console.error(err);
        });
    },

    openForm() {
      this.editMode = false;
      this.readOnly = false;
      this.openTripPlanningTemplateForm = true;
    },

    editTemplate(id) {
      this.editMode = true;
      this.readOnly = false;
      this.openTripPlanningTemplateForm = true;
      this.$refs.tripPlanningForm.getTripPlanningTemplateDetails(id);
    },

    viewDialog(id) {
      this.editMode = false;
      this.readOnly = true;
      this.openTripPlanningTemplateForm = true;
      this.$refs.tripPlanningForm.getTripPlanningTemplateDetails(id);
    },

    viewLogsHandler(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.tripPlanningTemplate
        .viewTemplateLogs(params)
        .then((res) => {
          this.tripPlanningLogs = res.results;
          this.openTripPlanningLogs = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
