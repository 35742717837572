var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"400px","nudge-left":"345","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-badge',{staticClass:"mr-4",attrs:{"content":_vm.unreadCount,"color":_vm.unreadCount == 0 ? '' : 'green',"top":"","overlap":""}},[_c('span',[_c('v-btn',_vm._g(_vm._b({staticClass:"mr-n5",class:_vm.openNotifications ? 'text-primary' : '',attrs:{"text":""},on:{"click":function($event){return _vm.getUserNotificationList(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{},[_vm._v("mdi-bell")])],1)],1)])]}}]),model:{value:(_vm.openNotifications),callback:function ($$v) {_vm.openNotifications=$$v},expression:"openNotifications"}},[_c('v-card',{attrs:{"width":"400px","elevation":"0"}},[_c('v-card-title',{staticClass:"pt-4 pb-2 text-primary body-1 d-flex justify-space-between"},[_c('span',[_vm._v("Notifications")]),_c('v-btn',{staticClass:"primary--text",attrs:{"disabled":_vm.userNotifications.length <= 0,"small":"","text":"","depressed":""},on:{"click":_vm.markAllAsRead}},[_vm._v("Clear All")])],1),_c('v-card-text',{ref:"notificationParent",staticClass:"pa-0 overflow-y-auto overflow-x-hidden",staticStyle:{"max-height":"350px"},attrs:{"elevation-0":""},on:{"scroll":function($event){return _vm.handleScroll()}}},[(
          _vm.userNotifications &&
          _vm.userNotifications.length == 0 &&
          !_vm.noNewNotifications
        )?_c('div',{staticClass:"d-flex justify-center py-2"},[_c('v-progress-circular',{attrs:{"width":3,"color":"grey","indeterminate":""}})],1):(_vm.noNewNotifications)?_c('div',{staticClass:"d-flex justify-center py-2"},[_c('span',[_vm._v("No new Notifications!")])]):_vm._l((_vm.userNotifications),function(noti,i){return _c('div',{key:i},[(noti && !noti.is_read)?_c('v-row',{staticClass:"pl-4 pa-0 my-1 notiication",style:({ backgroundColor: 'white' })},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('hr',{staticStyle:{"opacity":"0.4"}})]),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"2"}},[_c('div',{class:[
                noti.notification_type == 'success' ? 'successMessage' : '',
                noti.notification_type == 'warning' ? 'warningMessage' : '',
                noti.notification_type == 'info' ? 'infoMessage' : '',
                noti.notification_type == 'error' ? 'errorMessage' : '',
              ],staticStyle:{"display":"flex","justiy-cotent":"center","align-items":"center","padding":"15px"}},[(noti.notification_category == 'driver')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-card-account-details-outline")]):(noti.notification_category == 'order')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-archive-outline")]):(noti.notification_category == 'trip')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-truck-fast")]):(noti.notification_category == 'trip_planning')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-map-marker-distance")]):(noti.notification_category == 'location')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-map-marker")]):(noti.notification_category == 'customer')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account")]):(noti.notification_category == 'vehicle')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-truck")]):(noti.notification_category == 'report')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chart-areaspline")]):(noti.notification_category == 'user')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account-check")]):_vm._e()],1)]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"8"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-body-2",attrs:{"cols":"12"}},[_c('span',[_vm._v(" "+_vm._s(noti.title)+" ")])]),_c('v-col',{staticClass:"text-caption",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(noti.message)+" ")]),_c('v-col',{staticClass:"text-caption font-weight-bold mt-1",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(noti.created)+" ")])],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"primary--text",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.markAsRead(noti.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)],1):_vm._e()],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }