<template>
  <v-dialog
    v-model="openViewUnplannedDialog"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="pa-4">
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="6" class="d-flex align-center justify-start">
            <span
              class="
                text-lg-subtitle-1 text-xl-h6 text-uppercase
                font-weight-black
                primary--text
              "
            >
              Planned Trips
            </span>
          </v-col>
          <v-col cols="6" class="d-flex align-center justify-end">
            <v-btn
              class="float-right"
              @click="openViewUnplannedDialog = false"
              small
              fab
              icon
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <Gmap
            mapWidth="100%"
            mapHeight="92vh"
            ref="viewTrip"
            :dialog-status="openViewUnplannedDialog"
            :clusterMarkers="true"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import Gmap from "@/components/common/Gmap.vue";
export default {
  components: {
    Gmap,
  },
  props: {
    value: Boolean,
  },
  computed: {
    openViewUnplannedDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    openViewUnplanned({ orders, zones = null }) {
      let interval = setInterval(() => {
        if (this.$refs.viewTrip) {
          clearInterval(interval);
          this.$refs.viewTrip.clearGeoJson();
          this.$refs.viewTrip.clearMarker();
          if (zones) {
            let FeatureArray = JSON.parse(JSON.stringify(zones));
            let newZone = FeatureArray.features.map((item) => {
              item.properties.color = "#3498eb";
              return item;
            });
            this.$refs.viewTrip.loadArea({
              type: "FeatureCollection",
              features: newZone,
            });
          }

          let newOrders = JSON.parse(JSON.stringify(orders));
          const newOrdersArray = newOrders.map((order) => {
            order.coordinates = [
              order.coordinates.longitude,
              order.coordinates.latitude,
            ];
            return order;
          });
          this.$refs.viewTrip.loadRoutes(null, newOrdersArray, null);
        }
      }, 100);
    },
  },
};
</script>
