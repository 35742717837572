import { project, systemLogs } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getProjectOldList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(project.oldBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(project.base, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDefaultServiceableArea(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.base}default_serviceable_area/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectZone(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.base}${params.project_id}/get_zone_wise_addresses/`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createProjectObject(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${project.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${project.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateProjectObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${project.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateProjectObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${project.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getProjectLogs(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(systemLogs, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
