import ProjectsIndex from "../pages/admin/projects/index.vue";

const Projects = [
  {
    path: "projects",
    name: "projects",
    component: ProjectsIndex,
  },
];

export default Projects;
