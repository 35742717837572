<template>
  <BaseDialogForm v-model="openTagsDialog" title="Vehicle Tags">
    <template #content>
      <v-form ref="tagFormAdd" id="tagFormAdd" v-model="isValid">
        <v-row class="px-4 pt-7 d-flex align-baseline">
          <v-col cols="6">
            <InputField
              v-model="tag_name"
              dense
              label="Add Tag"
              outlined
              :rules="[(v) => !!v || 'Tag name is required']"
              :error-messages="error && error.tag"
              @input="error.tag = []"
            ></InputField>
          </v-col>
          <v-col cols="2">
            <v-btn
              :disabled="!isValid"
              @click.prevent="submitVehicleTagForm()"
              class="primary text-uppercase mr-3"
            >
              <span>add</span>
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-text-field
              color="primary"
              v-model="search_tags"
              @input="searchTags"
              label="Search Tags.."
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-4">
            <AgGridVue
              @grid-ready="gridReady"
              :grid-options="gridOptions"
              :column-defs="columnDefs"
              :row-data="tagList"
              :context="context"
              :pagination="true"
              :paginationPageSize="10"
              style="width: 100%; height: 450px"
              class="ag-theme-alpine"
            >
            </AgGridVue>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </BaseDialogForm>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable */
import { AgGridVue } from "ag-grid-vue";
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
import VehicleTagsButton from "@/components/common/aggrid/buttons/VehicleTagsButton.vue";
export default {
  components: {
    BaseDialogForm,
    AgGridVue,
    VehicleTagsButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: false,
      gridApi: null,
      columnApi: null,
      tag_name: null,
      search_tags: null,
      error: {},
      tagList: [],
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },

        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
      columnDefs: [
        {
          headerName: "Tag Name",
          field: "tag",
        },
        {
          headerName: "Actions",
          width: 170,
          maxWidth: 170,
          minWidth: 170,
          field: "actions",
          pinned: "right",
          cellRendererFramework: "VehicleTagsButton",
        },
      ],
    };
  },
  watch: {
    openTagsDialog(val) {
      if (val) {
        this.getTagsList({ limit: 10, offset: 0 });
      }
    },
  },
  computed: {
    openTagsDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    gridHeight() {
      return `${window.innerHeight - 296}px`;
    },
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    getTagsList(params = {}) {
      bus.$emit("showLoader", true);
      this.$api.tags
        .getTagList(params)
        .then((res) => {
          bus.$emit("showLoader", false);

          this.tagList = res.data;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.error = err;
        });
    },
    submitVehicleTagForm() {
      let payload = {
        tag: this.tag_name,
        tag_type: "vehicle_tag",
        description: "1",
      };
      bus.$emit("showLoader", true);
      this.$api.tags
        .createTagObject(payload)
        .then(() => {
          bus.$emit("showToastMessage", {
            message: "Tag added successfully!",
            color: "success",
          });
          bus.$emit("showLoader", false);
          this.resetForm();
          this.getTagsList();
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          this.error = err;
        });
    },

    deleteTag(id) {
      this.$api.tags
        .deleteTagObject(id)
        .then(() => {
          bus.$emit("showToastMessage", {
            message: "Tag deleted successfully!",
            color: "success",
          });
          this.getTagsList();
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Something went wrong",
            color: "error",
          });
        });
    },
    resetForm() {
      this.$refs.tagFormAdd.reset();
    },
    closeDialog() {
      this.openTagFormDialog = false;
    },
    searchTags() {
      this.gridOptions.api.setQuickFilter(this.search_tags);
    },
  },
};
</script>

<style>
</style>