function downloadBlobData(data, filename) {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: "application/vnd.ms-excel" })
  );
  var a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function toCapitalize(string) {
  let words = string.split(" ");
  return words
    .map((word) => {
      word = word.split("");
      let char = "";
      if (word.length !== 0) {
        char = word[0].toUpperCase();
      }
      word[0] = char;
      return word.join("");
    })
    .join(" ");
}

function toTitleCase(text) {
  text = text.replace("_", " ").replace("-", " ");
  return toCapitalize(text);
}

export function returnToday() {
  let today = new Date();
  return getDateTime(today);
}

export function getDateTime(_d) {
  let _ = new Date(_d),
    date = _.getDate() < 10 ? "0" + _.getDate() : _.getDate(),
    month = _.getMonth() + 1,
    year = _.getFullYear();

  month = month < 10 ? "0" + month : month;

  return [year, month, date].join("-");
}

export { downloadBlobData, toCapitalize, toTitleCase };
