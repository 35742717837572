import ZonesIndex from "../pages/admin/zones/index.vue";

const Zones = [
  {
    path: "zones",
    name: "zones",
    component: ZonesIndex,
  },
];

export default Zones;
