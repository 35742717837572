<template>
  <BaseDialogForm title="Logs" v-model="projectLogDialog">
    <template #content>
      <v-row class="pt-4" no-gutters>
        <v-col v-if="logs && logs.length > 0">
          <v-timeline dense>
            <v-timeline-item
              small
              v-for="(log, index) in logs"
              :key="index"
              class="ma-0 pa-0"
            >
              <v-card class="elevation-0 pa-lg-3" outlined>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="6" class="pb-2">
                      <span class="font-weight-bold">
                        {{ log.added_by }}
                      </span>
                    </v-col>
                    <v-col cols="6" class="text-right">
                      <span class="caption font-weight-bold">{{
                        log.created
                      }}</span>
                    </v-col>
                    <v-col
                      class="ma-0 pt-0 pb-0 text-caption text-body-2"
                      cols="12"
                    >
                      <span> {{ log.message }} </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
        <v-col
          v-else
          cols="12"
          class="d-flex justify-center align-center pt-8 pb-6"
        >
          <span>No logs found...</span>
        </v-col>
      </v-row>
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
export default {
  components: {
    BaseDialogForm,
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    projectLogDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>