<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 edit white--text"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.vehicle && userPermissions.vehicle.change"
          @click="btnClickedHandler()"
          depressed
        >
          <v-icon small class="ma-0">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 primary"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.vehicle && userPermissions.vehicle.change"
          @click="openChangeBranchDialog()"
          depressed
        >
          <v-icon small class="ma-0">mdi-alpha-p-box</v-icon>
        </v-btn>
      </template>
      <span>Change Project</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="grey white--text"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.vehicle && userPermissions.vehicle.view"
          @click="viewLogs"
          depressed
        >
          <v-icon small class="ma-0">mdi-alpha-l-box</v-icon>
        </v-btn>
      </template>
      <span>Show Logs</span>
    </v-tooltip>
  </div>
</template>

<script>
// import { makeInstance, encryptLocal } from "~/assets/js/encryptLocal";

export default {
  data() {
    return {
      openVehicleForm: false,
      loader: false,
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  computed: {},
  methods: {
    btnClickedHandler() {
      this.params.context.parentComponent.editVehicle(this.params.data.id);
    },
    openChangeBranchDialog() {
      this.params.context.parentComponent.openChangeBranch(this.params.data.id);
    },
    viewLogs() {
      this.params.context.parentComponent.viewLogsHandler({
        vehicle_id: this.params.data.id,
      });
    },
  },
  // beforeCreate() {
  //   if (encryptLocal == null) {
  //     makeInstance(localStorage.getItem("user"));
  //   }
  // },
};
</script>