<template>
  <v-dialog
    scrollable
    v-model="showDialogForm"
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    :persistent="persistent"
  >
    <v-card class="pa-4" :class="customClass" style="position: relative">
      <v-card-title
        :class="
          isStickTitleRequired
            ? 'line-box-shadow text-lg-subtitle-1 text-xl-h6 text-uppercase font-weight-black primary--text pb-4 position-sticky'
            : 'text-lg-subtitle-1 text-xl-h6 text-uppercase font-weight-black primary--text'
        "
      >
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          :color="isBgRequired ? 'white' : 'primary'"
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text :class="isStickTitleRequired ? 'pt-0' : 'pt-3'">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            v-if="nonFieldErrors && nonFieldErrors.length > 0"
            class="pt-0"
          >
            <v-alert dense type="error" class="ma-0">
              <v-list
                class="pa-0"
                dense
                style="background: inherit !important"
                v-for="(error, i) in nonFieldErrors"
                :key="i"
              >
                <v-list-item dense style="min-height: 20px !important">
                  <span>{{ i }} .</span><span>{{ error }}</span>
                </v-list-item>
              </v-list>
            </v-alert>
          </v-col>
          <v-col cols="12">
            <slot name="content" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isBgRequired: {
      type: Boolean,
      default: false,
    },
    isStickTitleRequired: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    nonFieldErrors: {
      type: [Array, null],
      default: null,
    },
    maxWidth: {
      default: "70%",
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    showDialogForm: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    closeDialog() {
      this.showDialogForm = false;
      this.$emit("reset");
    },
  },
};
</script>

<style scoped lang="scss">
.position-sticky {
  position: sticky !important;
  top: 0px;
  background-color: white;
  z-index: 2;
}
.line-box-shadow {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.19);
}
</style>
