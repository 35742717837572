import OperationsIndex from "../pages/admin/operations/index.vue";

const Operations = [
  {
    path: "operations",
    name: "operations",
    component: OperationsIndex,
  },
];

export default Operations;
