import axiosInstance from "./axios";

import auth from "../services/auth";
import bulkUpload from "../services/bulkUpload";
import chat from "../services/chat";
import customerAddresses from "../services/customerAddresses";
import dashboard from "../services/dashboard";
import driverApp from "../services/driverApp";
import drivers from "../services/drivers";
import integration from "../services/integration";
import items from "../services/items";
import operations from "../services/operations";
import orderHistory from "../services/orderHistory";
import orders from "../services/orders";
import profile from "../services/profile";
import projects from "../services/projects";
import role from "../services/role";
import status from "../services/status";
import support from "../services/support";
import tags from "../services/tags";
import tripPlanningTemplate from "../services/tripPlanningTemplate";
import user from "../services/user";
import userNotifications from "../services/userNotifications";
import vehicles from "../services/vehicles";
import zone from "../services/zone";
import trips from "../services/trips";
import tripPlanningOld from "@/services/tripPlanningOld";
import tripPlanningNew from "../services/tripPlanningNew";
import report from "../services/report";

export default {
  auth: auth(axiosInstance),
  bulkUpload: bulkUpload(axiosInstance),
  chat: chat(axiosInstance),
  customerAddresses: customerAddresses(axiosInstance),
  dashboard: dashboard(axiosInstance),
  driverApp: driverApp(axiosInstance),
  drivers: drivers(axiosInstance),
  integration: integration(axiosInstance),
  items: items(axiosInstance),
  operations: operations(axiosInstance),
  orderHistory: orderHistory(axiosInstance),
  orders: orders(axiosInstance),
  profile: profile(axiosInstance),
  projects: projects(axiosInstance),
  role: role(axiosInstance),
  status: status(axiosInstance),
  support: support(axiosInstance),
  tags: tags(axiosInstance),
  tripPlanningTemplate: tripPlanningTemplate(axiosInstance),
  user: user(axiosInstance),
  userNotifications: userNotifications(axiosInstance),
  vehicles: vehicles(axiosInstance),
  zone: zone(axiosInstance),
  trips: trips(axiosInstance),
  tripPlanningOld: tripPlanningOld(axiosInstance),
  tripPlanningNew: tripPlanningNew(axiosInstance),
  report: report(axiosInstance),
};
