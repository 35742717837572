<template>
  <BaseDialogForm
    v-model="openStatusDialog"
    :title="`${formType} Status`"
    @reset="closeDialog()"
  >
    <template #content>
      <v-form
        v-if="openStatusDialog"
        v-model="isValid"
        ref="statusForm"
        id="statusForm"
      >
        <v-row>
          <!-- <v-col cols="6" lg="4">
            <InputField
              outlined
              v-model="statusDetails.status_category"
              class="background-white mt-1"
              label="Status Category*"
              disabled
            ></InputField>
          </v-col> -->
          <v-col cols="6" lg="6">
            <InputField
              outlined
              hide-details="auto"
              class="background-white mt-1"
              label="Status Name*"
              ref="statusName"
              v-model="statusDetails.name"
              :error-messages="error.name"
              @input="error && error.name ? delete error.name : ''"
              :rules="[(v) => !!v || 'Status Name is a required']"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="6">
            <SelectField
              outlined
              hide-details="auto"
              :items-list="statusType"
              item-text="name"
              item-value="value"
              v-model="statusDetails.keyword"
              class="mt-1"
              label="Status Type*"
              @change="error && error.keyword ? delete error.keyword : ''"
              :rules="[(v) => !!v || 'Status Type is a required']"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="12">
            <TextAreaField
              outlined
              hide-details="auto"
              class="background-white"
              label="Description"
              v-model="statusDetails.description"
            ></TextAreaField>
          </v-col>
        </v-row> </v-form
    ></template>
    <template #actions>
      <v-btn
        class="primary mr-4"
        @click="clear()"
        v-show="formType == 'add' || formType == 'Add'"
      >
        <span>Reset</span>
      </v-btn>
      <v-btn
        class="primary"
        :disabled="!isValid"
        @click.prevent="submitStatusForm()"
      >
        <span>Submit</span>
      </v-btn></template
    >
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      statusDetails: {
        status_category: "Order",
        keyword: "failed",
      },
      error: {},
      isValid: false,
      nonFieldError: [],
      radioGroup: "active",
      statusType: [
        {
          name: "Delivered",
          value: "successful",
        },
        {
          name: "Returned",
          value: "failed",
        },
      ],
    };
  },
  watch: {
    openStatusDialog(val) {
      if (!val) {
        this.clear();
      }
    },
  },
  computed: {
    openStatusDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async closeDialog() {
      await this.clear();
      this.openStatusDialog = false;
    },
    getStatusObject(id) {
      this.$api.status
        .getStatusObject(id)
        .then((res) => {
          this.statusDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    submitStatusForm() {
      bus.$emit("showLoader", true);
      if (this.formType == "Add") {
        this.$api.status
          .createStatusObject(this.statusDetails)
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Status Added successfully",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      } else {
        let payload = {
          id: this.statusDetails.id,
          data: this.statusDetails,
        };
        this.$api.status
          .updateStatusObject(payload)
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Status Updated successfully",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      }
    },
    clear() {
      this.statusDetails = {
        added_by: null,
        description: null,
        keyword: "failed",
        name: null,
        status_category: "Order",
      };
      if (this.$refs && this.$refs.statusForm) {
        this.$refs.statusForm.reset();
        this.$refs.statusForm.resetValidation();
      }
    },
  },
};
</script>

<style>
</style>