import TripPlanningTemplatesIndex from "../pages/admin/trip_planning_templates/index.vue";

const TripPlanningTemplates = [
  {
    path: "trip_planning_templates",
    name: "trip_planning_templates",
    component: TripPlanningTemplatesIndex,
  },
];

export default TripPlanningTemplates;
