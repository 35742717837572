<template>
  <ListLayout
    title="Order Upload History"
    :tableData="orderUploadHistoryList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getOrderUploadHistory"
    :tableScrollable="false"
    localStorageKey="order_upload_history_columns"
    :isColumnShowVisibility="false"
    :hasFilter="true"
    :searchInput="false"
  >
    <template #leftFilterSlot>
      <v-row>
        <v-col cols="12" class="d-flex">
          <v-col cols="3">
            <SelectField
              class=""
              color="light_black"
              label="Select Project"
              hide-details="auto"
              v-model="filter.project_id"
              :itemsList="allProjects"
              item-text="text"
              :menu-props="{ offsetY: true }"
              item-value="value"
              @change="getOrderUploadHistory()"
              outlined
              dense
              clearable
            ></SelectField
          ></v-col>
          <v-col cols="3">
            <v-row>
              <v-col class="d-flex">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <InputField
                      v-model="filter.start_date"
                      label="From"
                      class="mr-4"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      dense
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></InputField>
                  </template>
                  <v-date-picker
                    @change="dateValidator"
                    :max="nowDate"
                    v-model="filter.start_date"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <InputField
                      v-model="filter.end_date"
                      label="To"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                    ></InputField>
                  </template>
                  <v-date-picker
                    @change="dateValidator"
                    v-model="filter.end_date"
                    :max="nowDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </template>
    <template #dialogs>
      <v-dialog v-model="viewBulkOrderListDialog" width="80%">
        <v-card>
          <v-card-title class="background-primary text-white px-3 py-2">
            <span class="text-subtitle-1 text-uppercase">Orders</span>
            <v-spacer />
            <v-btn small icon @click="viewBulkOrderListDialog = false">
              <v-icon class="text-white">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-0">
            <AgGridVue
              @grid-ready="gridReady"
              :grid-options="gridOptions"
              :column-defs="bulkOrderTableHeader"
              :context="{ parent: this }"
              :row-data="bulkUploadOrderList"
              :style="{ width: '100%', height: gridHeight }"
              class="ag-theme-alpine"
            >
            </AgGridVue>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable */
import XLSX from "xlsx";
import { AgGridVue } from "ag-grid-vue";
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import OrderHistoryChip from "@/components/common/aggrid/chip/OrderHistoryChip.vue";
import OrderHistoryButton from "@/components/common/aggrid/buttons/OrderHistoryButton.vue";
import { downloadBlobData } from "@/assets/utils.js";
import { bus } from "@/main";
export default {
  name: "orderHistoryIndex",
  components: {
    AgGridVue,
    ListLayout,
    OrderHistoryChip,
    OrderHistoryButton,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      orderUploadHistoryList: [],
      headerSelected: [
        {
          headerName: "Uploaded By",
          field: "uploaded_by",
        },
        {
          headerName: "Uploaded On",
          field: "uploaded_on",
        },
        {
          headerName: "No Of Orders",
          field: "order_count",
        },
        {
          headerName: "No Of Items",
          field: "item_count",
        },
        {
          headerName: "No Of Customers",
          field: "customer_count",
        },
        {
          headerName: "Delivery Date",
          field: "execution_date",
        },
        {
          headerName: "Processed",
          field: "processed",
          cellRendererFramework: "OrderHistoryChip",
        },
        {
          headerName: "Actions",
          width: 270,
          field: "actions",
          pinned: "right",
          cellRendererFramework: "OrderHistoryButton",
        },
      ],
      allProjects: [],
      bulkOrderTableHeader: [],
      filter: {},
      viewBulkOrderListDialog: false,
      customFilter: {},
      bulkUploadOrderList: [],
      downloadData: [],
      menu1: false,
      menu2: false,
      nowDate: new Date().toISOString().slice(0, 10),
      defaultColDef: {
        lockPosition: true,
      },
      gridApi: null,
      columnApi: null,
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        headerHeight: 40,
        rowHeight: 40,
        rowSelection: "multiple",
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
      },
    };
  },
  computed: {
    gridHeight() {
      return `${window.innerHeight - 296}px`;
    },
    tableHeaders() {
      return [...this.headerSelected];
    },
    context() {
      return { parentComponent: this };
    },
  },
  methods: {
    gridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    dateValidator() {
      if (
        this.filter &&
        this.filter.start_date &&
        this.filter.end_date &&
        this.filter.start_date > this.filter.end_date
      ) {
        let _ = this.filter.start_date;
        this.filter.start_date = this.filter.end_date;
        this.filter.end_date = _;
        this.getOrderUploadHistory();
      } else {
        this.getOrderUploadHistory();
      }
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getOrderUploadHistory(params = {}) {
      params = {
        ...params,
        ...this.filter,
        ...this.customFilter,
      };
      bus.$emit("showLoader", true);
      this.$api.orderHistory
        .getOrderHistoryList(params)
        .then((res) => {
          this.orderUploadHistoryList = res.data.results;
          this.totalItems = res.count;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.log(err);
          bus.$emit("showLoader", false);
        });
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    downloadReportData(id) {
      this.$api.orderHistory.getUploadOrderList(id).then((response) => {
        let excelData = XLSX.utils.aoa_to_sheet(response.data.report_data);
        var wb = XLSX.utils.book_new();
        wb.Props = {
          Title: "Uploaded Orders",
          CreatedDate: new Date(),
        };

        wb.SheetNames.push(`Sheet 1`);

        wb.Sheets[`Sheet 1`] = excelData;

        var wbout = XLSX.write(wb, { bookType: "xls", type: "binary" });

        let blob = new Blob([this.s2ab(wbout)], {
          type: "application/octet-stream",
        });
        downloadBlobData(blob, "Uploaded Orders");
      });
    },

    displayBulkOrderDialog(id) {
      this.viewBulkOrderListDialog = true;
      this.$api.orderHistory.getUploadOrderList(id).then((response) => {
        this.bulkOrderTableHeader = response.data.report_data[0].map((item) => {
          return {
            headerName: item,
            field: item.replaceAll(" ", "_"),
          };
        });

        this.bulkUploadOrderList = response.data.report_data.map(
          (item, index) => {
            return {
              SO_Number: item[0],
              Item_No: item[1],
              Quantity: item[2],
              Customer_Code: item[3],
              Execution_Date: item[4],
            };
          }
        );
        this.downloadData = this.bulkUploadOrderList;
        this.bulkUploadOrderList.splice(0, 1);
      });
    },
  },
  mounted() {
    this.getAllProjects();
  },
};
</script>

<style>
</style>