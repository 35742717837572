import { render, staticRenderFns } from "./BaseDialogForm.vue?vue&type=template&id=4f72a200&scoped=true&"
import script from "./BaseDialogForm.vue?vue&type=script&lang=js&"
export * from "./BaseDialogForm.vue?vue&type=script&lang=js&"
import style0 from "./BaseDialogForm.vue?vue&type=style&index=0&id=4f72a200&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f72a200",
  null
  
)

export default component.exports