<template>
  <Dash />
</template>

<script>
import Dash from "@/components/dashboard/Dash.vue";
export default {
  name: "Dashboard-Index",
  components: {
    Dash,
  },
};
</script>

<style></style>
