<template>
  <v-dialog
    v-model="orderDetailsDialog"
    scrollable
    width="80%"
    max-width="1000px"
  >
    <v-card>
      <v-card-title class="background-primary py-2 px-4">
        <span class="text-uppercase text-white text-body-1">Order Details</span>
        <v-spacer />
        <v-btn fab depressed x-small @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col cols="7" v-if="orderDetails">
            <OrderDetailOperation :orderDetail="orderDetails" />
          </v-col>
          <v-col cols="5" class="border-left-light_grey" v-if="orderDetails">
            <OrderLogsOperations :orderDetail="orderDetails" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderDetailOperation from "@/components/operations/OrderDetailOperation.vue";
import OrderLogsOperations from "@/components/operations/OrderLogsOperations.vue";
import { bus } from "@/main.js";
export default {
  components: {
    OrderDetailOperation,
    OrderLogsOperations,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      orderDetails: {},
    };
  },
  computed: {
    orderDetailsDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getOrderDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.orders
        .getOrderDetails(id)
        .then((result) => {
          this.orderDetails = result.data;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
        });
    },
    closeDialog() {
      this.orderDetailsDialog = false;
    },
  },
};
</script>

<style>
</style>