<template>
  <v-dialog persistent v-model="openProfileDialog" max-width="400px">
    <v-card v-if="userDetails">
      <v-card-title class="primary white--text py-2">
        <v-row>
          <v-col cols="10">
            <span class="text-subtitle-1 text-capitalize" v-if="isEditMode">
              Edit User Profile
            </span>
            <span class="text-subtitle-1 text-capitalize" v-else>
              User Profile
            </span>
          </v-col>
          <v-col cols="2" class="d-flex justify-end white--text">
            <v-btn small icon @click="closeForm">
              <v-icon class="white--text">mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="nonFieldError.length">
            <v-alert dense type="error">
              <v-list
                class="pa-0"
                dense
                style="background: inherit !important"
                v-for="(error, i) in nonFieldError"
                :key="i"
              >
                <v-list-item dense style="min-height: 20px !important">
                  <span>{{ i }} . </span><span>{{ error }}</span>
                </v-list-item>
              </v-list>
            </v-alert>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="isValid" ref="profileForm" id="profileForm">
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-center pb-2 pt-8">
              <v-img
                contain
                :src="
                  userDetails.profile_image
                    ? userDetails.profile_image
                    : userImage
                "
                max-height="200"
                max-width="200"
                height="200"
                width="200"
                class="rounded-circle"
              />
              <input
                name="profile_image"
                ref="profileUpload"
                class="d-none"
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                @change="onFileChanged($event)"
              />
            </v-col>
          </v-row>
          <div class="my-2">
            <v-row class="py-2">
              <v-col cols="6">
                <span class="font-weight-bold text-body-1 text-capitalize">
                  User Details</span
                >
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      small
                      icon
                      @click="isEditMode = !isEditMode"
                    >
                      <v-icon class="text-primary">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-capitalize"> Edit Details</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <hr />
            <v-row class="pa-4" v-if="!isEditMode">
              <v-col cols="6" class="d-flex justify-start">
                <span class="text-capitalize"> Username</span>
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <span class="font-weight-bold"
                  >: {{ userDetails.username }}</span
                >
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <span class="text-capitalize"> Contact Number</span>
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <span class="font-weight-bold"
                  >: {{ userDetails.contact_number }}</span
                >
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <span class="text-capitalize"> Email ID</span>
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <span class="font-weight-bold">: {{ userDetails.email }}</span>
              </v-col>
              <v-col cols="6" class="d-flex justify-start">
                <span class="text-capitalize"> Role Name </span>
              </v-col>
              <v-col cols="6" class="d-flex justify-start text-capitalize">
                <span class="font-weight-bold" v-if="userDetails.role_name">
                  : {{ userDetails.role_name.replace("_", " ") }}
                </span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-2" v-else>
              <v-col cols="12" class="pt-2">
                <InputField
                  outlined
                  class="background-white"
                  name="first_name"
                  hide-details="auto"
                  dense
                  required
                  label="First Name"
                  :rules="[
                    (v) => !!v || 'First Name is required',
                    (v) =>
                      !/[^a-zA-Z0-9 ]/.test(v) ||
                      'Special characters are not allowed',
                  ]"
                  :error-messages="formErrors.first_name"
                  v-model="userDetails.first_name"
                  @input="delete formErrors.first_name"
                ></InputField>
              </v-col>
              <v-col cols="12" class="pt-4">
                <InputField
                  outlined
                  class="background-white"
                  label="Last Name"
                  name="last_name"
                  hide-details="auto"
                  dense
                  required
                  :rules="[
                    (v) => !!v || 'Last Name is required',
                    (v) =>
                      !/[^a-zA-Z0-9 ]/.test(v) ||
                      'Special characters are not allowed',
                  ]"
                  :error-messages="formErrors.last_name"
                  v-model="userDetails.last_name"
                  @input="delete formErrors.last_name"
                ></InputField>
              </v-col>
              <v-col cols="12" class="pt-4">
                <InputField
                  outlined
                  class="background-white"
                  min="0"
                  step="01"
                  name="contact_number"
                  hide-details="auto"
                  dense
                  label="Contact Number"
                  :rules="[
                    (v) => !!v || 'Contact Number is required',
                    (v) =>
                      !/[^0-9]/.test(v) || `Only numeric values are allowed`,
                    (v) =>
                      (v && 12 <= v.length && v.length <= 12) ||
                      'Contact Number must be 12 digits only',
                  ]"
                  :error-messages="formErrors.contact_number"
                  v-model="userDetails.contact_number"
                  @input="delete formErrors.contact_number"
                ></InputField>
              </v-col>
              <v-col cols="12" class="pt-4">
                <InputField
                  outlined
                  class="background-white"
                  label="Email"
                  hide-details="auto"
                  name="email"
                  :rules="[
                    (value) => !!value || 'Email is required',
                    (value) => {
                      const pattern =
                        /^(([^<>()[\]\\.,;:\s@&quot;]+(\.[^<>()[\]\\.,;:\s@&quot;]+)*)|(&quot;.+&quot;))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(value) || 'invalid email id';
                    },
                  ]"
                  dense
                  :error-messages="formErrors.email"
                  v-model="userDetails.email"
                  @input="delete formErrors.email"
                ></InputField>
              </v-col>
              <v-col cols="12" class="pt-4"> </v-col>
            </v-row>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="isEditMode" class="d-flex justify-end">
        <v-btn
          type="submit"
          @click="submitUserDetails"
          class="primary text-uppercase"
          :disabled="!isValid"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserImage from "@/assets/default-user.jpg";
import { userLogout } from "@/utils/functions.js";
import { bus } from "@/main";

export default {
  name: "mainProfile",
  props: {
    value: Boolean,
  },
  data() {
    return {
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
      userLogout,
      userImage: UserImage,
      isValid: false,
      isEditMode: false,
      nonFieldError: [],
      formErrors: {},
      userDetails: {},
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getProfileDetails();
      }
    },
  },
  computed: {
    openProfileDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeForm() {
      this.openProfileDialog = false;
      this.isEditMode = false;
    },
    onFileChanged(e) {
      const selectedFile = e.target.files[0];
      if (
        selectedFile.type.toLowerCase().indexOf("png") == -1 &&
        selectedFile.type.toLowerCase().indexOf("jpg") == -1 &&
        selectedFile.type.toLowerCase().indexOf("jpeg") == -1
      ) {
        alert("File type not supported \nSupported Types: PNG, JPG, JPEG");
        return null;
      }
      if (selectedFile) {
        const url = URL.createObjectURL(selectedFile);
        this.userDetails.update_profile_image = true;
        this.userDetails = {
          ...this.userDetails,
          profile_image: url,
        };
      }
    },
    getProfileDetails() {
      this.$api.profile
        .getUserProfile()
        .then((result) => {
          localStorage.setItem("userdetails", result.data);
          this.userDetails = result.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    submitUserDetails() {
      this.$api.profile
        .updateProfilePost(this.userDetails)
        .then(() => {
          this.openProfileDialog = false;
          bus.$emit("showToastMessage", {
            message: "User Details Updated Successfully",
            color: "success",
          });
          let that = this;
          setTimeout(() => {
            that.userLogout();
          }, 2000);
        })
        .catch((err) => {
          console.error(err);
          if (err.data.non_field_errors) {
            this.nonFieldError = err.data.non_field_errors;
          }
          this.formErrors = err.data;
          bus.$emit("showToastMessage", {
            message: err.data.message,
            color: "error",
          });
        });
    },
  },
};
</script>
