import { operations } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getTripList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(operations.tripList, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTripDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${operations.tripDetails}${id}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(operations.orderList, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOperationsList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(operations.operationsList, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
