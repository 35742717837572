<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 edit white--text"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.zone && userPermissions.zone.change"
          @click="btnClickedHandler"
          depressed
        >
          <v-icon small class="ma-0">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 delete darken-1 white--text"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.zone && userPermissions.zone.delete"
          @click="btnDeleteZone"
          depressed
        >
          <v-icon small class="ma-0">mdi-delete</v-icon>
        </v-btn>
      </template>
      <span>Delete Details</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="grey white--text"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.zone"
          @click="viewLogs"
          depressed
        >
          <v-icon small class="ma-0">mdi-alpha-l-box</v-icon>
        </v-btn>
      </template>
      <span>Show Logs</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openZoneForm: false,
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  computed: {},
  methods: {
    async btnClickedHandler() {
      this.params.context.parentComponent.editZone(this.params.data.id);
    },
    btnDeleteZone() {
      this.params.context.parentComponent.deleteZone(this.params.data.id);
    },
    viewLogs() {
      this.params.context.parentComponent.viewLogsHandler({
        zone: this.params.data.id,
      });
    },
  },
  mounted() {},
  // beforeCreate() {
  //   if (encryptLocal == null) {
  //     makeInstance(localStorage.getItem("user"));
  //   }
  // },
};
</script>