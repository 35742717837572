<template>
  <ListLayout
    ref="listLayout"
    title="Drivers"
    :tableData="driverList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getDriverList"
    :tableScrollable="false"
    :isColumnShowVisibility="true"
  >
    <template #listAction>
      <v-row no-gutters>
        <v-col
          class="d-flex justify-end"
          v-if="permissions && permissions.driver && permissions.driver.add"
        >
          <v-btn small depressed class="primary d-none mr-1" @click="addTag()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span class="mr-2">Add Tag</span>
          </v-btn>
          <v-btn small depressed class="primary mr-1" @click="addDriver()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span class="mr-2">Add Driver</span>
          </v-btn>
          <v-menu
            offset-y
            rounded="lg"
            elevation="0"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  permissions &&
                  permissions.driver &&
                  (permissions.driver.add || permissions.driver.change)
                "
                small
                depressed
                v-bind="attrs"
                v-on="on"
                Dropdown
                class="primary mr-1"
              >
                <v-icon small class="mr-1">mdi-arrow-up-bold</v-icon>
                <span>Bulk Operations</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="showBulkDialog('drivers')"
                v-if="
                  permissions && permissions.driver && permissions.driver.add
                "
              >
                <v-list-item-icon class="text-center d-flex align-center mx-0">
                  <v-icon class="primary--text" small
                    >mdi-table-arrow-up</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="ma-0">
                  <v-list-item-title class="text-capitalize text-center">
                    Bulk Upload
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="showBulkDialog('drivers_update')"
                v-if="
                  permissions && permissions.driver && permissions.driver.change
                "
              >
                <v-list-item-icon class="text-center d-flex align-center mx-0">
                  <v-icon class="primary--text" small
                    >mdi-microsoft-excel</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="ma-0">
                  <v-list-item-title class="text-capitalize text-center">
                    Bulk Update
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            small
            depressed
            class="primary mr-1"
            @click="bulkUploadDialog = true"
          >
            <v-icon small class="mr-1">mdi-arrow-up-bold</v-icon>
            <span>Bulk Upload</span>
          </v-btn> -->
          <v-btn
            small
            depressed
            class="primary"
            @click="openDownloadDataDialog = true"
          >
            <v-icon small class="mr-1">mdi-arrow-down-bold</v-icon>
            <span>Download All Driver Data</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <template #leftFilterSlot>
      <v-col class="d-flex justify-start pa-0">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="success"
              overlap
              small
              dot
              :value="appliedFilters && appliedFilters.length > 0"
            >
              <v-btn
                small
                fab
                depressed
                color="primary"
                class="rounded ml-2"
                @click="openDriverFilterDialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="">mdi-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Driver Filters</span>
        </v-tooltip>
      </v-col>
    </template>

    <template #dialogs>
      <DriverFilter
        ref="driverFilterDialog"
        v-model="openDriverFilterDialog"
        @driverFilterChanged="$refs.listLayout.searchTriggered()"
      />
      <DriverForm
        ref="driverForm"
        v-model="openDriverForm"
        :formType="formType"
        @refreshList="$refs.listLayout.searchTriggered()"
      />
      <LogDialog
        :logsArray="driverLogs"
        title="Logs"
        v-model="openDriverLogs"
      />

      <DownloadAllData parent="drivers" v-model="openDownloadDataDialog" />
      <BulkUpload
        v-model="bulkUploadDialog"
        :upload-to="bulkDialogType"
        @refreshList="getDriverList({ limit: 10, offset: 0 })"
      />
      <ChangeProject
        ref="changeBranch"
        v-model="openChangeBranchDialog"
        type="driver"
      />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import DriverButton from "@/components/common/aggrid/buttons/DriverButton.vue";
import DriverTagChip from "@/components/common/aggrid/chip/driverTagChip.vue";
import DriverForm from "@/components/driver/DriverForm.vue";
import { bus } from "@/main.js";
import LogDialog from "@/components/BaseComponents/LogDialog.vue";
import DriverFilter from "@/components/driver/DriverFilter.vue";
import DownloadAllData from "@/components/common/DownloadAllData.vue";
import { downloadBlobData } from "@/assets/utils.js";
import BulkUpload from "@/components/common/BulkUpload.vue";
import ChangeProject from "@/components/common/ChangeProject.vue";

export default {
  name: "driversIndex",
  components: {
    ListLayout,
    DriverButton,
    DriverTagChip,
    DriverForm,
    LogDialog,
    DriverFilter,
    DownloadAllData,
    BulkUpload,
    ChangeProject,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      customFilter: {},
      driverList: [],
      driverLogs: [],
      openDriverForm: false,
      openDriverLogs: false,
      openDriverFilterDialog: false,
      openDownloadDataDialog: false,
      bulkUploadDialog: false,
      openChangeBranchDialog: false,

      bulkDialogType: "",
      formType: "Add",

      headerSelected: [
        {
          headerName: "First name",
          field: "first_name",
        },
        {
          headerName: "Last name",
          field: "last_name",
        },
        {
          headerName: "Username",
          field: "username",
        },
        {
          headerName: "Contact Number",
          field: "contact_number",
        },
        {
          headerName: "Project",
          field: "project_name",
        },
        {
          headerName: "Shift Start",
          field: "shift_start",
        },
        {
          headerName: "Shift End",
          field: "shift_end",
        },
        {
          headerName: "Zone",
          field: "zone",
        },
        {
          headerName: "Vehicle Number",
          field: "vehicle_plate_number",
        },
        {
          headerName: "Nationality",
          field: "nationality",
        },
        {
          headerName: "License Number",
          field: "license_number",
        },

        {
          headerName: "Duty Status",
          field: "status",
          cellRenderer: (params) => {
            return params.data.status.replace("_", " ").toUpperCase();
          },
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        ...(this.permissions.driver &&
        (this.permissions.driver.change ||
          this.permissions.driver.view ||
          this.permissions.driver.delete)
          ? [
              {
                headerName: "Actions",
                maxWidth: 200,
                minWidth: 200,
                field: "actions",
                pinned: "right",
                cellRendererFramework: "DriverButton",
              },
            ]
          : []),
      ];
    },
    context() {
      return { parentComponent: this };
    },
    appliedFilters() {
      return Object.keys(this.customFilter).map((filter) => {
        return filter.replace(/\_/, " ");
      });
    },
  },
  methods: {
    showBulkDialog(type) {
      this.bulkDialogType = type;
      this.bulkUploadDialog = true;
    },
    downloadAllDrivers(params) {
      params = {
        ...params,
      };
      // if (projects && projects.length) {
      //   params.project_id = projects;
      // }
      bus.$emit("showLoader", true);
      this.$api.drivers
        .downloadDriverSheet(params)
        .then((result) => {
          bus.$emit("showLoader", false);
          downloadBlobData(result.data, "Drivers.xls");
          bus.$emit("showToastMessage", {
            message: "All Driver Data Downloaded",
            color: "success",
          });
          this.openDownloadDataDialog = false;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          if (err.message) {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          } else {
            bus.$emit("showToastMessage", {
              message: "Something went wrong!",
              color: "error",
            });
          }
        });
    },

    getDriverList(params = {}) {
      let filter = localStorage.getItem("driverFilters");
      if (!filter) {
        filter = {};
      }
      if (typeof filter == typeof "string") {
        filter = JSON.parse(filter);
      }
      this.customFilter = filter;

      params = {
        ...params,
        ...this.customFilter,
      };

      if (params && params.status && Array.isArray(params.status)) {
        params.status = params.status.join(",");
      }
      if (
        params &&
        params.project_id &&
        Array.isArray(params.project_id) &&
        params.project_id.length > 0
      ) {
        params.project_id = params.project_id.join(",");
      }
      if (params && params.ordering && params.sorting) {
        if (params.sorting == "descending") {
          params.ordering = "-" + params.ordering;
        }
      }
      bus.$emit("showLoader", true);
      this.$api.drivers
        .getDriverList(params)
        .then((res) => {
          bus.$emit("showLoader", false);

          this.driverList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);

          console.error(err);
        });
    },

    addDriver() {
      this.formType = "Add";
      this.openDriverForm = true;
    },

    editDriver(id) {
      this.formType = "Edit";
      this.openDriverForm = true;
      this.$refs.driverForm.getDriverDetails(id);
    },

    openChangeBranch(id) {
      this.$refs.changeBranch.openChangeBranch(id);
    },

    viewLogsHandler(params = {}) {
      params = {
        ...params,
        limit: "all",
        module_type: "driver",
      };
      this.$api.drivers
        .getDriverLogs(params)
        .then((res) => {
          this.driverLogs = res.data.results;
          this.openDriverLogs = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couln't fetch data",
            color: "error",
          });
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
