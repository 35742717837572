<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-1 edit white--text"
          small
          v-bind="attrs"
          v-on="on"
          @click="openTemplateEditDialog"
          depressed
        >
          <v-icon small class="ma-0">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>

    <!-- <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="userPermissions.trip.change"
          class="mr-1 grey white--text"
          v-bind="attrs"
          v-on="on"
          small
          @click="openTemplateViewDialog"
          depressed
        >
          <v-icon small class="ma-0">mdi-eye</v-icon>
        </v-btn>
      </template>
      <span>View Details</span>
    </v-tooltip> -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-1 grey white--text"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.trip.change"
          @click="viewLogs"
          depressed
        >
          <v-icon small class="ma-0">mdi-alpha-l-box</v-icon>
        </v-btn>
      </template>
      <span>Show Logs</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  methods: {
    // openTemplateViewDialog() {
    //   this.params.context.parentComponent.viewDialog(this.params.data.id);
    // },
    openTemplateEditDialog() {
      this.params.context.parentComponent.editTemplate(this.params.data.id);
    },
    viewLogs() {
      this.params.context.parentComponent.viewLogsHandler({
        template: this.params.data.template_name,
      });
    },
  },
  // beforeCreate() {
  //   if (encryptLocal == null) {
  //     makeInstance(localStorage.getItem("user"));
  //   }
  // },
};
</script>

<style>
</style>