<template>
  <v-dialog
    v-model="orderInadequate"
    persistent
    scrollable
    width="40%"
    max-width="500px"
  >
    <v-form ref="orderInadequate" id="orderInadequate" v-model="isValid">
      <v-card class="pa-4">
        <v-card-title class="d-flex justify-space-between">
          <span
            class="
              text-lg-subtitle-1 text-xl-h6 text-uppercase
              font-weight-black
              primary--text
            "
          >
            Integration Statistics
          </span>
          <v-btn depressed fab class="mt-n3" small @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="9" lg="9" class="mt-2">
              <v-menu
                ref="order_date"
                v-model="DatePickerMenu1"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                max-width="auto"
                min-width="150px"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <InputField
                    outlined
                    hide-details="auto"
                    label="Date*"
                    class="background-white"
                    aria-autocomplete="false"
                    dense
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    name="order_date"
                    readonly
                    :rules="[(v) => !!v || 'Order Date is required']"
                    :error-messages="error.order_date"
                    :value="order_date"
                    v-model="order_date"
                  ></InputField>
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  reactive
                  :value="order_date"
                  v-model="order_date"
                  @input="DatePickerMenu1 = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mt-2">
              <v-btn
                :disabled="!isValid"
                color="primary"
                :loading="loading"
                @click="submitData()"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions class="pa-8">
        <v-btn
          :disabled="!isValid"
          color="primary"
          :loading="loading"
          @click="submitData()"
          >Submit</v-btn
        >
      </v-card-actions> -->
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { downloadBlobData } from "@/assets/utils.js";
import { bus } from "@/main.js";

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      instructionDialog: {
        dialog: false,
        RequestType: null,
      },
      order_date: null,
      error: {
        user: {},
      },
      nowDate: new Date().toISOString().slice(0, 10),
      date: null,
      DatePickerMenu1: false,
      loading: false,
      isValid: false,
    };
  },
  computed: {
    totalRecordCount() {
      return this.fulldata.filter((obj) => Object.keys(obj).length !== 0)
        .length;
    },
    orderInadequate: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    // syncData(e, key, subKey) {
    //   this.order_date = e;
    // },

    submitData() {
      let params;
      params = {
        ...params,
        order_date: this.order_date,
      };
      this.$api.integration
        .fetchOrderInadequate(params)
        .then((res) => {
          downloadBlobData(res.data, "IntegrationStatistics.xls");
          bus.$emit("showToastMessage", {
            message: "Integration Statistics",
            color: "success",
          });
          this.closeDialog();
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },
    closeDialog() {
      this.orderInadequate = false;
      this.clearDialogData();
    },
    clearDialogData() {
      this.order_date = "";
    },
  },
};
</script>

<style>
</style>
