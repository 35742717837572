export default {
  permissions: {},
  getPermissionsFromLocalStorage() {
    let userData = JSON.parse(localStorage.getItem("userDetails"));
    if (userData) {
      this.permissions = userData.permissions;
    }
    return userData.permissions;
  },
  setPermissions(userData) {
    if (userData) {
      localStorage.setItem("userDetails", JSON.stringify(userData));
      this.permissions = userData.permissions;
    }
  },
  getPermission(key, subKey) {
    if (!this.permissions || Object.keys(this.permissions).length == 0) {
      this.getPermissionsFromLocalStorage();
    }
    if (subKey) {
      return this.permissions[key][subKey];
    } else {
      return this.permissions[key];
    }
  },
};
