import { report } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions.js";

export default (axios) => ({
  getReportList(data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${report.base}${data.url}/`, {
          params: data.params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  getTripStatistics(data = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${data.url}/`, {
          params: data.params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },

  downloadReportSheet(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${report.base}${params.url}/`, {
          responseType: "blob",
          headers: {
            // Accept: "application/xlsx",
          },
          params: params.params,
        })
        .then((success) => {
          resolve(success.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
});
