import { user } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getUserList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(user.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getUserObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${user.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createUserObject(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${user.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateUserObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${user.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateUserObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${user.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteUserObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${user.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
