export const version = "/api/v1/";
export const version2 = "/api/v2/";
export const version3 = "/api/v3/";

const roleBase = `${version}role/`;
const userBase = `${version}user/`;
const profileBase = `${version}profile/`;
const supportBase = `${version}support/`;
const tripPlanningTemplateBase = `${version2}planning_template/`;
const userNotificationBase = `${version}notification/`;
const projectOldBase = `${version}project/`;
const projectBase = `${version2}project/`;
const statusBase = `${version2}status/`;
const customerAddressBase = `${version2}customer_address/`;
const customerAddressDropdown = `${version2}dropdown/customer_address_list/`;
const integrationBase = `${version2}integration/`;
const tagsBase = `${version}tags/`;
const itemsBase = `${version2}item/`;
const ordersBase = `${version2}order/`;
const vehiclesBase = `${version2}vehicle/`;
const driverOldBase = `${version}driver/`;
const driversBase = `${version2}driver/`;
const orderHistoryBase = `${version2}order-upload-history/`;
const driverAppBase = `${version2}app/`;
const dashboardBase = `${version}dashboard/`;
const operationsBase = `${version2}operations/`;
const chatBase = `${version}chat/`;
const tripsBase = `${version2}trip/`;
const zoneBase = `${version2}zone/`;
const tripPlanningOldBase = `${version}trip_plan/`;
const tripPlanningBase = `${version2}trip_planner/trip_plan/`;
export const systemLogs = `${version2}system_logs/`;

export const tripPlanningOld = {
  base: `${tripPlanningOldBase}`,
};

export const tripPlanning = {
  base: `${tripPlanningBase}`,
};

export const dropdownCustomers = {
  base: `${customerAddressDropdown}`,
};

export const zones = {
  base: `${zoneBase}`,
  zoneLogs: `${version2}`,
};

export const trips = {
  base: `${tripsBase}`,
};

export const chat = {
  base: `${chatBase}`,
};
export const operations = {
  tripList: `${operationsBase}trip_list/`,
  tripDetails: `${operationsBase}trip_details/`,
  orderList: `${operationsBase}order_list/`,
  operationsList: `${version}${operationsBase}overview/`,
};

export const dashboard = {
  fleetUtilization: `${dashboardBase}fleet_utlization/`,
  orderGraphData: `${dashboardBase}orders/`,
  scorecard: `${dashboardBase}scorecard/`,
};

export const driverApp = {
  order: `${driverAppBase}order/`,
  trip: `${driverAppBase}trip/`,
  driver: `${driverAppBase}driver/`,
  reasons: `${driverAppBase}statuses/`,
};

export const orderHistory = {
  base: `${orderHistoryBase}`,
};

export const drivers = {
  base: `${driversBase}`,
  oldBase: `${driverOldBase}`,
  bulkUploadDrivers: `${version}driver/ADD_DRIVER`,
};

export const vehicles = {
  base: `${vehiclesBase}`,
  bulkUploadVehicles: `${version}vehicle/ADD_VEHICLE`,
  vehicleLogs: `${version2}`,
};
export const role = {
  base: `${roleBase}`,
};

export const user = {
  base: `${userBase}`,
};

export const profile = {
  base: `${profileBase}`,
  changePassword: `/change_password/`,
};

export const support = {
  base: `${supportBase}`,
};

export const tripPlanningTemplate = {
  base: `${tripPlanningTemplateBase}`,
  logs: `${version2}planning_template_logs/`,
};

export const userNotification = {
  base: `${userNotificationBase}`,
  markAsAllRead: `${userNotificationBase}mark_all_as_read/`,
  notificationCount: `${userNotificationBase}get_notification_count/`,
};

export const project = {
  base: `${projectBase}`,
  oldBase: `${projectOldBase}`,
  projectLogs: `${version2}`,
};

export const status = {
  base: `${statusBase}`,
};

export const customerAddress = {
  base: `${customerAddressBase}`,
  bulkUploadCustomers: `${version}customer/customerAddress/ADD_CUSTOMER_ADDRESS_DETAILS`,
};

export const integration = {
  fetchCustomers: `${integrationBase}customers/fetch/`,
  fetchItems: `${integrationBase}items/fetch/`,
  fetchB2BOrders: `${integrationBase}orders/fetch/b2b/`,
  fetchB2COrders: `${integrationBase}orders/fetch/b2c/`,
  fetchSameDayDeliveryOrders: `${integrationBase}orders/fetch/same_day/`,
  fetchInvoice: `${integrationBase}invoice/fetch/`,
  fetchOrderInadequate: `${integrationBase}inadequate_data_download/`,
  sendLoadSheet: `${integrationBase}send_load_sheet/`,
  B2COrder: `${integrationBase}b2c_order_logs/`,
  customer: `${integrationBase}customer_logs/`,
  addCustomer: `${integrationBase}add_customer/`,
  items: `${integrationBase}item_logs/`,
  addItem: `${integrationBase}add_item/`,
  order: `${integrationBase}order_logs/`,
};

export const tags = {
  base: `${tagsBase}`,
};

export const items = {
  base: `${itemsBase}`,
  bulkUploadItems: `${version}item/ADD_ITEM`,
};

export const orders = {
  base: `${ordersBase}`,
  bulkUploadOrders: `${version}bulk_upload/UPLOAD_ORDERS`,
  bulkUpdateOrders: `${version}bulk_upload/UPDATE_ORDERS`,
  bulkUploadB2COrders: `${version}bulk_upload/UPLOAD_B2C_ORDERS`,
};

export const report = {
  base: `${version2}report/`,
  driverReport: `${version2}report/driver_report/`,
  fleetUtilizationReport: `${version2}report/fleet_utilization_report/`,
  orderReport: `${version2}report/order_report/`,
  tripReport: `${version2}report/trip_report/`,
  tripVolumeUtilizationReport: `${version2}report/trip_volume_utilization/`,
  vehicleReport: `${version2}report/vehicle_report/`,
}