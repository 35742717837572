export const moduleName = [
  {
    title: "Customer",
    value: "customer",
  },
  {
    title: "Order",
    value: "order",
  },
  {
    title: "Trip",
    value: "trip",
  },
  {
    title: "Trip Planning",
    value: "trip_planning",
  },
  {
    title: "Report",
    value: "report",
  },
  {
    title: "Driver",
    value: "driver",
  },
  {
    title: "Project",
    value: "project",
  },
  {
    title: "Zone",
    value: "zone",
  },
  {
    title: "Vehicle",
    value: "vehicle",
  },
  {
    title: "Item master",
    value: "item_master",
  },
  {
    title: "Other",
    value: "other",
  },
];

export const priority = [
  {
    title: "High",
    value: "high",
  },
  {
    title: "Medium",
    value: "medium",
  },
  {
    title: "Low",
    value: "low",
  },
];

export const ticketStatus = [
  {
    title: "Open",
    value: "open",
  },
  {
    title: "In Progress",
    value: "in_progress",
  },
  {
    title: "Resolved",
    value: "resolved",
  },
  {
    title: "Cancelled",
    value: "cancelled",
  },
  {
    title: "Closed",
    value: "closed",
  },
];

export const configurationOptions = [
  { text: "Optimize Distance", value: "optimize_distance" },
  { text: "Optimize Transporters", value: "optimize_transports" },
  { text: "Optimize Locality Grouping", value: "optimize_locality_grouping" },
  { text: "Optimize Time", value: "optimize_time" },
  { text: "Optimize Cars Then Time", value: "optimize_cars_then_time" },
  {
    text: "Optimize Cars Then Locality Grouping",
    value: "optimize_cars_then_locality_grouping",
  },
];

export const vehicleStatusList = [
  { text: "Activate", value: "idle" },
  { text: "Deactivate", value: "deactivated" },
];
