var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4 border-x-light_grey border-y-light_grey full-width background-white rounded-lg",class:_vm.selectedTrip != null && _vm.selectedTrip.id == _vm.trip.id
      ? 'selected-card'
      : null,on:{"click":function($event){return _vm.$emit('selectTrip')}}},[_c('div',{staticClass:"pa-2 background-light_grey rounded-t-lg"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"text-body-2 font-weight-bold"},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.trip.reference_number)+" ")])]}}])},[_c('span',[_vm._v("Reference Number")])])],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('div',[_c('span',{staticClass:"caption text-capitalize"},[_vm._v(" "+_vm._s(_vm.trip.status)+" ")]),_c('v-avatar',{staticClass:"ml-1",class:_vm.getTripStatusLightColor(_vm.trip.status),attrs:{"size":"16"}})],1)])],1)],1),_c('div',{staticClass:"pa-2"},[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.trip && _vm.trip.driver_details)?_c('v-col',{staticClass:"text-caption",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.trip.driver_details.first_name)+" - "+_vm._s(_vm.trip.driver_details.last_name)+" ")])]}}],null,false,1590505762)},[_c('span',[_vm._v("Driver Name")])])],1):_vm._e(),(
          _vm.trip && _vm.trip.driver_details && _vm.trip.driver_details.contact_number
        )?_c('v-col',{staticClass:"text-caption d-flex justify-end",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('b',[_vm._v(_vm._s(_vm.trip.driver_details.contact_number))])])]}}],null,false,3077392199)},[_c('span',[_vm._v("Driver Contact Number")])])],1):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[(
          _vm.trip &&
          _vm.trip.vehicle_details &&
          _vm.trip.vehicle_details.vehicle_plate_no
        )?_c('v-col',{staticClass:"text-caption",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-truck")]),_vm._v(" "+_vm._s(_vm.trip.vehicle_details.vehicle_plate_no)+" ")],1)]}}],null,false,1959099718)},[_c('span',[_vm._v("Assigned Vehicle")])])],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end text-caption",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('b',[_vm._v(_vm._s(_vm.trip.trip_date))])])]}}])},[_c('span',[_vm._v("Trip Date")])])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }