import { driverApp } from "@/utils/constants";
import { handleResponse, handleError } from "@/utils/functions";

export default (axios) => ({
  getTripDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driverApp.trip}${id}`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getDriverDetails(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driverApp.driver}profile/`, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTripRoute(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driverApp.trip}${id}/map_route/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getPartiallyReasons() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driverApp.reasons}successful/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getFailedReasons() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driverApp.reasons}failed/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  driverDuty(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driverApp.driver}${id}/status/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deliveredOrder(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${driverApp.order}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getOrderDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${driverApp.order}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  activeTrip(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${driverApp.trip}${payload.id}/status/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  driverAttachmentUpload(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${driverApp.order}${payload.id}/attachments/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
