<template>
  <v-dialog
    v-model="invoiceDialog"
    persistent
    scrollable
    width="40%"
    max-width="500px"
  >
    <v-form ref="fetchB2COrder" id="fetchB2COrder" v-model="isValid">
      <v-card class="pa-4">
        <v-card-title class="d-flex justify-space-between">
          <span
            class="
              text-lg-subtitle-1 text-xl-h6 text-uppercase
              font-weight-black
              primary--text
            "
          >
            Fetch B2C Orders
          </span>
          <v-btn depressed fab class="mt-n3" small @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="9" lg="9" class="mt-2">
              <v-menu
                ref="delivery_date"
                v-model="DatePickerMenu1"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                max-width="auto"
                min-width="150px"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <InputField
                    outlined
                    hide-details="auto"
                    label="Delivery Date*"
                    class="background-white"
                    aria-autocomplete="false"
                    dense
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    name="delivery_date"
                    readonly
                    :rules="[(v) => !!v || 'Delivery Date is required']"
                    :error-messages="error.delivery_date"
                    v-model="delivery_date"
                    @input="syncData($event, 'delivery_date')"
                  ></InputField>
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  reactive
                  v-model="selectedDate"
                  :value="delivery_date"
                  :min="nowDate"
                  @change="syncData($event, 'delivery_date')"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mt-2">
              <v-btn
                :disabled="!isValid"
                color="primary"
                :loading="loading"
                @click="submitData()"
                >Submit</v-btn
              >
            </v-col>
            <v-col cols="5" class="mt-0">
              <hr />
            </v-col>
            <v-col
              cols="2"
              class="d-flex justify-center align-center pa-0 ma-0 mt-0"
            >
              <h5 class="ma-0 pa-0">OR</h5>
            </v-col>
            <v-col cols="5" class="mt-0">
              <hr />
            </v-col>
            <v-col cols="12" class="my-0 text-center">
              <v-btn
                color="primary"
                :loading="loading"
                @click="submitData((is_today = true))"
                >Fetch B2C Orders for today</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions class="pa-8">
        
      </v-card-actions> -->
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { bus } from "@/main.js";
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      instructionDialog: {
        dialog: false,
        RequestType: null,
      },
      delivery_date: "",
      error: {
        user: {},
      },
      selectedDate: null,
      nowDate: new Date().toISOString().slice(0, 10),
      date: null,
      DatePickerMenu1: false,
      loading: false,
      isValid: false,
    };
  },
  computed: {
    totalRecordCount() {
      return this.fulldata.filter((obj) => Object.keys(obj).length !== 0)
        .length;
    },
    invoiceDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    syncData(e, key, subKey) {
      this.delivery_date = e;
    },

    submitData(is_today = false) {
      let payload = {
        is_today: is_today,
        delivery_date: this.selectedDate,
      };
      this.$api.integration
        .fetchB2COrders(payload)
        .then(() => {
          bus.$emit("showToastMessage", {
            message: "Fetching B2C Orders",
            color: "success",
          });
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
      this.delivery_date = "";
      this.invoiceDialog = false;
    },
    closeDialog() {
      this.invoiceDialog = false;
      this.clearDialogData();
    },
    clearDialogData() {
      this.delivery_date = "";
    },
  },
};
</script>

<style>
</style>
