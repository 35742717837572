<template>
  <v-dialog
    v-model="invoiceDialog"
    persistent
    scrollable
    width="40%"
    max-width="500px"
  >
    <v-form ref="sendLoadSheet" id="sendLoadSheet" v-model="isValid">
      <v-card class="pa-4">
        <v-card-title class="d-flex justify-space-between">
          <span
            class="
              text-lg-subtitle-1 text-xl-h6 text-uppercase
              font-weight-black
              primary--text
            "
          >
            Send Loadsheet to MSD
          </span>
          <v-btn depressed fab class="mt-n3" small @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" lg="6" class="mt-2">
              <v-menu
                ref="delivery_date"
                v-model="DatePickerMenu1"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                max-width="auto"
                min-width="150px"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <InputField
                    outlined
                    hide-details="auto"
                    label="Delivery Date*"
                    class="background-white"
                    aria-autocomplete="false"
                    dense
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    name="delivery_date"
                    readonly
                    :rules="[(v) => !!v || 'Delivery Date is required']"
                    :error-messages="error.delivery_date"
                    v-model="selectedFilter.delivery_date"
                  ></InputField>
                </template>
                <v-date-picker
                  no-title
                  scrollable
                  reactive
                  :value="delivery_date"
                  :min="nowDate"
                  v-model="selectedFilter.delivery_date"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" lg="6" class="mt-2">
              <SelectField
                multiple
                hide-details
                outlined
                dense
                label="Select Project"
                :itemsList="allProjects"
                item-text="text"
                item-value="value"
                :menu-props="{ offsetY: true }"
                v-model="selectedFilter.selected_projects"
              >
                <!-- <template v-slot:selection="{ item, index }">
              <span v-if="index === 0">{{ item.text }} &nbsp;</span>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ orderFilter.project.length - 1 }} others)
              </span>
            </template> -->
              </SelectField>
            </v-col>
            <v-col class="mt-2">
              <v-btn
                :disabled="!isValid"
                color="primary"
                :loading="loading"
                @click="submitData()"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions class="pa-8">
        <v-btn
          :disabled="!isValid"
          color="primary"
          :loading="loading"
          @click="submitData()"
          >Submit</v-btn
        >
      </v-card-actions> -->
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { bus } from "@/main.js";

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      instructionDialog: {
        dialog: false,
        RequestType: null,
      },
      delivery_date: "",
      error: {
        user: {},
      },
      nowDate: new Date().toISOString().slice(0, 10),
      date: null,
      DatePickerMenu1: false,
      loading: false,
      isValid: false,
      selectedFilter: {},
      allProjects: [],
    };
  },
  watch: {
    invoiceDialog(val) {
      if (val) {
        this.getAllProjects();
      }
    },
  },
  computed: {
    totalRecordCount() {
      return this.fulldata.filter((obj) => Object.keys(obj).length !== 0)
        .length;
    },
    invoiceDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    syncData(e, key, subKey) {
      this.delivery_date = e;
    },

    submitData() {
      this.$api.integration
        .sendLoadSheet(this.selectedFilter)
        .then(() => {
          bus.$emit("showToastMessage", {
            message: "Load Sheets Sent",
            color: "success",
          });
          this.closeDialog();
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },
    closeDialog() {
      this.invoiceDialog = false;
      this.clearDialogData();
    },
    clearDialogData() {
      this.selectedFilter = {};
    },
  },
};
</script>

<style>
</style>
