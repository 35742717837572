<template>
  <v-dialog v-model="tripFilterDialog" width="35%">
    <v-card>
      <v-card-title class="background-primary text-white px-3 py-2">
        <span class="text-subtitle-1 text-uppercase"> Trip Filters </span>
        <v-spacer />
        <v-btn small icon @click="tripFilterDialog = false">
          <v-icon class="text-white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-form ref="tripFilterForm">
          <v-row no-gutters class="pt-4 px-4 background-light_primary">
            <v-col cols="12">
              <SelectField
                class="mb-4 background-white"
                dense
                v-model="tripFilter.project"
                :rules="[(v) => !!v || 'Project is required']"
                hide-details="auto"
                outlined
                label="Select Project"
                :items-list="allProjects"
                item-text="project_name"
                item-value="project_id"
                @change="syncTripFilter($event, 'project')"
                :menu-props="{ offsetY: true }"
              ></SelectField>

              <!-- <CommonCustomselect
                :itemsList="allProjects"
                label="Select Project"
                :returnObject="false"
                :value="tripFilter.project"
                :isMounted="tripFilterDialog"
                @selectionChanged="syncTripFilter($event, 'project')"
                :dense="true"
                class="mb-4 background-white"
              /> -->
            </v-col>
            <v-col cols="12">
              <SelectField
                hide-details
                outlined
                dense
                :menu-props="{ offsetY: true }"
                label="Select Status"
                placeholder="Select Status"
                :items-list="statusTypes"
                item-text="name"
                item-value="value"
                multiple
                :value="tripFilter.status"
                @change="syncTripFilter($event, 'status')"
                class="mb-4 background-white"
              ></SelectField>
            </v-col>

            <v-col cols="12">
              <SelectField
                hide-details
                outlined
                dense
                :menu-props="{ offsetY: true }"
                label="Select Driver"
                placeholder="Select Driver"
                :items-list="driversList"
                item-text="first_name"
                item-value="id"
                multiple
                :value="tripFilter.driver"
                @change="syncTripFilter($event, 'driver')"
                class="mb-4 background-white"
              ></SelectField>
            </v-col>
            <v-col cols="12">
              <SelectField
                hide-details
                outlined
                dense
                :menu-props="{ offsetY: true }"
                label="Select Vehicle"
                placeholder="Select Vehicle"
                :items-list="vehiclesList"
                item-text="vehicle_plate_no"
                item-value="id"
                multiple
                :value="tripFilter.vehicle"
                @change="syncTripFilter($event, 'vehicle')"
                class="mb-4 background-white"
              ></SelectField>
            </v-col>
          </v-row>
          <v-row no-gutters class="pb-4 pt-2 px-4 background-white">
            <v-col cols="12" class="px-1 pb-1 mb-4 border-bottom-light_black">
              <span class="text-subtitle-1 font-weight-bold text-grey">
                Filter By Date Range
              </span>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              xl="6"
              :class="`pb-4 ${$vuetify.breakpoint.lgAndUp ? 'pr-2' : null}`"
            >
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Start Date"
                    prepend-inner-icon="mdi-calendar"
                    hide-details
                    outlined
                    readonly
                    dense
                    :value="tripFilter.start_date"
                    @input="syncTripFilter($event, 'start_date')"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="tripFilter.start_date"
                  @click:date="
                    $refs.startDateMenu.save(startDate),
                      syncTripFilter($event, 'start_date')
                  "
                  no-title
                  scrollable
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              xl="6"
              :class="`pb-4 ${$vuetify.breakpoint.lgAndUp ? 'pl-2' : null}`"
            >
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="End Date"
                    prepend-inner-icon="mdi-calendar"
                    outlined
                    hide-details
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :value="tripFilter.end_date"
                    @input="syncTripFilter($event, 'end_date')"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="tripFilter.end_date"
                  @click:date="
                    $refs.endDateMenu.save(endDate),
                      syncTripFilter($event, 'end_date')
                  "
                  no-title
                  scrollable
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters class="pb-4 px-4 background-white">
            <v-col cols="12" class="px-1 pb-1 mb-4 border-bottom-light_black">
              <span class="text-subtitle-1 font-weight-bold text-grey">
                Ordering & Sorting
              </span>
            </v-col>
            <v-col cols="6" class="pr-2">
              <SelectField
                hide-details
                outlined
                dense
                label="Order by"
                :items-list="orderBy"
                :value="tripFilter.ordering"
                :menu-props="{ offsetY: true }"
                @change="syncTripFilter($event, 'ordering')"
              ></SelectField>
            </v-col>
            <v-col cols="6" class="pl-2">
              <SelectField
                hide-details
                outlined
                dense
                label="Sort by"
                :items-list="sortBy"
                :value="tripFilter.sorting"
                :menu-props="{ offsetY: true }"
                @change="syncTripFilter($event, 'sorting')"
              ></SelectField>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-4 background-light_grey">
        <v-spacer></v-spacer>
        <v-btn small depressed @click="tripFilterDialog = false">
          Cancel
        </v-btn>
        <v-btn small depressed type="reset" @click="resetFilters(true)">
          Reset
        </v-btn>
        <v-btn small depressed color="primary" @click="applyFilters(true)">
          Apply
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
export default {
  name: "Trip-Filter",
  props: {
    value: Boolean,
  },
  components: {
    SelectField,
  },
  data() {
    return {
      allProjects: [],
      vehiclesList: [],
      driversList: [],
      startDate: null,
      startDateMenu: false,
      endDate: null,
      endDateMenu: false,
      tripFilter: {},
      statusTypes: [
        {
          name: "Scheduled",
          value: "scheduled",
        },
        {
          name: "Active",
          value: "active",
        },
        {
          name: "Paused",
          value: "paused",
        },
        {
          name: "Completed",
          value: "completed",
        },
      ],
      orderBy: [
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Reference Number",
          value: "reference_number",
        },
        {
          text: "Trip Date",
          value: "trip_date",
        },
        {
          text: "Driver Name",
          value: "driver",
        },
      ],
      sortBy: [
        {
          text: "Ascending",
          value: "ascending",
        },
        {
          text: "Descending",
          value: "descending",
        },
      ],
    };
  },
  watch: {
    tripFilterDialog(value) {
      if (value) {
        this.getAllProjects();
        this.getVehicleList();
        this.getDriverList();
        let filters = localStorage.getItem("tripFilters");
        if (!filters) {
          filters = {};
        }
        if (typeof filters == typeof "string") {
          filters = JSON.parse(filters);
        }
        this.tripFilter = filters;
      }
    },
  },
  computed: {
    tripFilterDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    syncTripFilter(value, key) {
      let filters = localStorage.getItem("tripFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      if (value !== null && value.length > 0 && value) {
        filters[key] = value;
      } else {
        delete filters[key];
      }
      this.tripFilter = filters;
      localStorage.setItem("tripFilters", JSON.stringify(filters));
    },
    applyFilters(reload) {
      this.$emit("tripFilterChanged");
      this.tripFilterDialog = false;
    },
    resetFilters(reload) {
      this.tripFilter = {};
      this.$refs.tripFilterForm.reset();
      localStorage.removeItem("tripFilters");
      this.$emit("tripFilterChanged");
      // this.$parent.getTripList(reload);
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getVehicleList(params = {}) {
      params = {
        ...params,
        // status: "idle",
        limit: "all",
      };
      this.$api.vehicles
        .getVehicleList(params)
        .then((res) => {
          this.vehiclesList = res.data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getDriverList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.drivers
        .getDriverList(params)
        .then((res) => {
          this.driversList = res.data.results;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
