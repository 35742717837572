<template>
  <BaseDialogForm
    v-model="addCustomerAddressFormDialog"
    persistent
    scrollable
    width="55%"
    :title="`${formType} Customer`"
    @reset="closeDialog()"
  >
    <template #content>
      <v-alert v-if="nonFieldError.length" dense type="error">
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ i + 1 }} .</span><span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-form
        v-model="isFormVaid"
        class="pb-16"
        ref="customerAddressForm"
        id="customerAddressForm"
        :key="formCount"
      >
        <v-row>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Customer Code*"
              :rules="[
                (v) =>
                  (!!v && v.trim().length > 0) || 'Customer Code is required',
              ]"
              :error-messages="error.customer_code"
              @input="delete error.customer_code"
              v-model="addressDetails.customer_code"
            ></InputField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Customer Name*"
              :rules="[
                (v) =>
                  (!!v && v.trim().length > 0) || 'Customer Name is required',
              ]"
              :error-messages="error.customer_name"
              @input="delete error.customer_name"
              v-model="addressDetails.customer_name"
            ></InputField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <SelectField
              outlined
              hide-details="auto"
              label="Customer Type"
              :itemsList="customerType"
              :error-messages="error.customer_type"
              v-model="addressDetails.customer_type"
              :menu-props="{ offsetY: true }"
              @change="delete error.customer_type"
              :isSearchRequired="false"
              :isClearable="false"
            ></SelectField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact Person"
              :error-messages="error.contact_person"
              v-model="addressDetails.contact_person"
              @input="delete error.contact_person"
            ></InputField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact Number"
              type="number"
              min="0"
              step="1"
              :error-messages="error.contact_number"
              v-model="addressDetails.contact_number"
              :required="addressDetails.customer_type == 'B2C' ? true : false"
              @input="delete error.contact_number"
            ></InputField>
          </v-col>
          <v-col cols="6" md="4" xl="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact email"
              :rules="emailRules"
              :error-messages="error.contact_email"
              v-model="addressDetails.contact_email"
              @input="delete error.contact_email"
            ></InputField>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="6">
            <SelectField
              outlined
              hide-details="auto"
              label="Project*"
              :itemsList="allProjects"
              item-text="text"
              item-value="value"
              :rules="[(v) => !!v || 'Project is required']"
              :error-messages="error.project"
              v-model="addressDetails.project"
              :menu-props="{ offsetY: true }"
              @change="delete error.project"
            ></SelectField>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              ref="customerAddressFormAddress"
              id="customerAddressFormAddress"
              hide-details="auto"
              class="background-white"
              placeholder="Enter Address*"
              :rules="[(v) => !!v || 'Address is required']"
              :error-messages="error.address"
              v-model="addressDetails.address"
              @input="delete error.address"
            ></v-text-field>
          </v-col>
          <v-col cols="3" v-if="addressDetails.coordinates">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Latitude"
              type="number"
              step="0.000001"
              :rules="[(v) => !!v || 'Latitude is required']"
              :error-messages="error.coordinates"
              v-model="addressDetails.coordinates.latitude"
              @input="delete error.coordinates"
            ></InputField>
          </v-col>
          <v-col cols="3" v-if="addressDetails.coordinates">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Longitude"
              type="number"
              step="0.0000001"
              :rules="[(v) => !!v || 'Longitude is required']"
              :error-messages="error.coordinates"
              @input="delete error.coordinates"
              v-model="addressDetails.coordinates.longitude"
            ></InputField>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="6">
            <SwitchField
              label="WhatsApp Notifications"
              v-model="addressDetails.whatsapp_notification"
            ></SwitchField>
          </v-col>
          <v-col cols="6">
            <SwitchField
              label="E-mail Notifications"
              v-model="addressDetails.email_notification"
            ></SwitchField>
          </v-col>
        </v-row>
        <v-row no-gutters class="pt-5">
          <v-col cols="6">
            <span class="text-h6">Time slots</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="addressDetails.from_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <InputField
                  label="From Time*"
                  outlined
                  type="time"
                  class="background-white"
                  hide-details="auto"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  :rules="[(v) => !!v || 'From time is required']"
                  :error-messages="error.from_time"
                  @input="delete error.from_time"
                  v-model="addressDetails.from_time"
                  v-bind="attrs"
                  v-on="on"
                ></InputField>
              </template>
              <v-time-picker
                format="24hr"
                v-if="menu1"
                v-model="addressDetails.from_time"
                full-width
                @change="$refs.menu1.save(addressDetails.from_time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col cols="5">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="addressDetails.to_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <InputField
                  label="To Time*"
                  type="time"
                  outlined
                  class="background-white"
                  hide-details="auto"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  readonly
                  :rules="[
                    (v) => !!v || 'To time is required',
                    (v) =>
                      (v &&
                        v.slice(0, 5) !==
                          addressDetails.from_time.slice(0, 5)) ||
                      'From time and To time not be same',
                  ]"
                  :error-messages="error.to_time"
                  @input="delete error.to_time"
                  v-model="addressDetails.to_time"
                  v-bind="attrs"
                  v-on="on"
                ></InputField>
              </template>
              <v-time-picker
                :min="addressDetails.from_time"
                format="24hr"
                v-if="menu2"
                v-model="addressDetails.to_time"
                full-width
                @change="$refs.menu2.save(addressDetails.to_time)"
              ></v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        :disabled="!isFormVaid"
        class="primary text-uppercase mr-3"
        @click="submit()"
      >
        <span>Submit</span>
      </v-btn></template
    >
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
  },
  props: {
    value: Boolean,
    formType: {
      type: String,
      default: "Add",
    },
  },
  data() {
    return {
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      timeDiff: 1,
      formCount: 0,
      addressDetails: {
        whatsapp_notification: false,
        email_notification: false,
      },
      timeSlots: [],
      fromTime: [],
      menu1: false,
      menu2: false,
      toTime: [],
      allProjects: [],
      radioGroup: "active",
      rcExpiryDate: null,
      drivertags: null,
      vehicletags: null,
      rcExpiryDatePicker: false,
      insuranceExpiryDatePicker: false,
      insuranceExpiryDate: null,
      isFormVaid: false,
      error: {},
      nonFieldError: [],
      customerType: [
        { text: "Business", value: "B2B" },
        { text: "Individual", value: "B2C" },
      ],
      customer_log_id: null,
    };
  },
  watch: {
    addCustomerAddressFormDialog(val) {
      if (val) {
        this.setAutoComplete();
        this.getAllProjects();
      }
    },
  },
  computed: {
    addCustomerAddressFormDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.$refs.customerAddressForm.reset();
      this.addCustomerAddressFormDialog = false;
    },
    getCustomerDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.integration
        .getCustomerLogsObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.formCount++;
          this.addressDetails = res.data;
          this.addressDetails.from_time = res.data.time_slots.from_time;
          this.addressDetails.to_time = res.data.time_slots.to_time;
          this.addressDetails["id"] = res.data.id;
          this.error = res.data.error;
          if (res.data.error.non_field_errors) {
            this.nonFieldError = res.data.error.non_field_errors;
          }
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setAutoComplete() {
      const interval = setInterval(async () => {
        if (
          this.$refs.customerAddressFormAddress &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(interval);
          let input = this.$refs.customerAddressFormAddress.$refs.input;
          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["AE"],
          });
          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat();
            let lon = place.geometry.location.lng();
            setTimeout(() => {
              this.addressDetails.address =
                place.name + ", " + place.formatted_address;
              this.addressDetails.coordinates.latitude = lat;
              this.addressDetails.coordinates.longitude = lon;
            }, 100);
          });
        }
      }, 100);
    },
    submit() {
      this.addressDetails["log_id"] = this.addressDetails.id;
      // this.addressDetails["time_slots"] = this.timeSlots;
      let postData = this.addressDetails;
      this.$api.integration
        .createCustomerLogs(postData)
        .then((result) => {
          this.closeDialog();
          this.$emit("refreshList");
          bus.$emit("showToastMessage", {
            message: "Address Successfully updated!",
            color: "success",
          });
        })
        .catch((err) => {
          if (err.data.non_field_errors) {
            this.nonFieldError = err.data.non_field_errors;
          }
          this.error = err.data;
        });
    },
  },
  mounted() {
    // this.getTagList();
    setTimeout(() => {
      this.$forceUpdate();
    }, 1000);
  },
};
</script>

<style>
</style>