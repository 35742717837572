<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="edit text-white"
          small
          v-bind="attrs"
          v-on="on"
          depressed
          @click="btnClickedHandler"
          v-if="
            userPermissions.itemmaster &&
            userPermissions.itemmaster.change &&
            params &&
            params.data &&
            !params.data.is_success
          "
        >
          <v-icon small class="ma-0">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>
  </div>
</template>


<script>
export default {
  data() {
    return {
      openItemForm: false,
      loader: false,
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  methods: {
    btnClickedHandler() {
      this.params.context.parentComponent.editItem(this.params.data.id);
    },
  },
};
</script>