<template>
  <BaseDialogForm
    v-model="openItemDialog"
    :title="`${formType} Item`"
    @reset="closeDialog()"
  >
    <template #content>
      <v-alert v-if="nonFieldError.length" dense type="error">
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ i }} .</span><span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-form ref="itemForm" v-model="isValid">
        <v-row>
          <v-col cols="6" lg="4">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Item number is required']"
              type="text"
              class="background-white"
              label="Item Number"
              required
              :error-messages="error.item_no"
              @input="error && error.item_no ? delete error.item_no : ''"
              v-model="itemFormDetails.item_no"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              hide-details="auto"
              outlined
              label="Item name"
              required
              class="background-white"
              :rules="[
                (v) => (!!v && v.trim().length > 0) || 'Item Name is required',
              ]"
              :error-messages="error.item_description"
              @input="
                error && error.item_description
                  ? delete error.item_description
                  : ''
              "
              v-model="itemFormDetails.item_description"
            ></InputField>
          </v-col>
          <v-col cols="12" lg="2"></v-col>
          <v-col cols="6" lg="3">
            <SelectField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Storage Type is required']"
              type="number"
              label="Storage Type"
              required
              :itemsList="storageType"
              item-text="title"
              item-value="value"
              :error-messages="error.storage_type"
              @change="
                error && error.storage_type ? delete error.storage_type : ''
              "
              v-model="itemFormDetails.storage_type"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="6" lg="3">
            <SelectField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Unit is required']"
              label="Unit"
              required
              :itemsList="unitType"
              item-text="text"
              item-value="value"
              v-model="itemFormDetails.unit"
              :error-messages="error.unit"
              @change="error && error.unit ? delete error.unit : ''"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Case Factor is required']"
              type="number"
              class="background-white"
              label="Case Factor"
              required
              min="0"
              v-model="itemFormDetails.case_factor"
              :error-messages="error.case_factor"
              @input="
                error && error.case_factor ? delete error.case_factor : ''
              "
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Length is required']"
              step="0.00001"
              type="number"
              class="background-white"
              label="Length"
              required
              min="0.00000"
              v-model="itemFormDetails.length"
              :error-messages="error.length"
              @input="error && error.length ? delete error.length : ''"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Width is required']"
              step="0.00001"
              type="number"
              class="background-white"
              label="Width"
              required
              min="0.00000"
              v-model="itemFormDetails.width"
              :error-messages="error.width"
              @input="error && error.width ? delete error.width : ''"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Height is required']"
              step="0.00001"
              type="number"
              class="background-white"
              label="Height"
              required
              min="0.00000"
              v-model="itemFormDetails.height"
              :error-messages="error.height"
              @input="error && error.height ? delete error.height : ''"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Volume is required']"
              type="number"
              class="background-white"
              label="Volume (CBM)"
              required
              min="0.0000000"
              v-model="itemFormDetails.cbm"
              :error-messages="error.cbm"
              @input="error && error.cbm ? delete error.cbm : ''"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              :rules="[(v) => !!v || 'Weight is required']"
              step="0.0000001"
              type="number"
              class="background-white"
              label="Weight"
              required
              min="0.00000"
              :error-messages="error.weight"
              @input="error && error.weight ? delete error.weight : ''"
              v-model="itemFormDetails.weight"
            ></InputField>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        class="primary text-uppercase mr-3"
        lg="6"
        @click.prevent="submitItemForm()"
        :disabled="!isValid"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        v-if="formType == 'Add'"
        type="reset"
        class="primary text-uppercase"
        lg="6"
        @click="clear()"
      >
        <span>Reset</span>
      </v-btn></template
    >
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
export default {
  components: { BaseDialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      itemFormDetails: {},
      isValid: false,
      error: {},
      nonFieldError: [],
      storageType: [
        { title: "Chilled", value: "Chilled" },
        { title: "Dry", value: "Dry" },
        { title: "Frozen", value: "Frozen" },
      ],
      unitType: [
        {
          text: "KG",
          value: "Kg",
        },
        {
          text: "Case",
          value: "Case",
        },
        {
          text: "Each",
          value: "Each",
        },
      ],
    };
  },

  watch: {
    openItemDialog(val) {
      if (!val) {
        this.clear();
      }
    },
  },

  computed: {
    openItemDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async closeDialog() {
      await this.clear();
      this.openItemDialog = false;
    },
    getItemDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.items
        .getItemObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.itemFormDetails = res.data;
          this.itemFormDetails.storage_type = res.data.verbose_storage_type;
          this.itemFormDetails.unit = res.data.verbose_unit;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.error(err);
        });
    },
    submitItemForm() {
      bus.$emit("showLoader", true);
      if (this.formType == "Add") {
        let payload = {
          isBulkupload: true,
          ...this.itemFormDetails,
        };

        this.$api.items
          .createItemObject(payload)
          .then((result) => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Item entry created!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      } else {
        let payload = {
          id: this.itemFormDetails.id,
          data: this.itemFormDetails,
        };
        this.$api.items
          .updateItemObject(payload)
          .then((result) => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            this.$emit("refreshList");
            bus.$emit("showToastMessage", {
              message: "Item updated successfully!",
              color: "success",
            });
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      }
    },
    clear() {
      this.itemFormDetails = {
        case_factor: null,
        cbm: null,
        height: null,
        item_description: null,
        item_no: null,
        length: null,
        storage_type: null,
        unit: null,
        weight: null,
        width: null,
      };
      if (this.$refs.itemForm) {
        this.$refs.itemForm.reset();
        this.$refs.itemForm.resetValidation();
      }
    },
  },
};
</script>

<style>
</style>