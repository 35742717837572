var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","width":"100%","overflow-x":"hidden","overflow-y":"scroll","position":"absolute"},on:{"scroll":function($event){return _vm.reSetColumn()}}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"report-bottom-box-shadow px-8",attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-uppercase pt-3"},[_vm._v("Reports")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"px-0 pt-4"},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.filter.start_date,"transition":"scale-transition","nudge-right":40,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "start_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "start_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                          _vm.generatedReportType != 'trip_volume_utilization'
                        )?_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start Date*","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.filter.start_date),callback:function ($$v) {_vm.$set(_vm.filter, "start_date", $$v)},expression:"filter.start_date"}},'v-text-field',attrs,false),on)):_vm._e()]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"max":_vm.nowDate,"no-title":"","scrollable":""},on:{"change":_vm.dateValidator,"click:date":function($event){return _vm.$refs.menu.save(_vm.filter.start_date)}},model:{value:(_vm.filter.start_date),callback:function ($$v) {_vm.$set(_vm.filter, "start_date", $$v)},expression:"filter.start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.filter.end_date,"transition":"scale-transition","nudge-right":40,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "end_date", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "end_date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                          _vm.generatedReportType != 'trip_volume_utilization'
                        )?_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date*","prepend-inner-icon":"mdi-calendar","readonly":"","dense":"","outlined":""},model:{value:(_vm.filter.end_date),callback:function ($$v) {_vm.$set(_vm.filter, "end_date", $$v)},expression:"filter.end_date"}},'v-text-field',attrs,false),on)):_vm._e()]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.todayDate},on:{"change":_vm.dateValidator,"click:date":function($event){return _vm.$refs.menu2.save(_vm.filter.end_date)}},model:{value:(_vm.filter.end_date),callback:function ($$v) {_vm.$set(_vm.filter, "end_date", $$v)},expression:"filter.end_date"}})],1)],1),(_vm.generatedReportType != 'trip_volume_utilization')?_c('v-col',{attrs:{"cols":"2"}},[_c('SelectField',{attrs:{"multiple":true,"dense":"","hide-details":"auto","outlined":"","label":"Select Project","items-list":_vm.allProjects,"menu-props":{ offsetY: true }},model:{value:(_vm.filter.project),callback:function ($$v) {_vm.$set(_vm.filter, "project", $$v)},expression:"filter.project"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"return-value":_vm.filter.date,"transition":"scale-transition","nudge-right":40,"offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "date", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "date", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(
                          _vm.generatedReportType == 'trip_volume_utilization'
                        )?_c('v-text-field',_vm._g(_vm._b({attrs:{"label":" Select Date*","prepend-inner-icon":"mdi-calendar","readonly":"","dense":"","outlined":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}},'v-text-field',attrs,false),on)):_vm._e()]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":_vm.dateValidator,"click:date":function($event){return _vm.$refs.menu3.save(_vm.filter.date)}},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[(_vm.generatedReportType == 'trip_volume_utilization')?_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.filter.date},on:{"click":function($event){return _vm.getReportType()}}},[_vm._v(" Get report ")]):_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.filter.start_date || !_vm.filter.end_date},on:{"click":function($event){return _vm.getReportType()}}},[_vm._v(" Get report ")])],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pa-0 ma-0",staticStyle:{"height":"85%"},attrs:{"cols":"12"}},[_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.generatedReportType == 'driver' ||
            _vm.generatedReportType == 'vehicle' ||
            _vm.generatedReportType == 'fleet_utilization' ||
            _vm.generatedReportType == 'driver_expense' ||
            _vm.generatedReportType == 'trip_volume_utilization'
          ),expression:"\n            generatedReportType == 'driver' ||\n            generatedReportType == 'vehicle' ||\n            generatedReportType == 'fleet_utilization' ||\n            generatedReportType == 'driver_expense' ||\n            generatedReportType == 'trip_volume_utilization'\n          "}],staticClass:"pa-5"},[_c('div',{staticClass:"set-grid"},[(
                _vm.generatedReportType == 'driver' ||
                _vm.generatedReportType == 'vehicle'
              )?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pb-3",attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-capitalize"},[_vm._v(" Top 10 "+_vm._s(_vm.generatedReportType)+" ")])]),_c('v-col',{staticClass:"d-flex align-center justify-end text-right",attrs:{"cols":"6"}}),_c('v-col',{staticStyle:{"overflow-y":"scroll","height":"50%"},attrs:{"cols":"12"}},_vm._l((_vm.listData),function(item,index){return _c('v-card',{key:index,staticClass:"pa-3 mt-3",staticStyle:{"border":"1px solid #8080803b","border-radius":"0px"},attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('h5',[_vm._v(_vm._s(index + 1))])]),_c('v-col',{attrs:{"cols":"6"}},[(item.driver_name)?_c('div',[_c('h5',[_vm._v(_vm._s(item.driver_name))]),_c('h5',{staticClass:"grey--text"})]):_vm._e(),(item.vehicle_plate_no)?_c('div',[_c('h5',[_vm._v(_vm._s(item.vehicle_plate_no))]),_c('h5',{staticClass:"grey--text"})]):_vm._e()]),_c('v-col',{attrs:{"cols":"5"}},[(item.total_trips)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-caption mr-3"},[_vm._v("Total Trip")]),_c('h5',{staticClass:"grey--text font-weight-bolder"},[_vm._v(" "+_vm._s(item.total_trips)+" ")])]):_vm._e(),(item.trip_count)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-caption mr-3"},[_vm._v("Total Trip")]),_c('h5',{staticClass:"grey--text font-weight-bolder"},[_vm._v(" "+_vm._s(item.trip_count)+" ")])]):_vm._e()])],1)],1)}),1)],1)],1):_vm._e(),(_vm.generatedReportType == 'driver_expense')?_c('div',{staticClass:"px-3"},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"5"}},[(_vm.generatedReportType == 'driver_expense')?_c('h4',[_vm._v(" Driver Expenses Chart ")]):_vm._e()]),_c('v-card',{staticClass:"mt-1 elevation-0",style:({ height: 'calc(75vh - 270px)', width: '450px' }),attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"py-lg-12"},[(_vm.pieChatVisible)?_c('PieChart',{attrs:{"data":{
                        labels: this.pieChartData.labels,
                        datasets: [
                          {
                            backgroundColor: [
                              '#9b182f',
                              '#90CAF9',
                              '#607D8B',
                              '#fab079',
                            ],
                            data: this.pieChartData.data,
                          },
                        ],
                      },"options":_vm.pieChartOptions,"height":320}}):_vm._e()],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"px-5"},[(
                  _vm.generatedReportType == 'driver' ||
                  _vm.generatedReportType == 'vehicle' ||
                  _vm.generatedReportType == 'driver_expense'
                )?_c('div',[_c('v-row',{staticClass:"pb-4"},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.generatedReportType == 'driver_expense')?_c('h4',[_vm._v(" Driver Expense Report ")]):_c('h4',[_vm._v("Document Expiry Report")])]),(_vm.generatedReportType == 'driver_expense')?_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"disabled":!_vm.filter.start_date || !_vm.filter.end_date,"tile":"","depressed":"","text":""},on:{"click":function($event){return _vm.getReportDownload(true)}}},[_c('h6',{staticClass:"text-caption mr-2"},[_vm._v("Download")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1)],1):_vm._e()],1),_c('AgGridVue',{staticClass:"ag-theme-alpine cf-table",staticStyle:{"width":"100%","height":"calc(75vh - 270px)"},attrs:{"grid-options":_vm.gridOptions1,"column-defs":_vm.documentHeader,"row-data":_vm.documentDataList}})],1):_vm._e()])])]),_c('div',{staticClass:"pa-5",staticStyle:{"height":"100%","overflow-y":"scroll"}},[_c('v-row',[(Object.keys(_vm.reportCountData).length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"pb-4 text-capitalize"},[_vm._v(" "+_vm._s(_vm.generatedReportType.replaceAll("_", " "))+" Statistics ")]),_c('div',{staticStyle:{"display":"grid","gap":"10px"},style:({
                  gridTemplateColumns: `repeat( ${
                    Object.keys(_vm.reportCountData).length
                  } , calc(100% / ${
                    Object.keys(_vm.reportCountData).length + 1
                  }))`,
                })},_vm._l((_vm.reportCountData),function(value,key,index){return _c('div',{key:index,staticClass:"set-border pa-3",attrs:{"cols":"2"}},[_c('h2',{staticClass:"font-weight-bolder primary--text text-center"},[_vm._v(" "+_vm._s(value ? value : 0)+" ")]),_c('h5',{staticClass:"grey--text pt-1 font-weight-bolder text-capitalize text-center"},[_vm._v(" "+_vm._s(key ? key.replaceAll("_", " ") : "")+" ")])])}),0)]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[(_vm.openOrderStatisticChart)?_c('LineChart',{attrs:{"datasets":_vm.OrderStatisticChart,"options":_vm.barOptions}}):_vm._e()],1),(_vm.generatedReportType != 'driver_expense')?_c('v-col',{staticClass:"px-8 pt-8",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"set-border"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[_c('h4',{staticClass:"mr-2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.generatedReportType.replaceAll("_", " "))+" Report ")])]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"4"}}),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"disabled":!_vm.filter.start_date || !_vm.filter.end_date,"tile":"","depressed":"","text":""},on:{"click":function($event){return _vm.getReportDownload(false)}}},[_c('h6',{staticClass:"text-caption mr-2"},[_vm._v("Download")]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1)],1)],1),_c('v-row',{staticClass:"set-border"},[_c('v-col',{attrs:{"cols":"12"}},[_c('AgGridVue',{staticClass:"ag-theme-alpine cf-table",staticStyle:{"width":"100%","height":"550px"},attrs:{"grid-options":_vm.gridOptions,"column-defs":_vm.tableHeader,"row-data":_vm.tableData}})],1)],1)],1):_vm._e()],1)],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }