<template>
  <BaseDialogForm
    v-model="B2COrderFormDialog"
    persistent
    scrollable
    max-width="70%"
    @keydown.esc="closeDialog()"
    @reset="closeDialog()"
    title="Create Order"
  >
    <template #content>
      <v-alert v-if="nonFieldError.length" dense type="error">
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-form
        v-if="B2COrderFormDialog"
        id="B2COrderForm"
        ref="B2COrderForm"
        v-model="isValid"
        class="pt-3"
        :key="formCount"
      >
        <v-row>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="SO Number*"
              name="reference_number"
              :rules="[
                (v) => (!!v && v.trim().length > 0) || 'SO Number is required',
              ]"
              v-model="orderFormDetails.reference_number"
              :error-messages="error.reference_number"
              @input="syncData($event, 'reference_number')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <v-menu
              ref="orderDateMenu"
              v-model="DatePickerMenu1"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              max-width="290px"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <InputField
                  label="Order Date*"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  hide-details="auto"
                  name="execution_date"
                  v-bind="attrs"
                  v-on="on"
                  class="background-white"
                  :error-messages="error.execution_date"
                  :rules="[(v) => !!v || 'Order date is required']"
                  v-model="orderFormDetails.execution_date"
                  @input="syncData($event, 'execution_date')"
                  :menu-props="{ offsetY: true }"
                ></InputField>
              </template>
              <v-date-picker
                no-title
                scrollable
                reactive
                :min="nowDate"
                v-model="orderFormDetails.execution_date"
                @click:date="
                  syncData($event, 'execution_date'),
                    $refs.orderDateMenu.save(date)
                "
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <SelectField
              outlined
              hide-details="auto"
              label="Project*"
              :itemsList="allProjects"
              item-text="text"
              item-value="value"
              :rules="[(v) => !!v || 'Project is required']"
              :error-messages="error.project"
              v-model="orderFormDetails.project"
              :menu-props="{ offsetY: true }"
              @change="syncData($event, 'project')"
            ></SelectField>
          </v-col>
        </v-row>
        <hr class="my-4" />
        <v-row>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Customer name*"
              name="customer_name"
              :error-messages="error.customer_name"
              :rules="[(v) => !!v || 'Customer name is required']"
              v-model="orderFormDetails.customer_name"
              @input="syncData($event, 'customer_name')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              type="number"
              class="background-white"
              label="Contact number*"
              name="contact_number"
              min="0"
              :rules="[(v) => !!v || 'Contact Number is required']"
              :error-messages="error.contact_number"
              v-model="orderFormDetails.contact_number"
              @input="syncData($event, 'contact_number')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact person"
              name="contact_person"
              :error-messages="error.contact_person"
              v-model="orderFormDetails.contact_person"
              @input="syncData($event, 'contact_person')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="3">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Contact email"
              name="contact_email"
              :rules="emailRules"
              :error-messages="error.contact_email"
              v-model="orderFormDetails.contact_email"
              @input="syncData($event, 'contact_email')"
            ></InputField>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              ref="customerAddressFormAddress"
              hide-details="auto"
              class="background-white"
              placeholder="Enter Address*"
              :rules="[
                (v) => (!!v && v.trim().length > 0) || 'Address is required',
              ]"
              :error-messages="error.address"
              v-model="orderFormDetails.address"
              @input="syncData($event, 'address')"
            ></v-text-field>
          </v-col>
          <v-col cols="3" v-if="orderFormDetails.coordinates">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Latitude"
              type="number"
              step="0.000001"
              :rules="[(v) => !!v || 'Latitude is required']"
              :error-messages="error.coordinates"
              v-model="orderFormDetails.coordinates.latitude"
              @input="syncData($event, 'coordinates', 'latitude')"
            ></InputField>
          </v-col>
          <v-col cols="3" v-if="orderFormDetails.coordinates">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Longitude"
              type="number"
              step="0.0000001"
              :rules="[(v) => !!v || 'Longitude is required']"
              :error-messages="error.coordinates"
              v-model="orderFormDetails.coordinates.longitude"
              @input="syncData($event, 'coordinates', 'longitude')"
            ></InputField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-4">
            <span class="font-weight-bold text-subtitle-1"> Add Item </span>
            <hr class="my-4" />
          </v-col>

          <v-col cols="12" class="px-6">
            <v-row
              v-for="(item, index) in orderFormDetails.order_items"
              :key="index"
            >
              <v-col cols="3" class="pl-0">
                <SelectField
                  :itemsList="itemCode"
                  label="Item Code"
                  v-model="item.item_no"
                  item-text="item_no"
                  item-value="item_no"
                  required
                  :return-object="true"
                  :rules="[(val) => !!val || `Item Code is required`]"
                  :error-messages="
                    error &&
                    error.order_items &&
                    error.order_items[index] &&
                    error.order_items[index].item
                      ? error.order_items[index].item
                      : []
                  "
                  @change="
                    error &&
                    error.order_items &&
                    error.order_items[index] &&
                    error.order_items[index].item
                      ? (delete error.order_items[index].item,
                        delete error.order_items[index].unit)
                      : [];
                    setItemCode($event, index);
                  "
                />
              </v-col>
              <v-col cols="3">
                <InputField
                  outlined
                  hide-details="auto"
                  dense
                  label="Item Name*"
                  :rules="[(v) => !!v || 'Item Name is required']"
                  :error-messages="error.item_description"
                  v-model="item.item_description"
                ></InputField>
              </v-col>
              <v-col cols="2">
                <InputField
                  type="number"
                  min="1"
                  outlined
                  hide-details="auto"
                  dense
                  label="Quantity*"
                  :error-messages="error.Quantity"
                  :rules="[
                    (v) => !!v || 'Quantity is required',
                    (v) => v > 0 || 'Quantity can not be 0',
                  ]"
                  v-model="item.quantity"
                ></InputField>
              </v-col>
              <v-col cols="2" class="d-flex justify-center">
                <div class="pr-3">
                  <v-btn
                    :small="$vuetify.breakpoint.xl"
                    :x-small="$vuetify.breakpoint.lgAndDown"
                    v-if="orderFormDetails.order_items.length > 1"
                    class="light_grey elevation-0 py-5"
                    @click="removeItem(index)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </div>
                <v-btn
                  :small="$vuetify.breakpoint.xl"
                  :x-small="$vuetify.breakpoint.lgAndDown"
                  class="primary elevation-0 py-5"
                  @click="addItem()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        :disabled="!isValid"
        width="9%"
        class="primary"
        @click.prevent="submitOrderForm()"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'add'"
        width="9%"
        class="primary mr-4"
        @click="clear()"
      >
        <span>Reset</span>
      </v-btn>
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
  },
  props: {
    value: Boolean,
    formType: {
      type: String,
      default: "Add",
    },
  },
  data() {
    return {
      emailRules: [
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      // variables for autocomplete
      longitude: null,
      latitude: null,
      // variables for widgets
      menuFromTime: false,
      menuToTime: false,
      nonFieldError: [],
      isLoading: false,
      isLoadingItem: false,
      itemCode: [],
      formCount: 0,
      order_log_id: null,
      error: {
        customer_code: [],
      },
      customer_data: {},
      searchItem: [],
      customerType: [
        { text: "Business", value: "B2B" },
        { text: "Individual", value: "B2C" },
      ],
      selectedItemIndex: 0,
      DatePickerMenu1: false,
      isValid: false,
      date: null,
      nowDate: new Date().toISOString().slice(0, 10),
      executed: true,
      allProjects: [],
      orderFormDetails: {
        order_items: [{}],
      },
    };
  },
  watch: {
    B2COrderFormDialog(val) {
      if (val) {
        this.getAllProjects();
        this.getAllItems();
        this.autoCompleteAddress();
      }
    },
  },
  computed: {
    B2COrderFormDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    getAllItems(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.items
        .getItemList(params)
        .then((res) => {
          this.itemCode = res.data.results;
        })
        .catch((err) => {});
    },
    getOrderDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.integration
        .getB2COrderObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.formCount++;
          this.orderFormDetails = res.data;

          if (res.data.error.non_field_errors) {
            this.nonFieldError = res.data.error.non_field_errors;
          }
          this.error = res.data.error;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
        });
    },
    setItemCode(e, index) {
      let itemNo = this.itemCode.find((v) => v.item_no == e.item_no).item_no;
      let itemDescription = this.itemCode.find(
        (v) => v.item_no == e.item_no
      ).item_description;

      this.orderFormDetails.order_items[index].item_no = itemNo;
      this.orderFormDetails.order_items[index].item_description =
        itemDescription;
    },
    async closeDialog() {
      this.B2COrderFormDialog = false;
      await this.clear();
    },
    syncData(input_value, key, subKey) {
      if (this.error[key]) {
        this.error[key] = null;
        delete this.error[key];
      }
      if (key == "address" && this.executed) {
        this.executed = false;
        this.autoCompleteAddress();
      }
      // }
    },
    addItem() {
      this.orderFormDetails.order_items.push({});
      setTimeout(() => {
        let elmnt = document.getElementById("B2COrderForm");
        elmnt.scrollIntoView({ block: "end", behavior: "smooth" });
      }, 300);
    },
    removeItem(index) {
      this.orderFormDetails.order_items.splice(index, 1);
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    submitOrderForm() {
      let postData = this.orderFormDetails;
      postData["log_id"] = this.orderFormDetails.id;
      this.$api.integration
        .createB2CLogs(postData)
        .then((result) => {
          this.closeDialog();
          this.$emit("refreshList");
          bus.$emit("showToastMessage", {
            message: "Order successfully Added!",
            color: "success",
          });
        })
        .catch((err) => {
          if (err.data.non_field_errors) {
            this.nonFieldError = err.data.non_field_errors;
          }
          this.error = err.data;
        });
    },
    clear() {
      this.error = {};
      this.nonFieldError = [];
      this.executed = true;
    },
    autoCompleteAddress() {
      const interval = setInterval(async () => {
        if (
          this.$refs.customerAddressFormAddress &&
          window.google &&
          window.google.maps
        ) {
          clearInterval(interval);
          let input = this.$refs.customerAddressFormAddress.$refs.input;

          this.autocomplete = new window.google.maps.places.Autocomplete(input);
          this.autocomplete.setComponentRestrictions({
            country: ["AE"],
          });
          this.autocomplete.addListener("place_changed", () => {
            let place = this.autocomplete.getPlace();
            let lat = place.geometry.location.lat();
            let lon = place.geometry.location.lng();

            setTimeout(() => {
              this.orderFormDetails.address =
                place.name + ", " + place.formatted_address;
              this.orderFormDetails.coordinates.latitude = lat;
              this.orderFormDetails.coordinates.longitude = lon;
            }, 100);
          });
        }
      }, 100);
    },
  },
};
</script>

<style>
</style>