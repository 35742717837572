import TripIndex from "@/pages/admin/trip/index.vue";
import TripCreate from "@/pages/admin/trip/create.vue";
import TripEdit from "@/pages/admin/trip/edit.vue";
import TripMarkComplete from "@/pages/admin/trip/mark_as_complete/index.vue";

const Trips = [
  {
    path: "trip",
    name: "trip",
    component: TripIndex,
  },
  {
    path: "trip/create",
    component: TripCreate,
  },
  {
    path: "trip/edit/:id",
    component: TripEdit,
  },
  {
    path: "trip/mark_as_complete/:id",
    component: TripMarkComplete,
  },
];

export default Trips;
