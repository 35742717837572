import StatusesIndex from "../pages/admin/statuses/index.vue";

const Statuses = [
  {
    path: "statuses",
    name: "statuses",
    component: StatusesIndex,
  },
];

export default Statuses;
