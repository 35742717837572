import { customerAddress, items, orders, trips } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  bulkUploadCustomerAddress(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${customerAddress.base}exists/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadItems(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${items.base}exists/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadOrders(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}bulk_upload`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateOrders(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}bulk_update`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadB2COrders(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orders.base}bulk_upload_b2c`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUploadTemperatureSheet(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${trips.base}bulk_upload_temperature_sheet/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
