import ItemsIndex from "../pages/admin/items/index.vue";

const Items = [
  {
    path: "items",
    name: "items",
    component: ItemsIndex,
  },
];

export default Items;
