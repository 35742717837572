<template>
   <GeneratedReport generatedReportType="fleet_utilization" />
</template>

<script>
import GeneratedReport from "@/components/reports/GeneratedReport.vue";
export default {
    name:'Fleet-Utilization-Report',
    components  : {
        GeneratedReport
    }
}
</script>