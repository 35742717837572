<template>
  <v-dialog v-model="showLogsDialog" max-width="60vw">
    <v-card>
      <v-card-title class="primary white--text"
        >{{ title }}
        <v-spacer></v-spacer>
        <v-btn depressed icon @click="showLogsDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <Logs
          v-if="logsArray && logsArray.length && logsArray.length > 0"
          :logs="logsArray"
        />
        <h5 v-else class="d-flex justify-center text-h5 mt-2">No data found ...</h5>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Logs from "./Logs.vue";
export default {
  name: "logDialog",
  components: { Logs },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Logs",
    },
    logsArray: {
      type: Array,
    },
  },
  computed: {
    showLogsDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style></style>
