<template>
  <BaseDialogForm
    v-model="openZoneDialog"
    :title="`${formType} Zone`"
    :fullscreen="true"
    transition="dialog-bottom-transition"
    maxWidth="60vw"
    :isStickTitleRequired="true"
  >
    <template #content>
      <v-form
        v-if="openZoneDialog"
        v-model="isValid"
        ref="userForm"
        id="userForm"
      >
        <v-row class="d-flex justify-end pb-4 pr-0">
          <v-col cols="12" md="2">
            <SelectField
              :items-list="allProjects"
              :rules="[
                (v) => (v != null && v.length > 0) || 'Project is required',
              ]"
              outlined
              hide-details="auto"
              dense
              label="Project*"
              v-model="zoneDetails.project"
              :disabled="formType == 'Edit'"
              @change="getSelectedProjectsZone($event)"
              @input="delete error.project"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="12" md="2">
            <InputField
              :rules="[(v) => !!v || 'Zone name is required']"
              outlined
              hide-details="auto"
              dense
              class="background-white"
              label="Zone Name*"
              :error-messages="error && error.zone_name ? error.zone_name : ''"
              v-model="zoneDetails.zone_name"
              @input="error && error.zone_name ? delete error.zone_name : ''"
            ></InputField>
          </v-col>
          <v-col cols="12" md="3">
            <InputField
              :rules="[
                (v) => !!v || 'Description is required',
                (v) =>
                  (!!v && v.length < 100) || 'Can not be more than 100 words',
              ]"
              outlined
              hide-details="auto"
              dense
              class="background-white"
              label="Description*"
              :error-messages="error && error.zone_desc ? error.zone_desc : ''"
              v-model="zoneDetails.zone_desc"
              @input="error && error.zone_desc ? delete error.zone_desc : ''"
            ></InputField>
          </v-col>
          <div class="d-flex align-center text-xs-center pa-0 ma-0">
            <v-btn
              type="submit"
              :disabled="!isValid"
              class="primary text-uppercase d-flex justify-center align-center"
              @click.prevent="submitZoneForm()"
            >
              <span>submit</span>
            </v-btn>
          </div>
        </v-row>

        <v-row no-gutters class="">
          <v-col cols="12" class="ma-0 cf-zone-map">
            <Gmap
              mapWidth="100%"
              mapHeight="80vh"
              :isDrawable="true"
              ref="ZoneMap"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import Gmap from "@/components/common/Gmap.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
    Gmap,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      zoneDetails: {},
      error: {},
      nonFieldError: [],
      geofence: {},
      isValid: false,
      allProjects: [],
    };
  },
  watch: {
    openZoneDialog(val) {
      if (val) {
        this.clear();
        this.getAllProjects();
      }
    },
  },
  computed: {
    openZoneDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getgeofenceData(data) {
      this.geofence = data;
    },
    closeDialog() {
      this.openZoneDialog = false;
      this.clear();
    },

    loadServiceableArea(coordinates) {
      let interval = setInterval(() => {
        if (this.$refs.ZoneMap) {
          clearInterval(interval);
          this.$refs.ZoneMap.initMap();
          this.$refs.ZoneMap.loadDisabledMultiPolygon(coordinates);
        }
      }, 100);
    },

    setEditZoneDetails(path, remaining_zones, coordinates) {
      let interval = setInterval(() => {
        if (this.$refs.ZoneMap) {
          clearInterval(interval);
          this.$refs.ZoneMap.loadRoutes(remaining_zones);
          this.$refs.ZoneMap.setEditablePolygon(path, coordinates);
        }
      }, 500);
    },

    getZoneDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.zone
        .getZonesDetails(id)
        .then((response) => {
          this.zoneDetails = response.data;
          this.zoneDetails.project = response.data.project_id;
          let path = [];
          response.data.geofence.coordinates[0].forEach((element) => {
            path.push({ lat: element[1], lng: element[0] });
          });

          if (
            response &&
            response.data &&
            response.data &&
            response.data.remaining_zones &&
            response.data.geofence &&
            response.data.geofence.coordinates &&
            response.data.geofence.coordinates.length > 0
          ) {
            if (this.$refs && this.$refs.ZoneMap) {
              if (
                response &&
                response.data &&
                response.data.remaining_zones &&
                response.data.remaining_zones.zones
              ) {
                let FeatureArray = JSON.parse(
                  JSON.stringify(response.data.remaining_zones.zones)
                );
                let newZone = FeatureArray.features.map((item) => {
                  item.properties.color = "#3498eb";
                  return item;
                });
                this.$refs.ZoneMap.loadArea({
                  type: "FeatureCollection",
                  features: newZone,
                });
              }
            }
            setTimeout(() => {
              this.setEditZoneDetails(
                path,
                null,
                response.data.geofence.coordinates[0]
              );
            }, 100);
          }

          this.getSelectedProjectsZone(this.zoneDetails.project);
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.log(err);
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Couln't fetch data",
            color: "error",
          });
        });
    },
    getSelectedProjectsZone(value) {
      bus.$emit("showLoader", true);
      this.$api.zone
        .getSelectProjectZones({ project_id: value })
        .then((result) => {
          bus.$emit("showLoader", false);
          setTimeout(() => {
            if (this.formType == "Add") {
              if (
                result &&
                result.data &&
                result.data.serviceable_area &&
                result.data.serviceable_area.coordinates &&
                result.data.serviceable_area.coordinates.length > 0 &&
                result.data.zones
              ) {
                let FeatureArray = JSON.parse(
                  JSON.stringify(result.data.zones)
                );
                let newZone = FeatureArray.features.map((item) => {
                  item.properties.color = "#3498eb";
                  return item;
                });
                this.$refs.ZoneMap.loadArea({
                  type: "FeatureCollection",
                  features: newZone,
                });
                this.loadServiceableArea(
                  result.data.serviceable_area.coordinates
                );
              } else {
                this.$refs.ZoneMap.clearGeoJson();
                this.$refs.ZoneMap.clearMarker();
                this.$refs.ZoneMap.initMap();
                bus.$emit("showToastMessage", {
                  message: "No serviceable area for the selected project",
                  color: "error",
                });
              }
            } else {
              if (
                result &&
                result.data &&
                result.data.serviceable_area &&
                result.data.serviceable_area.coordinates &&
                result.data.serviceable_area.coordinates.length > 0
              ) {
                this.loadServiceableArea(
                  result.data.serviceable_area.coordinates
                );
              } else {
                this.$refs.ZoneMap.clearGeoJson();
                this.$refs.ZoneMap.clearMarker();
                this.$refs.ZoneMap.initMap();
                bus.$emit("showToastMessage", {
                  message: "No serviceable area for the selected project",
                  color: "error",
                });
              }
            }
          }, 100);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          if (err.response.data.message) {
            bus.$emit("showToastMessage", {
              message: err.response.data.message,
              color: "error",
            });
          } else {
            bus.$emit("showToastMessage", {
              message: "Something went wrong",
              color: "error",
            });
          }
        });
    },

    submitZoneForm() {
      let getData = this.$refs.ZoneMap.convertToGeoJSON();
      if (!getData) {
        bus.$emit("showToastMessage", {
          message: "Please draw your GeoFence",
          color: "error",
        });
        return false;
      }
      let newObject = { ...this.zoneDetails };
      newObject.geofence = getData.geometry;
      bus.$emit("showLoader", true);
      if (this.formType == "Add") {
        let newObject = { ...this.zoneDetails };
        newObject.geofence = getData.geometry;
        this.$api.zone
          .createZoneObject(newObject)
          .then((result) => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Zone Added successfully",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      } else {
        this.$api.zone
          .updateZoneObject(newObject)
          .then((result) => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Zone Updated successfully",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      }
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    clear() {
      this.zoneDetails = {
        geofence: {},
        id: null,
        project: null,
        zone_desc: null,
        zone_name: null,
      };
    },
  },
};
</script>

