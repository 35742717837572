<template>
  <BaseDialogForm
    v-model="openVehicleDialog"
    :title="`${formType} Vehicle`"
    maxWidth="60vw"
    @reset="resetForm"
  >
    <template #content>
      <v-alert v-if="nonFieldError.length" dense type="error">
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ i }} .</span><span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-form
        v-if="openVehicleDialog"
        v-model="isValid"
        ref="vehicleForm"
        id="vehicleForm"
      >
        <v-row>
          <v-col cols="6" lg="4">
            <InputField
              outlined
              :rules="[(v) => !!v || 'Vehicle number is required']"
              name="vehicle_plate_no"
              :error-messages="error.vehicle_plate_no"
              v-model="vehicleFormDetails.vehicle_plate_no"
              hide-details="auto"
              label="Vehicle number*"
              class="background-white"
              @input="syncData($event, 'vehicle_plate_no')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <SelectField
              outlined
              :rules="[(v) => !!v || 'Project is required']"
              name="project"
              :error-messages="error.project"
              :items-list="allProjects"
              hide-details="auto"
              label="Select Project*"
              v-model="vehicleFormDetails.project"
              @change="syncData($event, 'project')"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="6" lg="4">
            <SelectField
              outlined
              :rules="[(v) => !!v || 'Fuel type is required']"
              name="fuel_type"
              :error-messages="error.fuel_type"
              hide-details="auto"
              label="Fuel Type*"
              :items-list="fuelType"
              item-text="title"
              item-value="value"
              v-model="vehicleFormDetails.fuel_type"
              @change="syncData($event, 'fuel_type')"
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              type="number"
              name="tonnage_capacity"
              :error-messages="error.tonnage_capacity"
              :rules="[(v) => !!v || 'Tonnage capacity is required']"
              step="0.01"
              min="0"
              outlined
              hide-details="auto"
              label="Tonnage Capacity*"
              class="background-white"
              @input="syncData($event, 'tonnage_capacity')"
              v-model="vehicleFormDetails.tonnage_capacity"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              outlined
              name="cbm_capacity"
              :error-messages="error.cbm_capacity"
              step="0.01"
              min="0"
              :rules="[(v) => !!v || 'CBM capacity is required']"
              type="number"
              hide-details="auto"
              label="CBM Capacity*"
              class="background-white"
              v-model="vehicleFormDetails.cbm_capacity"
              @input="syncData($event, 'cbm_capacity')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              outlined
              name="box_capacity"
              :error-messages="error.box_capacity"
              type="number"
              hide-details="auto"
              label="Box Capacity"
              class="background-white"
              max="999"
              v-model="vehicleFormDetails.box_capacity"
              @input="syncData($event, 'box_capacity')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              :rules="[(v) => !!v || 'Vehicle Permits is required']"
              outlined
              :error-messages="error.permits"
              name="permits"
              hide-details="auto"
              label="Permits*"
              class="background-white"
              v-model="vehicleFormDetails.permits"
              @input="syncData($event, 'permits')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              outlined
              :rules="[(v) => !!v || 'Insurance number is required']"
              name="insurance_policy_number"
              hide-details="auto"
              label="Insurance number*"
              :error-messages="error.insurance_policy_number"
              v-model="vehicleFormDetails.insurance_policy_number"
              @input="syncData($event, 'insurance_policy_number')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <v-menu
              ref="insuranceExpiryDatePicker"
              v-model="insuranceExpiryDatePicker"
              :close-on-content-click="false"
              :return-value.sync="insuranceExpiryDate"
              transition="scale-transition"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <InputField
                  name="insurance_expiry_date"
                  :error-messages="error.insurance_expiry_date"
                  label="Insurance Expiry Date"
                  hide-details="auto"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  v-model="vehicleFormDetails.insurance_expiry_date"
                  @input="syncData($event, 'insurance_expiry_date')"
                ></InputField>
              </template>
              <v-date-picker
                no-title
                reactive
                v-model="vehicleFormDetails.insurance_expiry_date"
                scrollable
                :min="nowDate"
                @change="
                  syncData($event, 'insurance_expiry_date'),
                    $refs.insuranceExpiryDatePicker.save(insuranceExpiryDate)
                "
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              outlined
              name="insurance_type"
              :error-messages="error.insurance_type"
              hide-details="auto"
              label="Insurance Type"
              v-model="vehicleFormDetails.insurance_type"
              @input="syncData($event, 'insurance_type')"
            ></InputField
          ></v-col>
          <v-col cols="6" lg="4">
            <InputField
              outlined
              :error-messages="error.rc_number"
              :rules="[(v) => !!v || 'RC number is required']"
              name="rc_number"
              hide-details="auto"
              label="RC number*"
              v-model="vehicleFormDetails.rc_number"
              @input="syncData($event, 'rc_number')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <v-menu
              ref="rcExpiryDatePicker"
              v-model="rcExpiryDatePicker"
              :close-on-content-click="false"
              :return-value.sync="rcExpiryDate"
              transition="scale-transition"
              min-width="auto"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <InputField
                  :rules="[(v) => !!v || 'RC expiry date is required']"
                  label="RC Expiry Date*"
                  name="rc_expiry_date"
                  :error-messages="error.rc_expiry_date"
                  hide-details="auto"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  v-model="vehicleFormDetails.rc_expiry_date"
                  @input="syncData($event, 'rc_expiry_date')"
                ></InputField>
              </template>
              <v-date-picker
                v-model="vehicleFormDetails.rc_expiry_date"
                :min="nowDate"
                no-title
                scrollable
                @change="
                  syncData($event, 'rc_expiry_date'),
                    $refs.rcExpiryDatePicker.save(rcExpiryDate)
                "
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              :rules="[
                (v) => !!v || 'Vehicle manufacturing company is required',
              ]"
              outlined
              :error-messages="error.vehicle_make"
              name="vehicle_make"
              hide-details="auto"
              label="Vehicle Make*"
              class="background-white"
              v-model="vehicleFormDetails.vehicle_make"
              @input="syncData($event, 'vehicle_make')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              :rules="[(v) => !!v || 'Vehicle model is required']"
              outlined
              :error-messages="error.vehicle_model"
              name="vehicle_model"
              hide-details="auto"
              label="Vehicle Model*"
              class="background-white"
              v-model="vehicleFormDetails.vehicle_model"
              @input="syncData($event, 'vehicle_model')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              :rules="[
                (v) => !!v || 'Vehicle Manufacturing year is required',
                (v) =>
                  v <= new Date().getFullYear() ||
                  'Vehicle can not be from future',
              ]"
              outlined
              :error-messages="error.vehicle_year"
              name="vehicle_year"
              hide-details="auto"
              type="number"
              min="1980"
              :max="new Date().getFullYear()"
              step="01"
              label="Vehicle Manufacturing Year*"
              class="background-white"
              v-model="vehicleFormDetails.vehicle_year"
              @input="syncData($event, 'vehicle_year')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              :rules="[(v) => !!v || 'Vehicle price is required']"
              outlined
              type="number"
              min="0"
              step="01"
              :error-messages="error.vehicle_cost"
              name="vehicle_cost"
              hide-details="auto"
              label="Vehicle Cost*"
              class="background-white"
              v-model="vehicleFormDetails.vehicle_cost"
              @input="syncData($event, 'vehicle_cost')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <InputField
              :rules="[
                (v) => !!v || 'Odometer reading of the vehicle is required',
              ]"
              outlined
              type="number"
              :min="1"
              step="01"
              :error-messages="error.mileage"
              name="mileage"
              hide-details="auto"
              label="Odometer reading*"
              class="background-white"
              v-model="vehicleFormDetails.mileage"
              @input="syncData($event, 'mileage')"
            ></InputField>
          </v-col>
          <v-col cols="6" lg="4">
            <SelectField
              outlined
              name="status"
              :error-messages="error.status"
              :itemsList="vehicleStatusList"
              hide-details="auto"
              item-text="text"
              item-value="value"
              label="Select Status*"
              v-model="vehicleFormDetails.status"
              @change="
                syncData($event, 'status'),
                  error && error.status ? delete error.status : ''
              "
              :menu-props="{ offsetY: true }"
            ></SelectField>
          </v-col>
        </v-row>
        <!---------------------------------------------- Storage section ---------------------------------------------->
        <v-row no-gutters>
          <v-col cols="12" class="my-4">
            <span class="font-weight-bold text-subtitle-1"
              >Vehicle Storage Information</span
            >
            <hr />
          </v-col>
          <v-col cols="3" class="pa-1">
            <SelectField
              outlined
              :rules="[(v) => !!v || 'Storage types are required']"
              :multiple="true"
              name="available_storages"
              :error-messages="error.available_storages"
              hide-details="auto"
              label="Storages*"
              :itemsList="storageType"
              itemText="title"
              itemValue="value"
              v-model="vehicleFormDetails.storages"
              @change="syncData($event, 'storages')"
              :menu-props="{ offsetY: true }"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.title }}</span>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ vehicleFormDetails.storages.length - 1 }} others)
                </span>
              </template>
            </SelectField>
          </v-col>

          <v-col
            cols="3"
            class="pa-1"
            v-for="(n, i) in vehicleFormDetails.storages"
            :key="i"
          >
            <!-- :hint="`${n}'s Sensor-id`" -->
            <InputField
              outlined
              multiple
              :error-messages="error.sensor_info"
              hide-details="auto"
              persistent-hint
              :label="`${n}'s Sensor ID`"
              class="background-white"
              v-model="vehicleFormDetails.sensors[n]"
              @input="syncData($event, 'sensors', n)"
            >
            </InputField>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" class="mt-6 mb-8">
            <span class="font-weight-bold text-subtitle-1"> Tags </span>
            <hr />
          </v-col>
          <v-col cols="4">
            <SelectField
              :items-list="vehicleTagsList"
              item-text="title"
              item-value="value"
              label="Select Tag"
              multiple
              :returnObject="false"
              v-model="assigned_tags"
            />
          </v-col>
        </v-row>

        <!------------------------------------------- Documnet upload section ------------------------------------------->
        <v-row no-gutters>
          <v-col cols="12" class="mt-4 mb-8">
            <span class="font-weight-bold text-subtitle-1"
              >Upload Documents</span
            >
            <hr />
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="3">
                <SelectField
                  outlined
                  hide-details="auto"
                  label="Document Type"
                  :items-list="documentType"
                  v-model="uploadDocumentType"
                  item-text="title"
                  item-value="value"
                  return-object
                  name="document_type"
                  :menu-props="{ offsetY: true }"
                ></SelectField>
              </v-col>
              <v-col cols="6">
                <v-file-input
                  ref="fileInput"
                  :clearable="true"
                  hide-details
                  dense
                  type="file"
                  v-model="uploadFiles"
                  label="Upload Document"
                  :disabled="uploadDocumentType ? false : true"
                  prepend-inner-icon="mdi-attachment mdi-rotate-90"
                  prepend-icon=""
                  accept="image/*, .pdf"
                  outlined
                  @change="getFileData($event)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12 pt-8 pb-14">
            <div>
              <div
                class="mt-0 mb-4 pl-4"
                v-show="
                  vehicleFormDetails.vehicle_documents &&
                  vehicleFormDetails.vehicle_documents.length
                "
              >
                <span class="font-weight-bold text-subtitle-1"> Uploaded </span>
              </div>

              <v-card
                elevation="0"
                outlined
                v-for="(data, i) in vehicleFormDetails.vehicle_documents"
                :key="i"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="11">
                      <a :href="data.document" target="_black">
                        <h4>{{ data.document_type }}</h4>
                      </a>
                    </v-col>
                    <v-col cols="1">
                      <v-icon
                        small
                        color="red"
                        @click="deleteDocument(data.id, i)"
                        >mdi-close</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>

            <div
              class="mt-5 mb-4 pl-4"
              v-show="documentList && documentList.length"
            >
              <span class="font-weight-bold text-subtitle-1"> New Upload </span>
            </div>

            <v-card
              elevation="0"
              outlined
              v-for="(data, j) in documentList"
              :key="j"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="7">
                    <a :href="generateUrl(data.file)" target="_black">
                      <h4>{{ data.document_name }}</h4>
                    </a>
                  </v-col>
                  <v-col cols="2">
                    <h4>{{ getSize(data.file.size) }}</h4>
                  </v-col>
                  <v-col cols="2"> </v-col>
                  <v-col cols="1">
                    <v-icon small color="primary" @click="removeFile(j)"
                      >mdi-close</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        :disabled="!isValid"
        @click.prevent="submitVehicleForm()"
        class="primary text-uppercase mr-3"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'Add'"
        class="primary text-uppercase"
        @click="resetForm()"
      >
        <span>Reset</span>
      </v-btn>
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { vehicleStatusList } from "@/utils/choices.js";
import InputField from "@/components/BaseFormComponents/InputField.vue";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import { bus } from "@/main.js";
export default {
  components: {
    BaseDialogForm,
    InputField,
    SelectField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      vehicleStatusList,
      vehicleFormDetails: {
        storages: [],
        sensors: {},
      },
      menu: false,
      activePicker: null,
      error: [],
      nonFieldError: [],
      isValid: false,
      documentList: [],
      allProjects: [],
      vehicleTagsList: [],
      assigned_tags: [],
      fileNameList: [],
      uploadFiles: null,
      uploadDocumentType: null,
      documentType: [
        {
          title: "Vehicle Photo",
          value: "vehicle_photo",
        },
        {
          title: "vehicle Rc Photo",
          value: "vehicle_rc_photo",
        },
        {
          title: "Food Delivery Permits",
          value: "food_delivery_permits",
        },
        {
          title: "Calibration Certificate",
          value: "calibration_certificate",
        },
        {
          title: "Insurance Certificate",
          value: "insurance_certificate",
        },
        {
          title: "Other Documents",
          value: "other",
        },
      ],
      storageType: [
        {
          title: "Dry",
          value: "Dry",
        },
        {
          title: "Chilled",
          value: "Chilled",
        },
        {
          title: "Frozen",
          value: "Frozen",
        },
      ],
      fuelType: [
        {
          title: "Petrol",
          value: "petrol",
        },
        {
          title: "Diesel",
          value: "diesel",
        },
        {
          title: "Other",
          value: "other",
        },
      ],
      nowDate: new Date().toISOString().slice(0, 10),
      rcExpiryDate: null,
      rcExpiryDatePicker: false,
      insuranceExpiryDatePicker: false,
      insuranceExpiryDate: null,
    };
  },
  watch: {
    openVehicleDialog(val) {
      if (val) {
        this.vehicleFormDetails = {
          storages: [],
          sensors: {},
        };
        this.assigned_tags = [];
        this.error = [];
        this.nonFieldError = [];
        this.getAllProjects();
        this.getTagsList();
      }
    },
  },
  computed: {
    openVehicleDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    syncData(event, key) {
      if (this.error[key]) {
        this.error[key] = null;
        delete this.error[key];
      }
      if (this.error["sensor_info"] && key == "sensors") {
        this.error["sensor_info"] = null;
        delete this.error["sensor_info"];
      }
      if (this.error["available_storages"] && key == "storages") {
        this.error["available_storages"] = null;
        delete this.error["available_storages"];
      }

      this.$forceUpdate();
    },
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getTagsList() {
      this.$api.tags
        .getTagList({ limit: "all" })
        .then((res) => {
          let tags = res.data.filter((item) => item.tag_type == "vehicle_tag");
          this.vehicleTagsList = tags.map((obj) => {
            return { title: obj.tag, value: obj.id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    imageFileCheck(fileName) {
      let extension = fileName.slice(
        (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1
      );
      if (
        extension == "png" ||
        extension == "jpeg" ||
        extension == "jpg" ||
        extension == "pdf"
      ) {
        return true;
      } else {
        bus.$emit("showToastMessage", {
          message: `Select file(s) are not supported.Support file Types: PNG, JPEG, JPG, PDF `,
          color: "error",
        });
        return false;
      }
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    removeFile(index) {
      this.documentList.splice(index, 1);
    },
    getFileData(fileInput) {
      if (fileInput && fileInput.name && !this.imageFileCheck(fileInput.name)) {
        this.$refs.fileInput.reset();
        // this.uploadFiles = null;
        this.uploadDocumentType = null;
        return false;
      }

      if (fileInput) {
        this.documentList.push({
          file: fileInput,
          document_type: this.uploadDocumentType.value,
          document_name: this.uploadDocumentType.title,
        });
        this.fileNameList.push(fileInput.name);
        this.$refs.fileInput.reset();
        // this.uploadFiles = null;
        this.uploadDocumentType = null;
        this.scrollTobottom();
      }
    },
    scrollTobottom() {
      setTimeout(() => {
        let elmnt = document.getElementById("vehicleForm");
        elmnt.scrollIntoView({ block: "end", behavior: "smooth" });
      }, 300);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },

    uploadDocument(id = null) {
      this.documentList.forEach((details, index) => {
        let payload;
        let newForm = new FormData();
        newForm.append("document_type", details.document_type);
        newForm.append("document", details.file);
        payload = {
          id: id,
          data: newForm,
        };
        this.$api.vehicles
          .uploadDocument(payload)
          .then(() => {
            bus.$emit("showToastMessage", {
              message: "Document uploaded successfully",
              color: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    deleteDocument(id, index) {
      this.$api.vehicles
        .deleteDocument(id)
        .then((response) => {
          this.vehicleFormDetails.vehicle_documents.splice(index, 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVehicleDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.vehicles
        .getVehicleDetail(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vehicleFormDetails = res.data;
          this.assigned_tags = res.data.assigned_tags.map((item) => item.id);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Couldn't fetch data",
            color: "error",
          });
        });
    },
    submitVehicleForm() {
      const form = document.getElementById("vehicleForm");
      let postData = new FormData(form);
      if (this.vehicleFormDetails.storages) {
        postData.set(
          "sensor_info",
          this.vehicleFormDetails.storages
            .map((key) => {
              return this.vehicleFormDetails.sensors[key];
            })
            .join()
        );
      }
      if (this.assigned_tags) {
        postData.set("tags", this.assigned_tags.join());
      }

      if (this.formType == "Add") {
        this.$api.vehicles
          .createVehicleObject(postData)
          .then((result) => {
            bus.$emit("showToastMessage", {
              message: "Vehicle added successfully!",
              color: "success",
            });
            this.uploadDocument(result.data.id);
            this.resetForm();
            this.openVehicleDialog = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      } else {
        let payload = {
          id: this.vehicleFormDetails.id,
          data: postData,
        };
        this.$api.vehicles
          .updateVehicleObject(payload)
          .then((result) => {
            bus.$emit("showToastMessage", {
              message: "Vehicle updated successfully!",
              color: "success",
            });
            this.uploadDocument(this.vehicleFormDetails.id);
            this.resetForm();
            this.openVehicleDialog = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      }
    },
    resetForm() {
      this.vehicleFormDetails = {
        cbm_capacity: null,
        fuel_type: null,
        insurance_expiry_date: null,
        insurance_policy_number: null,
        insurance_type: null,
        permits: null,
        box_capacity: null,
        project: null,
        rc_expiry_date: null,
        rc_number: null,
        status: null,
        tonnage_capacity: null,
        vehicle_plate_no: null,
        sensors: {},
      };
      this.documentList = [];
      this.fileNameList = [];
      this.error = [];
      this.nonFieldError = [];
      this.uploadFiles = null;
      this.uploadDocumentType = null;

      this.$refs.vehicleForm.reset();
      this.$refs.vehicleForm.resetValidation();
    },
  },
};
</script>

<style>
</style>