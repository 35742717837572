var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialogForm',{attrs:{"title":`${_vm.formType} Ticket`,"maxWidth":"60vw"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.ticketFormDialog)?_c('v-form',{ref:"ticketForm",attrs:{"id":"ticketForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"pt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('InputField',{attrs:{"hide-details":"auto","label":"Title*","name":"title","error-messages":_vm.formErrors && _vm.formErrors.title ? _vm.formErrors.title : '',"rules":[
              (v) => (!!v && v.trim().length > 0) || 'Title  is Required',
            ],"prepend-icon":"","outlined":""},on:{"input":function($event){_vm.formErrors && _vm.formErrors.title ? delete _vm.formErrors.title : ''}},model:{value:(_vm.ticketDetails.title),callback:function ($$v) {_vm.$set(_vm.ticketDetails, "title", $$v)},expression:"ticketDetails.title"}})],1),_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('SelectField',{attrs:{"outlined":"","hide-details":"auto","items-list":_vm.moduleName,"item-text":"title","name":"module","item-value":"value","error-messages":_vm.formErrors && _vm.formErrors.module ? _vm.formErrors.module : '',"label":"Module Name*","rules":[(v) => !!v || 'Module Name is Required'],"menu-props":{ offsetY: true }},on:{"change":function($event){_vm.formErrors && _vm.formErrors.module ? delete _vm.formErrors.module : ''}},model:{value:(_vm.ticketDetails.module),callback:function ($$v) {_vm.$set(_vm.ticketDetails, "module", $$v)},expression:"ticketDetails.module"}})],1),_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('SelectField',{attrs:{"outlined":"","hide-details":"auto","items-list":_vm.priority,"name":"priority","item-text":"title","item-value":"value","error-messages":_vm.formErrors && _vm.formErrors.priority ? _vm.formErrors.priority : '',"label":"Priority*","rules":[(v) => !!v || 'Priority  is Required'],"menu-props":{ offsetY: true }},on:{"change":function($event){_vm.formErrors && _vm.formErrors.priority
                ? delete _vm.formErrors.priority
                : ''}},model:{value:(_vm.ticketDetails.priority),callback:function ($$v) {_vm.$set(_vm.ticketDetails, "priority", $$v)},expression:"ticketDetails.priority"}})],1),(_vm.formType == 'Add')?_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('FileFieldVue',{attrs:{"dense":"","multiple":"multiple","hide-details":"auto","label":"Upload Document*","rules":_vm.formType == 'add' || _vm.formType == 'Add'
                ? [(v) => !!v || 'Upload Document  is Required']
                : [],"outlined":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1):_vm._e(),(_vm.formType == 'Edit')?_c('v-col',{attrs:{"cols":"6","lg":"4"}},[(_vm.formType == 'Edit')?_c('SelectField',{attrs:{"hide-details":"auto","items-list":_vm.ticketStatus,"name":"status","item-text":"title","item-value":"value","label":"Status","menu-props":{ offsetY: true },"outlined":""},model:{value:(_vm.ticketDetails.status),callback:function ($$v) {_vm.$set(_vm.ticketDetails, "status", $$v)},expression:"ticketDetails.status"}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('TextAreaField',{attrs:{"hide-details":"auto","label":"Description*","name":"description","rules":[
              (v) =>
                (!!v && v.trim().length > 0) || 'Description  is Required',
            ],"prepend-icon":"","outlined":""},model:{value:(_vm.ticketDetails.description),callback:function ($$v) {_vm.$set(_vm.ticketDetails, "description", $$v)},expression:"ticketDetails.description"}})],1)],1)],1):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"primary text-uppercase mr-3",attrs:{"type":"submit","disabled":!_vm.isValid},on:{"click":function($event){$event.preventDefault();return _vm.submitTicketForm()}}},[_c('span',[_vm._v("Submit")])]),(_vm.formType == 'add' || _vm.formType == 'Add')?_c('v-btn',{staticClass:"primary text-uppercase",attrs:{"type":"reset"},on:{"click":function($event){return _vm.clear()}}},[_c('span',[_vm._v("Reset")])]):_vm._e()]},proxy:true}]),model:{value:(_vm.ticketFormDialog),callback:function ($$v) {_vm.ticketFormDialog=$$v},expression:"ticketFormDialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }