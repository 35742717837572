<template>
  <ListLayout
    ref="listLayout"
    title="Customer Addresses"
    :tableData="customerAddressesList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getCustomerAddressList"
    :tableScrollable="false"
    localStorageKey="customer_addresses_columns"
    :isColumnShowVisibility="true"
  >
    <template #listAction>
      <v-row no-gutters>
        <v-col
          class="d-flex justify-end align-center"
          v-if="
            permissions &&
            permissions.customeraddress &&
            permissions.customeraddress.add
          "
        >
          <v-btn
            small
            depressed
            class="primary mr-1"
            @click="openCustomerAddressForm()"
          >
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span>Add Address</span>
          </v-btn>

          <!-- <v-btn
            depressed
            small
            class="primary mr-1"
            @click="customerAddressBulkUpload = true"
          >
            <v-icon small class="mr-1">mdi-arrow-up-bold</v-icon>
            <span>Bulk Upload</span>
          </v-btn> -->
          <v-menu
            offset-y
            rounded="lg"
            elevation="0"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  permissions &&
                  permissions.customeraddress &&
                  (permissions.customeraddress.add ||
                    permissions.customeraddress.change)
                "
                small
                depressed
                v-bind="attrs"
                v-on="on"
                Dropdown
                class="primary mr-1"
              >
                <v-icon small class="mr-1">mdi-arrow-up-bold</v-icon>
                <span>Bulk Operations</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="showBulkDialog('customer_address')"
                v-if="
                  permissions &&
                  permissions.customeraddress &&
                  permissions.customeraddress.add
                "
              >
                <v-list-item-icon class="text-center d-flex align-center mx-0">
                  <v-icon class="primary--text" small
                    >mdi-table-arrow-up</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="ma-0">
                  <v-list-item-title class="text-capitalize text-center">
                    Bulk Upload
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="showBulkDialog('customer_address_update')"
                v-if="
                  permissions &&
                  permissions.customeraddress &&
                  permissions.customeraddress.change
                "
              >
                <v-list-item-icon class="text-center d-flex align-center mx-0">
                  <v-icon class="primary--text" small
                    >mdi-microsoft-excel</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="ma-0">
                  <v-list-item-title class="text-capitalize text-center">
                    Bulk Update
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            small
            depressed
            class="primary"
            @click="openDownloadDataDialog = true"
          >
            <v-icon small class="mr-1">mdi-arrow-down-bold</v-icon>
            <span>Download All Data</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #leftFilterSlot>
      <v-col class="d-flex justify-start pa-0">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="success"
              overlap
              small
              dot
              :value="appliedFilters && appliedFilters.length > 0"
            >
              <v-btn
                small
                fab
                depressed
                color="primary"
                class="rounded ml-2"
                @click="openCustomerFilterDialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="">mdi-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Customer Addresses Filters</span>
        </v-tooltip>
      </v-col>
    </template>
    <template #dialogs>
      <CustomerFilter
        ref="customerFilterDialog"
        v-model="openCustomerFilterDialog"
        @customerFilterChanged="$refs.listLayout.searchTriggered()"
      />
      <CustomerForm
        ref="customerForm"
        v-model="openCustomerForm"
        :formType="formType"
        @refreshList="$refs.listLayout.searchTriggered()"
      />

      <BulkUpload
        v-model="customerAddressBulkUpload"
        :uploadTo="bulkDialogType"
        @refreshList="getCustomerAddressList({ limit: 10, offset: 0 })"
      />
      <DownloadAllData parent="customers" v-model="openDownloadDataDialog" />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import customerAddressEdit from "@/components/common/aggrid/buttons/customerAddressEdit.vue";
import tagChip from "@/components/common/aggrid/chip/customerAddressTagChip.vue";
import customerError from "@/components/customer/customerError.vue";
import CustomerForm from "@/components/customer/CustomerForm.vue";
import CustomerFilter from "@/components/customer/CustomerFilter.vue";
import BulkUpload from "@/components/common/BulkUpload.vue";
import { bus } from "@/main.js";
import { downloadBlobData } from "@/assets/utils.js";
import DownloadAllData from "@/components/common/DownloadAllData.vue";

export default {
  name: "customerAddressIndex",
  components: {
    ListLayout,
    customerAddressEdit,
    tagChip,
    customerError,
    CustomerForm,
    CustomerFilter,
    BulkUpload,
    DownloadAllData,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      customFilter: {},
      customerAddressesList: [],
      openCustomerForm: false,
      openCustomerFilterDialog: false,
      customerAddressBulkUpload: false,
      openDownloadDataDialog: false,
      bulkDialogType: "",
      formType: "Add",
      headerSelected: [
        {
          headerName: "Customer Code",
          pinned: "left",
          field: "customer_code",
        },
        {
          headerName: "Customer Name",
          field: "customer_name",
        },
        {
          headerName: "Address",
          field: "address",
        },
        {
          headerName: "Contact Person",
          field: "contact_person",
        },
        {
          headerName: "Contact number",
          field: "contact_number",
        },
        {
          headerName: "Customer Type",
          field: "customer_type",
        },
        {
          headerName: "Project",
          field: "project",
        },
        {
          headerName: "Processing Time",
          field: "processing_time",
        },
        {
          headerName: "Remarks",
          field: "remarks",
        },
        {
          headerName: "Tags",
          field: "assigned_tags",
          hide: false,
          cellRendererFramework: "tagChip",
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return [
        {
          headerName: "Error",
          field: "error",
          minWidth: 100,
          maxWidth: 100,
          pinned: "left",
          cellRendererFramework: "customerError",
        },
        ...this.headerSelected,
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          minWidth: 150,
          sortable: false,
          width: 250,
          cellRenderer: "customerAddressEdit",
        },
      ];
    },
    context() {
      return { parentComponent: this };
    },
    appliedFilters() {
      return Object.keys(this.customFilter).map((filter) => {
        return filter.replace(/\_/, " ");
      });
    },
  },
  methods: {
    showBulkDialog(type) {
      this.bulkDialogType = type;
      this.customerAddressBulkUpload = true;
    },
    getCustomerAddressList(params = {}) {
      let filters = localStorage.getItem("customerFilters");
      if (!filters) {
        this.customFilter = {};
      } else {
        if (typeof filters == typeof "string") {
          this.customFilter = JSON.parse(filters);
        }
        // this.customFilter =
        //   typeof filters == typeof "string"
        //     ? (filters = JSON.parse(filters))
        //     : "";
      }

      params = {
        ...params,
        ...this.customFilter,
      };

      if ("project" in params && Array.isArray(params.project)) {
        params.project = params.project.join(",");
      }
      if (params && "tags" in params && Array.isArray(params.tags)) {
        params.tags = params.tags.join(",");
      }
      if (params && "ordering" in params && "sorting" in params) {
        if (params.sorting == "descending") {
          params.ordering = "-" + params.ordering;
        }
      }
      bus.$emit("showLoader", true);
      this.$api.customerAddresses
        .getCustomerAddressList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.customerAddressesList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },

    openCustomerAddressForm() {
      this.formType = "Add";
      this.openCustomerForm = true;
    },

    editCustomer(id) {
      this.formType = "Edit";
      this.openCustomerForm = true;
      setTimeout(() => {
        if (this.$refs && this.$refs.customerForm) {
          this.$refs.customerForm.getCustomerAddressDetails(id);
        }
      }, 100);
    },

    downloadAllCustomers(params) {
      params = {
        ...params,
      };
      bus.$emit("showLoader", true);
      this.$api.customerAddresses
        .downloadCustomerAddressSheet(params)
        .then((result) => {
          bus.$emit("showLoader", false);
          downloadBlobData(result.data, "Customer Addresses.xls");
          bus.$emit("showToastMessage", {
            message: "All Customer Data Downloaded",
            color: "success",
          });
          this.openDownloadDataDialog = false;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          if (err.message) {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          } else {
            bus.$emit("showToastMessage", {
              message: "Something went wrong!",
              color: "error",
            });
          }
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    headersChanged(value) {
      this.headerSelected = value;
    },
  },
  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
