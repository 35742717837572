<template>
  <BaseDialogForm
    title="Trip Planning Template Details"
    v-model="showTemplateDetailsDialog"
    maxWidth="50%"
  >
    <template #content>
      <v-row class="ma-0">
        <v-col cols="6">
          <v-row>
            <v-col cols="6">
              <span>Template Name :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1">{{
                templateDetails.template_name
              }}</span>
            </v-col>
            <v-col cols="6">
              <span>Configuration :</span>
            </v-col>
            <v-col cols="6">
              <span
                class="font-weight-bold text-body-1 text-capitalize"
                v-if="templateDetails.configuration"
                >{{ templateDetails.configuration.replace(/_/g, " ") }}</span
              >
            </v-col>
            <v-col cols="6">
              <span>Fill Ratio :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.fill_ratio
              }}</span>
            </v-col>
            <v-col cols="6">
              <span>Loading Time Window :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize"
                >{{ templateDetails.loading_start_time }} To
                {{ templateDetails.loading_end_time }}</span
              >
            </v-col>
            <v-col cols="6">
              <span>Loading Time :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize"
                >{{ templateDetails.loading_time }} Minutes</span
              >
            </v-col>
            <v-col cols="6">
              <span>OffLoading Time Window :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize"
                >{{ templateDetails.offloading_start_time }} To
                {{ templateDetails.offloading_end_time }}</span
              >
            </v-col>
            <v-col cols="6">
              <span>OffLoading Time :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.offloading_time
              }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="6">
              <span>Region Restriction :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.region_restriction ? "Yes" : "No"
              }}</span>
            </v-col>
            <v-col cols="6">
              <span>Round Trip :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.round_trip ? "Yes" : "No"
              }}</span>
            </v-col>
            <v-col cols="6">
              <span>Tag Validation :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.tag_validations ? "Yes" : "No"
              }}</span>
            </v-col>
            <v-col cols="6">
              <span>Toll Roads :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.toll_roads ? "Yes" : "No"
              }}</span>
            </v-col>
            <v-col cols="6">
              <span>Traffic Jams :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.traffic_jams ? "Yes" : "No"
              }}</span>
            </v-col>
            <v-col cols="6">
              <span>Zone Constraint :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.zone_constraint ? "Yes" : "No"
              }}</span>
            </v-col>
            <v-col cols="6">
              <span>Disable Time Windows :</span>
            </v-col>
            <v-col cols="6">
              <span class="font-weight-bold text-body-1 text-capitalize">{{
                templateDetails.disable_time_windows ? "Yes" : "No"
              }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main";
export default {
  components: { BaseDialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    templateId: {
      type: [Number, null],
    },
  },
  data() {
    return {
      templateDetails: {},
    };
  },
  watch: {
    value(val) {
      if (val) {
        if (this.templateId) {
          this.getTripPlanningTemplateObject(this.templateId);
        }
      }
    },
  },
  computed: {
    showTemplateDetailsDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getTripPlanningTemplateObject(data) {
      bus.$emit("showLoader", true);
      this.$api.tripPlanningTemplate
        .getTripPlanningTemplateObject(data.id)
        .then((res) => {
          this.templateDetails = res.data;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          bus.$emit("showLoader", false);
        });
    },
  },
};
</script>

<style></style>
