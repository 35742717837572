import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";

// Vars Imports
import colors from "@/assets/colors.js";
import globalObject from "@/utils/globalConfig";
import api from "@/plugins/api.js";
import axiosInstance from "@/plugins/axios.js";
import per from "./plugins/permissions.js";

import MainLayout from "../src/layouts/MainLayout.vue";
import Default from "../src/layouts/Default.vue";

import Login from "../src/pages/login/Login.vue";
import AdminRoutes from "../src/router/admin.js";

import InputField from "@/components/BaseFormComponents/InputField.vue";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
import FileField from "@/components/BaseFormComponents/FileField.vue";
import SwitchField from "@/components/BaseFormComponents/SwitchField.vue";
import TextAreaField from "@/components/BaseFormComponents/TextAreaField.vue";
import DatePicker from "@/components/BaseFormComponents/DatePicker.vue";

export const bus = new Vue();

Vue.component("InputField", InputField);
Vue.component("SelectField", SelectField);
Vue.component("FileField", FileField);
Vue.component("SwitchField", SwitchField);
Vue.component("TextAreaField", TextAreaField);
Vue.component("DatePicker", DatePicker);

Vue.config.productionTip = false;

Vue.use(VueRouter);

Vue.prototype.$axios = axiosInstance;
Vue.prototype.$api = api;
Vue.prototype.$globalConstant = globalObject;
Vue.prototype.$permissions = per;
Vue.prototype.$colors = colors;

export const router = new VueRouter({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/app",
    },
    {
      path: "/app",
      component: Default,
      children: [
        {
          path: "login",
          name: "login",
          component: Login,
        },
        {
          path: "admin",
          name: "admin",
          component: MainLayout,
          children: AdminRoutes,
        },
      ],
    },
    {
      path: "**",
      redirect: "/app",
    },
  ],
});

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
