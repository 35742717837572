<template>
  <BaseDialogForm
    v-model="roleFormDialog"
    :title="`${formType} Role`"
    maxWidth="60vw"
    @reset="closeDialog()"
  >
    <template #content>
      <v-form
        v-if="roleFormDialog"
        v-model="isValid"
        ref="roleForm"
        id="roleForm"
      >
        <v-alert v-if="nonFieldError.length" dense type="error">
          <v-list
            class="pa-0"
            dense
            style="background: inherit !important"
            v-for="(error, i) in nonFieldError"
            :key="i"
          >
            <v-list-item dense style="min-height: 20px !important">
              <span>{{ i }} .</span><span>{{ error }}</span>
            </v-list-item>
          </v-list>
        </v-alert>
        <v-row class="pt-4">
          <v-col cols="6" lg="4">
            <InputField
              outlined
              hide-details="auto"
              class="background-white"
              label="Role Name*"
              v-model="roleDetails.role_name"
              :rules="[(v) => !!v || 'Role Name is Required']"
              :error-messages="error.role_name"
              @input="delete error.role_name"
            ></InputField>
          </v-col>
          <v-col cols="12" class="mt-4 mb-4">
            <span class="font-weight-bold text-subtitle-1" color="grey"
              >Permissions</span
            >
            <hr class="mt-3" />
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="primary px-10">
                <v-row>
                  <v-col><span class="white--text">Module</span></v-col>
                  <v-col><span class="white--text">View</span></v-col>
                  <v-col><span class="white--text">Change</span></v-col>
                  <v-col><span class="white--text">Add</span></v-col>
                  <v-col><span class="white--text">Delete</span></v-col>
                </v-row>
              </v-col>
              <v-col
                class="px-10"
                cols="12"
                v-for="(permission, index) in permissions"
                :key="index"
              >
                <v-row>
                  <v-col>{{ permission.Modules }}</v-col>
                  <v-col>
                    <v-checkbox
                      color="success"
                      class="mt-1"
                      v-model="Permissionselected"
                      @change="valueChanged($event, permission.view)"
                      :value="permission.view"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      :disabled="permission.change == 'change_orderuploadfile'"
                      color="success"
                      class="mt-1"
                      v-model="Permissionselected"
                      :value="permission.change"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      :disabled="permission.add == 'add_orderuploadfile'"
                      color="success"
                      class="mt-1"
                      v-model="Permissionselected"
                      :value="permission.add"
                      hide-details
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      :disabled="permission.delete == 'delete_orderuploadfile'"
                      color="success"
                      class="mt-1"
                      hide-details
                      @change="valueChanged($event, permission.delete)"
                      v-model="Permissionselected"
                      :value="permission.delete"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        class="primary text-uppercase mr-3"
        :disabled="!isValid || Permissionselected.length == 0"
        @click.prevent="submitRoleForm()"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'add'"
        @click="clear()"
        class="primary text-uppercase"
      >
        <span>Reset</span>
      </v-btn>
    </template>
  </BaseDialogForm>
</template>

<script>
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import { bus } from "@/main.js";
export default {
  components: { BaseDialogForm },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      roleDetails: {},
      error: {},
      nonFieldError: [],
      Permissionselected: [],
      isValid: false,
      typeOfPermission: ["delete", "change", "view", "add"],
      permissions: [
        {
          Modules: "Role",
          view: "view_role",
          change: "change_role",
          add: "add_role",
          delete: "delete_role",
        },
        {
          Modules: "User",
          view: "view_dashuser",
          change: "change_dashuser",
          add: "add_dashuser",
          delete: "delete_dashuser",
        },
        {
          Modules: "Project",
          view: "view_project",
          change: "change_project",
          add: "add_project",
          delete: "delete_project",
        },
        {
          Modules: "Item Master",
          view: "view_itemmaster",
          change: "change_itemmaster",
          add: "add_itemmaster",
          delete: "delete_itemmaster",
        },
        {
          Modules: "Order",
          view: "view_order",
          change: "change_order",
          add: "add_order",
          delete: "delete_order",
        },
        {
          Modules: "Customer",
          view: "view_customeraddress",
          change: "change_customeraddress",
          add: "add_customeraddress",
          delete: "delete_customeraddress",
        },
        {
          Modules: "Trip",
          view: "view_trip",
          change: "change_trip",
          add: "add_trip",
          delete: "delete_trip",
        },
        {
          Modules: "Driver",
          view: "view_driver",
          change: "change_driver",
          add: "add_driver",
          delete: "delete_driver",
        },
        {
          Modules: "Vehicle",
          view: "view_vehicle",
          change: "change_vehicle",
          add: "add_vehicle",
          delete: "delete_vehicle",
        },
        {
          Modules: "Zone",
          view: "view_zone",
          change: "change_zone",
          add: "add_zone",
          delete: "delete_zone",
        },
        {
          Modules: "Support",
          view: "view_ticket",
          change: "change_ticket",
          add: "add_ticket",
          delete: "delete_ticket",
        },
        {
          Modules: "Order History",
          view: "view_orderuploadfile",
          change: "change_orderuploadfile",
          add: "add_orderuploadfile",
          delete: "delete_orderuploadfile",
        },
      ],
    };
  },

  watch: {
    roleFormDialog(val) {
      if (val) {
        this.roleDetails = {};
      }
    },
  },

  computed: {
    roleFormDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    closeDialog() {
      this.clear();
      this.roleFormDialog = false;
    },
    valueChanged(e, input) {
      let module_name = input.split("_")[1];
      let permission = input.split("_")[0];
      this.typeOfPermission.forEach((value) => {
        if (permission == "delete") {
          if (
            permission != value &&
            !this.Permissionselected.includes(`${value}_${module_name}`)
          ) {
            this.Permissionselected.push(`${value}_${module_name}`);
          }
        } else if (permission == "view") {
          let index = this.Permissionselected.indexOf(
            `${value}_${module_name}`
          );
          if (index > -1 && value != permission) {
            this.Permissionselected.splice(index, 1);
          }
        }
      });
    },

    getRoleDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.role
        .getRoleObject(id)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.roleDetails = res.data;
          this.Permissionselected = res.data.permissions;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          bus.$emit("showToastMessage", {
            message: "Something went wrong!",
            color: "error",
          });
        });
    },

    submitRoleForm() {
      bus.$emit("showLoader", true);
      if (this.formType == "Add") {
        this.roleDetails.updated_permissions = this.Permissionselected;
        this.$api.role
          .addRole(this.roleDetails)
          .then(() => {
            bus.$emit("showLoader", false);
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Role added successfully!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.non_field_errors) {
              this.nonFieldError = err.non_field_errors;
            }
            this.error = err;
          });
      } else {
        this.roleDetails.updated_permissions = this.Permissionselected;
        let payload = {
          id: this.roleDetails.id,
          data: this.roleDetails,
        };
        this.$api.role
          .updateRole(payload)
          .then(() => {
            this.closeDialog();
            bus.$emit("showToastMessage", {
              message: "Role updated successfully!",
              color: "success",
            });
            this.$emit("refreshList");
          })
          .catch((err) => {
            if (err.non_field_errors) {
              this.nonFieldError = err.non_field_errors;
            }
            this.error = err;
          });
      }
    },

    clear() {
      this.Permissionselected = [];
      this.roleDetails = {
        all_permissions: [],
        id: null,
        is_deletable: null,
        permissions: [],
        role_name: null,
      };
      this.$refs.roleForm.reset();
    },
  },
};
</script>

<style>
</style>