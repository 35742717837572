<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2"
          v-bind="attrs"
          outlined
          v-on="on"
          small
          @click="viewbtn"
        >
          view
        </v-btn>
      </template>
      <span>View Details</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="primary text-white"
          small
          v-bind="attrs"
          v-on="on"
          @click="btnClickedHandler"
        >
          DOWNLOAD
        </v-btn>
      </template>
      <span>Download Details</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openRoleForm: false,
    };
  },
  methods: {
    btnClickedHandler() {
      this.params.context.parentComponent.downloadReportData(
        this.params.data.id
      );
    },
    viewbtn() {
      this.params.context.parentComponent.displayBulkOrderDialog(
        this.params.data.id
      );
    },
  },
};
</script>
