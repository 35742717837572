import { items } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getItemList(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(items.base, { params: params })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createItemObject(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${items.base}`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getItemObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${items.base}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateItemObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${items.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateItemObject(payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${items.base}${payload.id}/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadItemSheet(params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${items.base}download/`, {
          responseType: "blob",
          headers: {
            // Accept: "application/xlsx",
          },
          params: params,
        })
        .then((success) => {
          resolve(success.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  bulkUploadItems(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${items.base}`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  bulkUpdateItems(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${items.base}bulk_update/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
