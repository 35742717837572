var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDialogForm',{attrs:{"title":`${_vm.formType} Zone`,"fullscreen":true,"transition":"dialog-bottom-transition","maxWidth":"60vw","isStickTitleRequired":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.openZoneDialog)?_c('v-form',{ref:"userForm",attrs:{"id":"userForm"},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"d-flex justify-end pb-4 pr-0"},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('SelectField',{attrs:{"items-list":_vm.allProjects,"rules":[
              (v) => (v != null && v.length > 0) || 'Project is required',
            ],"outlined":"","hide-details":"auto","dense":"","label":"Project*","disabled":_vm.formType == 'Edit',"menu-props":{ offsetY: true }},on:{"change":function($event){return _vm.getSelectedProjectsZone($event)},"input":function($event){delete _vm.error.project}},model:{value:(_vm.zoneDetails.project),callback:function ($$v) {_vm.$set(_vm.zoneDetails, "project", $$v)},expression:"zoneDetails.project"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('InputField',{staticClass:"background-white",attrs:{"rules":[(v) => !!v || 'Zone name is required'],"outlined":"","hide-details":"auto","dense":"","label":"Zone Name*","error-messages":_vm.error && _vm.error.zone_name ? _vm.error.zone_name : ''},on:{"input":function($event){_vm.error && _vm.error.zone_name ? delete _vm.error.zone_name : ''}},model:{value:(_vm.zoneDetails.zone_name),callback:function ($$v) {_vm.$set(_vm.zoneDetails, "zone_name", $$v)},expression:"zoneDetails.zone_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('InputField',{staticClass:"background-white",attrs:{"rules":[
              (v) => !!v || 'Description is required',
              (v) =>
                (!!v && v.length < 100) || 'Can not be more than 100 words',
            ],"outlined":"","hide-details":"auto","dense":"","label":"Description*","error-messages":_vm.error && _vm.error.zone_desc ? _vm.error.zone_desc : ''},on:{"input":function($event){_vm.error && _vm.error.zone_desc ? delete _vm.error.zone_desc : ''}},model:{value:(_vm.zoneDetails.zone_desc),callback:function ($$v) {_vm.$set(_vm.zoneDetails, "zone_desc", $$v)},expression:"zoneDetails.zone_desc"}})],1),_c('div',{staticClass:"d-flex align-center text-xs-center pa-0 ma-0"},[_c('v-btn',{staticClass:"primary text-uppercase d-flex justify-center align-center",attrs:{"type":"submit","disabled":!_vm.isValid},on:{"click":function($event){$event.preventDefault();return _vm.submitZoneForm()}}},[_c('span',[_vm._v("submit")])])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ma-0 cf-zone-map",attrs:{"cols":"12"}},[_c('Gmap',{ref:"ZoneMap",attrs:{"mapWidth":"100%","mapHeight":"80vh","isDrawable":true}})],1)],1)],1):_vm._e()]},proxy:true}]),model:{value:(_vm.openZoneDialog),callback:function ($$v) {_vm.openZoneDialog=$$v},expression:"openZoneDialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }