var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('span',{staticClass:"text-lg-subtitle-1 text-xl-h6 text-uppercase font-weight-black primary--text"},[_vm._v(" Instruction ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-n3",attrs:{"depressed":"","fab":"","small":""},on:{"click":_vm.closeDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-3"},[(
          _vm.requestType == 'Orders Update' || _vm.requestType == 'orders_update'
        )?_c('div',[_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Note")]),_c('h4',{staticClass:"text-lg-caption mb-4"},[_vm._v(" The header given in your excel must be same as below Mandatory Fields. For reference download sample excel file. ")]),_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Mandatory Fields are :")]),_c('h4',{staticClass:"text-lg-caption"},[_vm._v(" SO Number,"),_c('br'),_vm._v(" Delivery Date,"),_c('br')])]):_vm._e(),(_vm.requestType == 'Orders' || _vm.requestType == 'orders')?_c('div',[_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Note")]),_c('h4',{staticClass:"text-lg-caption mb-4"},[_vm._v(" The header given in your excel must be same as below Mandatory Fields. For reference download sample excel file. ")]),_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Mandatory Fields are :")]),_c('h4',{staticClass:"text-lg-caption"},[_vm._v(" Item No,"),_c('br'),_vm._v(" SO Number,"),_c('br'),_vm._v(" Customer Code, "),_c('br'),_vm._v(" Delivery Date, "),_c('br'),_vm._v(" Quantity "),_c('br')])]):_vm._e(),(_vm.requestType == 'B2C Orders' || _vm.requestType == 'b2c_orders')?_c('div',[_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Note")]),_c('h4',{staticClass:"text-lg-caption mb-4"},[_vm._v(" The header given in your excel must be same as below Mandatory Fields. For reference download sample excel file. ")]),_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Mandatory Fields are :")]),_c('h4',{staticClass:"text-lg-caption"},[_vm._v(" Item No,"),_c('br'),_vm._v(" SO Number,"),_c('br'),_vm._v(" Delivery Date, "),_c('br'),_vm._v(" Quantity "),_c('br'),_vm._v(" Customer Number, "),_c('br'),_vm._v(" Customer Name, "),_c('br'),_vm._v(" Project Id, "),_c('br'),_vm._v(" Addresss, "),_c('br'),_vm._v(" Latitude, "),_c('br'),_vm._v(" Longitude, "),_c('br'),_vm._v(" From Time, "),_c('br'),_vm._v(" To Time, "),_c('br')])]):(
          _vm.requestType == 'customer_address' ||
          _vm.requestType == 'customer_address_update'
        )?_c('div',[_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Note")]),_c('h4',{staticClass:"text-lg-caption mb-4"},[_vm._v(" The header given in your excel must be same as below Mandatory Fields. For reference download sample excel file. ")]),_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Mandatory Fields are :")]),_c('h4',{staticClass:"text-lg-caption"},[_vm._v(" Customer Code "),_c('br'),_vm._v(" Customer Name"),_c('br'),_vm._v(" Customer Type"),_c('br'),_vm._v(" Contact Email"),_c('br'),_vm._v(" Project Id"),_c('br'),_vm._v(" Address"),_c('br')])]):(_vm.requestType == 'items' || _vm.requestType == 'items_update')?_c('div',[_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Note")]),_c('h4',{staticClass:"text-lg-caption mb-4"},[_vm._v(" The header given in your excel must be same as below Mandatory Fields. For reference download sample excel file. ")]),_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Mandatory Fields are :")]),_c('h4',{staticClass:"text-lg-caption"},[_vm._v(" Item name"),_c('br'),_vm._v(" item No"),_c('br'),_vm._v(" Storage Type"),_c('br'),_vm._v(" Unit"),_c('br'),_vm._v(" Case Factor"),_c('br'),_vm._v(" Length"),_c('br'),_vm._v(" Width"),_c('br'),_vm._v(" Height"),_c('br'),_vm._v(" Volume (CBM)"),_c('br'),_vm._v(" Weight"),_c('br')])]):(
          _vm.requestType == 'drivers' || _vm.requestType == 'drivers_update'
        )?_c('div',[_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Note")]),_c('h4',{staticClass:"text-lg-caption mb-4"},[_vm._v(" The header given in your excel must be same as below Mandatory Fields. For reference download sample excel file. ")]),_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Mandatory Fields are :")]),_c('h4',{staticClass:"text-lg-caption"},[_vm._v(" First Name"),_c('br'),_vm._v(" Last Name"),_c('br'),_vm._v(" User Name"),_c('br'),_vm._v(" Password"),_c('br'),_vm._v(" Contact Number"),_c('br'),_vm._v(" Project id"),_c('br'),_vm._v(" Shift Start"),_c('br'),_vm._v(" Shift End"),_c('br'),_vm._v(" License Number"),_c('br'),_vm._v(" License Expiry"),_c('br'),_vm._v(" National Id Expiry"),_c('br')])]):(
          _vm.requestType == 'vehicles' || _vm.requestType == 'vehicles_update'
        )?_c('div',[_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Note")]),_c('h4',{staticClass:"text-lg-caption mb-4"},[_vm._v(" The header given in your excel must be same as below Mandatory Fields. For reference download sample excel file. ")]),_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Mandatory Fields are :")]),_c('h4',{staticClass:"text-lg-caption"},[_vm._v(" Vehicle Number"),_c('br'),_vm._v(" Project Id"),_c('br'),_vm._v(" Fuel Type"),_c('br'),_vm._v(" Tonnage Capacity"),_c('br'),_vm._v(" CBM Capacity"),_c('br'),_vm._v(" Permits"),_c('br'),_vm._v(" Insurance Policy Number"),_c('br'),_vm._v(" RC Number"),_c('br'),_vm._v(" RC Expiry Date"),_c('br'),_vm._v(" Vehicle Make"),_c('br'),_vm._v(" Vehicle Model"),_c('br'),_vm._v(" Vehicle Year"),_c('br'),_vm._v(" Vehicle Cost"),_c('br'),_vm._v(" Odometer reading"),_c('br'),_vm._v(" Storages"),_c('br')])]):(_vm.requestType == 'temperaturesheet')?_c('div',[_c('h4',{staticClass:"text-lg-Subtitle-1"},[_vm._v("Note")]),_c('h4',{staticClass:"text-lg-caption mb-4"},[_vm._v(" Only supports Excel files "),_c('br'),_vm._v(" Files with the same names are not allowed"),_c('br'),_vm._v(" You can select multiple files from the directory(Folder)"),_c('br')])]):_vm._e()]),_c('v-card-actions',{staticClass:"d-flex justify-end"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }