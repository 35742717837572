<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="edit white--text mr-1"
          small
          @click="btnClickedHandler"
          depressed
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small class="ma-0">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="delete white--text mr-1"
          small
          v-bind="attrs"
          v-on="on"
          @click="btnDeleteUser"
          depressed
        >
          <v-icon small class="ma-0">mdi-delete</v-icon>
        </v-btn>
      </template>
      <span>Delete Details</span>
    </v-tooltip>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  methods: {
    async btnClickedHandler() {
      this.params.context.parentComponent.editStatus(this.params.data.id);
    },
    async btnDeleteUser() {
      this.params.context.parentComponent.deleteStatus(this.params.data.id);
    },
  },
};
</script>