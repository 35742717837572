<template>
  <BaseDialogForm
    v-model="openDriverDialog"
    :title="`${formType} Driver`"
    maxWidth="60vw"
  >
    <template #content>
      <v-alert
        v-if="nonFieldError && nonFieldError.length > 0"
        dense
        type="error"
      >
        <v-list
          class="pa-0"
          dense
          style="background: inherit !important"
          v-for="(error, i) in nonFieldError"
          :key="i"
        >
          <v-list-item dense style="min-height: 20px !important">
            <span>{{ i }} .</span><span>{{ error }}</span>
          </v-list-item>
        </v-list>
      </v-alert>
      <v-form
        ref="DriverFormAdd"
        id="DriverFormAdd"
        v-model="isValid"
        :key="formCount"
      >
        <v-row>
          <v-col cols="12" lg="8">
            <v-row>
              <v-col cols="6" lg="4">
                <InputField
                  outlined
                  hide-details="auto"
                  label="First Name*"
                  class="background-white"
                  name="first_name"
                  :rules="[
                    (v) =>
                      (!!v && v.trim().length > 0) || 'First name is required',
                  ]"
                  :error-messages="error.first_name"
                  @input="
                    error && error.first_name ? delete error.first_name : ''
                  "
                  v-model="driverFormDetails.first_name"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  outlined
                  hide-details="auto"
                  label="Last Name*"
                  class="background-white"
                  name="last_name"
                  :rules="[
                    (v) =>
                      (!!v && v.trim().length > 0) || 'Last name is required',
                  ]"
                  :error-messages="error.last_name"
                  @input="
                    error && error.last_name ? delete error.last_name : ''
                  "
                  v-model="driverFormDetails.last_name"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  outlined
                  hide-details="auto"
                  label="Username*"
                  name="username"
                  class="background-white"
                  :rules="[
                    (v) =>
                      (!!v && v.trim().length > 0) || 'Username is required',
                  ]"
                  :error-messages="error.username"
                  @input="error && error.username ? delete error.username : ''"
                  v-model="driverFormDetails.username"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  outlined
                  hide-details="auto"
                  type="password"
                  name="password"
                  label="Password"
                  :required="formType == 'Add' ? true : false"
                  class="background-white"
                  :rules="
                    formType == 'Add'
                      ? [
                          (v) =>
                            (!!v && v.trim().length > 0) ||
                            'Password is required',
                        ]
                      : []
                  "
                  :error-messages="error.password"
                  @input="error && error.password ? delete error.password : ''"
                  v-model="driverFormDetails.password"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  outlined
                  hide-details="auto"
                  type="password"
                  label="Confirm Password"
                  :required="formType == 'Add' ? true : false"
                  class="background-white"
                  :rules="
                    formType == 'Add'
                      ? [
                          (v) =>
                            (!!v && v.trim().length > 0) ||
                            'Confirm password is required',
                        ].concat([
                          (v) =>
                            driverFormDetails.password ===
                              driverFormDetails.confirm_password ||
                            'Passwords do not match',
                        ])
                      : [
                          (v) =>
                            driverFormDetails.password ===
                              driverFormDetails.confirm_password ||
                            'Passwords do not match',
                        ]
                  "
                  :error-messages="error.confirm_password"
                  @input="
                    error && error.confirm_password
                      ? delete error.confirm_password
                      : ''
                  "
                  v-model="driverFormDetails.confirm_password"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  outlined
                  hide-details="auto"
                  label="Contact Number*"
                  type="number"
                  min="0"
                  name="contact_number"
                  class="background-white"
                  :rules="[(v) => !!v || 'Contact Number is required']"
                  :error-messages="error.contact_number"
                  @input="
                    error && error.contact_number
                      ? delete error.contact_number
                      : ''
                  "
                  v-model="driverFormDetails.contact_number"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <SelectField
                  outlined
                  hide-details="auto"
                  label="Project*"
                  :itemsList="allProjects"
                  name="project"
                  class=""
                  :rules="[(v) => !!v || 'Project is required']"
                  :error-messages="error.project"
                  v-model="driverFormDetails.project"
                  @change="
                    updateVehicleZoneList($event),
                      error && error.project ? delete error.project : ''
                  "
                  :menu-props="{ offsetY: true }"
                ></SelectField>
              </v-col>
              <v-col cols="6" lg="4">
                <SelectField
                  outlined
                  hide-details="auto"
                  label="Zone"
                  ref="zoneField"
                  :itemsList="allZones"
                  item-text="zone_name"
                  item-value="zone_name"
                  name="zone"
                  :no-data-text="
                    !driverFormDetails.project
                      ? 'Select Project First !'
                      : 'No data available.'
                  "
                  :error-messages="error.zone"
                  @change="error && error.zone ? delete error.zone : ''"
                  v-model="driverFormDetails.zone"
                  :menu-props="{ offsetY: true }"
                ></SelectField>
              </v-col>
              <v-col cols="6" lg="4">
                <SelectField
                  outlined
                  ref="vehicleAssigned"
                  hide-details="auto"
                  label="Vehicle Assigned"
                  :itemsList="allVehicles"
                  name="vehicle_assigned"
                  item-text="vehicle_plate_no"
                  item-value="vehicle_plate_no"
                  :no-data-text="
                    !driverFormDetails.project
                      ? 'Select Project First !'
                      : 'No data available.'
                  "
                  :error-messages="error.vehicle_assigned"
                  @change="
                    error && error.vehicle_assigned
                      ? delete error.vehicle_assigned
                      : ''
                  "
                  v-model="driverFormDetails.vehicle_assigned"
                  :menu-props="{ offsetY: true }"
                ></SelectField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  outlined
                  hide-details="auto"
                  type="time"
                  label="Shift Timings (Start)*"
                  :rules="[(v) => !!v || 'Shift start time is required']"
                  class="background-white"
                  name="shift_start"
                  :error-messages="error.shift_start"
                  v-model="driverFormDetails.shift_start"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="4">
                <InputField
                  outlined
                  hide-details="auto"
                  type="time"
                  label="Shift Timings (End)*"
                  class="background-white"
                  :rules="[
                    (v) => !!v || 'Shift end time is required',
                    (v) =>
                      (v &&
                        v.slice(0, 5) !==
                          driverFormDetails.shift_start.slice(0, 5)) ||
                      'Start and End Shift time cant be same',
                  ]"
                  name="shift_end"
                  :error-messages="error.shift_end"
                  @input="
                    error && error.shift_end ? delete error.shift_end : ''
                  "
                  v-model="driverFormDetails.shift_end"
                ></InputField>
              </v-col>

              <v-col cols="6" lg="4">
                <SelectField
                  :isSearchRequired="false"
                  outlined
                  hide-details="auto"
                  label="User Status"
                  name="is_active"
                  :itemsList="statusType"
                  :error-messages="error.is_active"
                  @change="
                    error && error.is_active ? delete error.is_active : ''
                  "
                  v-model="driverFormDetails.is_active"
                  :menu-props="{ offsetY: true }"
                  :isClearable="false"
                ></SelectField>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4" class="d-flex flex-column align-center">
            <v-img
              v-if="!driverFormDetails.profile_image"
              :src="driverImage"
              height="200px"
              width="200px"
              contain
            />
            <div v-else class="pos-rel mt-12">
              <v-btn
                fab
                x-small
                class="floating-btn-tr"
                @click="removeImage('profile')"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
              <v-img
                :src="driverFormDetails.profile_image"
                height="200px"
                width="200px"
                contain
              />
            </div>
            <v-btn
              v-if="!driverFormDetails.profile_image"
              small
              depressed
              class="primary"
              :loading="isProfilePhotoSelecting"
              @click="onButtonClick('profile')"
            >
              <v-icon small>mdi-attachment</v-icon>
              <span class="pl-2">Driver Photo</span>
            </v-btn>
            <input
              name="profile_image"
              ref="profilePhotoUploader"
              class="d-none"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              @change="onFileChanged($event, 'profile')"
            />
          </v-col>
          <v-col cols="12" lg="8">
            <v-row>
              <v-col cols="6" lg="3">
                <InputField
                  outlined
                  hide-details="auto"
                  label="License Number*"
                  class="background-white"
                  name="license_number"
                  :rules="[
                    (v) =>
                      (!!v && v.trim().length > 0) ||
                      'License number is required',
                  ]"
                  :error-messages="error.license_number"
                  @input="
                    error && error.license_number
                      ? delete error.license_number
                      : ''
                  "
                  v-model="driverFormDetails.license_number"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="3">
                <v-menu
                  ref="licenseExpiry"
                  v-model="DatePickerMenu1"
                  :close-on-content-click="false"
                  :return-value.sync="driverFormDetails.license_expiry"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <InputField
                      outlined
                      hide-details="auto"
                      label="License Expiry*"
                      class="background-white"
                      aria-autocomplete="false"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      name="license_expiry"
                      readonly
                      :rules="[(v) => !!v || 'License expiry is required']"
                      :error-messages="error.license_expiry"
                      @input="
                        error && error.license_expiry
                          ? delete error.license_expiry
                          : ''
                      "
                      v-model="driverFormDetails.license_expiry"
                    ></InputField>
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    reactive
                    :min="nowDate"
                    v-model="driverFormDetails.license_expiry"
                    @change="
                      $refs.licenseExpiry.save(
                        driverFormDetails.license_expiry
                      ),
                        error && error.license_expiry
                          ? delete error.license_expiry
                          : ''
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" lg="3">
                <InputField
                  outlined
                  hide-details="auto"
                  label="Nationality"
                  name="nationality"
                  class="background-white"
                  :error-messages="error.nationality"
                  @input="
                    error && error.nationality ? delete error.nationality : ''
                  "
                  v-model="driverFormDetails.nationality"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="3">
                <v-menu
                  ref="nationalIdExpiry"
                  v-model="DatePickerMenu2"
                  :close-on-content-click="false"
                  :return-value.sync="driverFormDetails.national_id_expiry"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <InputField
                      outlined
                      hide-details="auto"
                      label="National Id Expiry Date*"
                      prepend-inner-icon="mdi-calendar"
                      class="background-white"
                      aria-autocomplete="false"
                      name="national_id_expiry"
                      v-bind="attrs"
                      v-on="on"
                      :rules="[(v) => !!v || 'National ID Expiry is required']"
                      readonly
                      :error-messages="error.national_id_expiry"
                      v-model="driverFormDetails.national_id_expiry"
                    ></InputField>
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    reactive
                    :min="nowDate"
                    v-model="driverFormDetails.national_id_expiry"
                    @change="
                      $refs.nationalIdExpiry.save(
                        driverFormDetails.national_id_expiry
                      ),
                        error && error.national_id_expiry
                          ? delete error.national_id_expiry
                          : ''
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="8">
            <v-row>
              <v-col cols="6" lg="3">
                <InputField
                  outlined
                  hide-details="auto"
                  label="Health Card Number"
                  name="health_card_number"
                  class="background-white"
                  :error-messages="error.health_card_number"
                  @input="
                    error && error.health_card_number
                      ? delete error.health_card_number
                      : ''
                  "
                  v-model="driverFormDetails.health_card_number"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="3">
                <v-menu
                  ref="healthCardExpiry"
                  v-model="DatePickerMenu3"
                  :close-on-content-click="false"
                  :return-value.sync="driverFormDetails.health_card_expiry"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <InputField
                      outlined
                      hide-details="auto"
                      label="Health Card Expiry Date"
                      prepend-inner-icon="mdi-calendar"
                      class="background-white"
                      name="health_card_expiry"
                      aria-autocomplete="false"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      :error-messages="error.health_card_expiry"
                      v-model="driverFormDetails.health_card_expiry"
                    ></InputField>
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    reactive
                    :min="nowDate"
                    v-model="driverFormDetails.health_card_expiry"
                    @change="
                      $refs.healthCardExpiry.save(
                        driverFormDetails.health_card_expiry
                      ),
                        error && error.health_card_expiry
                          ? delete error.health_card_expiry
                          : ''
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" lg="3">
                <InputField
                  outlined
                  hide-details="auto"
                  label="Visa number"
                  class="background-white"
                  name="visa_number"
                  :error-messages="error.visa_number"
                  @input="
                    error && error.visa_number ? delete error.visa_number : ''
                  "
                  v-model="driverFormDetails.visa_number"
                ></InputField>
              </v-col>
              <v-col cols="6" lg="3">
                <v-menu
                  ref="visaExpiry"
                  v-model="DatePickerMenu4"
                  :close-on-content-click="false"
                  :return-value.sync="driverFormDetails.visa_expiry"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="auto"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <InputField
                      outlined
                      hide-details="auto"
                      label="Visa Expiry Date"
                      class="background-white"
                      name="visa_expiry"
                      aria-autocomplete="false"
                      prepend-inner-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      :error-messages="error.visa_expiry"
                      v-model="driverFormDetails.visa_expiry"
                    ></InputField>
                  </template>
                  <v-date-picker
                    no-title
                    scrollable
                    reactive
                    :min="nowDate"
                    v-model="driverFormDetails.visa_expiry"
                    @change="
                      $refs.visaExpiry.save(driverFormDetails.visa_expiry),
                        error && error.visa_expiry
                          ? delete error.visa_expiry
                          : ''
                    "
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4">
            <v-row>
              <v-col cols="6">
                <InputField
                  outlined
                  hide-details="auto"
                  type="number"
                  min="0"
                  step="01"
                  name="salary"
                  label="Salary"
                  class="background-white"
                  :error-messages="error.salary"
                  @input="error && error.salary ? delete error.salary : ''"
                  v-model="driverFormDetails.salary"
                ></InputField>
              </v-col>

              <v-col cols="6">
                <SelectField
                  :isSearchRequired="false"
                  outlined
                  hide-details="auto"
                  label="Service Type"
                  :itemsList="serviceType"
                  name="service_type"
                  :error-messages="error.service_type"
                  v-model="driverFormDetails.service_type"
                  :menu-props="{ offsetY: true }"
                  :isClearable="false"
                  @change="
                    error && error.service_type ? delete error.service_type : ''
                  "
                ></SelectField>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- NOte : this functionality used in future -->
        <!-- <v-row no-gutters>
            <v-col cols="12" class="mt-6 mb-8">
              <span class="font-weight-bold text-subtitle-1"> Tag </span>
              <hr />
            </v-col>
            <v-col cols="4">
              <SelectField
                outlined
                hide-details="auto"
                label="Tags"
                multiple="multiple"
                :itemsList="drivertagslist"
                item-text="title"
                item-value="value"
                class="background-white"
                name="tags"
                v-model="assigned_tags"
                @input="syncData($event, 'tags')"
                :menu-props="{ offsetY: true }"
              ></SelectField>
            </v-col>
          </v-row> -->

        <v-row no-gutters>
          <v-col cols="12" class="mt-6 mb-8">
            <span class="font-weight-bold text-subtitle-1">
              Upload Documents
            </span>
            <hr />
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="3">
                <SelectField
                  :isSearchRequired="false"
                  outlined
                  hide-details="auto"
                  label="Document Type"
                  :itemsList="documentType"
                  v-model="uploadDocumentType"
                  item-text="title"
                  item-value="value"
                  return-object
                  name="document_type"
                  :menu-props="{ offsetY: true }"
                ></SelectField>
              </v-col>
              <v-col cols="6">
                <v-file-input
                  ref="fileInput"
                  :clearable="true"
                  hide-details
                  type="file"
                  dense
                  :disabled="uploadDocumentType ? false : true"
                  v-model="uploadFiles"
                  label="Upload Document"
                  prepend-inner-icon="mdi-attachment mdi-rotate-90"
                  prepend-icon=""
                  accept="image/*, .pdf"
                  outlined
                  @change="getFileData($event)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12 pt-8 pb-14">
            <div>
              <div
                class="mt-0 mb-4 pl-4"
                v-show="
                  driverFormDetails.driver_documents &&
                  driverFormDetails.driver_documents.length
                "
              >
                <span class="font-weight-bold text-subtitle-1"> Uploaded </span>
              </div>

              <v-card
                elevation="0"
                outlined
                v-for="(data, i) in driverFormDetails.driver_documents"
                :key="i"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="11">
                      <a :href="data.document" target="_black">
                        <h4>{{ data.document_type }}</h4>
                      </a>
                    </v-col>
                    <v-col cols="1">
                      <v-icon
                        small
                        color="red"
                        @click="deleteDocument(data.id, i)"
                        >mdi-close</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>

            <div
              class="mt-5 mb-4 pl-4"
              v-show="documentList && documentList.length"
            >
              <span class="font-weight-bold text-subtitle-1"> New Upload </span>
            </div>

            <v-card
              elevation="0"
              outlined
              v-for="(data, j) in documentList"
              :key="j"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="7">
                    <a :href="generateUrl(data.file)" target="_black">
                      <h4>{{ data.document_name }}</h4>
                    </a>
                  </v-col>
                  <v-col cols="2">
                    <h4>{{ getSize(data.file.size) }}</h4>
                  </v-col>
                  <v-col cols="2"> </v-col>
                  <v-col cols="1">
                    <v-icon small color="primary" @click="removeFile(j)"
                      >mdi-close</v-icon
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        :disabled="!isValid"
        @click.prevent="submitDriverForm()"
        class="primary text-uppercase mr-3"
      >
        <span>Submit</span>
      </v-btn>
      <v-btn
        type="reset"
        v-if="formType == 'Add'"
        class="primary text-uppercase"
        @click="resetForm()"
      >
        <span>Reset</span>
      </v-btn>
    </template>
  </BaseDialogForm>
</template>

<script>
import defaultDriverImage from "@/assets/user.png";
import { bus } from "@/main.js";
import BaseDialogForm from "@/components/LayoutComponents/BaseDialogForm.vue";
import SelectField from "@/components/BaseFormComponents/SelectField.vue";
export default {
  components: {
    BaseDialogForm,
    SelectField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      default: "Add",
    },
  },
  data() {
    return {
      // Form handling varibles
      driverFormDetails: {},
      nonFieldError: [],
      allProjects: [],
      documentList: [],
      allZones: [],
      allVehicles: [],
      uploadFiles: null,
      uploadDocumentType: null,
      formCount: 0,
      error: {
        user: {},
      },
      documentType: [
        {
          title: "Licence",
          value: "license_photo",
        },
        {
          title: "Food safety certificate ",
          value: "food_safety_permits",
        },
        {
          title: "Emirates Id ",
          value: "emirates_id",
        },
        {
          title: "Vaccination ID",
          value: "vaccination_card",
        },
        {
          title: "Health ID ",
          value: "health_card",
        },
        {
          title: "Visa ID",
          value: "visa",
        },
        {
          title: "National Id Card ",
          value: "national_id_card",
        },
        {
          title: "Other Documents",
          value: "other",
        },
      ],
      driverImage: defaultDriverImage,
      isValid: false,
      // loader variable
      isProfilePhotoSelecting: false,

      // image removal flag varibles for edit
      hasProfileImage: false,
      // date picker variables
      nowDate: new Date().toISOString().slice(0, 10),
      date: null,
      DatePickerMenu1: false,
      DatePickerMenu2: false,
      DatePickerMenu3: false,
      DatePickerMenu4: false,
      serviceType: ["B2B", "B2C"],
      statusType: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Deactivate",
          value: false,
        },
      ],
    };
  },
  watch: {
    openDriverDialog(val) {
      if (val) {
        this.getAllProjects();
      } else {
        this.resetForm();
      }
    },
  },

  computed: {
    openDriverDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getAllProjects() {
      this.$api.projects
        .getProjectList({ limit: "all" })
        .then((res) => {
          this.allProjects = res.data.results.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    removeFile(index) {
      this.documentList.splice(index, 1);
    },
    imageFileCheck(fileName) {
      let extension = fileName.slice(
        (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1
      );
      if (
        extension == "png" ||
        extension == "jpeg" ||
        extension == "jpg" ||
        extension == "pdf"
      ) {
        return true;
      } else {
        bus.$emit("showToastMessage", {
          message: `Select file(s) are not supported.Support file Types: PNG, JPEG, JPG, PDF `,
          color: "error",
        });
        return false;
      }
    },
    getFileData(fileInput) {
      if (fileInput && fileInput.name && !this.imageFileCheck(fileInput.name)) {
        this.$refs.fileInput.reset();
        this.uploadDocumentType = null;
        return false;
      }
      if (fileInput) {
        this.documentList.push({
          file: fileInput,
          document_type: this.uploadDocumentType.value,
          document_name: this.uploadDocumentType.title,
        });
        this.$refs.fileInput.reset();
        this.uploadDocumentType = null;

        this.scrollTobottom();
      }
    },
    scrollTobottom() {
      setTimeout(() => {
        let elmnt = document.getElementById("DriverFormAdd");
        elmnt.scrollIntoView({ block: "end", behavior: "smooth" });
        // this.$refs.DriverFormAdd.scrollTo({
        //   top: this.$refs.DriverFormAdd.scrollHeight,
        //   behavior: "smooth",
        // });
      }, 100);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    uploadDocument(id = null) {
      this.documentList.forEach((details, index) => {
        let payload = {
          id: null,
          data: null,
        };
        payload.id = id;
        let newForm = new FormData();
        newForm.append("document_type", details.document_type);
        newForm.append("document", details.file);
        payload.data = newForm;
        this.$api.drivers
          .uploadDocument(payload)
          .then((response) => {
            bus.$emit("showToastMessage", {
              message: "Document Uploaded Successfully!",
              color: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    deleteDocument(id, index) {
      this.$api.drivers
        .deleteDocument(id)
        .then((response) => {
          this.driverFormDetails.driver_documents.splice(index, 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateVehicleZoneList(project) {
      if (project) {
        this.$api.vehicles
          .getVehicleList({ project_id: project, limit: "all" })
          .then((result) => {
            this.allVehicles = result.data.results;
            this.$forceUpdate();
          })
          .catch((err) => {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          });
        this.$api.zone
          .getZonesList({ project_id: project, limit: "all" })
          .then((result) => {
            this.allZones = result.data.results;
            this.$forceUpdate();
          })
          .catch((err) => {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          });
      }
    },
    closeDialog() {
      this.resetForm();
      this.openDriverDialog = false;
    },
    onButtonClick(buttonType) {
      if (buttonType == "profile") {
        this.isProfilePhotoSelecting = true;
        window.addEventListener(
          "focus",
          () => {
            this.isProfilePhotoSelecting = false;
          },
          { once: true }
        );
        this.$refs.profilePhotoUploader.click();
      }
    },
    onFileChanged(e, input_type) {
      const selectedFile = e.target.files[0];
      if (
        selectedFile.type.toLowerCase().indexOf("png") == -1 &&
        selectedFile.type.toLowerCase().indexOf("jpg") == -1 &&
        selectedFile.type.toLowerCase().indexOf("jpeg") == -1
      ) {
        alert("File type not supported \nSupported Types: PNG, JPG, JPEG");
        return null;
      }
      if (input_type == "profile" && selectedFile) {
        const url = URL.createObjectURL(selectedFile);
        this.hasProfileImage = true;
        this.driverFormDetails.update_profile_image = true;
        this.driverFormDetails.profile_image = url;
      }
      this.$forceUpdate();
    },
    removeImage(buttonType) {
      this.hasProfileImage = false;
      let obj = { ...this.driverFormDetails };
      obj.update_profile_image = true;
      obj.profile_image = null;
      this.$refs.profilePhotoUploader.type = "text";
      this.$refs.profilePhotoUploader.type = "file";
      this.driverFormDetails = obj;
    },
    getDriverDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.drivers
        .getDriverObject(id)
        .then((res) => {
          this.formCount++;
          this.driverFormDetails = res.data;
          setTimeout(() => {
            this.updateVehicleZoneList(this.driverFormDetails.project);
          }, 100);
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.log(err);
          bus.$emit("showLoader", false);
        });
    },
    submitDriverForm() {
      const form = document.getElementById("DriverFormAdd");
      let postData = new FormData(form);
      postData = this.checkFields(postData);

      // let payload = {
      //   isBulkupload: true,
      //   data: postData,
      // };
      bus.$emit("showLoader", true);
      if (this.formType == "Add") {
        this.$api.drivers
          .createDriverObject(postData)
          .then((result) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Driver added Successfully!",
              color: "success",
            });
            this.uploadDocument(result.data.id);
            this.openDriverDialog = false;
            this.$emit("refreshList");
            this.resetForm();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      } else {
        postData.append(
          "update_profile_image",
          this.driverFormDetails && this.driverFormDetails.update_profile_image
            ? this.driverFormDetails.update_profile_image
            : false
        );
        let payload = {
          id: this.driverFormDetails.id,
          data: postData,
        };
        this.$api.drivers
          .updateDriverObject(payload)
          .then((result) => {
            bus.$emit("showLoader", false);
            bus.$emit("showToastMessage", {
              message: "Driver Updated Successfully!",
              color: "success",
            });

            this.uploadDocument(this.driverFormDetails.id);
            this.openDriverDialog = false;
            this.$emit("refreshList");
            this.resetForm();
          })
          .catch((err) => {
            bus.$emit("showLoader", false);
            if (err.data.non_field_errors) {
              this.nonFieldError = err.data.non_field_errors;
            }
            this.error = err.data;
          });
      }
    },
    checkFields(formData) {
      if (formData.has("password") && formData.get("password").trim() == "") {
        formData.delete("password");
      }
      return formData;
    },
    resetForm() {
      this.driverFormDetails = {
        first_name: null,
        last_name: null,
        username: null,
        profile_image: null,
        contact_number: null,
        project: null,
        license_number: null,
        license_expiry: null,
        nationality: null,
        national_id: null,
        national_id_expiry: null,
        shift_start: null,
        shift_end: null,
        is_active: null,
        tags: null,
      };
      this.documentList = [];
      this.uploadFiles = null;
      this.uploadDocumentType = null;

      this.$refs.DriverFormAdd.resetValidation();
    },
  },
  mounted() {
    if (
      this.driverFormDetails &&
      this.driverFormDetails.project &&
      this.driverFormDetails.project != null
    ) {
      this.updateVehicleZoneList(this.driverFormDetails.project);
    }
  },
};
</script>

<style>
</style>