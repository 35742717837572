<template>
  <v-text-field
    hide-details="auto"
    autocomplete="new-password"
    dense
    v-on="$listeners"
    v-bind="$attrs"
    :label="`${label}${required ? '*' : ''}`"
    :rules="[
      ...(required ? [(val) => !!val || `${label} is required`] : []),
      ...rules,
    ]"
    outlined
  ></v-text-field>
</template>

<script>
export default {
  name: "InputTag",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    label: String,
    rules: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
