<template>
  <div
    @click="$emit('selectOrder')"
    class="
      mb-4
      border-x-light_grey
      border-y-light_grey
      full-width
      background-white
      rounded-lg
    "
    :class="
      selectedOrders != null && selectedOrders.id == order.id
        ? 'selected-card'
        : null
    "
  >
    <div class="pa-2 light_grey rounded-t-lg">
      <v-row dense>
        <v-col cols="6" lg="7">
          <v-row class="d-flex align-center">
            <v-col cols="2">
              <v-checkbox
                class="ma-0 pa-0 float-left"
                hide-details
                color="primary"
                :input-value="isSelectedOrder(order.id)"
                @click.stop.prevent
                @change="$emit('orderSelected', $event, order.id)"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" lg="8">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text-caption details-sub-title"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <h4
                      class="text-subtitle-2 font-weight-bold details-title"
                      v-if="order.reference_number"
                    >
                      {{ order.reference_number }}
                    </h4>
                  </span>
                </template>
                <span class="text-capitalize"> Reference Number </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="6" lg="5" class="d-flex justify-end align-start pr-3">
          <div v-if="order.status">
            <span
              class="text-caption text-capitalize"
              v-if="order.status == 'pickedup'"
            >
              Shipped
            </span>
            <span
              class="text-caption text-capitalize"
              v-else-if="order.status == 'successful'"
            >
              delivered
            </span>
            <span
              class="text-caption text-capitalize"
              v-else-if="order.status == 'failed'"
            >
              returned
            </span>
            <span v-else class="caption text-capitalize">
              {{ order.status.replace(/\_/g, " ") }}
            </span>
            <v-avatar
              :class="`${order.status}`"
              size="16"
              class="ml-1"
            ></v-avatar>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="pa-2">
      <v-row no-gutters>
        <v-col cols="6" class="text-caption">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                {{ order.customer_name }}
              </span>
            </template>
            <span>Customer Name</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" class="text-caption d-flex justify-end">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <b>{{ order.contact_number }}</b>
              </span>
            </template>
            <span>Customer Contact Number</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="order && order.invoice_number">
        <v-col cols="6" class="text-caption"> Invoice Number </v-col>
        <v-col cols="6" class="text-caption d-flex justify-end">
          <b>{{ order.invoice_number }}</b>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6" class="text-caption d-flex align-center">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs" class="pr-1">
                <v-icon small>mdi-archive</v-icon>
                <span>{{ order.no_of_items }}</span>
              </span>
            </template>
            <span>Quantity</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <v-icon small>mdi-weight</v-icon>
                <span>{{ order.total_cbm }}</span>
              </span>
            </template>
            <span>Total CBM</span>
          </v-tooltip>
        </v-col>
        <v-col cols="5" class="d-flex justify-end text-caption">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <b>{{ order.execution_date }}</b>
              </span>
            </template>
            <span>Order Date</span>
          </v-tooltip>
          <span><b>&nbsp;|&nbsp;</b></span>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs" class="text-capitalize">
                <b>{{ order.order_type }}</b>
              </span>
            </template>
            <span>Order Type</span>
          </v-tooltip>
        </v-col>
        <v-col cols="1" class="d-flex text-caption">
          <v-tooltip
            right
            v-if="
              order &&
              order.warnings != null &&
              Object.keys(order.warnings).length > 0
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="cf-card-text font-weight-bold"
              >
                <v-icon
                  class="red--text set-icon-position"
                  @click.stop.prevent
                  @click="$emit('openReasonDialog', order.warnings)"
                >
                  mdi-alert
                </v-icon>
              </span>
            </template>
            <span>Order Warning</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      required: true,
    },
    selectedOrders: {
      required: true,
    },
  },
  methods: {
    isSelectedOrder(orderId) {
      return this.selectedOrders.indexOf(orderId) > -1;
    },
  },
};
</script>

<style lang="scss" scoped>
// .set-icon-position {
//   position: absolute;
//   bottom: 0;
//   right: 0;
// }
</style>