import OrderHistoryIndex from "../pages/admin/order_history/index.vue";

const OrderHistory = [
  {
    path: "order_history",
    name: "order_history",
    component: OrderHistoryIndex,
  },
];

export default OrderHistory;
