<template>
  <v-tooltip
    right
    color="error"
    v-if="params.data.warning && Object.keys(params.data.warning).length > 0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="red" v-bind="attrs" v-on="on"> mdi-information </v-icon>
    </template>
    <span v-if="params && params.data && params.data.warning">
      <span v-if="'order_items' in params.data.warning">
        <span
          v-for="(item, index) in params.data.warning.order_items"
          :key="index"
        >
          <span v-if="Object.keys(item).length > 0">
            <span
              class="text-capitalize"
              v-for="[k, v, i] in Object.entries(item)"
              :key="i"
            >
              {{ k.replaceAll("_", " ") }} - {{ v[0] }} <br />
            </span>
          </span>
        </span>
      </span>
      <span v-else>
        <span
          class="text-capitalize"
          v-for="[key, value, index] in Object.entries(params.data.warning)"
          :key="index"
        >
          {{ key.replaceAll("_", " ") }} - {{ value }} <br />
        </span>
      </span>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
  },
};
</script>
