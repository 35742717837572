<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 edit white--text"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.driver && userPermissions.driver.change"
          @click="openDriverForm()"
          depressed
        >
          <v-icon small class="ma-0">mdi-pencil</v-icon>
        </v-btn>
      </template>
      <span>Edit Details</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="mr-2 primary"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.driver && userPermissions.driver.change"
          @click="openChangeBranchDialog()"
          depressed
        >
          <v-icon small class="ma-0">mdi-alpha-p-box</v-icon>
        </v-btn>
      </template>
      <span>Change Project</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="grey white--text"
          small
          v-bind="attrs"
          v-on="on"
          v-if="userPermissions.driver && userPermissions.driver.change"
          @click="viewLogs"
          depressed
        >
          <v-icon small class="ma-0">mdi-alpha-l-box</v-icon>
        </v-btn>
      </template>
      <span>Show Logs</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      openDriverDetails: null,
      userPermissions: JSON.parse(localStorage.getItem("permissions")),
    };
  },
  computed: {},
  methods: {
    async btnClickedHandler() {
      this.openDriverDetails = true;
    },
    openDriverForm() {
      this.params.context.parentComponent.editDriver(this.params.data.id);
      // this.$store.dispatch("customer/customerAddress/GET_TAG_LIST");
    },

    openChangeBranchDialog() {
      this.params.context.parentComponent.openChangeBranch(this.params.data.id);
    },

    viewLogs() {
      this.params.context.parentComponent.viewLogsHandler({
        driver_id: this.params.data.id,
      });
    },
  },
  // beforeCreate() {
  //   if (encryptLocal == null) {
  //     makeInstance(localStorage.getItem("user"));
  //   }
  // },
};
</script>