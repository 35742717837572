<template>
  <v-dialog
    v-model="openTripViewDialog"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="pa-4">
      <v-card-title>
        <v-row no-gutters>
          <v-col cols="6" class="d-flex align-center justify-start">
            <span
              class="
                text-lg-subtitle-1 text-xl-h6 text-uppercase
                font-weight-black
                primary--text
              "
            >
              Planned Trips
            </span>
          </v-col>
          <v-col cols="6" class="d-flex align-center justify-end">
            <v-btn class="float-right" @click="closeDialog()" small fab icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col
            v-if="Object.keys(trip).length > 0"
            cols="12"
            class="pt-6 pb-4"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-left">
                <v-row no-gutters>
                  <v-col cols="2" class="text-center">
                    <span class="font-weight-bold primary--text">
                      {{ trip.cargos_per.chilled }}%
                    </span>
                    <br />
                    <span class="text-caption primary--text"> Chilled </span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <span class="font-weight-bold primary--text">
                      {{ trip.cargos_per.dry }}%
                    </span>
                    <br />
                    <span class="text-caption primary--text"> Dry </span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <span class="font-weight-bold primary--text">
                      {{ trip.cargos_per.frozen }}%
                    </span>
                    <br />
                    <span class="text-caption primary--text"> Frozen </span>
                  </v-col>
                  <v-col cols="2" class="text-center" v-if="trip.mass !== null">
                    <span class="font-weight-bold primary--text">
                      {{ trip.mass }}
                    </span>
                    <br />
                    <span class="text-caption primary--text"> Weight </span>
                  </v-col>
                  <v-col
                    cols="2"
                    class="text-center"
                    v-if="trip.volume !== null"
                  >
                    <span class="font-weight-bold primary--text">
                      {{ trip.volume }}
                    </span>
                    <br />
                    <span class="text-caption primary--text"> Volume </span>
                  </v-col>
                  <v-col cols="2" class="text-center">
                    <span class="font-weight-bold primary--text">
                      {{ trip.orders_count }}
                    </span>
                    <br />
                    <span class="text-caption primary--text">Orders</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" class="text-center">
                <span class="font-weight-bold primary--text">
                  {{ trip.distance }}
                </span>
                <br />
                <span class="text-caption primary--text"> Distance</span>
              </v-col>
              <v-col cols="2" class="text-center">
                <span class="font-weight-bold primary--text">
                  {{ trip.travelling_time }}
                </span>
                <br />
                <span class="text-caption primary--text">Travelling Time</span>
              </v-col>
              <v-col cols="2" class="text-center">
                <span class="font-weight-bold primary--text">
                  {{ trip.total_time }}
                </span>
                <br />
                <span class="text-caption primary--text"> Total Time</span>
              </v-col>
              <v-col cols="2" class="text-center">
                <span class="font-weight-bold primary--text">
                  {{ trip.planned_locations_count }}
                </span>
                <br />
                <span class="text-caption primary--text"> Drop Points</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <Gmap
            mapWidth="100%"
            mapHeight="72vh"
            ref="viewTrip"
            :dialog-status="openTripViewDialog"
            :clusterMarkers="true"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import Gmap from "@/components/common/Gmap.vue";
export default {
  components: {
    Gmap,
  },
  props: {
    value: Boolean,
    trip: {
      type: Object,
      default: () => {},
    },
    locationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // servicable_arae: {
      //   type: "MultiPolygon",
      //   coordinates: [
      //     [
      //       [
      //         [51.56260058860779, 24.44275533728144],
      //         [51.50766894798279, 24.0420558856042],
      //         [52.62827441673279, 22.84262237226834],
      //         [55.21006152610779, 22.56898104980554],
      //         [55.36387011985779, 22.86287030702982],
      //         [55.33091113548279, 23.14602369928816],
      //         [55.68247363548279, 23.68035372537902],
      //         [55.56162402610779, 24.00191670800093],
      //         [56.13291308860779, 24.07215203988581],
      //         [55.92417285423279, 24.37272414901716],
      //         [55.88022754173279, 24.61266903120283],
      //         [55.94614551048279, 24.77237724469655],
      //         [56.12192676048279, 24.65261526642286],
      //         [56.52842090110779, 24.84218512734971],
      //         [56.63828418235779, 25.14091502307581],
      //         [56.47348926048279, 25.34959246925259],
      //         [56.77012011985779, 25.54799874581566],
      //         [56.42954394798279, 25.63717471217785],
      //         [56.57236621360779, 25.99321132122451],
      //         [56.57236621360779, 26.22011675959864],
      //         [56.13291308860779, 26.34817244965147],
      //         [55.91318652610779, 25.86476606081212],
      //         [55.49570605735779, 25.59754915679724],
      //         [55.21006152610779, 25.24032992871584],
      //         [54.78159472923279, 24.78235220567484],
      //         [54.26523730735779, 24.35270810772247],
      //         [53.60605761985779, 24.26259677701474],
      //         [52.94687793235779, 24.18244414648596],
      //         [52.19980761985779, 24.27261230707233],
      //         [51.68345019798279, 24.32267811109064],
      //         [51.56260058860779, 24.44275533728144],
      //       ],
      //     ],
      //   ],
      // },
      // Zones: {
      //   type: "FeatureCollection",
      //   features: [
      //     {
      //       type: "Feature",
      //       properties: {
      //         id: 35,
      //         project_name: "West Dubai",
      //         color: "blue",
      //       },
      //       geometry: {
      //         type: "Polygon",
      //         coordinates: [
      //           [
      //             [51.48308918457031, 24.51535472935417],
      //             [51.49407551269531, 23.94430327018608],
      //             [52.50481770019531, 22.70344591248594],
      //             [53.88909504394531, 22.44983592090712],
      //             [53.72430012207031, 23.80365491860333],
      //             [53.98797199707031, 24.21512057591497],
      //             [53.69134113769531, 24.03463923729682],
      //             [53.52654621582031, 24.36532671628751],
      //             [51.48308918457031, 24.51535472935417],
      //           ],
      //         ],
      //       },
      //     },
      //     {
      //       type: "Feature",
      //       properties: {
      //         id: 34,
      //         project_name: "Abu Dhabi",
      //         color: "blue",
      //       },
      //       geometry: {
      //         type: "Polygon",
      //         coordinates: [
      //           [
      //             [53.95089313964844, 24.25632759471829],
      //             [53.67623493652344, 23.73441281167224],
      //             [53.79708454589844, 22.63363089325172],
      //             [54.97262165527344, 22.48144370492662],
      //             [55.39010212402344, 23.03863791428898],
      //             [55.41207478027344, 23.10938981089342],
      //             [54.68697712402344, 24.96549013469239],
      //             [53.95089313964844, 24.46649802850386],
      //             [53.59933063964844, 24.19621488939236],
      //             [53.70919392089844, 24.05584174286559],
      //             [53.95089313964844, 24.25632759471829],
      //           ],
      //         ],
      //       },
      //     },
      //     {
      //       type: "Feature",
      //       properties: {
      //         id: 33,
      //         project_name: "PJ Dubai",
      //         color: "blue",
      //       },
      //       geometry: {
      //         type: "Polygon",
      //         coordinates: [
      //           [
      //             [54.76113483886719, 24.82838903674528],
      //             [55.32693073730469, 23.22294123544292],
      //             [56.54091999511719, 25.0823842172557],
      //             [56.42007038574219, 25.59374720118127],
      //             [56.54091999511719, 26.32470236565243],
      //             [56.25527546386719, 26.37884816506591],
      //             [55.71694538574219, 25.59870127160636],
      //             [55.35988972167969, 25.32592345233964],
      //             [54.76113483886719, 24.81343184864343],
      //             [54.76113483886719, 24.82838903674528],
      //           ],
      //         ],
      //       },
      //     },
      //   ],
      // },
    };
  },
  watch: {
    openTripViewDialog(value) {
      if (value) {
        let interval = setInterval(() => {
          if (this.$refs.viewTrip) {
            this.$refs.viewTrip.clearGeoJson();
            this.$refs.viewTrip.clearMarker();
            this.$refs.viewTrip.initMap();
            // this.$refs.viewTrip.loadArea(this.Zones);
            this.$refs.viewTrip.loadRoutes(
              this.locationData.driving_directions,
              this.locationData.order_details,
              this.locationData.warehouse_details
            );
            clearInterval(interval);
          }
        }, 100);
      }
    },
  },
  computed: {
    openTripViewDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.openTripViewDialog = false;
    },
  },
};
</script>
