<template>
  <ListLayout
    ref="listLayout"
    title="Vehicles"
    :tableData="vehiclesList"
    :tableHeader="tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getVehicleList"
    :tableScrollable="false"
    :isColumnShowVisibility="true"
  >
    <template #listAction>
      <v-row no-gutters>
        <v-col
          class="d-flex justify-end"
          v-if="permissions && permissions.vehicle && permissions.vehicle.add"
        >
          <v-btn small depressed class="primary mr-1" @click="addTag()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span class="mr-2">Add Tag</span>
          </v-btn>

          <v-btn small depressed class="primary mr-1" @click="addVehicle()">
            <v-icon small class="mr-1">mdi-plus</v-icon>
            <span class="mr-2">Add Vehicle</span>
          </v-btn>
          <!-- <v-btn
            small
            depressed
            class="primary mr-1"
            @click="vehicleBulkUpload = true"
          >
            <v-icon small class="mr-1">mdi-arrow-up-bold</v-icon>
            <span>Bulk Upload</span>
          </v-btn> -->
          <v-menu
            offset-y
            rounded="lg"
            elevation="0"
            transition="slide-y-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="
                  permissions &&
                  permissions.vehicle &&
                  (permissions.vehicle.add || permissions.vehicle.change)
                "
                small
                depressed
                v-bind="attrs"
                v-on="on"
                Dropdown
                class="primary mr-1"
              >
                <v-icon small class="mr-1">mdi-arrow-up-bold</v-icon>
                <span>Bulk Operations</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="showBulkDialog('vehicles')"
                v-if="
                  permissions && permissions.vehicle && permissions.vehicle.add
                "
              >
                <v-list-item-icon class="text-center d-flex align-center mx-0">
                  <v-icon class="primary--text" small
                    >mdi-table-arrow-up</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="ma-0">
                  <v-list-item-title class="text-capitalize text-center">
                    Bulk Upload
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                @click="showBulkDialog('vehicles_update')"
                v-if="
                  permissions &&
                  permissions.vehicle &&
                  permissions.vehicle.change
                "
              >
                <v-list-item-icon class="text-center d-flex align-center mx-0">
                  <v-icon class="primary--text" small
                    >mdi-microsoft-excel</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content class="ma-0">
                  <v-list-item-title class="text-capitalize text-center">
                    Bulk Update
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            small
            depressed
            class="primary"
            @click="showProjectListDialog = true"
          >
            <v-icon small class="mr-1">mdi-arrow-down-bold</v-icon>
            <span>Download All Data</span>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #leftFilterSlot>
      <v-col class="d-flex justify-start pa-0">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-badge
              bordered
              color="success"
              overlap
              small
              dot
              :value="appliedFilters && appliedFilters.length > 0"
            >
              <v-btn
                small
                fab
                depressed
                color="primary"
                class="rounded ml-2"
                @click="openVehicleFilterDialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="">mdi-filter</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>Vehicle Filters</span>
        </v-tooltip>
      </v-col>
    </template>
    <template #dialogs>
      <VehicleFilter
        ref="vehicleFilterDialog"
        v-model="openVehicleFilterDialog"
        @vehicleFilterChanged="$refs.listLayout.searchTriggered()"
      />
      <VehicleTags v-model="openVehicleTags" />
      <VehicleForm
        ref="vehicleForm"
        v-model="openVehicleForm"
        :formType="formType"
        @refreshList="$refs.listLayout.searchTriggered()"
      />
      <LogDialog
        :logsArray="vehicleLogs"
        title="Logs"
        v-model="openVehicleLogs"
      />
      <DownloadAllData parent="vehicles" v-model="showProjectListDialog" />
      <BulkUpload
        v-model="vehicleBulkUpload"
        :uploadTo="bulkDialogType"
        @refreshList="getVehicleList({ limit: 10, offset: 0 })"
      />
      <ChangeProject
        ref="changeBranch"
        v-model="openChangeBranchDialog"
        type="vehicle"
      />
    </template>
  </ListLayout>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable */
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import VehicleEdit from "@/components/common/aggrid/buttons/vehicleEdit.vue";
import VehicleChip from "@/components/common/aggrid/chip/vehicleTagChip.vue";
import { toTitleCase } from "@/utils/functions.js";
import VehicleForm from "@/components/vehicles/VehicleForm.vue";
import VehicleTags from "@/components/vehicles/VehicleTags.vue";
import LogDialog from "@/components/BaseComponents/LogDialog.vue";
import { bus } from "@/main.js";
import VehicleFilter from "@/components/vehicles/VehicleFilter.vue";
import { downloadBlobData } from "@/assets/utils.js";
import DownloadAllData from "@/components/common/DownloadAllData.vue";
import BulkUpload from "@/components/common/BulkUpload.vue";
import ChangeProject from "@/components/common/ChangeProject.vue";
export default {
  name: "vehicleIndex",
  components: {
    ListLayout,
    VehicleEdit,
    VehicleChip,
    VehicleForm,
    VehicleTags,
    LogDialog,
    VehicleFilter,
    DownloadAllData,
    BulkUpload,
    ChangeProject,
  },

  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      permissions: null,
      customFilter: {},
      vehiclesList: [],
      vehicleLogs: [],
      openVehicleLogs: false,
      openVehicleForm: false,
      openVehicleTags: false,
      openVehicleFilterDialog: false,
      showProjectListDialog: false,
      vehicleBulkUpload: false,
      openChangeBranchDialog: false,
      bulkDialogType: "",
      formType: "Add",
      headerSelected: [
        {
          headerName: "Vehicle No. Plate",
          field: "vehicle_plate_no",
          pinned: "left",
        },
        {
          headerName: "Tonnage Capacity",
          field: "tonnage_capacity",
        },
        {
          headerName: "CBM capacity",
          field: "cbm_capacity",
        },
        {
          headerName: "Fuel Type",
          field: "fuel_type",
          cellRenderer: (params) => {
            return toTitleCase(params.value);
          },
        },
        {
          headerName: "Project",
          field: "project_name",
        },
        {
          headerName: "Assigned Tags",
          field: "assigned_tags",
          hide: false,
          cellRendererFramework: "VehicleChip",
        },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: (params) => {
            return toTitleCase(
              params.data.status == "idle" ? "Active" : params.data.status
            );
          },
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      return [
        ...this.headerSelected,
        ...(this.permissions.vehicle &&
        (this.permissions.vehicle.change ||
          this.permissions.vehicle.view ||
          this.permissions.vehicle.delete)
          ? [
              {
                headerName: "Actions",
                width: 220,
                maxWidth: 220,
                minWidth: 220,
                field: "actions",
                pinned: "right",
                cellRendererFramework: "VehicleEdit",
              },
            ]
          : []),
      ];
    },
    context() {
      return { parentComponent: this };
    },
    appliedFilters() {
      return Object.keys(this.customFilter).map((filter) => {
        return filter.replace(/\_/, " ");
      });
    },
  },
  methods: {
    showBulkDialog(type) {
      this.bulkDialogType = type;
      this.vehicleBulkUpload = true;
    },
    downloadAllVehicles(params) {
      params = {
        ...params,
      };

      bus.$emit("showLoader", true);
      this.$api.vehicles
        .downloadVehicleSheet(params)
        .then((result) => {
          bus.$emit("showLoader", false);
          downloadBlobData(result.data, "Vehicles.xls");
          bus.$emit("showToastMessage", {
            message: "All Vehicles Data Downloaded",
            color: "success",
          });
          this.showProjectListDialog = false;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          if (err.message) {
            bus.$emit("showToastMessage", {
              message: err.message,
              color: "error",
            });
          } else {
            bus.$emit("showToastMessage", {
              message: "Something went wrong!",
              color: "error",
            });
          }
        });
    },
    getVehicleList(params = {}) {
      let filters = localStorage.getItem("vehicleFilters");
      if (!filters) {
        filters = {};
      }
      if (typeof filters == typeof "string") {
        filters = JSON.parse(filters);
      }
      this.customFilter = filters;

      params = {
        ...params,
        ...this.customFilter,
      };
      if (params && params.status && Array.isArray(params.status)) {
        params.status = params.status.join(",");
      }
      if (
        params &&
        params.project_id &&
        Array.isArray(params.project_id) &&
        params.project_id.length > 0
      ) {
        params.project_id = params.project_id.join(",");
      }
      if (params && params.tags && Array.isArray(params.tags)) {
        params.tags = params.tags.join(",");
      }
      if (params && params.ordering && params.sorting) {
        if (params.sorting == "descending") {
          params.ordering = "-" + params.ordering;
        }
      }
      bus.$emit("showLoader", true);
      this.$api.vehicles
        .getVehicleList(params)
        .then((res) => {
          bus.$emit("showLoader", false);
          this.vehiclesList = res.data.results;
          this.totalItems = res.count;
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
          console.log(err);
        });
    },

    addTag() {
      this.openVehicleTags = true;
    },

    addVehicle() {
      this.formType = "Add";
      this.openVehicleForm = true;
    },
    editVehicle(id) {
      this.openVehicleForm = true;
      this.$refs.vehicleForm.getVehicleDetails(id);
      this.formType = "Edit";
    },

    openChangeBranch(id) {
      this.$refs.changeBranch.openChangeBranch(id);
    },

    viewLogsHandler(params = {}) {
      params = {
        ...params,
        limit: "all",
        module_type: "vehicle",
      };
      this.$api.vehicles
        .getVehicleLogs(params)
        .then((res) => {
          this.vehicleLogs = res.data.results;
          this.openVehicleLogs = true;
        })
        .catch((err) => {
          bus.$emit("showToastMessage", {
            message: "Couln't fetch data",
            color: "error",
          });
        });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.headerSelected = value;
    },
  },
  // mounted() {
  //   this.vehicleFilterChanged();
  // },
  created() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"));
  },
};
</script>
