import VehiclesIndex from "../pages/admin/vehicles/index.vue";

const Vehicles = [
  {
    path: "vehicles",
    name: "vehicles",
    component: VehiclesIndex,
  },
];

export default Vehicles;
