import OrdersIndex from "../pages/admin/orders/index.vue";

const Orders = [
  {
    path: "orders",
    name: "orders",
    component: OrdersIndex,
  },
];

export default Orders;
