<template>
  <ListLayout
    title="Plan My Trip"
    ref="listTable"
    :tableData="
      !showOrderData
        ? recentlyPlannedTrips
        : currentTab === 0
        ? ordersData
        : currentTab === 1
        ? driverData
        : currentTab === 2
        ? ZoneData
        : []
    "
    :tableHeader="!showOrderData ? columnDefs : tableHeaders"
    :total="totalItems"
    :context="context"
    @getList="getAllPlanTripsData"
    :tableScrollable="false"
    localStorageKey="trip_planning_columns"
    :isColumnShowVisibility="false"
    :searchInput="false"
    :hasAfterTitle="true"
    :filtersOnRight="true"
  >
    <template #listAction>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-badge
            bordered
            color="success"
            overlap
            small
            dot
            :value="appliedFilters && appliedFilters.length > 0"
          >
            <v-btn
              small
              fab
              depressed
              color="primary"
              class="rounded"
              @click="openTripPlanFilterDialog = true"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small class="">mdi-filter</v-icon>
            </v-btn>
          </v-badge>
        </template>
        <span>Trip Planning Filters</span>
      </v-tooltip>
    </template>
    <template #afterTitle>
      <v-col class="py-0" cols="12" v-if="userPermissions.trip.add">
        <v-form ref="planTripForm" v-model="planTripForm">
          <v-row>
            <v-col cols="3">
              <InputField
                color="light_black"
                name="plan_name"
                v-model="tripDetail.plan_name"
                :rules="[
                  (v) =>
                    (!!v && v.trim().length > 0) || 'Plan Name is Required',
                ]"
                dense
                outlined
                hide-details="auto"
                label="Plan Name*"
                :error-messages="error.plan_name"
                @input="'plan_name' in error ? (error['plan_name'] = []) : null"
              >
              </InputField>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="datepicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <InputField
                    color="light_black"
                    name="plan_date"
                    v-model="tripDetail.date"
                    outlined
                    dense
                    label="Plan Date"
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="error.plan_date"
                    @input="delete error.plan_date"
                  ></InputField>
                </template>
                <v-date-picker
                  @change="validateField"
                  v-model="tripDetail.date"
                  @input="
                    datepicker = false;
                    error['plan_date'] = [];
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <SelectField
                ref="planTripProjectSelect"
                color="light_black"
                name="project"
                item-color="grey"
                :itemsList="projectList"
                v-model="tripDetail.project"
                outlined
                dense
                hide-details="auto"
                label="Project*"
                :rules="[(v) => !!v || 'Project is Required']"
                :menu-props="{ offsetY: true }"
                :error-messages="error.project"
                @change="validateField(), delete error.project"
                :isClearable="false"
              >
              </SelectField>
            </v-col>
            <v-col cols="3">
              <SelectField
                ref="plantripFormDriversSelect"
                color="light_black"
                item-color="grey"
                :itemsList="driverList"
                v-model="tripDetail.selected_drivers"
                name="drivers"
                outlined
                dense
                hide-details="auto"
                label="Driver"
                :menu-props="{ offsetY: true }"
                :error-messages="error.selected_drivers"
                @change="delete error.selected_drivers"
                :multiple="true"
              >
              </SelectField>
            </v-col>
            <v-col cols="1" class="d-flex justify-end align-center pa-0">
              <v-btn
                depressed
                :disabled="!planTripForm"
                class="primary"
                @click="planNewTrip"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </template>
    <template #rightFilterSlot>
      <v-col cols="12" class="d-flex pa-0">
        <v-col cols="8" class="">
          <div v-if="!showOrderData">
            <span class="font-weight-bold text-subtitle-1"> Recent Plans</span>
          </div>
          <v-row v-else class="d-flex align-center">
            <v-col cols="2">
              <span class="font-weight-bold text-subtitle-1"> Order List </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="d-flex justify-end py-2 pa-0">
          <div class="d-flex pa-0">
            <InputField
              outlined
              dense
              class=""
              v-model="searchPlanString"
              label="Search"
              clearable
              @input="getPlanList"
              hide-details
              v-show="!showOrderData"
              prepend-inner-icon="mdi-magnify"
            ></InputField>

            <v-btn
              class="primary mr-2"
              v-show="showOrderData"
              :disabled="
                (driverData && driverData.length == 0) ||
                (ZoneData && ZoneData.length == 0) ||
                (ordersData && ordersData.length == 0)
              "
              small
              @click="exportWarningOrders"
            >
              Export
            </v-btn>
          </div>
          <v-btn
            small
            depressed
            @click="
              showOrderData = !showOrderData;
              searchString = null;
            "
            class="primary"
            v-show="showOrderButton"
            :class="!showOrderData ? 'ma-1' : ''"
          >
            <span v-if="showOrderData">Show Recent Plans</span>
            <span v-else>Show Order List</span>
          </v-btn>
        </v-col>
      </v-col>
      <v-col cols="12" v-if="showOrderData">
        <v-row>
          <v-col cols="9">
            <v-tabs v-if="showOrderData" v-model="currentTab">
              <v-tab>
                <span class="mr-1">Orders </span>
                <span v-if="ordersData"> ({{ ordersData.length }})</span>
              </v-tab>
              <v-tab> Drivers </v-tab>
              <v-tab v-show="zoneConstraint"> Zones </v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="3" v-if="showOrderData" class="d-flex justify-end pa-0">
            <InputField
              class="my-3"
              outlined
              dense
              v-model="searchString"
              label="Search"
              clearable
              hide-details
              prepend-inner-icon="mdi-magnify"
            ></InputField>
          </v-col>
        </v-row>
      </v-col>
    </template>
    <template #dialogs>
      <PlanInfoOld
        ref="planInfo"
        v-model="openFailedInfoDialog"
        :info="system_warnings" />
      <WarningsInfo v-model="openWarningInfoDialog" :info="warnings" />
      <TripPlanningOldFilter
        ref="tripplanFilterDialog"
        v-model="openTripPlanFilterDialog"
        @tripplanFilterChanged="getAllPlanTripsData({ limit: 10, offset: 0 })"
    /></template>
  </ListLayout>
</template>

<script>
/* eslint-disable */
import XLSX from "xlsx";
import { toCapitalize, toTitleCase } from "@/assets/utils.js";
import { AgGridVue } from "ag-grid-vue";
import PlannedTripOldButton from "@/components/common/aggrid/buttons/PlannedTripOldButton.vue";
import PlanningTripButtonOld from "@/components/common/aggrid/buttons/PlanningTripOldButton.vue";
import { bus } from "@/main.js";
import Pagination from "@/components/BaseComponents/Pagination.vue";
import PlanInfoOld from "@/components/tripPlanningOld/PlanInfoOld.vue";
import TripPlanningNewFilter from "@/components/tripPlanningNew/TripPlanningNewFilter.vue";
import WarningsInfo from "@/components/tripPlanningNew/WarningsInfo.vue";
import ListLayout from "@/components/LayoutComponents/ListLayout.vue";
import TripPlanningOldFilter from "@/components/tripPlanningOld/TripPlanningOldFilter.vue";

export default {
  name: "planningTripNew",
  components: {
    AgGridVue,
    PlannedTripOldButton,
    PlanningTripButtonOld,
    Pagination,
    PlanInfoOld,
    TripPlanningNewFilter,
    WarningsInfo,
    ListLayout,
    TripPlanningOldFilter,
  },
  data() {
    return {
      sorting: {},
      itemsPerPage: 10,
      pageNo: 1,
      totalItems: 0,

      userPermissions: JSON.parse(localStorage.getItem("permissions")),

      openFailedInfoDialog: false,
      openWarningInfoDialog: false,
      openTripPlanFilterDialog: false,
      customFilter: {},
      zoneConstraint: false,
      system_warnings: {},
      warnings: {},
      currentTab: 0,
      driverData: [],
      ZoneData: [],
      ordersData: [],
      interval: null,
      searchPlanString: "",
      searchString: "",
      showOrderData: false,
      showOrderButton: false,
      error: {},
      driverSearchText: null,
      projectSearchText: null,
      planTripForm: false,
      datepicker: false,
      gridApi: null,
      tripDetail: {
        date: new Date().toISOString().substr(0, 10),
        plan_name: null,
        project: null,
        drivers: [],
      },
      columnDefs: [
        {
          headerName: "Plan Name",
          field: "plan_name",
          width: 300,
          pinned: "left",
        },
        {
          headerName: "Date",
          field: "plan_date",
        },
        {
          headerName: "Project",
          field: "project_name",
        },
        {
          headerName: "Status",
          field: "status",
          cellRenderer: (params) => {
            return toTitleCase(params.value);
          },
        },
        {
          headerName: "Progress",
          field: "progress",
          cellRenderer: (param) => {
            if (param.value > -1) {
              return param.value + "%";
            }
          },
        },
        {
          headerName: "Total Order",
          field: "orders_count",
          cellRenderer: (param) => {
            if (param.data.status == "PENDING") {
              return "N/A";
            } else {
              return param.value;
            }
          },
        },
        {
          headerName: "Total Distance",
          field: "distance",
          cellRenderer: (param) => {
            if (param.data.status !== "COMPLETED") {
              return "N/A";
            } else {
              return param.value / 1000 + " km";
            }
          },
        },
        {
          headerName: "Total Trips",
          field: "trips_count",
          cellRenderer: (param) => {
            if (param.data.status !== "COMPLETED") {
              return "N/A";
            } else {
              return param.value;
            }
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          width: 300,
          pinned: "right",
          cellRendererFramework: "PlannedTripOldButton",
        },
      ],
      orderColumnDefs: [],
      driverColumnDefs: [],
      zoneColumnDefs: [],
      filter: {},

      ordersMismatchWithDriverZone: [],
      projectList: [],
      unassignedDriverList: [],
      recentlyPlannedTrips: [],
      headerSelected: [],
    };
  },
  watch: {
    searchString(str) {
      if (str) {
        this.$refs.listTable.setQuickFilter(str);
      }
    },
  },
  computed: {
    tableHeaders() {
      if (this.currentTab == 0) {
        return [
          ...this.headerSelected,
          {
            headerName: "Reference Number",
            pinned: "left",
            field: "reference_number",
          },
          {
            headerName: "Customer Code",
            field: "customer_code",
            width: 200,
          },
          {
            headerName: "Customer Name",
            field: "customer_name",
            width: 400,
          },
          {
            headerName: "Address",
            field: "address",
          },
          {
            headerName: "Customer Contact No.",
            field: "contact_number",
          },
          {
            headerName: "No. Of Items",
            field: "no_of_items",
            width: 130,
          },
          {
            headerName: "Planned processing time",
            field: "planned_processing_time",
          },
          {
            headerName: "Order Date",
            field: "execution_date",
          },
          {
            headerName: "Actions",
            field: "actions",
            width: 300,
            pinned: "right",
            cellRendererFramework: "PlanningTripButtonOld",
          },
        ];
      }
      if (this.currentTab == 1) {
        return [
          ...this.headerSelected,
          {
            headerName: "Driver Name",
            pinned: "left",
            cellRenderer: (param) => {
              return param.data.first_name + " " + param.data.last_name;
            },
          },
          {
            headerName: "Vehicle",
            field: "vehicle_plate_number",
          },
          {
            headerName: "Contact Number",
            field: "contact_number",
          },
          {
            headerName: "Shift Start",
            field: "shift_start",
          },
          {
            headerName: "Shift End",
            field: "shift_end",
          },
          {
            headerName: "Zone",
            field: "zone",
          },
          {
            headerName: "Actions",
            field: "actions",
            width: 300,
            pinned: "right",
            cellRendererFramework: "PlanningTripButtonOld",
          },
        ];
      }
      if (this.currentTab == 2) {
        return [
          ...this.headerSelected,
          {
            headerName: "Zone Name",
            field: "zone_name",
            pinned: "left",
          },
          {
            headerName: "Zone Shift Start",
            field: "zone_details.zone_shift_start",
          },
          {
            headerName: "Zone Shift End",
            field: "zone_details.zone_shift_end",
          },
          {
            headerName: "Driver Count",
            field: "zone_details.driver_count",
          },
          {
            headerName: "Order Count",
            field: "zone_details.order_count",
          },
          {
            headerName: "Actions",
            field: "actions",
            width: 300,
            pinned: "right",
            cellRendererFramework: "PlanningTripButtonOld",
          },
        ];
      }
    },
    context() {
      return { parentComponent: this };
    },
    planedTripList() {
      return this.recentlyPlannedTrips;
    },
    driverList() {
      return this.driverData.map((driver) => {
        return {
          text: driver.first_name,
          value: driver.id,
        };
      });
    },
    checkedAll() {
      return this.tripDetail.drivers.length === this.driverList.length;
    },
    checkedSome() {
      return this.tripDetail.drivers.length > 0 && !this.checkedAll;
    },
    icon() {
      if (this.checkedAll) return "mdi-checkbox-marked";
      if (this.checkedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    appliedFilters() {
      return Object.keys(this.customFilter).map((filter) => {
        return filter.replace(/\_/, " ");
      });
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    headersChanged(value) {
      this.columnDefs = value;
    },
    viewPlanData(id) {
      this.$router.push(`/app/admin/trip_planning_old/view/${id}`);
    },
    getAllProjects() {
      this.$api.projects
        .getProjectOldList({ limit: "all" })
        .then((res) => {
          this.projectList = res.data.map((obj) => {
            return { text: obj.project_name, value: obj.project_id };
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    exportWarningOrders() {
      let orders = this.ordersData
        .filter((order) => order.warnings && order.warnings.length != 0)
        .map((order) => {
          return {
            reference_number: order.reference_number,
            reasons: order.warnings.toString(),
          };
        });
      let drivers = this.driverData
        .filter((driver) => driver.warnings && driver.warnings.length != 0)
        .map((driver) => {
          return {
            driver_name: driver.driver_name,
            reasons: driver.warnings.toString(),
          };
        });
      var zones = [];
      if (this.ZoneData) {
        zones = this.ZoneData.filter(
          (zone) =>
            zone &&
            zone.zone_details &&
            zone.zone_details.warnings &&
            zone.zone_details.warnings.length != 0
        ).map((zone) => {
          return {
            zone_name: zone.reference_number,
            reasons: zone.zone_details.warnings.toString(),
          };
        });
      }
      this.downlaodTripSheet({
        orders: orders,
        drivers: drivers,
        zones: zones,
      });
    },
    formatHeaders(data) {
      return data.map((e, i) => {
        let obj = {};
        Object.keys(e).forEach((header, j) => {
          let h = header.replace(/\_/g, " ");
          obj[toCapitalize(h)] = e[header];
        });
        return obj;
      });
    },
    downlaodTripSheet(warningList) {
      const orders_data = XLSX.utils.json_to_sheet(
        this.formatHeaders(warningList.orders)
      );
      const drivers_data = XLSX.utils.json_to_sheet(
        this.formatHeaders(warningList.drivers)
      );

      const wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "Warning Sheet excel file",
        Subject: "Warning Sheet Excel",
        Author: "chefme",
        CreatedDate: new Date(),
      };
      if (warningList.zones && warningList.zones.length > 0) {
        const zones_data = XLSX.utils.json_to_sheet(
          this.formatHeaders(warningList.zones)
        );
        XLSX.utils.book_append_sheet(wb, zones_data, "Zones");
      }
      XLSX.utils.book_append_sheet(wb, orders_data, "Orders");
      XLSX.utils.book_append_sheet(wb, drivers_data, "Drivers");
      XLSX.writeFile(wb, "order-driver-zone-warnings.xlsx");
    },
    openWarningDialog(data) {
      this.warnings = data;
      this.openWarningInfoDialog = true;
    },
    openPlanInformationDialog(id) {
      this.openFailedInfoDialog = true;
      this.$refs.planInfo.getPlanInformation(id);
    },
    deleteRecentPlans(id) {
      if (confirm("Do You really want to delete this Trip Plan ?")) {
        this.$api.tripPlanningOld
          .deletePlannedTrip(id)
          .then((result) => {
            bus.$emit("showToastMessage", {
              message: "Plan Deleted successfully",
              color: "success",
            });
            this.getAllPlanTripsData({ limit: 10, offset: 0 });
          })
          .catch((err) => {
            bus.$emit("showToastMessage", {
              message: "Plan not delete",
              color: "error",
            });
          });
      }
    },

    selectItem(item) {
      if (this.tripDetail.drivers.indexOf(item.value) > -1) {
        this.tripDetail.drivers.splice(
          this.tripDetail.drivers.indexOf(item.value),
          1
        );
      } else {
        this.tripDetail.drivers.push(item.value);
      }
    },
    searchSelect(textToMatch, searchString) {
      if (searchString == null || searchString.trim().length == 0) {
        return true;
      } else {
        return (
          textToMatch.toLowerCase().indexOf(searchString.toLowerCase()) > -1
        );
      }
    },
    validateField(e) {
      this.error["project"] = [];

      if (this.tripDetail.date && this.tripDetail.project) {
        this.showOrderData = false;
        let driverParams = {
          project_id: this.tripDetail.project,
          limit: "all",
          is_active: true,
        };
        let orderParams = {
          project_id: this.tripDetail.project,
          date: this.tripDetail.date,
        };

        this.getUnassignedOrderList(orderParams);
        this.getDriverLists(driverParams);
      }
    },
    getUnassignedOrderList(params = {}) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.tripPlanningOld
        .getUnassignedOrders(params)
        .then((res) => {
          this.ordersMismatchWithDriverZone =
            res.data.orders_mismatch_with_driver_zone;
          this.ordersData = res.data.orders;

          this.ZoneData = res.data.zones;
          this.showOrderData = true;
          this.zoneConstraint = res.data.zone_constraint;
          this.showOrderButton = true;
          if (
            this.ordersMismatchWithDriverZone &&
            this.ordersMismatchWithDriverZone.length > 0
          ) {
            this.getProjectDetails(this.tripDetail.project);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getDriverLists(params) {
      params = {
        ...params,
        limit: "all",
      };
      this.$api.drivers
        .getDriverOldList(params)
        .then((res) => {
          this.driverData = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    clearForm() {
      this.tripDetail = {
        date: new Date().toISOString().substr(0, 10),
        plan_name: null,
        project: null,
        drivers: [],
      };
      this.driverData = [];
      this.ZoneData = [];
      this.ordersData = [];
      this.showOrderData = false;
      this.showOrderButton = false;
      this.$refs.planTripForm.reset();
    },
    planNewTrip() {
      let details = {
        plan_name: this.tripDetail.plan_name,
        plan_date: this.tripDetail.date,
        selected_drivers: this.tripDetail.selected_drivers.join(","),
        project: this.tripDetail.project,
      };
      bus.$emit("showLoader", true);
      this.$api.tripPlanningOld
        .planNewTrip(details)
        .then((res) => {
          bus.$emit("showLoader", false);

          this.getAllPlanTripsData({ limit: 10, offset: 0 });
          this.clearForm();
        })
        .catch((err) => {
          this.error = err.data;
          bus.$emit("showLoader", false);
        });
    },
    getPlanList() {
      this.filter.search = this.searchPlanString;
      this.getAllPlanTripsData(this.filter);
    },
    getAllPlanTripsData(params = {}) {
      let filters = localStorage.getItem("tripplanOldFilters");
      if (!filters) {
        this.customFilter = {};
      } else {
        if (typeof filters == typeof "string") {
          this.customFilter = JSON.parse(filters);
        }
      }
      params = {
        ...params,
        ...this.filter,
        ...this.customFilter,
      };
      if (params && "status" in params && Array.isArray(params.status)) {
        params.status = params.status.join(",");
      }
      if (params && "project" in params && Array.isArray(params.project)) {
        params.project = params.project.join(",");
      }
      if (params && "ordering" in params && "sorting" in params) {
        if (params.sorting == "descending") {
          params.ordering = "-" + params.ordering;
        }
      }
      bus.$emit("showLoader", true);
      this.$api.tripPlanningOld
        .getTripPlans(params)
        .then((res) => {
          this.recentlyPlannedTrips = res.data.results;
          this.totalItems = res.count;
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", false);
        });
    },
    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.checkedAll) {
          this.tripDetail.drivers = [];
        } else {
          this.tripDetail.drivers = this.driverList
            .map((driver) => {
              return driver.value;
            })
            .slice();
        }
      });
    },
    getProjectDetails(id) {
      bus.$emit("showLoader", true);
      this.$api.projects
        .getProjectObject(id)
        .then((result) => {
          this.$refs.projectForm.loadServisableArea(
            result.serviceable_area.coordinates
          );
          bus.$emit("showLoader", false);
        })
        .catch((err) => {
          bus.$emit("showLoader", true);

          // bus.$emit("showToastMessage",{
          //   content: "Couln't fetch data",
          //   color: "error",
          // });
        });
    },
  },
  mounted() {
    this.getAllProjects();
    this.interval = setInterval(() => {
      if (!this.filter.search) {
        delete this.filter["search"];
      }
    }, 30000);
  },
};
</script>

<style>
</style>