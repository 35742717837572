<template>
  <GeneratedReport generatedReportType="vehicle" />
</template>

<script>
import GeneratedReport from "@/components/reports/GeneratedReport.vue";
export default {
  name: "vehicle-Report",
  components: {
    GeneratedReport,
  },
};
</script>
